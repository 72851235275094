import { ViewEditDeleteActionComponentForDiscount } from "../../Components/Reports/Operational Reports/ActionComponentForDiscountReports";
import { operationalReportsForDiscountTable } from "../../functions/admin/Reports/Reports";

export const discountReports = {
  headers: [
    {
      name: "Registered Date",
      apiKey: "created_at",
      headerClass: "w-[100px] text-start",
      className: "w-[100px] text-start",
    },
    {
      name: "Student Name",
      apiKey: "student_name",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Student Number",
      apiKey: "student_phone_number",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Course",
      apiKey: "course_name",
      headerClass: "w-[140px] text-center px-2",
      className: "w-[140px] text-center px-2",
    },
    {
      name: "Branch",
      apiKey: "branch_name",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center break-words px-2",
    },
    {
      name: "Discount",
      apiKey: "discount_percentage",
      headerClass: "w-[80px] text-center px-2",
      className: "w-[80px] text-center break-words px-2",
    },
    {
      name: "Reason",
      apiKey: "reason",
      headerClass: "w-[80px] text-center px-2",
      className: "w-[80px] text-center break-words px-2",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return operationalReportsForDiscountTable(params);
  },
  filters: {
    // dateFilters: [
    //   {
    //     name: "Date",
    //     apiKey: "date",
    //     isSearchNeeded: true,
    //     className: "w-[170px]",
    //   },
    // ],
    checkBox: [
      {
        name: "Registered Date",
        apiKey: "batchCompletionPercentage",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Discount Range(%)",
        apiKey: "discount_range",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Ownership",
        apiKey: "branch_ownership",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Course",
        apiKey: "course_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "City",
        apiKey: "city_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Branch",
        apiKey: "branch_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["student_name", "student_phone_number"],
        searchKey: "student",
        name: "Student Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
};
