import { TableNames } from "../../config/Tables";
import { FetchData } from "../../crud";
import { percentageBasedOnRange } from "../../commonHelper";

export const calculatePaidCourseCompletion = (overallDuration: string, sessions: any[]) => {
    const completedDuration = sessions?.reduce((acc: number, currentValue: any) => Number(acc) + Number(currentValue?.duration), 0);
    // console.log(completedDuration, "completedDuration", completedDuration, overallDuration, ((completedDuration / Number(overallDuration)) * 100))
    const percentageCalc = `${((completedDuration / Number(overallDuration)) * 100) === 0 ? ((completedDuration / Number(overallDuration)) * 100) : ((completedDuration / Number(overallDuration)) * 100)?.toFixed(1)}`;
    return percentageCalc;
}

const nullConversion=(value:string|null)=>{
    return value ?? "";
}

export const formatPendingBatchPayments = async (batchData: any[]) => {
    const { data: users, error: userError } = await FetchData(TableNames.users, ["first_name", "last_name", "courses", "cities", "branches", "user_type"])
    if (userError) throw userError;
    const check_CRM_User = batchData?.map((bt: any) => {
        const matchingUser:any = users?.find((dt: any) => 
            dt?.cities?.includes(bt?.city_id) && dt?.branches?.includes(bt?.branch_id) && dt?.courses?.includes(bt?.course?.id) && dt?.user_type === "BC" 
        );
        return matchingUser && {
            id: bt?.id,
            name: `${matchingUser?.first_name} ${matchingUser?.last_name}`
        };
    });
    // console.log(check_CRM_User, "check_CRM_User")
    const result = batchData.map((bd) => {
        let batchSize = bd?.trainings?.length;
        let total_fee = 0;
        let total_paid = 0;
        // let crmName = null;
        let batchProgress = Number((bd?.batch_progress_in_hrs));
        let batchCompletionPercentage =
          Math.round((batchProgress / bd?.duration) * 100) === 0
            ? "0"
            : Math.round((batchProgress / bd?.duration) * 100);
        bd?.trainings?.forEach((tr:any) => {
            total_fee += Number(tr.total_fee)
            // crmName = nullConversion(tr?.crm?.first_name) +" "+ nullConversion(tr?.crm?.last_name)
            total_paid=tr?.transactions?.reduce((total:number,fee:any)=>Math.round(total+Number(fee?.paid_amount)),0)
            // batchSize+=1
        });
        let balance_due = Math.round(Number(total_fee - total_paid));
        let trainerName = nullConversion(bd?.trainer?.first_name) +" "+ nullConversion(bd?.trainer?.last_name);
        let trainerNumber = bd?.trainer?.phone_number ||null;
        delete bd?.trainings;
        delete bd?.trainer;
        delete bd?.batch_progress_in_hrs;
        delete bd?.duration;
        const crmName = check_CRM_User?.length>0 ? check_CRM_User?.filter((user:any)=> user?.id === bd?.id).map((filteredUser: any) => filteredUser.name) : '';    
        return {
            ...bd,
            batchSize: batchSize===0?'0':batchSize,
            total_fee: batchSize===0 ? '-' : (total_fee===0?'0':total_fee.toFixed(1)),
            total_paid: batchSize===0 ? '-' : (total_paid===0?'0':total_paid.toFixed(1)),
            crmName,
            balance_due: batchSize===0 ? '-' : (balance_due===0 ? '0': balance_due.toFixed(1)),
            trainerName,
            trainerNumber,
            batchCompletionPercentage: batchSize === 0 ? '-' : (isNaN(Number(batchCompletionPercentage)) ? '0' : batchCompletionPercentage),
            progress_range: percentageBasedOnRange(batchSize !== 0 ? 
                (isNaN(Number(batchCompletionPercentage)) ? '0' : batchCompletionPercentage) : "0")
        }
    });
    // console.log(result, "result");
    return result;
}