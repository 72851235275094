import { Components } from "./ModuleImports"

export const NullifyPayment = () => {
    const { ReadData, formatNumberWithCommas }: any = Components;
    const status = [
        {
            date: '02 Dec 2022',
            fee: "21240",
            paid_amount: '17700',
            nullify_amount: '3540',
            total_amount: '17740'
        }
    ]
  return (
      <div className="w-11/12 mx-auto space-y-2">
          <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">Nullify Payment</h1>
          <div>
              {status.map((pay:any, index:number)=>{
                return(
                    <div className='shadow-md shadow-[#00000029] p-4'>
                        <div className="flex items-center justify-between w-[90%]">
                            <ReadData head={"Date"} body={pay.date} />
                            <ReadData head={"Course Fee (Incl. GST)"} body={formatNumberWithCommas(pay.fee)} addSpan={pay.fee} />
                            <ReadData head={"Paid Amount"} body={formatNumberWithCommas(pay.paid_amount)} addSpan={pay.paid_amount} />
                            <ReadData head={"Nullified Amount"} body={formatNumberWithCommas(pay.nullify_amount)} addSpan={pay.refund_amount} />
                            <ReadData head={"Total Amount After Nullified"} body={formatNumberWithCommas(pay.total_amount)} addSpan={pay.total_amount} />
                        </div>
                    </div>
                )
              })}
          </div>
    </div>
  )
}
