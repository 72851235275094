import { AdminUserTable } from "../../../utils/json/adminUser";
import { AddNewUser } from "./AddNewUser";
import { ViewUserInfo } from "./ViewUserInfo";
import { BackButton } from "../../Elements/BackButton";
import createUserButton from "../../../Assets/createUserButton.svg";
import {
  useSharedLogic,
  CommonTableComp,
} from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const AdminUserManagement = () => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  return (
    <div>
      {mode === "view" && (
        <div className="space-y-2">
          <BackButton topDivStyle={"w-10/12 justify-end"} />
          <ViewUserInfo id={id} />
        </div>
      )}
      {(id === "add-user" || mode === "edit") && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-[95%] justify-end"} />
          <AddNewUser id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions("view") && (
        <CommonTableComp
          id={id}
          head={"Admin User Management"}
          buttonType={"left"}
          buttonText={"Create User"}
          onClick={() => createFunc("add-user")}
          buttonImg={createUserButton}
          TABLE_JSON={AdminUserTable}
          allowCondition={allowOnlyActions("create")}
        />
      )}
    </div>
  );
};
