import { Components } from "./ModuleImports";
import { passNumber } from "../../../Services/CommonFunctions";
import { getTimeDifferenceInHours } from "../../../functions/commonHelper";

export const StudentDetails = ({ studentsData }: any) => {
  const { Students_Data, formatNumberWithCommas }: any = Components;
  const Students = Students_Data(studentsData);
  // console.log(studentsData, "??", Students, "MM+")
  const headers = [
    "Student Name",
    "Student Phone Number",
    "Total Fee",
    "Amount Paid",
    "Balance Due",
    "Hours Attended",
    "Student Status",
  ];
  const footerStyle = `text-[#686868] text-[14px] font-Roboto font-[400] w-[12%] text-center`;
  return (
    <div className="space-y-4 w-11/12 mx-auto py-6">
      <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        {Object.keys(studentsData).length > 0 && "Student Details"}
      </h1>
      <div className="space-y-3">
        {Students &&
          Students?.map((student: any) => {
            if (student?.data?.length > 0) {
              return (
                <div className="space-y-1 shadow-md shadow-[#00000029] rounded-[7px] py-3">
                  <h1 className="text-[#000000] text-[20px] font-Roboto font-[500] px-2">
                    {student.studentsStat}
                  </h1>
                  <div>
                    <div className="flex items-center justify-evenly bg-[#EFF7FF] py-2">
                      {headers?.map((head: any) => (
                        <h1 className="text-[#686868] text-[16px] font-Roboto font-[500] w-[12%] text-center">
                          {head}
                        </h1>
                      ))}
                    </div>
                    <div className="min-h-[90px] max-h-[120px] scroll-smooth overflow-y-scroll scrollbar-hide">
                      {student.data &&
                        student?.data.map((data: any, index: number) => {
                          const trainerSessionBasedCount = data?.batch?.session?.filter((bt: any) => bt?.trainer_id === data?.students_based_on_trainer);
                          const totalHours = trainerSessionBasedCount.reduce((acc: number, session: any) => {
                            // const sessionHours = getTimeDifferenceInHours(session.start_time, session.end_time);
                            // console.log(sessionHours,session.start_time, session.end_time, "TEST")
                            return acc + Number(session?.duration);
                          }, 0);
                          return (
                            <div
                              className={`flex items-center justify-evenly ${
                                index % 2 !== 0 && "bg-[#EFF7FF]"
                              } py-2`}
                            >
                              <p className={footerStyle}>
                                {data?.student?.name}
                              </p>
                              <p className={footerStyle}>
                                {data?.student?.phoneNumber}
                              </p>
                              <p className={footerStyle}>
                                <span className="px-1">&#8377;</span>
                                {passNumber(data?.total_fee)}
                              </p>
                              <p className={footerStyle}>
                                <span className="px-1">&#8377;</span>
                                {passNumber(data?.paid_amount)}
                              </p>
                              <p className={footerStyle}>
                                <span className="px-1">&#8377;</span>
                                {passNumber(data?.balance_due)}
                              </p>
                              <p className={`${footerStyle}`}>
                                {data?.hours_attended}
                                <span className="bg-[#036AD1] rounded-full p-0.5 text-white px-1.5 ml-1">
                                  {totalHours}
                                </span>
                              </p>
                              <p
                                className={`${footerStyle} border-[#707070] border-[0.5px] px-2 rounded-[3px] py-0.5`}
                              >
                                {data.status}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};
