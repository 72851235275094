import { useState, useEffect } from "react";
import { Loader } from "../../../Elements/Loader/Loader";
import { RoleManagementJSON } from "./roleJSON";
import { AllRoles } from "./AllRoles";
import {
  getRoles,
  getPrivilegesData,
  getRoleById,
  createNewRole,
  updateRole,
} from "../../../../functions/admin/Role Management/roleMangement";
import { StatusButton } from "../../../Elements/StatusButton";
import {
  useSharedLogic,
  FormikCommonFunc,
} from "../../../CommonFunctions/CommonFunc";
import { AddRoleValidation } from "../../../Formik/FormikValidation";
import toast from "react-hot-toast";
import { onKeyDown } from "../../../../Services/CommonFunctions";
import { searchMenus } from "../helper";
import searchIconDark from "../../../../Assets/searchIconDark.svg";
import { SelectLogic } from "./SelectLogic";
import { RoleName } from "./RoleName";

export const Components = {
    useState,
    useEffect,
    Loader,
    RoleManagementJSON,
    AllRoles,
    getRoles,
    getPrivilegesData,
    getRoleById,
    createNewRole,
    updateRole,
    StatusButton,
    useSharedLogic,
    FormikCommonFunc,
    AddRoleValidation,
    toast,
    onKeyDown,
    searchMenus,
    searchIconDark,
    SelectLogic,
    RoleName
}