// import { ViewEditDeleteActionComponent } from "../../Components/HR & Student Placement/Student for HR/ActionComponent";
import { getStudentForHRTableData } from "../../functions/admin/HR Management/hr_management";
import {PageRouting} from "../../Components/HR & Student Placement/Student for HR/PageRouting";
import { StudentPageRouting } from "../../Components/HR & Student Placement/Student for HR/StudentPageRouting";
//import { StudentForHrRelevantJobs } from "../../Components/HR & Student Placement/Student for HR/StudentForHrRelevantJobs";
export const studentForHr = {
    headers: [
        {
            name: "Date",
            apiKey: "date",
            headerClass: "w-[120px] text-center",
            className: "text-center",
        },
        {
            name: "Name",
            apiKey: "student_name",
            headerClass: "w-[120px] text-center",
            className: "text-center",
        },
        {
            name: "Phone Number",
            apiKey: "student_mobile",
            headerClass: "w-[120px] text-center",
            className: "text-center break-words",
        },
        {
            name: "Looking For Placement",
            apiKey: "course_name",
            headerClass: "w-[150px] text-center",
            className: "text-center break-words",
        },
        {
            name: "Branch",
            apiKey: "branch",
            headerClass: "w-[100px] text-center",
            className: "text-center",
        },
        {
            name: "City",
            apiKey: "city",
            headerClass: "w-[100px] text-center",
            className: "text-center",
        },
        {
            name: "Shortlisted",
            apiKey: "shortlisted",
            headerClass: "w-[80px] text-center",
            className: "text-center",
        },


        {
            name: "Placed",
            apiKey: "placed",
            headerClass: "w-[90px] text-center",
            className: "text-center break-words",
        },

        {
            name: "Company Joining Status",
            apiKey: "joined",
            headerClass: "w-[110px] text-center",
            className: "text-center break-words",
        },
        {
            name: "Relevant Jobs",
            apiKey: "relevant_jobs",
            Comp: PageRouting,
            isComponent: true,
            headerClass: "w-[100px] text-center",
            className: "text-center text-[#036AD1]",
        },
        {
            name: "Applied",
            apiKey: "applied_jobs",
            Comp: StudentPageRouting,
            isComponent: true,
            headerClass: "w-[100px] text-center",
            className: "text-center text-[#036AD1]",
        },

    ],
     BackendFunctionCall: getStudentForHRTableData,
    // bodyData: [
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Digital Marketing",
    //         branch: "Classroom - Anna Nagar",
    //         city: "Chennai",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "20",
    //         applied: "5"
    //     },
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Digital Marketing",
    //         branch: "Online - OMR",
    //         city: "Chennai",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "20",
    //         applied: "5"
    //     },
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Digital Marketing",
    //         branch: "Classroom - Tambaram",
    //         city: "Chennai",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "20",
    //         applied: "5"
    //     },
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Digital Marketing",
    //         branch: "Online - Marathahalli",
    //         city: "Bangalore",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "20",
    //         applied: "5"
    //     },
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Digital Marketing",
    //         branch: "Classroom - Anna Nagar",
    //         city: "Chennai",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "20",
    //         applied: "5"
    //     },
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Digital Marketing",
    //         branch: "Classroom - Anna Nagar",
    //         city: "Chennai",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "20",
    //         applied: "5"
    //     },
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Digital Marketing",
    //         branch: "Classroom - Anna Nagar",
    //         city: "Chennai",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "20",
    //         applied: "5"
    //     },
    //     {
    //         date: "03 Dec 2022",
    //         name: "Anu Radha",
    //         phone_number: "9876543210",
    //         looking_for_placement: "Software Testing",
    //         branch: "Online - Marathahalli",
    //         city: "Bangalore",
    //         shortlisted: "Yes",
    //         placed: "Yes",
    //         company_joining_status: "Yes",
    //         relevant_jobs: "15",
    //         applied: "10"
    //     },

    // ],
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "date",
                isSearchNeeded: true,
                className: "w-[180px] ",
            },
        ],
        checkBox: [
            // {
            //     name: "Date",
            //     apiKey: "date",
            //     isSearchNeeded: true,
            //     className: "w-[170px]",
            // },
            // {
            //     name: "Placement Question",
            //     apiKey: "placement_question",
            //     isSearchNeeded: true,
            //     className: "md:w-[180px] lg:w-[180px] xl:w-[190px] 2xl:w-[180px]",
            // },
            {
                name: "Technology",
                apiKey: "technology",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[120px] xl:w-[200px] 2xl:w-[120px]",
            },
            {
                name: "Course",
                apiKey: "course_name",
                isSearchNeeded: true,
                className: "w-[170px]",
            },
            {
                name: "City",
                apiKey: "city",
                isSearchNeeded: true,
                className: "w-[160px]",
            },
            {
                name: "Branch",
                apiKey: "branch",
                isSearchNeeded: true,
                className: "w-[160px]",
            },

            {
                name: "Shortlisted",
                apiKey: "shortlisted",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[120px] xl:w-[120px] 2xl:w-[120px]",
            },
            {
                name: "Placed",
                apiKey: "placed",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[100px] xl:w-[90px] 2xl:w-[110px]",
            },
            {
                name: "Joined",
                apiKey: "joined",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[100px] xl:w-[90px] 2xl:w-[120px]",
            },
        ],
        searchBox: [

            {
                matchFields: ["student_name", "student_mobile"],
                searchKey: "placedname",
                name: "Search...",
                searchStyle: "w-[200px]",
            }
        ]
    },
    // ActionComponent: ViewEditDeleteActionComponent,
};