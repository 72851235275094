import React from 'react';
// import { ReadData } from '../../Elements/ReadData';
import { Components } from './ModuleImports';

export const TrainerDetails = ({ trainerData }: any) => {
  const { Trainer }: any = Components;
  // const Details = Trainer(trainerData)
  // const checkKey = (key: any) => `${key === "trainer_name" ? "Trainer Name" : key === "trainer_mobile" ? "Trainer Phone Number" :
  //   key === "start_date" ? "Start Date" : key === "end_date" ? "End Date" : key === "duration" && "Duration"}`

  // const objectElements: any = trainerData?.map((obj: any, index: number) => {
  //     const valueEntries: any = Object.entries(obj)
        
  //     const rearrangedEntries = [
  //       valueEntries[3],
  //       valueEntries[4],
  //       valueEntries[0],
  //       valueEntries[1],
  //       valueEntries[5],
  //       valueEntries[6]
  //     ];  
        
  //   const valueElements = rearrangedEntries.map(
  //     ([key, value]: any, valueIndex: any) => (
  //       key !== "current_trainer" &&
  //       <div key={valueIndex} className={`relative w-full flex-1 flex-col p-5 ${valueIndex % 2 === 0 ? "bg-[#FFE4E4]" : "bg-[#E6E4FF]"}`}>
  //           <ReadData
  //             head={ key !== "current_trainer" && checkKey(key)}
  //             body={key !== "current_trainer" && value ? value : "-"}
  //           />
  //           {key === "trainer_name" && <span className={`absolute bottom-1.5 ${key === "current_trainer" ?
  //             "bg-[#FFCC00] text-black" : "bg-[#FF0000] text-white"} text-[10px] font-Roboto font-[400] rounded-[1px] p-0.1 px-1`}>
  //             {key === "current_trainer" && value ? 'Current Trainer' : 'Past Trainer'}</span>}
  //       </div>
  //     )
  //   );
  //   return (
  //     <div key={index} className='flex items-start justify-between'>
  //       {valueElements}
  //     </div>
  //   );
  // });
  
  return (
    <div className="w-[97%] mx-auto space-y-4">
      <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        Trainer Details
      </h1>
      {/* <div className='space-y-1'>{objectElements}</div> */}
      <div className="space-y-1">
        {trainerData?.map((trainer: any, index: number) => {
          return (
            <div className="flex">
              <ReadData
                head={"Trainer Name"}
                body={trainer?.trainer_name}
                isCurrentTrainer={trainer?.current_trainer}
                index={index}
              />
              <ReadData
                head={"Trainer Phone Number"}
                body={trainer?.trainer_mobile}
                index={index + 1}
              />
              <ReadData
                head={"Start Date"}
                body={trainer?.start_date}
                index={index}
              />
              <ReadData
                head={"End Date"}
                body={trainer?.end_date}
                index={index + 1}
              />
              <ReadData
                head={"Duration"}
                body={trainer?.duration}
                index={index}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

const ReadData = ({ head, body, isCurrentTrainer, index }: any) => {
  return (
    <div
      className={`min-h-[46px] pl-6 relative w-[20%] p-4 ${
        index === 0 ? "bg-[#FFE4E4]" : "bg-[#E6E4FF]"
      }`}
    >
      <p className="text-[#686868] text-[15px] 2xl:text-[16px] font-Roboto font-[400]">
        {head}
      </p>
      <h1
        className={`text-[18px] 2xl:text-[19px] font-Roboto font-[500] break-words`}
      >
        {body}
        <span className="ml-1">
          {head === "Duration" && (body > 1 ? "Hrs" : "Hr")}
        </span>
      </h1>
      {isCurrentTrainer && (
        <p
          className={`absolute bottom-0 ${
            isCurrentTrainer
              ? "bg-[#FFCC00] text-black"
              : "bg-[#FF0000] text-white"
          } text-[10px] font-Roboto font-[400] rounded-[1px] p-0.1 px-1`}
        >
          {isCurrentTrainer ? "Current Trainer" : "Past Trainer"}
        </p>
      )}
    </div>
  );
};
