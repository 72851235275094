import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables"
import { DeleteData, FetchData, InsertData } from "../../crud"
import { TestimonialInterface } from "./interface"

export const getAllTestimonials = async () => {
    try {
        const { data: testimonials, error } = await FetchData(TableNames.testimonials)
        if (error) throw error
        return testimonials
    } catch (error) {
        return errorNotifier(error)
    }
}

export const createTestimonial = async (testimonialData: TestimonialInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.testimonials, testimonialData)
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const deleteTestimonial = async (testimonialId: number) => {
    try {
        const { data, error } = await DeleteData(TableNames.testimonials, { conditionKey: "id", conditionValue: testimonialId})
        if (error) throw error
        return true
    } catch (error) {
        return errorNotifier(error)
    }
}