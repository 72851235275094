import { useState } from "react";
import selectedCheckbox from "../../../../Assets/selectedCheckbox.svg";
import emptyCheckbox from "../../../../Assets/emptyCheckbox.svg";
import dropDownIcon from "../../../../Assets/dropDownIcon.svg";

export const AllRoles = ({
  roles,
  searchFilter,
  CheckValue,
  checkSubMenuValue,
  checkboxValues,
  handleCheckboxChange,
  handleCheckboxChangeForSubMenu,
}: any) => {
  const [selectedTabs, setSelectedTabs] = useState([] as any);

  const selectTabs = (label: any) => {
    if (selectedTabs.includes(label)) {
      const tabs = [...selectedTabs];
      const filterTab = tabs.filter((tab: string) => tab !== label);
      setSelectedTabs(filterTab);
    } else setSelectedTabs([...selectedTabs, label]);
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-10 w-full pt-6">
      {checkboxValues?.filter(searchFilter).map((mainMenu: any) => {
        return (
          <div key={mainMenu?.id} className="w-[96%] mx-auto space-y-4">
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2 w-[30%]">
                <h1 className="text-start text-[#686868] 2xl:text-[17px] lg:text-[16px] font-Roboto font-[400]">
                  {mainMenu?.label}
                </h1>
                {mainMenu?.submenus?.length > 0 && (
                  <img
                    src={dropDownIcon}
                    alt={mainMenu?.idkey}
                    className={`${
                      !selectedTabs.includes(mainMenu.label) && "rotate-180"
                    } cursor-pointer w-3 h-3`}
                    onClick={() => selectTabs(mainMenu.label)}
                  />
                )}
              </div>
              <div className="flex items-center justify-around gap-2 w-[70%]">
                {mainMenu?.data?.map((opt: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className="flex items-center gap-2 cursor-pointer"
                    >
                      <input
                        id={opt?.value + mainMenu?.id}
                        type="checkbox"
                        className="w-4 h-4 cursor-pointer"
                        checked={opt?.checked}
                        onChange={(e: any) =>
                          handleCheckboxChange(
                            e.target.checked,
                            mainMenu?.id,
                            opt?.value
                          )
                        }
                      />
                      <label
                        htmlFor={opt?.value + mainMenu?.id}
                        className="text-[#707070] 2xl:text-[17px] lg:text-[16px] font-Roboto font-normal cursor-pointer select-none"
                      >
                        {opt?.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="space-y-2">
              {selectedTabs.includes(mainMenu.label) &&
                mainMenu?.submenus?.map((subMenu: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex items-center justify-between"
                    >
                      <h1 className="text-start px-4 text-[#686868] text-[19px] font-Roboto font-[400]">
                        {subMenu?.label}
                      </h1>
                      <div className="flex items-center justify-around gap-2 w-[70%]">
                        {subMenu?.data?.map((subOpt: any, i: number) => {
                          return (
                            <div
                              key={i}
                              className="flex items-center gap-2 cursor-pointer"
                            >
                              <input
                                id={subOpt?.value + subMenu?.id}
                                type="checkbox"
                                className="w-4 h-4 cursor-pointer"
                                checked={subOpt?.checked}
                                onChange={(e: any) =>
                                  handleCheckboxChangeForSubMenu(
                                    e.target.checked,
                                    mainMenu?.id,
                                    subOpt?.value,
                                    index
                                  )
                                }
                              />
                              <label
                                htmlFor={subOpt?.value + subMenu?.id}
                                className="text-[#707070] text-[19px] font-Roboto font-normal cursor-pointer select-none"
                              >
                                {subOpt?.label}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
    // <div className="space-y-8 py-6">
    //   {roles.filter(searchFilter).map((role: any, index: number) => (
    //     <div className="space-y-4">
    //       <div
    //         key={index}
    //         className="flex items-center gap-6 bg-[#F8FBFF] py-4"
    //       >
    //         <div className="w-[25%] pl-4 flex items-center justify-between">
    //           <h2 className="text-[#686868] text-[19px] font-Roboto font-[400]">
    //             {role.label}
    //           </h2>
    //           {role.submenus && role.submenus.length > 0 && (
    //             <img
    //               src={dropDownIcon}
    //               alt={"submenus"}
    //               className={`${
    //                 !selectedTabs.includes(role.label) && "rotate-180"
    //               } cursor-pointer`}
    //               onClick={() => selectTabs(role.label)}
    //             />
    //           )}
    //         </div>
    //         <div className="w-full flex items-center justify-evenly">
    //           {role.data && role.data.map((item: any) => (
    //             <Checkbox
    //               show={true}
    //               checked={item?.checked}
    //               label={item?.label}
    //               CheckValue={CheckValue}
    //               textStyle={"text-[#707070] text-[19px]"}
    //               boxSize={"w-4 h-4"}
    //               mapOneIndex={index}
    //               mapTwoIndex={`${item?.id - 1}`}
    //             />
    //           ))}
    //         </div>
    //       </div>
    //       <div className="space-y-4">
    //         {selectedTabs.includes(role.label) &&
    //           roles[index].submenus &&
    //             roles[index].submenus.map((sub: any, i: number) => (
    //             <div key={i} className="flex items-center gap-6">
    //               <h2 className="text-[#686868] text-[19px] font-Roboto font-[400] w-[23.5%] px-10">
    //                 {sub?.label}
    //               </h2>
    //               <div className="w-full flex items-center justify-evenly">
    //                 {sub?.data && sub?.data.map((subitem: any) => (
    //                   <Checkbox
    //                     show={true}
    //                     checked={subitem?.checked}
    //                     label={subitem?.label}
    //                     CheckValue={checkSubMenuValue}
    //                     textStyle={"text-[#707070] text-[19px]"}
    //                     boxSize={"w-4 h-4"}
    //                     mapOneIndex={index}
    //                     mapTwoIndex={i}
    //                     mapThirdIndex={`${subitem?.id-1}`}
    //                   />
    //                 ))}
    //               </div>
    //             </div>
    //           ))}
    //       </div>
    //     </div>
    //   ))}
    // </div>
  );
};

const Checkbox = ({
  show,
  checked,
  label,
  CheckValue,
  textStyle,
  boxSize,
  mapOneIndex,
  mapTwoIndex,
  mapThirdIndex,
}: any) => {
  const allowChange = show ?? show;
  return (
    <div
      className="flex items-center gap-2 cursor-pointer"
      onClick={() =>
        allowChange &&
        CheckValue(label, checked, mapOneIndex, mapTwoIndex, mapThirdIndex)
      }
    >
      <img
        src={checked ? selectedCheckbox : emptyCheckbox}
        alt="CheckType"
        className={`${boxSize ? boxSize : ""}`}
      />
      <label
        className={`${textStyle} font-Roboto font-normal cursor-pointer select-none`}
      >
        {label}
      </label>
    </div>
  );
};
