import React from 'react'

export const RadioInput = ({ index, label, classStyle, onChange, value, name, ...rest }: any) => {
  return (
    <div key={index} className="flex items-center gap-2 cursor-pointer">
      <input
        // id={value}
        name={name}
        type={"radio"}
        value={value}
        className="cursor-pointer w-5 h-5"
        checked={
          (value === true && label === "Yes") ||
          (value === false && label === "No")
            ? true
            : label === value
        }
        onChange={() => onChange(name, label)}
        {...rest}
      />
      <label
        // htmlFor={value}
        // onClick={() => onChange(name, label)}
        className={`cursor-pointer text-[#000000] font-Roboto ${classStyle}`}
      >
        {label}
      </label>
    </div>
  );
}
