import { useState, useEffect } from 'react';
import { Components } from './ModuleImports';
import { mapFunction } from '../../../Services/CommonFunctions';
import { getSessionsAndTrainersByBatchId } from '../../../functions/admin/Course Progress/courseProgress';
import { dateFormat } from '../../../Services/CommonFunctions';
import toast from 'react-hot-toast';

export const Course = ({ isId, formik, batchDetail, Time, dateExists, existingDateCheck, LoginUserNames }: any) => {
  const [checkPreviousSessions, setCheckPreviousSessions] = useState([] as any);
  const { ReadData, DropdownSingleInputWithCheckbox, InputBox }: any = Components;
  // const today = new Date().toISOString().split('T')[0];

  const today:any = new Date();
  const twoDaysBefore:any = new Date(today);
  twoDaysBefore.setDate(today.getDate() - 2);

  const startDate = dateFormat(batchDetail?.start_date, "YYYY-MM-DD");
  const twoDaysBeforeFormatted = dateFormat(twoDaysBefore, "YYYY-MM-DD");

  // Check if startDate includes two days before formatted date
  const includesTwoDaysBefore = startDate.includes(twoDaysBeforeFormatted);

  let minDate;
  if (includesTwoDaysBefore) {
    minDate = twoDaysBeforeFormatted;
  } else {
    minDate = dateFormat(twoDaysBefore, "YYYY-MM-DD");
  }

  // Calculate maxDate as today
  const maxDate = dateFormat(today, "YYYY-MM-DD");

    // const roleExists = LoginUserNames.some((role:any) =>
    //   role.toLowerCase() === values.role.toLowerCase()
    // );

  const handleSessionDate = async (index: number, value: string, check: string) => {
    formik.setFieldValue(check, value);
    const SessionAndTrainers:any = await getSessionsAndTrainersByBatchId(isId);
    // console.log(SessionAndTrainers, "ST")
    // setCheckPreviousSessions(SessionAndTrainers?.updatedSessionData)
    if (SessionAndTrainers?.updatedSessionData?.length > 0) {
      const dateChecker = dateFormat(value, 'DD MMM YYYY');
      const isSameSessionAvailable =
        SessionAndTrainers?.updatedSessionData?.filter(
          (data: any) => data?.session_date === dateChecker
        );
      // console.log(isSameSessionAvailable, "isSameSessionAvailable")
      dateExists({
        start_time: isSameSessionAvailable?.[0]?.start_time,
        end_time: isSameSessionAvailable?.[0]?.end_time
      })
    }
  }

  return (
    <div className="space-y-6">
      <ReadData
        head={"Course"}
        body={
          batchDetail?.course?.course_name
            ? batchDetail?.course?.course_name
            : ""
        }
      />
      <div className="flex items-center gap-12">
        <InputBox
          label="Session Date"
          placeholder="Session Date"
          type={"date"}
          topDivStyle={"flex flex-col w-1/2"}
          classNameInput={"w-full"}
          name={"session_date"}
          value={formik.values.session_date}
          onChange={handleSessionDate}
          onBlur={formik.handleBlur}
          error={formik.errors.session_date}
          isTouched={formik.touched.session_date}
          min={(LoginUserNames.includes('Trainer') || (LoginUserNames.includes('trainer')))? minDate : startDate}
          max={maxDate}
          index={0}
          check={"session_date"}
        />
        <DropdownSingleInputWithCheckbox
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          label="Start Time"
          placeholder="Start Time"
          options={
            Time.includes(formik.values.end_time)
              ? mapFunction(Time, formik.values.end_time)
              : Time
          }
          name={"start_time"}
          value={formik.values.start_time}
          onBlur={formik.handleBlur}
          error={formik.errors.start_time}
          isTouched={formik.touched.start_time}
          mapThrough={"start_time"}
          formik={formik}
          disabled={false}
          zIndex={"z-30"}
        />
        <DropdownSingleInputWithCheckbox
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          label="End Time"
          placeholder="End Time"
          options={
            Time.includes(formik.values.start_time)
              ? mapFunction(Time, formik.values.start_time)
              : Time
          }
          name={"end_time"}
          value={formik.values.end_time}
          onBlur={formik.handleBlur}
          error={formik.errors.end_time}
          isTouched={formik.touched.end_time}
          mapThrough={"end_time"}
          formik={formik}
          disabled={false}
          zIndex={"z-30"}
        />
      </div>
      <div className="rounded-[17px] shadow-md shadow-[#00000029] py-4 pb-10">
        <div className="w-11/12 mx-auto space-y-6">
          <InputBox
            label="Topic"
            placeholder="Topic"
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"details.topic"}
            value={formik.values.details?.topic}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.details?.topic}
            isTouched={formik.touched.details?.topic}
          />
          <SubTopics formik={formik} />
        </div>
      </div>
    </div>
  );
}

const SubTopics = ({ formik }: any) => {
  const { useEffect, AddItemsButton, InputBox }: any = Components;
  const firstObject: any = formik.values?.details?.subtopics?.[0];

  useEffect(() => {
    formik.setFieldValue('title', formik.values.details?.topic)
  },[formik.values.details?.topic])

  const AddSubTopic = () => {
    const add = [...formik.values.details?.subtopics, ""];
    formik.setFieldValue("details.subtopics", add);
  };

  const deleteSubTopic = (index: number) => {
    const newArray = [...formik.values.details?.subtopics];
    newArray.splice(index, 1);
    formik.setFieldValue("details.subtopics", newArray);
  };

  const handleChangeForSubTopics = (index: number, value: string, data:any) => {
    formik.setFieldValue(`details.subtopics[${index}]`, value);
  };

  return (
    <div>
      <div className='flex items-center justify-between pb-2'>
          <h1 className='text-[#707070] text-[20px] font-Roboto font-[500]'>Sub Topics (Optional)</h1>
          <AddItemsButton text="Add Sub Topic" onClick={AddSubTopic} disabled={false} />
      </div>
      <div className='space-y-2'>
        <InputBox
          placeholder="Topic 1"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"details.subtopics"}
          value={firstObject}
          onChange={handleChangeForSubTopics}
          onBlur={formik.handleBlur}
          index={0}
          check={"details.subtopics"}
        />
        {formik.values.details?.subtopics.map((sub: any, index: number) => {
          if (index !== 0) {
            return (
              <div className='flex items-center relative'>
                <InputBox
                  placeholder={`Topic ${[index+1]}`}
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={`details.subtopics`}
                  value={sub}
                  onChange={handleChangeForSubTopics}
                  onBlur={formik.handleBlur}
                  index={`${index}`}
                  check={"details.subtopics"}
                />
                <div
                  onClick={()=>deleteSubTopic(index)}
                  className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-7 bottom-2"
                >
                  x
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}
