import {useState, useEffect} from 'react';
import { getRefundPreloadData, getCoursesForStudent, getStudentTrainingBasedOnBranch, getTrainingFortheStudent } from '../../../../functions/admin/Action center/Refund and Adjust Payment/issueRefundAndAdjustPayment';
import { DropdownWithHandleOut } from '../../../Elements/DropdownWithHandleOut';
import { DropdownInputWithCheckbox } from '../../../Elements/DropdownInputWithCheckbox';
import toast from 'react-hot-toast';

export const IssueRefund = ({ formik, setIsLoading, setBasicInfoDetails }: any) => {
    const [studentsData, setStudentsData] = useState([] as any);
    const [studentsName, setStudentsName] = useState([] as any);
    const [coursesData, setCoursesData] = useState([] as any);
    const [coursesName, setCoursesName] = useState([] as any);

    useEffect(() => {
      (async () => {
          const preloadDataResponse: any = await getRefundPreloadData();
        //   console.log(preloadDataResponse, "preloadData")
          if (preloadDataResponse) {
              setStudentsData(preloadDataResponse?.student);
              const mapStudents = preloadDataResponse?.student?.map(
              (stu: any) => `${stu?.name} - ${stu?.phoneNumber} - ${stu?.branch}`
              );
              setStudentsName(mapStudents);
          }
      })()
    },[])

    const handleInputChange = async (event: any) => {
        formik.setFieldValue("course", "")
        formik.setFieldValue("courseId", null);
        const { name, value, checked } = event.target;
        const filterStudent = studentsData.filter((fil: any) => `${fil?.name} - ${fil?.phoneNumber} - ${fil?.branch}` === value);
        formik.setFieldValue("student", `${filterStudent?.[0]?.name} - ${filterStudent?.[0]?.phoneNumber} - ${filterStudent?.[0]?.branch}`);
        formik.setFieldValue("studentUid", filterStudent?.[0]?.uid);
        formik.setFieldValue("branch_id", filterStudent?.[0]?.branch_id);
        if (filterStudent?.length > 0) {
            const studentBasedCourseResponse:any = await getCoursesForStudent(filterStudent?.[0]?.uid);
            setCoursesData(studentBasedCourseResponse);
            const mapCourseNames = studentBasedCourseResponse?.map((cr: any) => cr?.course_name);
            setCoursesName(mapCourseNames)
        }
    }

    const handleEnteredCourse = async (
      option: any,
      forId: any,
      name: any,
      mapData: any,
      mapThrough: any,
    ) => {
      formik.setFieldValue(name, option);

      const filterForID = mapData?.filter(
        (data: any) => data[mapThrough] === option
      )?.[0];
        console.log(filterForID, "filterForID");
      if (!filterForID) {
        return; // Exit early if filterForID is not found
      }

      const course_id = Number(filterForID["id"]);
      formik.setFieldValue(forId, course_id);

      try {
        setIsLoading(true);

        const [branchGSTDetails, response]:any = await Promise.all([
          getStudentTrainingBasedOnBranch(formik.values.studentUid, course_id),
          getTrainingFortheStudent(formik.values.studentUid, course_id, formik.values.branch_id),
        ]);

        if (Object.keys(branchGSTDetails).length) {
          formik.setFieldValue("branch_based_GST", branchGSTDetails[0]);
        }

        if (response.status) {
          setBasicInfoDetails(response);
          formik.setFieldValue("training_id", response?.updatedStudentData?.id);
        } else {
          setBasicInfoDetails({});
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
  return (
    <div className="w-11/12 mx-auto space-y-3">
      <div className="w-full flex items-center justify-between">
        <DropdownWithHandleOut
          topDivStyle={"flex flex-col w-[48%]"}
          classNameInput={"w-full"}
          options={studentsName}
          label="Student Details"
          placeholder="Student Details"
          name={"student"}
          value={formik.values.student}
          handleInputChange={handleInputChange}
          onBlur={formik.handleBlur}
          formik={formik}
          zIndex={"z-20"}
        />
        <DropdownInputWithCheckbox
          topDivStyle={"flex flex-col w-[48%]"}
          classNameInput={"w-full"}
          label="Course"
          placeholder="Course"
          options={coursesName}
          name={"course"}
          forId={"courseId"}
          value={formik.values.course}
          handleOut={handleEnteredCourse}
          onBlur={formik.handleBlur}
          mapData={coursesData}
          mapThrough={"course_name"}
          formik={formik}
          disabled={false}
        />
      </div>
    </div>
  );
}
