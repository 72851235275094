import { useState, useEffect } from "react";
import { CloseIcon } from "../../Elements/CloseIcon";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { addStudentFeedbackFollowUpDate, getAllStudentFeedbackFollowupsForTraining } from "../../../functions/admin/Action center/Session Feedback/sessionFeedback";

export const getImports = {
    useState, useEffect, CloseIcon, useSharedLogic, createSearchParams, toast, addStudentFeedbackFollowUpDate, FormikCommonFunc, getAllStudentFeedbackFollowupsForTraining
}