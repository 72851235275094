import { useState, useEffect } from 'react';
import { CardLayoutTwo } from '../../Elements/Cards/CardLayoutTwo';
import { InputBox } from '../../Elements/InputBox';
import { FormikCommonFunc } from '../../CommonFunctions/CommonFunc';
import { add_Contact } from '../../Formik/InitialValues';
import { AddContact } from '../../Formik/FormikValidation';
import { getContactInfo, createContactInfo, updateContactInfo } from '../../../functions/admin/Contact Info/ContactInfo';
import { onKeyDown } from '../../../Services/CommonFunctions';
import { Loader } from '../../Elements/Loader/Loader';
import { useSearchParams } from "react-router-dom";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';

export const ContactInfoSubmission = ({ id, mode }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const readData: any = await getContactInfo();
      // console.log(readData, "readData")
      if (readData) {
        const { id, email, phone_number }: any = readData;
        setValues({
          id, email, phone_number
        }, true)
      }
      setIsLoading(false)
    })()
  }, [message])


  const forContactInfo = async () => {
    setIsLoading(true)
    const { id, ...rest }: any = values;
    const body: any = { ...rest };
    // console.log(body, "body")
    const response = await (values.id===null ? createContactInfo(body) : updateContactInfo(body, values.id))
    if (response) {
      // setSearchParams({
      //   id: "contact-info",
      //   mode: "edit"
      // }); 
      setMessage(!message);
    }
    setIsLoading(false)
  }

  const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue }: any = FormikCommonFunc(add_Contact, AddContact, forContactInfo);

  return (
    <CardLayoutTwo>
      {isLoading ? (
        <Loader widthFix={"w-8/12"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="py-4 w-8/12 mx-auto space-y-10 flex flex-col items-center justify-center"
        >
          <div className="space-y-4 w-8/12">
            <InputBox
              label="Phone Number"
              placeholder="+91"
              type={"number"}
              topDivStyle={"flex flex-col w-full"}
              classNameInput={"w-full"}
              name={"phone_number"}
              value={values.phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone_number}
              isTouched={touched.phone_number}
              // disabled={((allowOnlyActions('create')&&values.id === null)||(allowOnlyActions('edit')&&values.id !== null))}
            />
            <InputBox
              label="Email"
              placeholder="Email"
              type={"email"}
              topDivStyle={"flex flex-col w-full"}
              classNameInput={"w-full"}
              name={"email"}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
              isTouched={touched.email}
              // disabled={((allowOnlyActions('create')&&values.id === null)||(allowOnlyActions('edit')&&values.id !== null))}
            />
          </div>
          {((allowOnlyActions('create') && values.id === null) || (allowOnlyActions('edit') && values.id !== null)) &&
          <button
            type="submit"
            className="cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 text-white text-[17px] font-Roboto font-normal"
          >
            {values.id === null ? "Submit" : "Update"}
          </button>}
          {message && (
            <h1 className="text-center w-full text-[#065B00] text-[22px] font-Roboto font-[500]">
              {/* {values.id === null
                ? "Contact Created Successfully"
                : "Contact Updated Successfully"} */}
                Contact Saved
            </h1>
          )}
        </form>
      )}
    </CardLayoutTwo>
  );
}
