import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartJSTooltip,
  Legend as ChartJsLegend,
  ArcElement,
} from "chart.js";
import { DashboardData } from "./dashboardData";
import { Tabs } from "./Tabs";
import { FunctionForPrivileges } from "../CommonFunctions/Privileges";
import { DashboardFilters } from "./DashboardFilters";
import _ from "lodash";
import { getDashboardData } from "../../functions/admin/Dashboard/Dashboard";
import { useAppSelector } from "../..";
import { Loader } from "../Elements/Loader/Loader";
import { dashBoardData } from "../../functions/admin/Dashboard/dashboardJSON";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartJSTooltip,
  ChartJsLegend,
  ArcElement
);

export const AdminPanelDashboard = () => {
  const { allowAllActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [dashboardData, setDashboardData] = useState({} as any);
  // const [dashboardDataJson, setDashboardDataJson] = useState(DashboardData({}));
  const [isLoading, setIsLoading] = useState(false);
  const DashboardJsonData: any = DashboardData(dashboardData)

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (signInData?.data?.length > 0) {
        const dashboardResponse: any = await getDashboardData(
          signInData?.data[0],_.cloneDeep(dashBoardData)
        );
        if (Object.keys(dashboardResponse).length) {
          // setDashboardDataJson(DashboardData(dashboardResponse));
          setDashboardData(dashboardResponse);
        }
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <div>
      {allowAllActions() && isLoading ? (
        <Loader widthFix={"w-11/12"} />
      ) : (
        <div>
          {/* <DashboardFilters /> */}
          <Tabs DashboardData={DashboardJsonData} response={dashboardData} />
        </div>
      )}
    </div>
  );
};
