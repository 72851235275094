export const basicDetails = (batchData:any) => {
    const Details = [
      {
        head: "Branch & City",
        body: `${batchData?.branch?.branch_name}, ${batchData?.city?.city_name}`,
      },
      {
        head: "Rooms",
        body: batchData?.rooms?batchData?.rooms:'-',
      },
      {
        head: "Schedule",
        body: batchData?.schedule?batchData?.schedule:'',
      },
      {
        head: "Course Duriation",
        body: `${batchData?.duration&&batchData?.duration>1?batchData?.duration+'Hrs':batchData?.duration+'Hr'}`,
      },
      {
        head: "Start Date",
        body: batchData?.start_date?batchData?.start_date:'',
      },
      {
        head: "Start Time",
        body: batchData?.start_time?batchData?.start_time:'',
      },
      {
        head: "Planned Date of Completion",
        body: batchData?.planned_date_of_completion?batchData?.planned_date_of_completion:'',
      },
    ];
    return Details;
}

export const Trainer = (data:any) => {
  const Details:any = [
    { head: "Trainer Name", body: data?.trainer_name?data?.trainer_name:'' },
    { head: "Trainer Phone Number", body: data?.trainer_mobile?data?.trainer_mobile:'' },
    { head: "Start Date", body: data?.start_date?data?.start_date:'' },
    { head: "End Date", body: data?.end_date?data?.end_date:'' },
    { head: "Duration", body: `${data?.duration&&data?.duration>1?data?.duration+'Hrs':data?.duration+'Hr'}` },
  ];
  return Details;
}