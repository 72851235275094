import {useState, useEffect} from 'react';
import { SubmitButton } from '../../../Elements/SubmitButton';
import { DropdownWithHandleOut } from '../../../Elements/DropdownWithHandleOut';
import { DropdownInputWithCheckbox } from '../../../Elements/DropdownInputWithCheckbox';
import { FunctionForPrivileges } from '../../../CommonFunctions/Privileges';
import { InputBox } from '../../../Elements/InputBox';
import { getRefundPreloadData, getCoursesForStudent, getStudentTrainingBasedOnBranch, getTrainingFortheStudent, getCities, getStudentBasedOnBranch } from '../../../../functions/admin/Action center/Refund and Adjust Payment/issueRefundAndAdjustPayment';
import { useAppSelector } from '../../../..';
import toast from 'react-hot-toast';

export const AdjustFor = ({formik, basicInfoDetails}:any) => {
    const [studentsData, setStudentsData] = useState([] as any);
    const [studentsName, setStudentsName] = useState([] as any);
    const [coursesData, setCoursesData] = useState([] as any);
    const [coursesName, setCoursesName] = useState([] as any);
    const [citiesData, setCitiesData] = useState([] as any);
    const [citiesName, setCitiesName] = useState([] as any);
    const [branchesData, setBranchesData] = useState([] as any);
    const [branchesName, setBranchesName] = useState([] as any);
    const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
    const { allowOnlyActions }: any = FunctionForPrivileges();
    const mapPaidAmount = basicInfoDetails?.paymentDetails?.map(
        (amt: any) => Number(amt?.paid_amount)
    );
    const sum =
    mapPaidAmount &&
    mapPaidAmount.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
        0
    );
    
    const numberWithoutCommas = mapPaidAmount?.length > 0 ? sum?.toString()?.replace(/[^0-9]/g, "") : sum;
    const finalPaidAmount = parseInt(numberWithoutCommas, 10);

    useEffect(() => {
        (async () => {
        if (signInData?.data[0]?.branches?.length>0) {
            const cityResponse: any = await getCities(signInData?.data[0]?.cities);
            if (cityResponse) {
                setCitiesData(cityResponse);
                const mapCityNames = cityResponse?.map((city: any) => city?.city_name)
                setCitiesName(mapCityNames);
            }
        }
        })()
    },[])

    useEffect(() => {
        (async () => {
        if ((formik.values.to_student_uid !== "") && (formik.values.to_course_id !== null) && (formik.values.to_branch_id !== null)) {
            const response:any = await getTrainingFortheStudent(formik.values.to_student_uid, formik.values.to_course_id, formik.values.to_branch_id);
            console.log(response, "response_0123")
            if (response.status) {
            formik.setFieldValue('to_training_id', response?.updatedStudentData?.id)
            } else toast.error(response.message);
        }
        })()
    },[formik.values.to_student_uid, formik.values.to_course_id, formik.values.to_branch_id])

    const handleChangeValue = (index: any, value: any, check: any) => {
        const numericValue = value.replace(/[^0-9\.]/g, '');
        const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        if (numericValue === "") {
            formik.setFieldValue('amount', "");
        } else {
            const [integerPart, decimalPart] = numericValue.split('.');
            const parsedValue = parseInt(integerPart) + (decimalPart ? parseFloat(`0.${decimalPart}`) : 0);

            if (parsedValue <= finalPaidAmount) {
            formik.setFieldValue('amount', formattedValue);
            } else {
            formik.setFieldValue('amount', formattedValue.substring(0, formattedValue.length - 1));
            }
        }
    };
    
    const handleInputChange = async(event: any) => {
        const { name, value, checked } = event.target;
        const filterStudent = studentsData.filter((fil: any) => `${fil?.name} - ${fil?.phoneNumber}` === value);
        formik.setFieldValue("to_student", `${filterStudent?.[0]?.name} - ${filterStudent?.[0]?.phoneNumber}`)
        formik.setFieldValue("to_student_uid", filterStudent?.[0]?.uid);
        formik.setFieldValue("to_course", "")
        formik.setFieldValue("to_course_id", null);
        if (filterStudent?.length > 0) {
            const studentCourseResponse: any = await getCoursesForStudent(filterStudent?.[0]?.uid);
            if ((formik.values.from_branch_id === formik.values.to_branch_id) && (formik.values.studentUid === filterStudent?.[0]?.uid)) {
                const filterCourse =
                    studentCourseResponse?.filter(
                    (cr: any) => cr?.id !== formik.values.courseId
                    );
                setCoursesData(filterCourse);
                const filterCourseNames =
                    filterCourse?.map((cr: any) => cr.course_name);
                setCoursesName(filterCourseNames);
            } else {
                setCoursesData(studentCourseResponse);
                const mapCourses: any = studentCourseResponse?.map(
                    (course: any) => course?.course_name
                );
                setCoursesName(mapCourses);
            }
        }
    }

    const handleOut = async(option: any, forId: string, name: any, mapData: any, mapThrough: any) => {
        formik.setFieldValue(name, option);
        const filterForID = mapData?.filter((data: any) => data[mapThrough] === option);
        const IdFilter:any = filterForID?.[0]
        formik.setFieldValue(forId, Number(IdFilter["id"]));
        const readData: any = await getRefundPreloadData();
        if (name === "to_city") {
            formik.setFieldValue('to_branch_id', null)
            formik.setFieldValue('to_branch', "")
            const filterOnlyAccessBasedBranches = readData?.branches?.filter((ft:any)=> signInData?.data[0]?.branches?.includes(ft?.id))
            const filterBranch = filterOnlyAccessBasedBranches?.filter((branch: any) => branch.city === Number(IdFilter["id"]));
            setBranchesData(filterBranch);
            const filterNames = filterBranch?.map((branch: any) => branch.branch_name);
            setBranchesName(filterNames);
        } else {
            formik.setFieldValue("to_student", "")
            formik.setFieldValue("to_student_uid", "")
            formik.setFieldValue("to_course", "")
            formik.setFieldValue("to_course_id", null);
            const studentResponse:any = await getStudentBasedOnBranch(IdFilter["id"]);
            setStudentsData(studentResponse);
            // const FilterStudentResponse = studentResponse?.filter((fil:any)=> `${fil?.name} - ${fil?.phoneNumber}` !== formik.values.student)
            const mapStudentsData = studentResponse?.map((stu: any) => `${stu?.name} - ${stu?.phoneNumber}`)
            setStudentsName(mapStudentsData);
        }
    }

  return (
    <div className="w-full py-10 flex flex-col items-center space-y-10">
        <h1 className="text-start w-11/12 mx-auto font-Roboto font-[500] text-[#000000] text-[28px]">
          Move To
        </h1>
        <div className="flex flex-col items-end justify-between w-full space-y-10">
          <div className="w-11/12 mx-auto space-y-3">
            <div className="w-full flex items-center justify-between">
              <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                label="City"
                placeholder="City"
                options={citiesName}
                name={"to_city"}
                forId={"to_city_id"}
                value={formik.values.to_city}
                handleOut={handleOut}
                onBlur={formik.handleBlur}
                mapData={citiesData}
                mapThrough={"city_name"}
                formik={formik}
                disabled={false}
                zIndex={"z-40"}
              />
              <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                label="Branch"
                placeholder="Branch"
                options={branchesName}
                name={"to_branch"}
                forId={"to_branch_id"}
                value={formik.values.to_branch}
                handleOut={handleOut}
                onBlur={formik.handleBlur}
                mapData={branchesData}
                mapThrough={"branch_name"}
                formik={formik}
                disabled={false}
                zIndex={"z-40"}
              />
            </div>
            <div className="w-full flex items-center justify-between">
              <DropdownWithHandleOut
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                options={studentsName}
                label="Student Details"
                placeholder="Student Details"
                name={"to_student"}
                value={formik.values.to_student}
                handleInputChange={handleInputChange}
                onBlur={formik.handleBlur}
                formik={formik}
                zIndex={"z-20"}
              />
              <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                label="Course"
                placeholder="Course"
                options={coursesName}
                name={"to_course"}
                forId={"to_course_id"}
                value={formik.values.to_course}
                onBlur={formik.handleBlur}
                mapData={coursesData}
                mapThrough={"course_name"}
                formik={formik}
                disabled={false}
              />
            </div>
          </div>
        <div
          className={`w-11/12 mx-auto flex gap-4 justify-between`}
        >
            <InputBox
              topDivStyle={"flex flex-col w-[35%]"}
              classNameInput={"w-full"}
              label={"Amount(₹)"}
              placeholder={"Amount"}
              type={"text"}
              inputMode="numeric"
              name={"amount"}
              value={formik.values.amount}
              onChange={handleChangeValue}
              onBlur={formik.handleBlur}
              error={formik.errors.amount}
              isTouched={formik.touched.amount}
              disabled={false}
              index={0}
              max={finalPaidAmount}
            />
          <div
            className={`w-full mx-auto`}
          >
            <textarea
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={false}
              placeholder="Enter the Reason"
              className="border-[#707070] border-[0.5px] p-1 w-full min-h-[100px] max-h-[150px] rounded-[3px] resize-none"
            >
              {formik.values.reason}
            </textarea>
            {formik.touched?.reason && formik.errors?.reason && (
              <span className="text-red-500 absolute -bottom-6 right-0">
                {formik.errors?.reason}
              </span>
            )}
          </div>
        </div>
      </div>
      {allowOnlyActions("create") && <SubmitButton />}
    </div>
  )
}
