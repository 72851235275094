import { useEffect, useState, useRef } from "react";
import { createSearchParams } from "react-router-dom";
import { Loader } from "../../Elements/Loader/Loader";
import { onKeyDown } from "../../../Services/CommonFunctions";
import { AddItemsButton } from "../../Elements/AddItemsButton";
import { add_Feature } from "../../Formik/InitialValues";
import { InputBox } from "../../Elements/InputBox";
import {
  createCourseFeatures,
  updateCourseFeatures,
  getCourseFeatureById,
} from "../../../functions/admin/Course Features/courseFeatures";
import { useSharedLogic, FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { UploadOrUpdateBucket } from "../../CommonFunctions/CommonFileFunction";
import { AddFeaturesData } from "../../Formik/FormikValidation";
import toast from 'react-hot-toast';
import upload from "../../../Assets/upload.svg";
import { RandomString } from '../../../Services/CommonFunctions';
import { updateFile, uploadFile, deleteFile } from '../../../functions/storage/storage';

export const AddNewFeature = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false)
  const [totalFile, setTotalFile] = useState({} as any);
  const isId: any = id !== "new-feature" && id;
  const fileInputRef: any = useRef(null);
  
  useEffect(() => {
    (async () => {
      if (id !== "new-feature") {
        const response = await getCourseFeatureById(isId);
        const { feature, icon, storage_key }: any = response;
        const body: any = {
          feature: feature,
          icon: icon,
          storage_key: storage_key
        }
        if (response) {
          setValues({
            ...values,
            features: [body],
          },true)
        }
      }
    })()
  },[])

      const addMoreFeature = () => {
        const add = [
          ...values.features,
          { feature: "", icon: "", storage_key: "" },
        ];
        setFieldValue("features", add);
      };

      const deleteAddedFeature = (i: number) => {
        const newArray = [...values.features];
        newArray.splice(i, 1);
        setFieldValue("features", newArray);
      };

    const forNewFeature = async() => {
      setIsLoading(true)
      const body:any = [...values.features]
      const courseFeatures = await (id === "new-feature" ? createCourseFeatures(body) : updateCourseFeatures(body, isId));
      if (courseFeatures) {
        navigate({
          pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
          search: createSearchParams({
            id: `${
              id === "new-feature"
                ? "created-success"
                : "update-success"
            }`,
          }).toString(),
        })
      }
      setIsLoading(false)
    }

    const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(add_Feature, AddFeaturesData, forNewFeature);
  
    const handleChangeForFeature = (
      index: number,
      value: string,
      data: any
    ) => {
      setFieldValue(`features[${index}][${data}]`, value);
    };

  const handleValues:any = (response: any, Method: string, index:number, subBucket:string, file?: any, name?: any, randomKey?: string) => {
    if (['Upload', 'Update'].includes(Method)) {
      const cacheBuster = Date.now();
      const updatedArray: any = [...values.features];
      updatedArray[index] = {
        ...updatedArray[index],
        icon:
          mode !== "edit"
            ? response?.publicUrl
            : `${response?.publicUrl}?cb=${cacheBuster}`,
        storage_key: randomKey,
      };
      setFieldValue("features", updatedArray);
    } else {
      const updatedArray: any = [...values.features];
      updatedArray[index] = {
        ...updatedArray[index],
        icon: "",
        storage_key: "",
      };
      setFieldValue("features", updatedArray);
    }
  }

  const handleFileChange = async (event: any, i: number) => {
    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024;
    const randomKey = RandomString();
    const fileName = file?.name + `_${randomKey}`;
    if (file && file.size > maxSize) {
      toast.error('File size exceeds the limit (1MB).')
    } else {
      setTotalFile(file);
      if(mode!=="edit"){
        const reader = new FileReader();
        reader.onloadend = () => {
          const newData = [...values.features];
          newData[i] = { ...newData[i], icon: reader.result, storage_key: randomKey };
          setFieldValue("features", newData)
        };
        reader.readAsDataURL(file);
      }
    }
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const removeFiles = (index: number) => {
    const newData = [...values.features];
    newData[index] = { ...newData[index], icon: "", storage_key: "" };
    setFieldValue("features", newData)
  }
    
  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="bg-white w-10/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] pb-6"
        >
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              {id === "new-feature"
                ? "Add Course Features"
                : "Edit Course Features"}
            </p>
          </div>
          <div className="flex flex-col items-end py-10 w-11/12 mx-auto space-y-3">
            {id === "new-feature" && (
              <AddItemsButton
                text={"Course Features"}
                onClick={addMoreFeature}
                disabled={false}
              />
            )}
            <div className="shadow-md shadow-[#00000029] rounded-[5px] py-8 flex items-center justify-between gap-1 w-full px-6">
              <div className="flex items-center w-6/12">
                <InputBox
                  label={"Features 1"}
                  placeholder={"Features 1"}
                  topDivStyle={"flex flex-col w-9/12"}
                  classNameInput={"w-full"}
                  name={`features[${0}].feature`}
                  value={values.features[0]?.feature}
                  onChange={handleChangeForFeature}
                  onBlur={handleBlur}
                  index={0}
                  check={"feature"}
                  error={
                    errors?.features &&
                    errors?.features[0] &&
                    errors?.features[0].feature
                  }
                  isTouched={
                    touched?.features &&
                    touched?.features[0] &&
                    touched?.features[0].feature
                  }
                />
              </div>
              <div className="flex items-center gap-5">
                {values.features[0]?.icon && (
                  <div className="relative flex items-center justify-center pt-1">
                    <img
                      src={values.features[0]?.icon}
                      alt={"Icon"}
                      className="w-[100px]"
                    />
                    <div
                      onClick={()=>removeFiles(0)}
                      className="select-none bg-[#FF0000] w-5 h-5 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-3 -top-1"
                    >
                      x
                    </div>
                  </div>
                  )}
                  <div className="flex flex-col space-y-1 relative">
                    <div className="relative flex items-center justify-evenly gap-2 bg-[#036AD1] rounded-[20px] cursor-pointer p-1 px-4">
                      <label
                        htmlFor={id}
                        className="text-white cursor-pointer font-Roboto font-[500]"
                      >
                        Upload Icon
                      </label>
                      <input
                        id={id}
                        name={`features[0]['icon']`}
                        disabled={false}
                        type="file"
                        accept={"image/*"} // "image/*, video/*"
                        style={{ display: "none" }}
                        // ref={checkUploadRef}
                        onChange={(event:any)=>handleFileChange(event, 0)}
                      />
                      <img
                        src={upload}
                        alt="Upload Icon"
                        onClick={handleUploadClick}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <p className="font-Roboto font-[400] text-[12px] text-[#FF0000] text-center">
                      Image Size 100x100
                    </p>
                    {errors.features?.[0]?.icon && touched.features?.[0]?.icon && (
                      <span className="text-red-500 absolute -bottom-6 right-0">
                        {errors.features[0]?.icon}
                      </span>
                    )}
                  </div>
              </div>
              {/* <UploadOrUpdateBucket
                mode={mode}
                id={"features0"}
                labelText={"Upload Icon"}
                accept={"image/*"}
                fileSize={100 * 100}
                sizeInText={"Image Size 100x100"}
                Method={values.features[0]?.icon==="" ? "Upload" : "Update"}
                bucketName={"course_features"}
                forValue={handleValues}
                // fileNameData={values.name_key}
                fileNameKey={values.features[0]?.storage_key}
                preview={values.features[0]?.icon}
                index={0}
                containerStyle={"absolute top-0 -left-40"}
                name={`features[${0}].icon`}
                error={
                  formik.errors?.features &&
                  formik.errors?.features[0] &&
                  formik.errors?.features[0].icon
                }
                isTouched={
                  formik.touched?.features &&
                  formik.touched?.features[0] &&
                  formik.touched?.features[0].icon
                }
              /> */}
            </div>
            {values.features.map((data: any, i: number) => {
              if (i !== 0) {
                return (
                  <div className="shadow-md shadow-[#00000029] rounded-[5px] py-6 flex items-center justify-between gap-1 w-full px-6 relative">
                    <div className="flex items-center w-6/12">
                      <InputBox
                        label={`Features ${i + 1}`}
                        placeholder={`Features ${i + 1}`}
                        topDivStyle={"flex flex-col w-9/12"}
                        classNameInput={"w-full"}
                        name={`features[${i}].feature`}
                        value={data?.feature}
                        onChange={handleChangeForFeature}
                        onBlur={formik.handleBlur}
                        index={`${i}`}
                        check={"feature"}
                        error={
                          errors?.features &&
                          errors?.features[i] &&
                          errors?.features[i].feature
                        }
                        isTouched={
                          touched?.features &&
                          touched?.features[i] &&
                          touched?.features[i].feature
                        }
                      />
                    </div>
                    {/* <div className="flex items-center gap-5">
                      {values.features[i]?.icon && (
                        <div className="relative flex items-center justify-center pt-1">
                          <img
                            src={values.features[i]?.icon}
                            alt={"Icon"}
                            className="w-[100px]"
                          />
                          <div
                            onClick={()=>removeFiles(i)}
                            className="select-none bg-[#FF0000] w-5 h-5 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-3 -top-1"
                          >
                            x
                          </div>
                        </div>
                        )}
                        <div className="flex flex-col space-y-1 relative">
                          <div className="relative flex items-center justify-evenly gap-2 bg-[#036AD1] rounded-[20px] cursor-pointer p-1 px-4">
                            <label
                              htmlFor={`icon_${i}`}
                              className="text-white cursor-pointer font-Roboto font-[500]"
                            >
                              Upload Icon
                            </label>
                            <input
                              id={`icon_${i}`}
                              name={`features[${i}]['icon']`}
                              disabled={false}
                              type="file"
                              accept={"image/*"} // "image/*, video/*"
                              style={{ display: "none" }}
                              onChange={(event:any)=>handleFileChange(event, i)}
                            />
                            <img
                              src={upload}
                              alt="Upload Icon"
                              onClick={handleUploadClick}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <p className="font-Roboto font-[400] text-[12px] text-[#FF0000] text-center">
                            Image Size 100x100
                          </p>
                          {errors.features?.[i]?.icon && touched.features?.[i]?.icon && (
                            <span className="text-red-500 absolute -bottom-6 right-0">
                              {errors.features[i]?.icon}
                            </span>
                          )}
                        </div>
                    </div> */}
                    <UploadOrUpdateBucket
                      mode={mode}
                      id={`features${i + 1}`}
                      labelText={"Upload Icon"}
                      accept={"image/*"}
                      fileSize={100 * 100}
                      sizeInText={"Image Size 100x100"}
                      Method={values.features[i]?.icon==="" ? "Upload" : "Update"}
                      bucketName={"course_features"}
                      forValue={handleValues}
                      // fileNameData={values.name_key}
                      fileNameKey={values.features[i]?.storage_key}
                      preview={values.features[i]?.icon}
                      index={i}
                      containerStyle={"absolute top-0 -left-40"}
                      name={`features[${i}].icon`}
                      error={
                        errors?.features &&
                        errors?.features[i] &&
                        errors?.features[i].icon
                      }
                      isTouched={
                        touched?.features &&
                        touched?.features[i] &&
                        touched?.features[i].icon
                      }
                    />
                    <div
                      onClick={() => deleteAddedFeature(i)}
                      className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-8"
                    >
                      x
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="select-none flex justify-center items-center w-4/12 mx-auto">
            <button className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2.5 px-6 font-Roboto font-[400]">
              {id === "new-feature"
                ? "Add Course Features"
                : "Update Course Features"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
