import { Components } from "./ModuleImports";

export const UserInputs = ({ formik, showChangePassword, isEdit, mode, cityNames, cityData, branchNames, branchData, roleNames, roleData, courseNames, courseData }: any) => {
    const { InputBox, DropdownInputWithCheckbox }: any = Components;
  return (
    <div className="w-full grid grid-cols-2 gap-6">
      <InputBox
        label="First Name:"
        placeholder="First Name"
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"first_name"}
        value={formik.values.first_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.first_name}
        isTouched={formik.touched.first_name}
        disabled={false}
      />
      <InputBox
        label="Last Name:"
        placeholder="Last Name"
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"last_name"}
        value={formik.values.last_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.last_name}
        isTouched={formik.touched.last_name}
        disabled={false}
      />
      <InputBox
        label="Phone Number:"
        placeholder="Phone Number"
        type={"number"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"phone_number"}
        value={formik.values.phone_number}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.phone_number}
        isTouched={formik.touched.phone_number}
        disabled={false}
      />
      <InputBox
        label="Email:"
        placeholder="Email"
        type={"email"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"email"}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.email}
        isTouched={formik.touched.email}
        disabled={false}
      />
      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="City:"
        placeholder="City"
        options={cityNames}
        name={"city_names"}
        forId={"cities"}
        value={formik.values.city_names}
        onBlur={formik.handleBlur}
        mapData={cityData}
        mapThrough={"city_name"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-30"}
      />
      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="Branch:"
        placeholder="Branch"
        options={branchNames}
        name={"branch_names"}
        forId={"branches"}
        value={formik.values.branch_names}
        onBlur={formik.handleBlur}
        mapData={branchData}
        mapThrough={"branch_name"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-30"}
      />
      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="Roles:"
        placeholder="Roles"
        options={roleNames}
        name={"role_names"}
        forId={"roles_array"}
        value={formik.values.role_names}
        onBlur={formik.handleBlur}
        mapData={roleData}
        mapThrough={"role"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-20"}
      />
      {/* <InputBox
        label="Roles:"
        placeholder="Roles"
        type={"text"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"role_names"}
        value={formik.values.role_names}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.role_names}
        isTouched={formik.touched.role_names}
        disabled={true}
      /> */}
      <DropdownInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="Courses:"
        placeholder="Courses"
        options={courseNames}
        name={"course_names"}
        forId={"courses"}
        value={formik.values.course_names}
        onBlur={formik.handleBlur}
        mapData={courseData}
        mapThrough={"course_name"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={"z-20"}
      />
      {((isEdit && showChangePassword) || !isEdit) && (
        <>
          <InputBox
            label="Password:"
            placeholder="Password"
            type={"password"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.password}
            isTouched={formik.touched.password}
            disabled={false}
            onPaste={(e: any) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e: any) => {
              e.preventDefault();
              return false;
            }}
          />
          <InputBox
            label="Confirm Password:"
            placeholder="Confirm Password"
            type={"password"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"confirm_password"}
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.confirm_password}
            isTouched={formik.touched.confirm_password}
            disabled={false}
            onPaste={(e: any) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e: any) => {
              e.preventDefault();
              return false;
            }}
          />
        </>
      )}
    </div>
  );
}
