import ViewEditDeleteActionComponent from "../../Components/AllStudents/ActionComponent";
// import { sampleFunc } from "../../Services/Supabase/databaseOperations";
import { getAllStudentsTableData } from "../../functions/admin/Students/students";

export const studentData = {
  headers: [
    {
      name: "Name",
      apiKey: "name",
      headerClass: "w-[110px] text-start px-2",
      className: "w-[110px] text-start break-words px-2",
    },
    {
      name: "Phone Number",
      apiKey: "phoneNumber",
      headerClass: "w-[150px] text-start px-2",
      className: "w-[150px] text-start px-2",
    },
    {
      name: "E-Mail",
      apiKey: "email",
      headerClass: "w-[180px] text-start px-2",
      className: "w-[180px] break-words text-start px-2",
    },
    {
      name: "Course",
      apiKey: "courses",
      headerClass: "w-[200px] text-center px-2",
      className: "w-[200px] text-center px-2 break-words",
    },
    {
      name: "Course Mode",
      apiKey: "mode",
      headerClass: "w-[160px] text-center px-2",
      className: "w-[160px] text-center px-2 break-words",
    },
    {
      name: "Student Type",
      apiKey: "type",
      headerClass: "w-[150px] text-start px-2",
      className: "w-[150px] text-start px-2 break-words",
    },
    {
      name: "Registration Date",
      apiKey: "created_at",
      headerClass: "w-[130px] text-start px-2",
      className: "w-[130px] text-start px-2",
    },
    {
      name: "Branch",
      apiKey: "branches",
      headerClass: "w-[155px] text-start px-2",
      className: "w-[155px] text-start break-words px-2",
    },
    {
      name: "City",
      apiKey: "city",
      headerClass: "w-[155px] text-start px-2",
      className: "w-[155px] text-start break-words px-2",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[130px] text-center",
      className: "w-[130px] text-center",
    },
  ],
  BackendFunctionCall: getAllStudentsTableData,
  filters: {
    dateFilters: [
      {
        name: "Registration Date",
        apiKey: "created_at",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
    ],
    checkBox: [
      // {
      //   name: "Registration Date",
      //   apiKey: "created_at",
      //   isSearchNeeded: true,
      //   className: "w-[165px]",
      // },
      {
        name: "All Students",
        apiKey: "type",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
      {
        name: "City",
        apiKey: "city",
        isSearchNeeded: true,
        className: "w-[120px]",
      },
      {
        name: "Branch",
        apiKey: "branches",
        isSearchNeeded: true,
        className: "w-[120px]",
      },
      {
        name: "Course",
        apiKey: "courses",
        isSearchNeeded: true,
        className: "w-[250px]",
      },
      {
        name: "Course Mode",
        apiKey: "mode",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["name", "phoneNumber", "email"],
        searchKey: "courses",
        name: "Search....",
        searchStyle: "w-[220px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
};
