import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { BulkDelete, DeleteData, FetchData, InsertData } from "../../crud";
import { HiringCompaniesInterface } from "./interface";

export const getAllHiringCompanies = async () => {
    try {
        const { data: companies, error }: any = await FetchData(TableNames.hiringCompanies);
        if (error) throw error;
        return companies;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const createHiringCompanies = async (companiesData: HiringCompaniesInterface[]) => {
     try {
        const { data, error } = await InsertData(TableNames.hiringCompanies, companiesData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

// export const updateHiringCompanies = async (companiesData: Partial<HiringCompaniesInterface>)

export const deleteHiringCompanies = async (ids: number[] | string[]) => {
    try {
        const { data, error } = await BulkDelete(TableNames.hiringCompanies, ids);
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}