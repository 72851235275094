import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import downloadImg from "../../../Assets/downloadImg.svg"
 

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  //const [searchParams, setSearchParams] = useSearchParams();



  const navigateToViewDownload = async () => {
  //  console.log("Record Data ID",recordData.id)
  //  console.log("Student Resume", recordData.student_resume.url);
    
  const response = await fetch(recordData.student_resume.url);
  const blob = await response.blob();

  
  const blobUrl = URL.createObjectURL(blob);
    
     
     const downloadFileName = recordData.student_resume.fileName;
     
     const anchor = document.createElement("a");
     anchor.href = blobUrl;
     anchor.download = downloadFileName;; 
 
    
     document.body.appendChild(anchor);
     anchor.click();
    
    
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
       
      
       <div>
        <a href="#" onClick={navigateToViewDownload}>
          <img
            src={downloadImg}
            alt="Download"
            className="w-[28px] h-[28px]"
          />
        </a>
        </div>
    </div>
  );
};