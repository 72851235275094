import { useState, useEffect } from 'react';
import { Components } from './ModuleImports';
import { mapFunction } from '../../../Services/CommonFunctions';

export const CourseDetails = ({ formik, mode, cityData, cityNames, branchData, branchNames, scheduleData, Time, roomNames, handleOut, handleCourseMode, CourseModes }: any) => {
  const { DropdownInputWithCheckbox, DropdownSingleInputWithCheckbox, InputBox }: any = Components;
  const [scheduleDays, setScheduleDays] = useState([] as any);
  const weekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const weekend = ['Saturday', 'Sunday'];

  // useEffect(() => {
  //   if (formik.values.schedule === "Weekday") {
  //     formik.setFieldValue('schedule_days', [])
  //     setScheduleDays(weekday)
  //   } else if (formik.values.schedule === "Weekend") {
  //     formik.setFieldValue('schedule_days', [])
  //     setScheduleDays(weekend)
  //   } else if (formik.values.schedule === "Fasttrack") {
  //     setScheduleDays([ ...weekday, ...weekend ])
  //   } else {setScheduleDays([])}
  // },[formik.values.schedule])

  const handleOutForDays = (option: any, forId: string, name: any, mapData: any, mapThrough: any) => {
    if (formik.values.schedule_days?.includes(option)) {
      formik.setFieldValue(name, formik.values.schedule_days?.filter((sch:any)=> sch !== option))
    } else formik.setFieldValue(name, [...formik.values.schedule_days, option])
  }

    return (
      <div className="space-y-6">
        <div>
          <p className="text-[#686868] text-[21px] font-Roboto font-[400]">
            Course
          </p>
          <h1 className="text-[#000000] text-[26px] font-Roboto font-[500]">
            {formik.values.course ? formik.values.course : "-"}
          </h1>
        </div>
        <div className="grid grid-cols-3 gap-10">
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Mode"
            placeholder="Course Mode"
            options={CourseModes}
            name={"course_mode"}
            value={formik.values.course_mode}
            // forId={"course_mode"}
            onBlur={formik.handleBlur}
            mapThrough={"course_mode"}
            formik={formik}
            disabled={false}
            zIndex={"z-40"}
            handleOut={handleCourseMode}
            inputId={"course_mode"}
          />
          {/* <DropdownInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Mode"
            placeholder="course_mode"
            options={CourseModes}
            name={"course_mode"}
            value={formik.values.course_mode}
            onBlur={formik.handleBlur}
            mapData={branchData}
            mapThrough={"course_mode"}
            formik={formik}
            disabled={false}
            zIndex={"z-40"}
          /> */}
          <DropdownInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="City"
            placeholder="City"
            options={cityNames}
            name={"city"}
            forId={"city_id"}
            value={formik.values.city}
            handleOut={handleOut}
            onBlur={formik.handleBlur}
            error={formik.errors?.city}
            isTouched={formik.touched?.city}
            mapData={cityData}
            mapThrough={"city_name"}
            formik={formik}
            disabled={mode === "view"}
            zIndex={"z-40"}
          />
          <DropdownInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Branch"
            placeholder="Branch"
            options={branchNames}
            name={"branch"}
            forId={"branch_id"}
            value={formik.values.branch}
            handleOut={handleOut}
            onBlur={formik.handleBlur}
            error={formik.errors?.branch}
            isTouched={formik.touched?.branch}
            mapData={branchData}
            mapThrough={"branch_name"}
            formik={formik}
            disabled={mode === "view"}
            zIndex={"z-40"}
          />
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Rooms (Optional)"
            placeholder="Classrooms"
            options={roomNames}
            name={"rooms"}
            value={formik.values.rooms}
            onBlur={formik.handleBlur}
            mapThrough={"rooms"}
            formik={formik}
            disabled={mode === "view"}
            zIndex={"z-30"}
          />
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Course Schedule"
            placeholder="Course Schedule"
            options={scheduleData}
            name={"schedule"}
            value={formik.values.schedule}
            onBlur={formik.handleBlur}
            error={formik.errors?.schedule}
            isTouched={formik.touched?.schedule}
            mapThrough={"schedule"}
            formik={formik}
            zIndex={"z-30"}
            disabled={mode === "view"}
          />
          <DropdownInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Schedule Days"
            placeholder="Schedule Days"
            options={scheduleDays}
            name={"schedule_days"}
            forId={"schedule_days"}
            value={formik.values.schedule_days}
            handleOut={handleOutForDays}
            onBlur={formik.handleBlur}
            // error={formik.errors?.schedule_days}
            // isTouched={formik.touched?.schedule_days}
            // mapData={branchData}
            mapThrough={"schedule_days"}
            formik={formik}
            disabled={mode === "view"}
            zIndex={"z-30"}
          />
          <InputBox
            label="Course Duration"
            placeholder="Course Duration"
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"duration"}
            value={formik.values.duration}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.duration}
            isTouched={formik.touched.duration}
            disabled={mode === "view"}
          />
          <InputBox
            label="Start Date"
            placeholder="Start Date"
            type={"date"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"start_date"}
            value={formik.values.start_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.start_date}
            isTouched={formik.touched.start_date}
            disabled={mode === "view"}
          />
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="Start Time"
            placeholder="Start Time"
            options={Time.includes(formik.values.end_time) ? mapFunction(Time, formik.values.end_time) : Time}
            name={"start_time"}
            value={formik.values.start_time}
            onBlur={formik.handleBlur}
            error={formik.errors?.start_time}
            isTouched={formik.touched?.start_time}
            mapThrough={"start_time"}
            formik={formik}
            disabled={mode === "view"}
            zIndex={"z-20"}
          />
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            label="End Time"
            placeholder="End Time"
            options={Time.includes(formik.values.start_time) ? mapFunction(Time, formik.values.start_time) : Time}
            name={"end_time"}
            value={formik.values.end_time}
            onBlur={formik.handleBlur}
            error={formik.errors?.end_time}
            isTouched={formik.touched?.end_time}
            mapThrough={"end_time"}
            formik={formik}
            disabled={mode === "view"}
            zIndex={"z-20"}
          />
          <InputBox
            label="Planned Date of Completion"
            placeholder="Planned Date of Completion"
            type={"date"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"planned_date_of_completion"}
            value={formik.values.planned_date_of_completion}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // error={formik.errors.planned_date_of_completion}
            // isTouched={formik.touched.planned_date_of_completion}
            disabled={mode === "view"}
          />
          <InputBox
            label="End Date"
            placeholder="End Date"
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"end_date"}
            value={formik.values.end_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={true}
          />
        </div>
      </div>
    );
}
