import { useState, useEffect } from 'react';
import { createSearchParams } from 'react-router-dom';
import { InputBox } from '../../Elements/InputBox';
import { CloseIcon } from '../../Elements/CloseIcon';
import { getResourceCategoryById, createResourceCategory, updateResourceCategory } from '../../../functions/admin/Resources/ResourceCategory';
import { useSharedLogic, FormikCommonFunc } from '../../CommonFunctions/CommonFunc';
import { add_ResourceCategory } from '../../Formik/InitialValues';
import { AddResourseCategory } from '../../Formik/FormikValidation';
import { onKeyDown } from '../../../Services/CommonFunctions';
import { Loader } from '../../Elements/Loader/Loader';
import { UploadOrUpdateBucket } from '../../CommonFunctions/CommonFileFunction';

export const AddResourceCategory = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const isId = id !== "new-resource-category" && id;
  
  useEffect(() => {
    (async () => {
      setIsLoading(true)
      if (id !== "new-resource-category") {
        const categoryResponse:any = await getResourceCategoryById(id);
        const { category_name, fileName, storage_key, thumbnail, order }: any =
          categoryResponse;
        if (categoryResponse) {
          setValues({
            category_name, fileName, storage_key, thumbnail
          }, true);
          }
      }
      setIsLoading(false)
    })()
  }, [])

  const forAddResource = async () => {
    setIsLoading(true)
    const body: any = { ...values }
    const response = await (id === "new-resource-category" ? createResourceCategory(body) : updateResourceCategory(body, isId))
    if (response) {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({ id: `${id==="new-resource-category" ? "created-success" : "update-success"}`, mode: "resource-category" }).toString(),
      });
    }
    setIsLoading(false)
  }
  
  const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(add_ResourceCategory, AddResourseCategory, forAddResource);

  const handleValues:any = (response: any, Method: string, index:number, subBucket:string, file?: any, name?: any, randomKey?: string) => {
    if (['Upload', 'Update'].includes(Method)) {
      const cacheBuster = Date.now();
      setValues(
        {
          ...values,
          ...(mode !== "edit"
            ? { thumbnail: response?.publicUrl }
            : { thumbnail: `${response?.publicUrl}?cb=${cacheBuster}` }),
          ...(mode !== "edit" && { storage_key: randomKey }),
          ...(mode !== "edit" && { fileName: file?.name }),
        },
        true
      );
    } else {
      setValues({
        ...values,
        thumbnail: "",
        storage_key: "",
        fileName: ""
      },true)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown} className="bg-white w-7/12 mx-auto py-20 space-y-8 relative">
          <CloseIcon
            closeFunc={() =>
              navigate(-1)
            }
            classStyle="text-[33px] w-[40px] h-[40px] absolute -top-4 -right-4"
          />
          <div className="w-7/12 mx-auto space-y-6">
            <InputBox
              label="Resource Category"
              placeholder="Category"
              topDivStyle={"flex flex-col w-full"}
              classNameInput={"w-full"}
              name={"category_name"}
              value={values.category_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.category_name}
              isTouched={touched.category_name}
              disabled={mode === "view"}
            />
            <InputBox
              label="Order"
              placeholder="Order"
              type={'number'}
              topDivStyle={"flex flex-col w-full"}
              classNameInput={"w-full"}
              name={"order"}
              value={values.order}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.order}
              isTouched={touched.order}
              disabled={mode === "view"}
            />
            <div
              className={`min-h-[100px] lg:min-h-[130px] rounded-[5px] border-[1px] border-[#707070] border-dashed mx-auto flex flex-col items-center justify-center space-y-1`}
            >
              <UploadOrUpdateBucket
                mode={mode}
                id={"thumbnail"}
                labelText={"Upload Thumbnail"}
                accept={"image/*"}
                fileSize={100 * 100}
                sizeInText={"Image Size 100x100"}
                Method={values.thumbnail===""?"Upload":"Update"}
                bucketName={"resources"}
                forValue={handleValues}
                fileNameData={values.fileName}
                fileNameKey={values.storage_key}
                preview={values.thumbnail}
                name={'thumbnail'}
                error={errors?.thumbnail}
                isTouched={touched?.thumbnail}
              />
            </div>
          </div>
          <div className="flex justify-center items-center w-4/12 mx-auto">
            <button type='submit' className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-8 font-Roboto font-[400]">
              {id === "new-resource-category" ? "Submit" : "Update"}
            </button>
          </div>
        </form>)}
    </>
  );
}


