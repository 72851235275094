import React from 'react'

export const ChangeTrainerReason = ({ batch }:any) => {
  return (
    <div className="w-11/12 mx-auto">
      <div className="w-[97%] space-y-1">
        <h1 className="text-[#707070] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
          Reason for Changing the Trainer
        </h1>
        <div className="w-1/2 text-[#000000] text-[18px] font-Roboto font-[400]">
          {batch?.trainer_change_reason ? batch?.trainer_change_reason : "-"}
        </div>
      </div>
    </div>
  );
}
