import { Components } from "./ModuleImports";
import { dateFormat } from "../../../Services/CommonFunctions";

export const AdjustPayment = ({adjustPaymentData}:any) => {
    const { ReadData, formatNumberWithCommas }: any = Components;
  
  return (
    <div className="w-11/12 mx-auto space-y-2">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Adjust Payment
      </h1>
      <div>
        {adjustPaymentData.map((pay: any) => {
          return (
            <div className="shadow-md shadow-[#00000029]">
              <div className={`flex items-center justify-between w-[96%] p-4`}>
                <ReadData
                  head={"Date"}
                  body={dateFormat(pay?.created_at, "DD-MM-YYYY")}
                />
                <ReadData
                  head={"Amount"}
                  body={formatNumberWithCommas(pay.amount)}
                  addSpan={pay.amount}
                />
                <ReadData
                  head={"Adjusted From"}
                  body={pay?.from_training?.student?.name}
                />
                <ReadData
                  head={"Adjusted To"}
                  body={pay?.to_training?.student?.name}
                />
                <ReadData
                  head={"Phone Number"}
                  body={
                    pay?.idIsFromOrTo === "from"
                      ? pay?.to_training?.student?.phoneNumber
                      : pay?.from_training?.student?.phoneNumber
                  }
                />
                <ReadData
                  head={"Course"}
                  body={
                    pay?.idIsFromOrTo === "from"
                      ? pay?.to_training?.course?.course_name
                      : pay?.from_training?.course?.course_name
                  }
                />
                {/* <ReadData
                  head={"Total Paid Amount"}
                  body={formatNumberWithCommas(pay?.total_paid_amount)}
                  addSpan={pay?.total_paid_amount}
                /> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
