import { useState, useEffect } from "react";
import {
  getAllHiringCompanies,
  createHiringCompanies,
  deleteHiringCompanies,
} from "../../../functions/admin/Hiring Companies/hiringCompanies";
import { add_hiringCompanies } from "../../Formik/InitialValues";
import { deleteFile } from "../../../functions/storage/storage";
import { useAppSelector } from "../../..";
import { Loader } from "../../Elements/Loader/Loader";
import { onKeyDown } from "../../../Services/CommonFunctions";
import _ from "lodash";
import { FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { UploadOrUpdateBucket } from "../../CommonFunctions/CommonFileFunction";
import { IndividualCardForCompany } from "./Companies";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const UpdateHiringCompanies = ({ id, mode }: any) => {
  const [companies, setCompanies] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const { allowOnlyActions }: any = FunctionForPrivileges();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const readData = await getAllHiringCompanies();
      setCompanies(readData);
      setIsLoading(false);
    })();
  }, []);

  const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(add_hiringCompanies, "", "");

  const logoDeletion = async(type: string, hire: any) => {
    if (type === "Available") {
      const deleteResponse = await deleteHiringCompanies([hire.id]);
      const deleteFileData = await deleteFile(hire.storage_key, "hiring_companies");
      if (deleteResponse && deleteFileData) {
        const existingArray = [...companies];
        const filterArray = existingArray.filter((fil: any) => fil.storage_key !== hire.storage_key);
        setCompanies(filterArray);
      }
    } else {
      const deleteResponse = await deleteFile(hire.storage_key, "hiring_companies");
      if (deleteResponse) {
        const existingArray = [...values.hiring_companies];
        const filterArray = existingArray.filter((fil: any) => fil.storage_key !== hire.storage_key);
        setFieldValue("hiring_companies", filterArray);
      }
    }
  };

  const handleValues:any = async(response: any, Method: string, index:number, file: any, name: any, randomKey: string) => {    
    const createResponse = await createHiringCompanies([{
      company_logo: response?.publicUrl,
      created_by: userDetails,
      storage_key: randomKey,
    }]);
    if (createResponse) {
      setFieldValue("hiring_companies", [...values.hiring_companies, {
        company_logo: response?.publicUrl,
        created_by: userDetails,
        storage_key: randomKey,
      }]);
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-[95%]"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="bg-white w-[95%] mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6"
        >
          <div className="w-[95%] lg:w-9/12 xl:w-8/12 2xl:7/12 mx-auto flex items-center justify-around py-6 shadow-md shadow-[#00000029] rounded-[7px]">
            <h1 className="text-[#707070] text-[20px] lg:text-[23px] xl:text-[25px] font-Roboto font-normal">
              Add Hiring Companies
            </h1>
            {allowOnlyActions('create') &&
            <UploadOrUpdateBucket
              mode={mode}
              id={"hiring_companies1"}
              labelText={"Upload"}
              accept={"image/*"}
              fileSize={100 * 100}
              sizeInText={"Image Size 100x100"}
              Method={"Upload"}
              bucketName={"hiring_companies"}
              forValue={handleValues}
              // fileNameData={values.name_key}
              fileNameKey={values.hiring_companies}
              // preview={values.hiring_companies}
              containerStyle={"absolute top-0 -left-40"}
            />}
          </div>
          <div className="flex flex-col items-center space-y-16 py-4 pt-16">
            <IndividualCardForCompany
              existingCompanies={companies}
              currentCompanies={values.hiring_companies}
              closeFunc={logoDeletion}
              acceptDelete={allowOnlyActions('delete')}
            />
          </div>
        </form>
      )}
    </>
  );
};
