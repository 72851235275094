import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { useAppDispatch, useAppSelector } from "../../..";
import { setTransaction } from "../../../Redux/Reducers/transactionIDS";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const dispatch = useAppDispatch();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { selectedIds } = useAppSelector(({ transaction }: any) => transaction);
  const [searchParams, setSearchParams] = useSearchParams();
  const mapIds = selectedIds?.transIds?.map((item:any)=> item?.id)

  const navigateSummaryId = () => {
    // setSearchParams({
    //   id: `${recordData.id}`
    // });
    if (mapIds.includes(recordData?.id)) {
      dispatch(setTransaction({transIds: selectedIds?.transIds?.filter((item:any)=> item.id !== recordData?.id)}));
    } else {
      dispatch(
        setTransaction({
          transIds: [
            ...selectedIds?.transIds,
            { id: recordData?.id, mode_of_payment: recordData?.mode_of_payment, amount: recordData?.paid_amount },
          ],
        })
      );
    }
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
      {allowOnlyActions('edit') && <div className="cursor-pointer" onClick={navigateSummaryId}>
        <p className={`${((mapIds?.includes(recordData?.id))||(recordData?.is_verified)) ? 'bg-[#006E16] select-none' : 'bg-[#FF0303]'} text-[#fff] p-0.5 px-4 rounded-[3px]`}>
          {((mapIds?.includes(recordData?.id))||(recordData?.is_verified))?'Verified':'Verify'}</p>
      </div>}
    </div>
  );
};
