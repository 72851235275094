import { useEffect, useState } from "react";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { useAppSelector } from "../../..";
import { deleteFile } from "../../../functions/storage/storage";
import { onKeyDown } from "../../../Services/CommonFunctions";
import {
  getAllCertificate,
  createCertificate,
  deleteCertificate,
  updateCertificate,
} from "../../../functions/admin/Certificates/Certificates";
import { Loader } from "../../Elements/Loader/Loader";
import { FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { UploadOrUpdateBucket } from "../../CommonFunctions/CommonFileFunction";
import { CloseIcon } from "../../Elements/CloseIcon";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const AddCertificate = ({ id, mode }: any) => {
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const [isLoading, setIsLoading] = useState(false);
  const { allowOnlyActions }: any = FunctionForPrivileges();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const readData = await getAllCertificate();
      if(readData){
        const { certificate, id }: any = readData;
        setValues({
          ...values,
          certificate,
          storage_key: "01",
          id
        },true)
      }
      setIsLoading(false);
    })()
  }, [])

  const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc({
      certificate: "",
      id: null,
      created_by: userDetails
    }, "", "");
  
  const RemoveCertificate = async () => {
    if (values.id !== null) {
      const deleteResponse = await deleteCertificate(values.id);
      const deleteFileData = await deleteFile("01", "certificates");
      if (deleteResponse && deleteFileData) {
        setValues({
          ...values,
          certificate: "",
          id: null
        },true)
      }
    } else {
      const deleteResponse = await deleteFile("01", "certificates");
      if (deleteResponse) {
        setValues({
          ...values,
          certificate: "",
        },true)
      }
    }
  }

  const handleValues = async(response: any, Method: string, index:number, file: any, name: any, randomKey: string) => {    
    const createResponse = await (values.id===null?createCertificate({certificate: response?.publicUrl}):
      updateCertificate({certificate: response?.publicUrl}, values.id));
    if (createResponse) {
      setValues({
        ...values,
        certificate: response?.publicUrl,
      },true)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <CardLayoutTwo>
          <form
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
            className="py-4 w-7/12 mx-auto space-y-12 flex flex-col items-center"
          >
            <div className="w-full flex items-center justify-between px-4 shadow-[#00000029_0px_3px_8px] py-5 rounded-[7px]">
              <h1 className="text-[#707070] text-[20px] lg:text-[23px] xl:text-[25px] font-Roboto font-[500]">
                Add Certificate
              </h1>
              {((values.id === null && allowOnlyActions('create') || (values.id !== null && allowOnlyActions('edit')))) &&
              <UploadOrUpdateBucket
                mode={mode}
                id={"certificates"}
                labelText={"Upload"}
                accept={"image/*"}
                fileSize={100 * 100}
                sizeInText={"Image Size 100x100"}
                Method={"Upload"}
                bucketName={"certificates"}
                forValue={handleValues}
                fileNameKey={values.certificates}
              />}
            </div>
            {values.certificate ? (
              <div className="shadow-md shadow-[#00000029] rounded-[6px] flex items-center justify-center w-[240px] h-[300px] relative">
                {allowOnlyActions('delete') && 
                <CloseIcon
                  closeFunc={RemoveCertificate}
                  classStyle="text-[13px] w-[24px] h-[24px] absolute -top-3 -right-3"
                />}
                <img
                  src={values.certificate}
                  alt="Certificate"
                  className="object-fill w-full h-full"
                />
              </div>
            ) : (
              <div className="h-[300px]"></div>
            )}
          </form>
        </CardLayoutTwo>
      )}
    </>
  );
}
