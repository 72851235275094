import { ContactInfoSubmission } from './ContactInfoSubmission';
import { HeadingSection } from '../../Elements/HeadingSection';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';

export const ContactInfo = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  return (
    <div className="w-11/12 mx-auto">
      <HeadingSection head={"Contact Info"} />
      {allowOnlyActions('view') && <ContactInfoSubmission id={id} mode={mode} />}
    </div>
  );
}
