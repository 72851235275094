import React, { useEffect } from "react";
import {
  updateTrainerPayment,
  trainerTransactionData,
} from "../../../functions/admin/Action center/Trainer Payments/trainerPayment";
import { formatNumberWithCommas } from "../../../Services/CommonFunctions";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { dateFormat } from "../../../Services/CommonFunctions";
import { handleSubmitDetail } from "./BatchInfo";

export const StudentDetailsPreviewForTrainerPayment = ({
  trainerBasedStudents,
  id,
  batch,
  batchStatus,
  setBatchStatus,
  preview,
  setPreview,
  setIsLoading,
  total_trainer_payment_comment,
  setTotal_trainer_payment_comment,
  newTrainerPayment,
}: any) => {
  const { navigate, routePath }: any = useSharedLogic();
  const conditionForPreview =
    batchStatus !== "Fully Processed" &&
    !preview &&
    routePath &&
    routePath !== "process_trainer_pay";
  const conditionsNotToShow =
    preview || (routePath && routePath === "process_trainer_pay");

  useEffect(() => {
    if (batch?.total_trainer_payment !== "") {
      setTotal_trainer_payment_comment(batch?.total_trainer_payment_comment);
    }
  }, []);

  const mapAmount = trainerBasedStudents
    ?.flatMap((innerArray: any) =>
      innerArray?.map(
        (item: any) =>
          parseFloat(item?.trainer_payment?.toString()?.replace(/,/g, "")) || 0
      )
    )
    ?.reduce((sum: any, payment: any) => sum + payment, 0);

  const previewFunction = () => {
    const mapStatus = trainerBasedStudents?.filter(
      (stu: any) => stu?.student_payment_status !== "Calculated"
    );
    mapStatus?.length === 0 && setBatchStatus("Calculated");
    setPreview(true);
  };

  const submitFunction = async () => {
    setIsLoading(true);
    const { newEntries, conditionForCalculated, conditionForProcessed }: any =
      handleSubmitDetail(trainerBasedStudents, routePath);
    const withTransactions = newEntries?.[0].map((item: any) => item.trainer_transactions);
    const trainersWithTransaction = withTransactions?.filter(
      (tr: any) => tr?.trainer_payment !== 0 && (tr?.student_payment_status === "Partially Processed" || tr?.student_payment_status === "Processed")
    );
    // console.log(trainersWithTransaction, "trainersWithTransaction")
    const withoutTransactions = newEntries?.[0].map((item: any) => {
      const { trainer_transactions, ...rest } = item;
      return rest;
    });

    const filterProcessedPayments = [withoutTransactions]?.map((fil: any) =>
      fil.filter(
        (stu: any) =>
          stu.student_payment_status === "Processed" ||
          stu.student_payment_status === "Partially Processed"
      )
    );

    const mapAmountOfExistingTrainer = [withoutTransactions]
      .flatMap((innerArray: any) =>
        innerArray.map(
          (item: any) =>
            parseFloat(
              item?.existing_trainer_payment?.toString()?.replace(/,/g, "")
            ) || 0
        )
      )
      .reduce((sum: any, payment: any) => sum + payment, 0);
    const filterProcessedPaymentsLength = filterProcessedPayments?.reduce(
      (accumulator: any, currentArray: any) =>
        accumulator + currentArray.length,
      0
    );
    const currentDate: any = new Date();
    const body: any = {
      process_trainer_payment_date:
        filterProcessedPaymentsLength > 0
          ? dateFormat(currentDate, "DD MMM YYYY")
          : "",
      processed_trainer_payments: mapAmountOfExistingTrainer,
      // total_trainer_payment: mapAmount,
      total_trainer_payment_comment: total_trainer_payment_comment,
      batch_payment_status:
        routePath && routePath === "process_trainer_pay"
          ? conditionForProcessed
          : conditionForCalculated,
      training_data: [withoutTransactions],
    };
    // console.log(body, "body");
    const response = await updateTrainerPayment(body, Number(id), routePath);
    if (response) {
      if(trainersWithTransaction?.length > 0) {
        await trainerTransactionData(trainersWithTransaction);
      }
      navigate({
        pathname: `/action_center/${
          routePath && routePath === "cal_trainer_pay"
            ? "calculate_trainer_payment"
            : "process_trainer_payment"
        }`,
      });
    } else console.log("error");
    setIsLoading(false);
  };

  return (
    <div className="space-y-4 w-full flex flex-col items-center">
      {conditionForPreview && (
        <div className="w-6/12 mx-auto">
          <textarea
            className="w-full min-h-[100px] p-1 px-2 resize-none border-[0.5px] border-[#707070]"
            name="total_trainer_payment_comment"
            value={total_trainer_payment_comment}
            placeholder="Enter Commercial or Other Information*"
            onChange={(event: any) =>
              setTotal_trainer_payment_comment(event.target.value)
            }
            required
          >
            {total_trainer_payment_comment}
          </textarea>
        </div>
      )}
      {conditionsNotToShow && (
        <div className="space-y-2 text-center w-6/12 mx-auto">
          <h1 className="text-[#FF0000] text-[34px] font-Roboto font-[500]">
            Total Trainer Payment: ₹
            <span className="px-1">{formatNumberWithCommas(mapAmount)}</span>
          </h1>
          <p className="text-[#000000] text-[15px] font-Roboto font-[400]">
            {total_trainer_payment_comment}
          </p>
          {batchStatus !== "Fully Processed" && (
            <button
              onClick={submitFunction}
              type="submit"
              className="cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 text-white text-[17px] font-Roboto font-normal"
            >
              Submit
            </button>
          )}
        </div>
      )}
      {conditionForPreview && (
        <button
          onClick={previewFunction}
          type="submit"
          className="cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 text-white text-[17px] font-Roboto font-normal"
        >
          Preview
        </button>
      )}
    </div>
  );
};
