import { useState, useEffect, useRef  } from 'react';
import { Loader } from '../../Elements/Loader/Loader';
import { useFormik } from 'formik';
import { add_Batch } from '../../Formik/InitialValues';
import { onKeyDown } from '../../../Services/CommonFunctions';
import { DropdownInputWithCheckbox } from '../../Elements/DropdownInputWithCheckbox';
import { CourseDetails } from './CourseDetails';
import { TrainerDetails } from './TrainerDetails';
import { StudentDetails } from './StudentDetails';
import { InputBox } from '../../Elements/InputBox';
import { getPastStudentsPreloadData, getCourseBasedStudentsList, getBatchPreloadData, getBatchById, getAllBatches, createBatch, updateBatch, getTrainerBasedOnBranch, getTrainerBasedOnBatchTime, getStudentBasedOnTrainer, updateStudentTraining } from '../../../functions/admin/Batches/Batches';
import { useSharedLogic, FormikCommonFunc } from '../../CommonFunctions/CommonFunc';
import { DropdownSingleInputWithCheckbox } from '../../Elements/DropdownSingleInputWithCheckbox';
import { DropdownWithHandleOut } from '../../Elements/DropdownWithHandleOut';
import toast from 'react-hot-toast';
import { ReadData } from '../../Elements/ReadData';
import { createSearchParams } from 'react-router-dom';
import { AddBatch } from '../../Formik/FormikValidation';
import { isTime1GreaterThanTime2 } from '../../../Services/CommonFunctions';
import { ModalBox } from '../../UI_Modal/UI_Modal';

export const Components = {
  useState,
  useEffect,
  useRef,
  Loader,
  useFormik,
  add_Batch,
  onKeyDown,
  DropdownInputWithCheckbox,
  CourseDetails,
  TrainerDetails,
  StudentDetails,
  InputBox,
  getBatchPreloadData,
  useSharedLogic,
  FormikCommonFunc,
  DropdownSingleInputWithCheckbox,
  DropdownWithHandleOut,
  getCourseBasedStudentsList,
  getAllBatches,
  createBatch,
  updateBatch,
  getTrainerBasedOnBranch,
  getTrainerBasedOnBatchTime,
  getStudentBasedOnTrainer,
  updateStudentTraining,
  getBatchById,
  toast,
  ReadData,
  createSearchParams,
  AddBatch,
  isTime1GreaterThanTime2,
  ModalBox,
  getPastStudentsPreloadData
};