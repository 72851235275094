import searchLogo from "../../Assets/searchLogo.svg";


type SearchFieldProps = {
  handleClick?: () => void,
  searchStyle?:string
} & React.ComponentProps<"input">

export const SearchField = ({ placeholder, searchStyle, type, handleClick, ...rest }: SearchFieldProps) => {
  const alertEnterMessage = () => {
  }
  return (
    <div className="flex items-center relative mr-4">
      <input
        type={type}
        placeholder={placeholder}
        className={`bg-[#F9F9F9] ${searchStyle} h-[41px] rounded-l-[25px] outline-none px-4 placeholder:text-[16px] placeholder:font-Roboto placeholder:font-normal placeholder:text-[#036AD1]`}
        {...rest}
      />
      <div
        className="bg-[#036AD1] h-[41px] rounded-r-[25px] w-[45px] flex items-center justify-center cursor-pointer"
        onClick={rest.value?.toString().length ?handleClick:alertEnterMessage}
      >
        <img src={searchLogo} className="w-[18px] h-[18px] mr-1" alt="searchIcon"/>
      </div>
    </div>
  );
};
