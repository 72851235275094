import { ViewEditDeleteActionComponent } from "../../Components/Logs/ActionComponent";
import { getUserLogs } from "../../functions/admin/User Logs/userLogs";
export const userLog = {
  headers: [
    {
      name: "Admin Name",
      apiKey: "admin_name",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center break-words",
    },
    {
      name: "Module",
      apiKey: "module",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center",
    },
    {
      name: "Branch",
      apiKey: "branch",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center break-words",
    },
    {
      name: "City",
      apiKey: "city",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center break-words",
    },
    {
      name: "Changes",
      apiKey: "changes",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: getUserLogs,
  // bodyData: [
  //     {
  //         id: 1,
  //         admin_name: "Anu Radha",
  //         module: "Role Management",
  //         branch: "Classroom - Anna Nagar",
  //         city: "Chennai",
  //         changes: "Role Changes for Manager...",
  //     },
  //     {
  //         id: 2,
  //         admin_name: "Anu Radha",
  //         module: "Admin User Management",
  //         branch: "Online - Anna Nagar",
  //         city: "Chennai",
  //         changes: "2 New user Created...",
  //     },
  //     {
  //         id: 3,
  //         admin_name: "Anu Radha",
  //         module: "Student Management",
  //         branch: "Online",
  //         city: "Chennai",
  //         changes: "Changes in payment section...",
  //     },
  //     {
  //         id: 4,
  //         admin_name: "Anu Radha",
  //         module: "Batch Management",
  //         branch: "Classroom - Saravanampatti",
  //         city: "Coimbatore",
  //         changes: "New Batch Created...",
  //     },
  //     {
  //         id: 5,
  //         admin_name: "Anu Radha",
  //         module: "Student Management",
  //         branch: "Online",
  //         city: "Chennai",
  //         changes: "Changes in payment section...",
  //     },
  //     {
  //         id: 6,
  //         admin_name: "Anu Radha",
  //         module: "Role Management",
  //         branch: "Classroom - Velachery",
  //         city: "Chennai",
  //         changes: "Role Changes for Manager...",
  //     },
  //     {
  //         id: 7,
  //         admin_name: "Anu Radha",
  //         module: "Admin User Management",
  //         branch: "Online - Velachery",
  //         city: "Chennai",
  //         changes: "2 New user Created...",
  //     },
  //     {
  //         id: 8,
  //         admin_name: "Anu Radha",
  //         module: "Student Management",
  //         branch: "Online - Madurai",
  //         city: "Madurai",
  //         changes: "Changes in payment section...",
  //     },
  //     {
  //         id: 9,
  //         admin_name: "Anu Radha",
  //         module: "Batch Management",
  //         branch: "Classroom - Bangalore",
  //         city: "Chennai",
  //         changes: "2 New user Created...",
  //     },
  //     {
  //         id: 10,
  //         admin_name: "Anu Radha",
  //         module: "Content Management",
  //         branch: "Online - Madurai",
  //         city: "Madurai",
  //         changes: "Changes in payment section...",
  //     }
  // ],
  filters: {
    checkBox: [
      {
        name: "Date",
        apiKey: "date",
        isSearchNeeded: true,
        className: "md:w-[130px] lg:w-[200px] xl:w-[150px] 2xl:w-[160px]",
      },
      {
        name: "Admin Name",
        apiKey: "admin_name",
        isSearchNeeded: true,
        className: "md:w-[130px] lg:w-[200px] xl:w-[150px] 2xl:w-[160px]",
      },
      {
        name: "City",
        apiKey: "city",
        isSearchNeeded: true,
        className: " md:w-[130px] lg:w-[200px] xl:w-[150px] 2xl:w-[160px]",
      },
      {
        name: "Branch",
        apiKey: "branch",
        isSearchNeeded: true,
        className: " md:w-[130px] lg:w-[200px] xl:w-[150px] 2xl:w-[160px]",
      },
      {
        name: "Module",
        apiKey: "module",
        isSearchNeeded: true,
        className: " md:w-[130px] lg:w-[200px] xl:w-[150px] 2xl:w-[160px]",
      },
    ],
  },
  ActionComponent: ViewEditDeleteActionComponent,
};
