export const BuildJSON = (data: any, routePath:any) => {
    const Details: any = [
      {
        head: "Branch & City",
        body: `${data?.branch?.branch_name} ${data?.city?.city_name}`,
      },
      {
        head: "Rooms",
        body: data?.rooms?data?.rooms:'-',
      },
      {
        head: "Schedule",
        body: data?.schedule,
      },
      {
        head: "Course Duration",
        body: `${data?.duration} Hrs`,
      },
      {
        head: "Start Date",
        body: data?.start_date,
      },
      {
        head: "Start Time",
        body: data?.start_time,
      },
      {
        head: routePath&&routePath==="cal_trainer_pay"?"End Date":"Planned Date of Completion",
        body: routePath&&routePath==="cal_trainer_pay"?(data?.end_date?data?.end_date:"-"):(data?.planned_date_of_completion?data?.planned_date_of_completion:"-"),
      },
    ];
    return Details;
}

export const TrainerData = () => {
    const Details: any = [
        {
            trainerStat: 'Current Trainer',
            name: 'Shyam',
            number: '9876543210',
            email: 'user456@gmail.com'
        },
        {
            trainerStat: 'Past Trainer',
            name: 'Jeevan',
            number: '9876543210',
            email: 'user321@gmail.com'
        }
    ]
    return Details;
}

export const Students_Data = (list:any) => {
    const Details: any = [
        {
            studentsStat: "Current Students",
            data: list?.Allocated
        },
        {
            studentsStat: "Past Students (Dropped)",
            data: list?.Dropped
        },
        {
            studentsStat: "Past Students (Hold)",
            data: list?.Hold
        }
    ]
    return Details;
}