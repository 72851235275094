import { useState, useEffect } from "react";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { HeadingSection } from "../../Elements/HeadingSection";
import {
  createSearchParams,
} from "react-router-dom";
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { AddPost } from "./AddPost";
import { BackButton } from "../../Elements/BackButton";
import { MessageCard } from "../../Elements/MessageCard";
import { AddResourceCategory } from "./AddResourceCategory";
import { resources } from "../../../utils/json/resource";
import { resourcesCategory } from "../../../utils/json/resourcesCategory";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { getResourceCategoryTableData } from "../../../functions/admin/Resources/ResourceCategory";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const Resources = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const [resourceCatLength, setResourceCatLength] = useState(null);
  const { allowOnlyActions }: any = FunctionForPrivileges();
    
  useEffect(() => {
    (async () => {
      const readData: any = await getResourceCategoryTableData();
      setResourceCatLength(readData.length)
    })()
  },[])

    const categoryTable = () => {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({ id: "category" }).toString(),
      });
    };
  
    const navigateTo = mode && mode === "resource-category" ? categoryTable() :
    `/${routeNameForHome}/${routeNameForSubSection}`

  return (
    <div className="w-11/12 mx-auto">
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Resources Created Successfully"
                : "Resources Updated Successfully"
            }
            buttonText={"Manage Resources"}
            handleClick={() =>
              navigate(navigateTo)
            }
            closeFunc={() =>
              navigate(navigateTo)
            }
          />
        </div>
      )}
      {(id === "new-resource-category" || mode === "edit-resource") && (
        <div className="space-y-4">
          <AddResourceCategory id={id} mode={mode} />
        </div>
      )}
      {(id === "new-post" || mode === "edit-post") && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-[95%] justify-between"}
            header={"Resource"}
          />
          <AddPost id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions('view') && id === "category" && (
        <>
          <HeadingSection
            head={"Resources"}
            buttonType={"right"}
            buttonText={"Add Resource Category"}
            onClick={()=>createFunc("new-resource-category")}
            buttonImg={buttonWithRightArrow}
            linkName={allowOnlyActions('view') && "All Resource"}
            linkOnclick={() => navigate({
              pathname: `/${routeNameForHome}/${routeNameForSubSection}`
            })}
            allowCondition={allowOnlyActions("create")}
          />
          <TableComponent TABLE_JSON={resourcesCategory} />
        </>
      )}
      {allowOnlyActions('view') && !id && (
        <>
          <HeadingSection
            head={"Resources"}
            buttonType={"right"}
            buttonText={"Add Post"}
            onClick={()=>createFunc("new-post")}
            buttonImg={buttonWithRightArrow}
            linkName={allowOnlyActions('view') && "Resource Category"}
            count={resourceCatLength}
            linkOnclick={categoryTable}
            allowCondition={allowOnlyActions("create")}
          />
          <TableComponent TABLE_JSON={resources} />
        </>
      )}
    </div>
  );
};
