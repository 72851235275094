import { useState, useEffect } from "react";
import { SUPABASE_CLIENT } from "../../Services/Supabase";
import { useNavigate } from "react-router-dom";
import TopRightCircle from "../../Assets/TopRightCircle.svg";
import BottomLeftCircle from "../../Assets/BottomLeftCircle.svg";
import FitaLoginLogo from "../../Assets/FitaLoginLogo.png";
import LoginLogo from "../../Assets/LoginLogo.svg";
import OneAppLogin from "../../Assets/OneAppLogin.svg";
import { CommonInput } from "../Elements/CommonInput";
import { useFormik } from "formik";
import { loginValidation } from "../Formik/FormikValidation";
import { for_Login } from "../Formik/InitialValues";
import toast from "react-hot-toast";
import { useAppDispatch } from "../..";
import { setLoggedValue } from "../../Redux/Reducers/loggedInUserDetails";
import { PulseLoader } from "react-spinners";
import { FunctionForPrivileges } from "../CommonFunctions/Privileges";
import { getLoggedUserDetails } from "../../functions/admin/User Mangement/userMangement";
import { setUserRoles } from "../../Redux/Reducers/userSelectedRoles";
import { FetchData } from "../../functions/crud";
import { TableNames } from "../../functions/config/Tables";
import { setSignInData } from "../../Redux/Reducers/signInUserData";
import { useAppSelector } from "../..";

export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [disableButton, setDisableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { mapMainComponent, concatenatedSubArray, ForSubDomainsNew }: any =
    FunctionForPrivileges();
  // console.log(concatenatedSubArray, "concatenatedSubArray")
  const { userDetails } = useAppSelector(({ login }: any) => login);
  // console.log(userDetails, typeof userDetails, "userDetails");

  useEffect(() => {
    (async () => {
      if (userDetails !== "") {
        setIsLoading(true);
        if (window.navigator.onLine) {
          const menuAccessResponse: any = await getLoggedUserDetails(
            userDetails
          );
          dispatch(
            setUserRoles({
              selectedRoles: menuAccessResponse?.menuAccess,
            })
          );
          const { data: tableData, error }: any = await FetchData(
            TableNames.users
          ).eq("uid", userDetails);
          // console.log(tableData, "tableData");
          const {
            data: loginUserAccessRole,
            error: loginUserAccessRoleError,
          }: any = await FetchData(TableNames.roles, ["id", "role"]);
          const filteredTableData = loginUserAccessRole?.filter((entry: any) =>
            tableData?.[0]?.roles_array?.includes(entry.id)
          );
          const mapFilteredTableData =
            filteredTableData &&
            filteredTableData?.map((data: any) => data?.role);
          // console.log(tableData, "tableData");
          dispatch(
            setSignInData({
              data: [
                {
                  uid: userDetails,
                  name:
                    tableData?.[0]?.first_name.concat(
                      " ",
                      tableData?.[0]?.last_name
                    ) || "",
                  cities: tableData?.[0]?.cities,
                  branches: tableData?.[0]?.branches,
                  roles: mapFilteredTableData,
                  user_type: tableData?.[0]?.user_type,
                },
              ],
            })
          );
        } else toast.error("Please Check your Internet Connection");
        setIsLoading(false);
      }
    })();
  }, [userDetails]);

  const checkSubsection = ForSubDomainsNew?.filter(
    (sub: any) =>
      sub?.idkey !== "dashboard" && sub?.idkey === mapMainComponent?.[0]
  );

  const proceedLogin = async () => {
    try {
      if (window.navigator.onLine) {
        setDisableButton(true);
        const { data, error } = await SUPABASE_CLIENT.auth.signInWithPassword(
          values
        );
        if (error) {
          toast.error("Invalid Username/Email");
        } else {
          dispatch(
            setLoggedValue({
              userDetails: data?.user?.id,
            })
          );
          navigate(
            checkSubsection?.length > 0
              ? `/${mapMainComponent?.[0]}/${concatenatedSubArray?.[0]}`
              : `/${mapMainComponent?.[0]}`
          );
          toast.success("User LoggedIn Successfully");
        }
        setDisableButton(false);
      } else toast.error("Please Check your Internet Connection");
    } catch (error) {
      toast.error("An error occurred during login. Please try again.");
    }
  };

  const { values, errors, touched, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: for_Login,
      validationSchema: loginValidation,
      onSubmit: () => {
        proceedLogin();
      },
    });
    
  return (
    <>
      {isLoading ? (
        <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
          <PulseLoader color="#065FEF" size={8} />
        </div>
      ) : (
        <div className="bg-[#F9FCFF] h-screen flex items-center relative">
          <img
            src={BottomLeftCircle}
            alt="BottomLeftCircle"
            className="absolute bottom-0 left-0 w-[270px] xl:w-[320px] 2xl:w-[450px]"
          />
          <img
            src={TopRightCircle}
            alt="TopRightCircle"
            className="absolute top-0 right-0 w-[270px] xl:w-[320px] 2xl:w-[430px]"
          />
          <div className="mt-[50px] xl:mt-[60px] bg-white h-[75vh] lg:h-[75vh] w-10/12 2xl:w-9/12 mx-auto rounded-[9px] shadow-md shadow-[#BDDAF6] z-10 relative">
            {/* <div className="absolute md:-top-10 lg:-top-20 2xl:-top-24 z-30 w-full flex items-center justify-center">
                <img
                  src={OneAppLogin}
                  alt="OneAppLogin"
                  className="w-[270px] xl:w-[320px] 2xl:w-[1050px] h-[180px]"
                />
              </div> */}
            <div className="bg-white w-11/12 mx-auto flex items-center justify-around gap-10 py-10 h-full">
              <img
                src={LoginLogo}
                alt="LoginLogo"
                className="w-[250px] lg:w-[340px] xl:w-[400px] 2xl:w-[450px]"
              />
              <form
                onSubmit={handleSubmit}
                className="w-[431px] 2xl:w-[481px] flex flex-col justify-center items-center shadow-md shadow-[#00000029] rounded-[7px] space-y-10 2xl:space-y-10 py-8 lg:py-6 2xl:py-12"
              >
                <img
                  src={FitaLoginLogo}
                  alt="FitaLoginLogo"
                  className="md:w-[140px] lg:w-[200px]"
                />
                <div className="w-11/12 mx-auto flex flex-col items-center justify-center space-y-2 2xl:space-y-4">
                  <CommonInput
                    label={"Email Address"}
                    placeholderName={"example@gmail.com"}
                    type={"email"}
                    name={"email"}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    isTouched={touched.email}
                    labelStyle={"text-[#707070] text-[18px]"}
                    inputStyle={
                      "md:h-[45px] lg:h-[50px] shadow-md shadow-[#00000029]"
                    }
                  />
                  <CommonInput
                    label={"Password"}
                    placeholderName={"Enter password"}
                    type={"password"}
                    name={"password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    isTouched={touched.password}
                    labelStyle={"text-[#707070] text-[18px]"}
                    inputStyle={
                      "md:h-[45px] lg:h-[50px] shadow-md shadow-[#00000029]"
                    }
                    openEyeStyle={"top-2 lg:top-4"}
                    closeEyeStyle={"top-2 lg:top-4"}
                  />
                </div>
                <button
                  type="submit"
                  disabled={disableButton}
                  className={`bg-[#036AD1] shadow-md shadow-[#00000029] rounded-[3px] text-white font-Roboto font-medium w-4/12 py-2.5 2xl:py-3 ${
                    disableButton && "opacity-40"
                  }`}
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
