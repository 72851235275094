import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { DeleteData, FetchData, InsertData, UpdateData } from "../../crud";
import { CertificateInterface } from "./interface";

export const getAllCertificate = async () => {
    try {
        const { data: certificates, error }: any = await FetchData(TableNames.certificates);
        if (error) throw error;
        return certificates[0];
    } catch (error) {
        return errorNotifier(error);
    }
}

export const createCertificate = async (certificateData: CertificateInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.certificates, certificateData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateCertificate = async (certificateData: Partial<CertificateInterface>, certificateId: number | string) => {
    try {
        const { data, error } = await UpdateData(TableNames.certificates, certificateData, { conditionKey: "id", conditionValue: certificateId })
        if (error) throw error
        return true
    } catch (error) {
        return errorNotifier(error)
    }
}

export const deleteCertificate = async (certificateId: number) => {
    try {
        const { data, error } = await DeleteData(TableNames.certificates, { conditionKey: "id", conditionValue: certificateId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}