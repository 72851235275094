//import { dateFormat } from "../../../Services/CommonFunctions"
// import { errorNotifier } from "../../commonHelper"
// import { TableNames } from "../../config/Tables"
// import { FetchData } from "../../crud"

// export const getAppEnquiriesTableData = async () => {
//     try {
//         const { data: enquiries, error } = await FetchData(TableNames.app_enquiries, ["id","student:students(name, email, phoneNumber)", "course:courses(course_name)", "status", "created_at"])
//         const updatedData = enquiries?.map((el: any) => ({...el, course_name: el.course.course_name, student_name: el.student.name, student_email: el.student.email, student_mobile: el.student.phoneNumber, date: dateFormat(el.created_at, "DD MMM YYYY")}))
//         if (error) throw error
//         return updatedData
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }
import { dateFormat } from "../../../Services/CommonFunctions"
import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables"
import { FetchData,UpdateData } from "../../crud"
import {UpdateAppEnquiryInterface} from "./interface"

// export const getAppEnquiriesTableData = async () => {
//     try {
//         const { data: enquiries, error } = await FetchData(TableNames.app_enquiries, ["id", "student:students(name, email, phoneNumber)", "course:courses(course_name)", "status", "created_at"])
//         const updatedData = enquiries?.map((el: any) => ({...el, course_name: el.course.course_name, student_name: el.student.student_name, student_email: el.student.email, student_mobile: el.student_phoneNumber, date: dateFormat(el.created_at, "DD MMM YYYY")}))
//         if (error) throw error
//         return updatedData
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }
export const getAppEnquiriesTableData = async () => {
    try {
        const { data: enquiries, error } = await FetchData(TableNames.app_enquiries, ["id","student:students(uid,name, email, phoneNumber)", "course:courses(course_name)", "status", "created_at"])
        const updatedData = enquiries?.map((el: any) => ({...el, course_name: el?.course?.course_name, student_id: el.student?.uid, student_name: el.student.name, student_email: el.student.email, student_mobile: el.student.phoneNumber, date: dateFormat(el.created_at, "DD MMM YYYY")}))
        if (error) throw error
        return updatedData
    } catch (error) {
        return errorNotifier(error)
    }
}

// export const updateAppEnquiry = async (updateData: UpdateAppEnquiryInterface,appId:string|number) => {
//     try {
//         const { data, error } = await UpdateData(TableNames.app_enquiries, updateData,{conditionKey:"id",conditionValue:appId});
//         if (error) throw error;
//          console.log("Help",{data});
//         return true;
//     } catch (error) {
//         return errorNotifier(error);
//     }
// }

export const updateAppEnquiry = async (updateData: UpdateAppEnquiryInterface,appId:string|number) => {

    try {
     //   console.log("inside updateappenq",updateData)
         await UpdateData(TableNames.app_enquiries, {status: updateData.status,course:updateData.course, branch:updateData.branch},{conditionKey:"id",conditionValue:appId});
      //   console.log("inside updateappenq status",updateData.status, appId)
         if(updateData.status=="Moved To BC")

         await UpdateData(TableNames.students, { email:updateData.student_email,phoneNumber:updateData.student_mobile,name:updateData.student_name},

                                              {conditionKey:"uid",conditionValue:updateData.student_id})

       // console.log("After update")

        return true;

    } catch (error) {

        return errorNotifier(error);

    }

}






