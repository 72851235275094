import moment from "moment";

export const onKeyDown = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

//DD-MMM-YYYY

export const dateFormat=(dateText:string, dateFormat:string)=>{
    let text;
    if (moment(dateText).format(dateFormat) !== "Invalid date") {
      text = moment(dateText).format(dateFormat);
    } else {
      text = moment(new Date()).format(dateFormat);
    }
    return text;
}

export const closeByClickingOutside = (event: any) => {
    // console.log(event, "ALT")
};
    
export const RandomString = () => {
    var result='';
    var characters='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength=characters.length;
    for(var i=0;i<12;i++){
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const hrsTypeConversion = (count: number, type: string) => {
  if (count > 1 && !type.includes("s")) {
    return type + "s";
  } else if (count === 1 && type.includes("s")) {
    return type.split("s")[0];
  } else {
    return type;
  }
};

export const formatNumberWithCommas = (number:any) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const passNumber = (numValue: any) => {
  return parseFloat(numValue?.toFixed(1))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isTime1GreaterThanTime2 = (time1: string, time2: string) => {
  const startTimeParts = time1.split(":");
  const endTimeParts = time2.split(":");
  const startTimeHours = parseInt(startTimeParts[0], 10);
  const startTimeMinutes = parseInt(startTimeParts[1], 10);
  const endTimeHours = parseInt(endTimeParts[0], 10);
  const endTimeMinutes = parseInt(endTimeParts[1], 10);

  // Check if time1 is greater than or equal to time2
  return (
    startTimeHours > endTimeHours ||
    (startTimeHours === endTimeHours && startTimeMinutes > endTimeMinutes)
  );
};


export const TypeConversion = (data:any) => {
  return typeof data === "string"
    ? data?.replace(/,/g, "")
    : String(data)?.replace(/,/g, "");
}

export const mapFunction = (arr: any, val: any) => {
  return arr.filter((item:any) => item !== val);
}