import { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '../../..';
import { Loader } from '../../Elements/Loader/Loader';
import { onKeyDown } from '../../../Services/CommonFunctions';
import { InputBox } from '../../Elements/InputBox';
import { StatusButton } from '../../Elements/StatusButton';
import {
  createCategory,
  updateCategory,
  getCategoryById,
} from "../../../functions/admin/Categories/Categories";
import { AddCategory } from '../../Formik/FormikValidation';
import { createSearchParams } from "react-router-dom";
import { useSharedLogic, FormikCommonFunc } from '../../CommonFunctions/CommonFunc';
import { UploadOrUpdateBucket } from '../../CommonFunctions/CommonFileFunction';
import toast from 'react-hot-toast';
import upload from "../../../Assets/upload.svg";
import { RandomString } from '../../../Services/CommonFunctions';
import { updateFile, uploadFile, deleteFile } from '../../../functions/storage/storage';

export const AddNewCategory = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState<any>("Active");
  const [totalFile, setTotalFile] = useState({} as any);
  // const [existingURL, setExistingURL] = useState("");
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const isId: any = id !== "new-category" && id;
  const fileInputRef: any = useRef(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      if (id !== "new-category") {
        const editResponse = await getCategoryById(isId);
        const { category_name, key, name_key, status, thumbnail }:any =
          editResponse;
        if (editResponse) {
          // setExistingURL(name_key);
          setValues(
            {
              ...values,
              category_name,
              key,
              name_key,
              status,
              thumbnail,
            },
            true
          );
        }
      }
      setIsLoading(false);
    })()
  },[])

  const forNewCategory = async () => {
    if (values.key === "") {
        toast.error("Please Upload Thumbnail")
    } else {
        setIsLoading(true)
        if (values.thumbnail !== "") {
          const response: any = await (mode!=="edit" ? uploadFile : updateFile)({ file: totalFile, fileName: values.key }, 'categories');
          if(response) {
            const cacheBuster = Date.now();
            setValues({
              ...values,
              ...(mode !== "edit" ? { thumbnail: response?.publicUrl } : { thumbnail: `${response?.publicUrl}?cb=${cacheBuster}` }),
            }, true);
          }
        }
        const body = { ...values };
        const response = await (id === "new-category" ? createCategory(body) : updateCategory(body, isId));
          if (response) {
              navigate({
                pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
                search: createSearchParams({
                  id: `${
                    id === "new-category"
                      ? "created-success"
                      : "update-success"
                  }`,
                }).toString(),
              })
          }
        setIsLoading(false);
      }
    }

  const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc({
    category_name: "",
    status: "Active",
    created_by: userDetails,
    thumbnail: "",
    key: "",
    name_key: "",
  }, AddCategory, forNewCategory);
    
        const CheckValue = (label: any) => {
          setChecked(label);
          setFieldValue('status', label)
        };

  const handleFileChange = async(event: any) => {
    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024;
    const randomKey = RandomString();
    const fileName = file?.name + `_${randomKey}`;
    if (file && file.size > maxSize) {
      toast.error('File size exceeds the limit (1MB).')
    } else {
      setTotalFile(file);
      mode !== "edit" && setValues({
        ...values,
        key: randomKey,
        name_key: fileName
      }, true)
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue('thumbnail', reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
    
  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="bg-white w-10/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6"
        >
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              {id === "new-category" ? "Add New Category" : "Edit Category"}
            </p>
          </div>
          <div className="w-10/12 mx-auto py-10 space-y-14">
            <div className="w-full flex items-start justify-between">
              <InputBox
                label="Category:"
                placeholder="Category"
                topDivStyle={"flex flex-col w-7/12"}
                classNameInput={"w-full"}
                name={"category_name"}
                value={values.category_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.category_name}
                isTouched={touched.category_name}
                disabled={mode === "view"}
              />
              <div className="space-y-1 flex flex-col items-center relative">
                <div className="relative flex items-center justify-evenly gap-2 bg-[#036AD1] rounded-[20px] cursor-pointer p-1 px-4">
                  <label
                    htmlFor={id}
                    className="text-white cursor-pointer font-Roboto font-[500]"
                  >
                    Upload Thumbnail
                  </label>
                  <input
                    id={id}
                    name={"thumbnail"}
                    disabled={false}
                    type="file"
                    accept={"image/*"} // "image/*, video/*"
                    style={{ display: "none" }}
                    // ref={checkUploadRef}
                    onChange={handleFileChange}
                  />
                  <img
                    src={upload}
                    alt="Upload Icon"
                    onClick={handleUploadClick}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <p className="font-Roboto font-[400] text-[12px] text-[#FF0000] text-center">
                  Image Size 100x100
                </p>
                {errors.thumbnail && touched.thumbnail && (
                  <span className="text-red-500 absolute -bottom-6 right-0">
                    {errors.thumbnail}
                  </span>
                )}
                {values.thumbnail && (
                  <div className="relative flex items-center justify-center pt-1">
                    <img
                      src={values.thumbnail}
                      alt={"Thumbnail"}
                      className="w-[100px]"
                    />
                    <div
                      onClick={() => setFieldValue("thumbnail", "")}
                      className="select-none bg-[#FF0000] w-5 h-5 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-3 -top-1"
                    >
                      x
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <StatusButton
                type={"submit"}
                buttonText={
                  id === "new-category" ? "Add New Category" : "Update Category"
                }
                CheckValue={CheckValue}
                checked={checked}
                buttonWidth={"w-10/12"}
                show={mode !== "view"}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
