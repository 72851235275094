import React from 'react';
import { ButtonOnImageLeft } from './ButtonOnImageLeft';
import { ButtonOnImageRight } from './ButtonOnImageRight';

export const HeadingSection = ({
  head,
  buttonType,
  buttonText,
  onClick,
  buttonImg,
  linkName,
  count,
  linkOnclick,
  allowCondition
}: any) => {
  return (
    <div className="flex justify-between w-[96%] mx-auto mb-2">
      <h1 className="text-[30px] text-white font-Roboto font-medium">{head}</h1>
      <div className="flex items-center gap-6">
        {linkName && (
          <div className='space-y-1 cursor-pointer' onClick={linkOnclick}>
            <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
              {linkName}
              {count && <span className="ml-1">{`(${count})`}</span>}
            </p>
            <hr />
          </div>
        )}
        {allowCondition && buttonType === "left" && (
          <ButtonOnImageLeft
            text={buttonText}
            onClick={onClick}
            buttonImg={buttonImg}
          />
        )}
        {allowCondition && buttonType === "right" && (
          <ButtonOnImageRight
            text={buttonText}
            onClick={onClick}
            buttonImg={buttonImg}
          />
        )}
      </div>
    </div>
  );
};
