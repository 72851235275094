import { ViewEditDeleteActionComponent } from "../../Components/Batch Management/ActionComponent";
import { getBatchTableData } from "../../functions/admin/Batches/Batches";

export const Batchmanagement = {
  headers: [
    {
      name: "Batch Code",
      apiKey: "batch_code",
      headerClass: "w-[120px] text-start",
      className: "w-[120px] text-start",
    },
    {
      name: "Course",
      apiKey: "course_name",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[100px] text-start break-words px-2",
    },
    {
      name: "Course Mode",
      apiKey: "mode",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center break-words px-2",
    },
    {
      name: "Schedule",
      apiKey: "schedule",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[100px] text-start break-words px-2",
    },
    {
      name: "BC Name",
      apiKey: "check_CRM_User",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[100px] text-start break-words px-2",
    },
    {
      name: "Trainer",
      apiKey: "trainer_name",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[100px] text-start break-words px-2",
    },
    {
      name: "Trainer Phone Number",
      apiKey: "trainer_mobile",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Batch Progress(%)",
      apiKey: "batch_progress_in_percent",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center px-2",
    },
    {
      name: "Batch Status",
      apiKey: "status",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center px-2",
    },
    {
      name: "Batch Start Date",
      apiKey: "start_date",
      headerClass: "w-[110px] text-center px-2",
      className: "w-[110px] text-center px-2",
    },
    {
      name: "Batch Time",
      apiKey: "start_time",
      headerClass: "w-[110px] text-center px-2",
      className: "w-[110px] text-center px-2",
    },
    {
      name: "Branch",
      apiKey: "branch_name",
      headerClass: "w-[110px] text-center px-2",
      className: "w-[110px] text-center break-words px-2",
    },
    {
      name: "City",
      apiKey: "city",
      headerClass: "w-[100px] text-center px-2",
      className: "w-[100px] text-center break-words px-2",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return getBatchTableData(params);
  },
  filters: {
    dateFilters: [
      {
        name: "Batch Start Date",
        apiKey: "start_date",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
    ],
    checkBox: [
      // {
      //   name: "Batch Start Date",
      //   apiKey: "start_date",
      //   isSearchNeeded: true,
      //   className: "lg:w-[170px] xl:w-[150px] 2xl:w-[170px]",
      // },
      {
        name: "City",
        apiKey: "city",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        name: "Branch",
        apiKey: "branch_name",
        isSearchNeeded: true,
        className: "w-[140px]",
      },
      {
        name: "Course",
        apiKey: "course_name",
        isSearchNeeded: true,
        className: "w-[250px]",
      },
      {
        name: "Course Mode",
        apiKey: "mode",
        isSearchNeeded: true,
        className: "w-[200px]",
      },
      {
        name: "Batch Status",
        apiKey: "status",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Schedule",
        apiKey: "schedule",
        isSearchNeeded: true,
        className: "w-[130px]",
      },
      {
        name: "Batch Progress(%)",
        apiKey: "progress_range",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
    ],

    searchBox: [
      {
        matchFields: ["trainer_name", "trainer_mobile"],
        searchKey: "batch_statusCityTrainerBatch_code",
        name: "Trainer Search",
        searchStyle: "w-[220px]",
      },
      {
        matchFields: ["student_list", "student_Phone_Numbers"],
        searchKey: "stud",
        name: "Student Search",
        searchStyle: "w-[220px]",
      },
    ],
  },
  // ActionComponent: ViewEditDeleteActionComponent,
};
