import React from 'react';
import { CardDesign } from './CardDesign';
import { Bar } from 'react-chartjs-2';

export const Admissions = ({ DashboardData, response }: any) => {
    const options = {
        responsive: true,
        plugins: {
        legend: {
        display:false
        },
        },
    
        scales: {
        x: {
            grid: {
            display: false,
            },
                },
        
        y: {
            grid: {
            display: false,
            },
    
        },
    
        },
    };

    const data = {
        labels: (DashboardData[0].graph || []).map((d: any) => d.name),
        datasets: [{
        label: "Count",
        backgroundColor: "#036AD1",
        data: (DashboardData[0].graph || []).map((d: any) => d.count)
        }],
    }

  return (
        <div className="">
          <div></div>
          <div className="bg-[#F9FCFF] w-11/12 mx-auto shadow-[#00000029_0px_3px_8px] rounded-[8px]">
            {DashboardData.map((item: any) => {
              return (
                <div className="py-4">
                  <div className="w-11/12 mx-auto space-y-4">
                    <h1 className="font-Roboto font-[500] text-[24px]">
                      {item.title}
                    </h1>
                    <div className="flex items-center justify-between">
                      <div className="w-[50%]">
                        <Bar data={data} options={options} height="150px" />
                      </div>
                      <div className="w-[42%] grid grid-cols-2 gap-3">
                        {item.admissionDetails.map((item: any) => {
                          return <CardDesign data={item} />;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
  )
}
