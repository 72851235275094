import { formatNumberWithCommas } from "../../Services/CommonFunctions";

export const CardDesign = (data: any) => {
    const header = [
      "Total Invoice",
      "Classroom Invoice",
      "Online Invoice",
      "Hybrid Invoice",
      "Total Revenue",
      "Classroom Revenue",
      "Online Revenue",
      "Hybrid Revenue",
    ];
  return (
    <div className='w-full rounded-[5px] shadow-[#00000029_0px_3px_8px] p-1 px-2 pt-2 border'>
      <div className='flex items-center'>
        <div className={`rounded-full p-3 h-[50px] w-[50px] flex items-center`} style={{ backgroundColor: data.data?.className }}>
          {data.data?.logo==="x"?<p className="text-white font-Roboto font-[400] text-[30px] flex items-center justify-center w-full">x</p>:<img src={data.data?.logo} alt="SVG" />}
        </div>
        <h1 className='text-[#707070] font-[Roboto] opacity-[1] font-medium ml-2 text-[16px]'>{data.data?.name}</h1>
      </div>
      <div className='flex items-center justify-between mt-4 pl-4 pr-4 pb-4'>
        <h1 className='text-[#181818] font-Roboto text-[20px] font-bold mt-2'>
            {header.includes(data.data?.name)?currency.format(Math.round(parseInt(data.data?.count))):formatNumberWithCommas(data.data?.count)}
        </h1>
        <img src={data.data?.img} className='w-10 h-10'/>
      </div>
    </div>
  )
}

let currency = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumSignificantDigits: 3
});