import { useParams } from "react-router-dom";
import { AdminPanelDashboard } from "../Dashboard/AdminPanelDashboard";
import { AdminManagement } from "../AdminManagement/AdminManagementSubSection/AdminManagement";
import { AllStudents } from "../AllStudents/AllStudents";
import { ContentManagement } from "../Content Management/ContentManagement";
import { StudentManagement } from "../StudentManagement/StudentManagement";
import { BatchManagement } from "../Batch Management/BatchManagement";
import { CourseProgress } from "../Course Progress/CourseProgress";
import { ActionCenter } from "../Action Center/ActionCenterSubsections/ActionCenter";
import { HrStudentPlacement } from "../HR & Student Placement/HrStudentPlacement";
import { Logs } from "../Logs/Logs";
import AppEnquiries from "../AppEnquiries/AppEnquiries";
import { Reports } from "../Reports/Reports";

export const Components = {
  useParams,
  AdminPanelDashboard,
  AdminManagement,
  AllStudents,
  ContentManagement,
  StudentManagement,
  BatchManagement,
  CourseProgress,
  ActionCenter,
  HrStudentPlacement,
  Logs,
  AppEnquiries,
  Reports,
};
