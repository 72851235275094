import { useState, useEffect } from "react";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { currentApprovalQueue } from "../../../utils/json/CurrentApprovalQueue";
import { pastApprovalQueue } from "../../../utils/json/PastApprovalQueue";
import { rejectQueue } from "../../../utils/json/RejectQueue";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { useSearchParams } from "react-router-dom";
import {
  getApprovalQueueTableData,
  getPastApprovalQueueTableData,
  getRejectApprovalQueueTableData,
} from "../../../functions/admin/Action center/Student Actions/studentActions";
import { DropdownSingleInputWithCheckbox } from "../../Elements/DropdownSingleInputWithCheckbox";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { useAppSelector } from "../../..";

export const ApprovalQueue = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [queue, setQueue] = useState([
    {
      approval: "Current Approval Queue",
      len: null,
    },
    {
      approval: "Past Approval Queue",
      len: null,
    },
    {
      approval: "Rejected Queue",
      len: null,
    },
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "current_approval_queue",
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) {
        const currentApprovalResponse: any = await getApprovalQueueTableData(signInData?.data[0]);
        const pastApprovalResponse: any = await getPastApprovalQueueTableData(signInData?.data[0]);
        const rejectResponse: any = await getRejectApprovalQueueTableData(signInData?.data[0]);
        const currentApprovalLength = currentApprovalResponse?.length;
        const pastApprovalLength = pastApprovalResponse?.length;
        const rejectLength = rejectResponse?.length;

        // Update queue state with the lengths
        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: currentApprovalLength,
          },
          {
            ...prevQueue[1],
            len: pastApprovalLength,
          },
          {
            ...prevQueue[2],
            len: rejectLength,
          },
        ]);
      }
    })();
  }, []);

  return (
    <div>
      <div className="space-y-2">
        <div className="w-[95%] mx-auto flex items-center justify-between">
          <h1 className="text-[30px] text-white font-Roboto font-medium">
            Approval Queue
          </h1>
          {allowOnlyActions("view") && (
            <QueueType queue={queue} setQueue={setQueue} />
          )}
        </div>
        {id === "current_approval_queue" && (
          <TableComponent TABLE_JSON={currentApprovalQueue} backEndCallParams={signInData?.data[0]} />
        )}
        {id === "past_approval_queue" && (
          <TableComponent TABLE_JSON={pastApprovalQueue} backEndCallParams={signInData?.data[0]} />
        )}
        {id === "rejected_queue" && (
          <TableComponent TABLE_JSON={rejectQueue} backEndCallParams={signInData?.data[0]} />
        )}
      </div>
    </div>
  );
};

const QueueType = ({ queue, setQueue }: any) => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  const linkOnclick = (q: any) => {
    const lowercaseString = q?.toLowerCase();
    setSearchParams({
      id: lowercaseString?.replace(/ /g, "_"),
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue?.map((que: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center ${
              (index === 0 || index === 1) && "gap-8"
            }`}
            onClick={() => linkOnclick(que?.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {que?.approval}{" "}
                <span className="ml-1">{`(${que?.len ? que?.len : 0})`}</span>
              </p>
              {id === que?.approval?.toLowerCase().replace(/\s+/g, "_") ? (
                <hr />
              ) : (
                <p></p>
              )}
            </div>
            {(index === 0 || index === 1) && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        );
      })}
    </div>
  );
};
