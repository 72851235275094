import {useState, useEffect} from 'react'
import { TableComponent } from '../../Elements/Table/TableComponent'
import { dailyTransactionForUnVerified } from '../../../utils/json/DailyTransactionForUnVerified'
import { dailyTransactionForVerified } from '../../../utils/json/DailyTransactionForVerified';
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { useSearchParams } from 'react-router-dom';
import { getVerifiedTransactions, getUnVerifiedTransactions } from '../../../functions/admin/Action center/Transactions/transactions';
import { UnverifiedTableEntries } from './UnverifiedTransactionTable';
import { VerifiedTableEntries } from './VerifiedTableEntries';
import { dateFormat } from "../../../Services/CommonFunctions";
import { Range } from "react-date-range";
import moment from "moment";
import { useAppSelector, useAppDispatch } from "../../..";
import { setTransaction } from "../../../Redux/Reducers/transactionIDS";

export const DailyTransaction = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentUnVerifiedData, setCurrentUnVerifiedData] = useState([] as any);
  const [unVerifiedDates, setUnVerifiedDates] = useState([] as any);
  const [currentVerifiedData, setCurrentVerifiedData] = useState([] as any);
  const [verifiedDates, setVerifiedDates] = useState([] as any);
  const dispatch = useAppDispatch();
  const currentDate:any = new Date();

  const [queue, setQueue] = useState([
    { 
      verification: "UnVerified",
      len: null
    },
    {
      verification: "Verified",
      len: null
    }
  ] as any);
  const [selectedRange, setSelectedRange] = useState<Range[]>([
      {
      startDate: new Date(),
      endDate: new Date(),  //addDays(new Date(), 7),
      key: 'selection'
      }
  ]);
  const [startDate, setStartDate] = useState<any>(dateFormat(currentDate, "DD-MM-YYYY"));
  const [endDate, setEndDate] = useState<any>(dateFormat(currentDate, "DD-MM-YYYY"));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSearchParams({
      id: "unverified",
    });
    dispatch(setTransaction({transIds: []}));
  },[])

  useEffect(() => {
    (async () => { 
      const currentDate:any = new Date();
      const verifiedResponse: any = await getVerifiedTransactions(signInData?.data[0]);
      const currentDateForVerified = filterDataByDateRange(verifiedResponse);
      setCurrentVerifiedData(currentDateForVerified)
      const unVerifiedResponse: any = await getUnVerifiedTransactions(signInData?.data[0]);
      const currentDateForUnVerified = filterDataByDateRange(unVerifiedResponse);
      setCurrentUnVerifiedData(currentDateForUnVerified);

      const mapDatesForVerified =
        currentDateForVerified &&
        currentDateForVerified?.map((fil: any) =>
          fil?.created_at
        );
      setVerifiedDates(mapDatesForVerified);

      const mapDatesForUnVerified =
        currentDateForUnVerified &&
        currentDateForUnVerified?.map((fil: any) =>
          fil?.created_at
        );
      setUnVerifiedDates(mapDatesForUnVerified);
      // console.log(startDate, endDate, "startDate || endDate");
      // console.log(mapDatesForUnVerified, "mapDatesForUnVerified")
      const verifiedLength = mapDatesForVerified?.length;
      const unVerifiedLength = mapDatesForUnVerified?.length;

      // Update queue state with the lengths
      setQueue((prevQueue:any) => [
        {
          ...prevQueue[0],
          len: unVerifiedLength
        },
        {
          ...prevQueue[1],
          len: verifiedLength
        }
      ]);
    })() 
  }, [startDate, endDate, isLoading])

  const handleDateRangeForFilter = (items:Range) => {
    // console.log(items, "items")
    setStartDate(moment(items?.startDate).format("DD-MM-YYYY"))
    setEndDate(moment(items?.endDate).format("DD-MM-YYYY"))
    setSelectedRange([items])
  }

  function dateReverseFormat(dateStr: string): string { 
    let splitTime = dateStr?.split("-").reverse().join("-");
    return splitTime;
  }

  const filterDataByDateRange = (unVerifiedResponse:any) => {
    // console.log(startDate, endDate, "startDate || endDate")
    if (!startDate || !endDate || !unVerifiedResponse) {
      return [];
    }

    const formattedStartDate = dateReverseFormat(startDate);
    const formattedEndDate = dateReverseFormat(endDate);

        const startTimestamp = new Date(formattedStartDate).getTime();
        // console.log(startTimestamp, "startTimestamp",formattedStartDate)
        const endTimestamp = new Date(formattedEndDate).getTime()
        // console.log(endTimestamp, formattedEndDate, "endTimestamp")

    return unVerifiedResponse && unVerifiedResponse?.filter((item: any) => {
      const itemTimestamp = new Date(
        dateReverseFormat(item.created_at)
      ).getTime();
      // console.log(item.created_at,"created")
      // console.log({itemTimestamp,startTimestamp,endTimestamp})
      // console.log(itemTimestamp, "itemTimestamp")
      return itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp;
    });
  };

  return (
    <div>
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="space-y-2">
            <div className="w-11/12 mx-auto flex items-center justify-between">
              <h1 className="text-[30px] text-white font-Roboto font-medium">
                Daily Transactions
              </h1>
              {allowOnlyActions("view") && (
                <QueueType queue={queue} setQueue={setQueue} />
              )}
            </div>
          </div>
          {id === "verified" && (
            // <TableComponent
            //   TABLE_JSON={dailyTransactionForVerified}
            //   backEndCallParams={signInData?.data[0]}
            // />
            <VerifiedTableEntries
              data={currentVerifiedData}
              setCurrentVerifiedData={setCurrentVerifiedData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              verifiedDates={verifiedDates}
              handleDateRangeForFilter={handleDateRangeForFilter}
              selectedRange={selectedRange}
            />
          )}
          {id === "unverified" && (
            // <TableComponent
            //   TABLE_JSON={dailyTransactionForUnVerified}
            //   backEndCallParams={signInData?.data[0]}
            // />
            <UnverifiedTableEntries
              data={currentUnVerifiedData}
              setCurrentUnVerifiedData={setCurrentUnVerifiedData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              unverifiedDates={unVerifiedDates}
              handleDateRangeForFilter={handleDateRangeForFilter}
              selectedRange={selectedRange}
            />
          )}
        </div>
      )}
    </div>
  );
}

const QueueType = ({ queue, setQueue }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const linkOnclick = (q: any) => {
    const lowercaseString = q?.toLowerCase();
      setSearchParams({
        id: lowercaseString?.replace(/ /g, "_"),
      }); 
    }

  return (
    <div className="flex items-center gap-6"> 
      {queue?.map((que: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center ${index === 0 && "gap-8"}`}
            onClick={() => linkOnclick(que?.verification)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {que?.verification}{" "}
                <span className="ml-1">{`(${que?.len ? que?.len : 0})`}</span>
              </p>
              {id === que?.verification?.toLowerCase().replace(/\s+/g, "_") ? (
                <hr />
              ) : (
                <p></p>
              )}
            </div>
            {index === 0 && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        );
      })}
    </div>
  );
}


