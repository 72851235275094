import React from 'react'
import { TableComponent } from '../../Elements/Table/TableComponent'
import { processTrainerPayment } from '../../../utils/json/ProcessTrainerPayment'
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from '../../..';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';

export const ProcessTrainerPayment = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  return (
    <div>
      {!id && <div className="space-y-2">
        <h1 className="w-[95%] mx-auto text-[30px] text-white font-Roboto font-medium">Process Trainer Payment</h1>
        {allowOnlyActions('view') && <TableComponent
          TABLE_JSON={processTrainerPayment}
          backEndCallParams={signInData?.data[0]}
        />}
      </div>}
    </div>
  )
}
