import React from "react";
import bell_with_notification from "../../Assets/bell_with_notification.svg";
import { useAppSelector } from "../..";

export const LoginUserDetails = () => {
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const words = signInData && signInData?.data[0]?.name.split(" ");
  const individualName = words && words.map((word: any) => word[0]).join("");

  return (
    <div className="flex items-center justify-center gap-2 absolute right-0 top-2">
      <img
        src={bell_with_notification}
        alt="bell_with_notification"
        className="text-white w-9 h-9 cursor-pointer"
      />
      <div className="bg-white w-9 h-9 flex items-center justify-center text-[#036AD1] rounded-full select-none">
        {individualName}
      </div>
      <div className="pr-10 select-none">
        <h1 className="text-[16px] 2xl:text-[18px] font-[500] font-Roboto text-white">
          {signInData && signInData?.data[0]?.name}
        </h1>
        <p className="text-[12px] 2xl:text-[13px] font-Roboto text-white">
          {signInData && signInData?.data[0]?.roles?.join(', ')}
        </p>
      </div>
    </div>
  );
};
