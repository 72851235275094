import { getImports } from "./ModuleImports";
import { useAppSelector } from "../../..";
import { AddPaymentFollowUp } from "../../Formik/InitialValues";

export const StudentAttendanceFollowUp = ({id}: any) => {
    const {
      useState,
      CloseIcon,
      useSharedLogic,
      createSearchParams,
      toast,
      addStudentAttendanceFollowUpDate,
      FormikCommonFunc,
    }: any = getImports;
    const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
    const { userDetails } = useAppSelector(({ login }: any) => login);

    const DataSubmit = async () => {
        const { added_by, training_id, ...rest }: any = values;
        const body: any = {
            added_by: userDetails,
            training_id: id,
            ...rest
        }
        const sendResponse = await addStudentAttendanceFollowUpDate(body);
        if (sendResponse) {
            navigate({
                pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
                search: createSearchParams({ id: id, mode: 'edit' }).toString(),
            });
        }
    }

    const {
      formik,
      values,
      errors,
      touched,
      handleBlur,
      handleSubmit,
      handleChange,
      setValues,
      setFieldValue,
    }: any = FormikCommonFunc(AddPaymentFollowUp, "", DataSubmit);

    return (
      <form className="bg-white shadow-md shadow-[#00000029] rounded-[6px] w-5/12 mx-auto py-4 space-y-4 relative">
        <CloseIcon
            closeFunc={() => navigate(-1)}
          classStyle="text-[33px] w-[40px] h-[40px] absolute -top-4 -right-4"
        />
        <div className="w-10/12 mx-auto space-y-4">
          <div className="relative">
            <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
              Attendance Follow-up Date
            </label>
            <input
              autoFocus
              required
              type={"date"}
              name={"follow_up_date"}
              placeholder="01/01/2023"
              value={values.follow_up_date}
              onBlur={handleBlur}
              onChange={(e: any) =>
                setFieldValue("follow_up_date", e.target.value)
              }
              className="resize-none outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full"
            />
            {errors.follow_up_date && touched.follow_up_date && (
              <span className="text-red-500 absolute -bottom-6 right-0">
                {errors.follow_up_date}
              </span>
            )}
          </div>
          <div className="relative">
            <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
              Comment
            </label>
            <textarea
              required
              name={"comment"}
              placeholder="Comment"
              value={values.comment}
              onChange={(e: any) => setFieldValue("comment", e.target.value)}
              className="resize-none outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full min-h-[130px]"
            >
              {values.comment}
            </textarea>
            {errors.comment && touched.comment && (
              <span className="text-red-500 absolute -bottom-6 right-0">
                {errors.comment}
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center w-4/12 mx-auto">
          <button
            type="button"
            onClick={DataSubmit}
            className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-8 font-Roboto font-[400]"
          >
            Submit
          </button>
        </div>
      </form>
    );
}