import certificateSample from "../../../Assets/certificateSample.png";
import { ReadData } from '../../Elements/ReadData';
import { BuildJSON } from './Json';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import { Progressbar } from "../../Elements/Progressbar";
import { createSearchParams } from "react-router-dom";

export const BasicInfo = ({
  basicInfoDetails,
}: any) => {
  const { id, courseId }: any = useSharedLogic();
  const isId: any = id !== "new-student-course" && id;
  const BasicInfo = BuildJSON(basicInfoDetails);

  return (
    <div className="space-y-4 w-11/12 mx-auto py-6">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Basic Info
      </h1>
      <div className="w-11/12 mx-auto flex justify-between">
        <div className="w-[48%] space-y-3">
          {BasicInfo?.map((read: any) => {
            if (read?.body !== "") {
              return <ReadData head={read.head} body={read.body} />;
            }
          })}
        </div>
        <div className="w-[48%] space-y-4">
          <Certificate basicInfoDetails={basicInfoDetails} />
          {Object.keys(basicInfoDetails?.batch_data).length !== 0 &&
            <CourseProgress
              basicInfoDetails={basicInfoDetails}
          />}
        </div>
      </div>
    </div>
  );
};

const Certificate = ({basicInfoDetails}:any) => {
  const courseName = basicInfoDetails?.updatedStudentData?.course_id?.course_name;
    return(
        <div className='w-[60%]'>
            <ReadData head={'Course'} body={courseName} />
            <img src={certificateSample} alt="Sample" />
        </div>
    )
}

const CourseProgress = ({basicInfoDetails}:any) => {
    return (
      <div className="w-[60%] flex items-center justify-around shadow-md shadow-[#00000029] py-1.5 rounded-[4px]">
        <ReadData
          head={"Batch Progress"}
          body={`${
            basicInfoDetails?.batch_data?.student_progress_in_hrs
              ? basicInfoDetails?.batch_data?.student_progress_in_hrs
              : 0
          }/${basicInfoDetails?.batch_data?.duration ? basicInfoDetails?.batch_data?.duration : 0} Hrs`}
        />
        <Progressbar
          percentage={
            basicInfoDetails?.batch_data?.student_progress
              ? basicInfoDetails?.batch_data?.student_progress
              : 0
          }
          mainDivStyle={"w-[40px] h-[40px]"}
        />
      </div>
    );
}