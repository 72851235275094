import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables";
import { FetchData, FetchDataById, InsertData, UpdateData } from "../../crud";
import { CourseMappingInterface } from "./interface";

export const getAllCourseMapping = async() => {
    try {
        const { data: course_map, error } = await FetchData(TableNames.course_mapping);
        if (error) throw error;
        return course_map;
    } catch (error) {
        return errorNotifier(error);
    }
} 

export const getCourseMappingTableData = async () => {
    try {
        const { data: course_map, error } = await FetchData(TableNames.course_mapping, ["id", "mapping_course", "courses", "status"]);

        const courseMapData = course_map?.map((mp: any) => ({...mp, no_of_courses: mp?.courses.length}))

        if (error) throw error;
        return courseMapData;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getCourseMappingById = async (mappingCourseId: string | number) => {
    try {
        const { data: course_map, error } = await FetchDataById(TableNames.course_mapping, mappingCourseId);
        if (error) throw error; 
        return course_map[0];
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const createCourseMapping = async (courseMapData: CourseMappingInterface) => {
    try {
        courseMapData.name_key = courseMapData?.mapping_courses?.toLowerCase()
    const { data, error } = await InsertData(TableNames.course_mapping, courseMapData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCourseMappingPreloadData = async (UsersDetails:any) => {
    try {
        const {data: courses} = await FetchData(TableNames.courses, ["id", "course_name"]).overlaps("branches", UsersDetails?.branches)
        return courses
    } catch (error) {
        return errorNotifier(error);
    }
}

export const updateCourseMapping = async (courseMapData: Partial<CourseMappingInterface>, mappingCourseId: string | number) => {
    try {
        const { data, error } = await UpdateData(TableNames.course_mapping, courseMapData, { conditionKey: "id", conditionValue: mappingCourseId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}