
// Past Approval Queue

import { ViewEditDeleteActionComponent } from "../../Components/Action Center/Students Actions Approval Queue/ActionComponent";
import { getPastApprovalQueueTableData } from "../../functions/admin/Action center/Student Actions/studentActions";

export const pastApprovalQueue = {
    headers: [
        {
            name: "Approved Date",
            apiKey: "approved_date",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Request Date",
            apiKey: "requested_date",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Registration Date",
            apiKey: "registration_date",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Student Name",
            apiKey: "student_name",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Phone Number",
            apiKey: "student_phone_number",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Student Email",
            apiKey: "student_email",
            headerClass: "w-[150px] text-start px-2",
            className: "w-[150px] text-start px-2 break-words",
        },
        {
            name: "Course",
            apiKey: "course_name",
            headerClass: "w-[140px] text-start px-2",
            className: "w-[140px] text-start px-2 break-words",
        },
        {
            name: "Status",
            apiKey: "status",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Course Progress",
            apiKey: "courseProgress",
            headerClass: "w-[100px] text-center px-2",
            className: "w-[100px] text-center px-2 break-words",
        },
        {
            name: "Total Fee",
            apiKey: "total_fee",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Balance Due",
            apiKey: "balanceDue",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Branch",
            apiKey: "branchName",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "City",
            apiKey: "cityName",
            headerClass: "w-[120px] text-start px-2",
            className: "w-[120px] text-start px-2 break-words",
        },
        {
            name: "Action",
            apiKey: "Courses",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
            headerClass: "w-[100px] text-center",
            className: "text-center",
        },
    ],
    BackendFunctionCall: getPastApprovalQueueTableData,
    filters: {
        checkBox: [
            {
                name: "Request Date",
                apiKey: "request_date",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "City",
                apiKey: "city",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Branch",
                apiKey: "branch",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Status",
                apiKey: "status",
                isSearchNeeded: true,
                className: "w-[200px]",
            }
        ]
    },
    ActionComponent: ViewEditDeleteActionComponent,
};