import ViewEditDeleteActionComponent from "../../Components/Content Management/CourseCategory/ActionComponent";
import { getCategoryTableData } from "../../functions/admin/Categories/Categories";

export const manageCategory = {
  headers: [
    {
      name: "Category",
      apiKey: "category_name",
      headerClass: "w-[200px] text-start px-2",
      className: "w-[200px] text-start break-words px-2",
    },
    {
      name: "Courses",
      apiKey: "courses",
      headerClass: "w-[200px] text-center",
      className: "w-[200px] text-center",
    },
    {
      name: "Status",
      apiKey: "status",
      headerClass: "w-[100px] text-start",
      className: "w-[100px] text-start",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: getCategoryTableData,
  filters: {
    searchBox: [
      {
        matchFields: ["category_name", "courses"],
        searchKey: "status",
        name: "Search",
        searchStyle: "w-[220px]",
      },
    ],
  },
};
