export const CommentSection = ({formik,mode}:any) => {
    return (
      <div className="flex items-end justify-between">
        <div className="space-y-1 w-[45%]">
          <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
            Comments
          </h1>
          <textarea
            name="comments"
            value={formik.values.comments}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={mode === "view"}
            placeholder="Comments here"
            className="border-[#707070] border-[0.5px] p-1 w-full min-h-[100px] max-h-[150px] rounded-[3px] resize-none"
          >
            {formik.values.comments}
          </textarea>
          {/* {formik.touched?.comments && formik.errors?.comments &&
                <span className="text-red-500 absolute -bottom-6 right-0">{formik.errors?.comments}</span>} */}
        </div>
        {/* <div className="flex items-center gap-2 cursor-pointer">
            <input id="parallel_batches" type="checkbox" className="w-5 h-5" />
            <label htmlFor="parallel_batches" className="cursor-pointer text-[19px] text-green-700 font-[500] font-Roboto select-none">Parallel Batches</label>
        </div> */}
      </div>
    );
}