import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  userRoles: {
    selectedRoles: [],
  },
};

const userRolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setUserRoles: (state: any, action: PayloadAction<any>) => {
      state.userRoles = {
        ...state.userRoles,
        ...action.payload,
      };
    },
  },
});

export const { setUserRoles } = userRolesSlice.actions;
export default userRolesSlice.reducer;
