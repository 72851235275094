import { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useParams,
  createSearchParams,
} from "react-router-dom";
import { Progressbar } from "../Elements/Progressbar";
import { ReadData } from "../Elements/ReadData";
import { CardLayoutOne } from "../Elements/Cards/CardLayoutOne";
import { Headline } from "../Elements/Headline/Headline";
import { getStudentInformation } from "../../functions/admin/Students/students";
import certificateSample from "../../Assets/certificateSample.png";
import dropArrow from "../../Assets/dropArrow.svg";
import { dateFormat } from "../../Services/CommonFunctions";
import { Loader } from "../Elements/Loader/Loader";
import { useSharedLogic } from "../CommonFunctions/CommonFunc";

export const FreeStudentInfo = ({ id, mode }: any) => {
  const {navigate,routeNameForHome}: any = useSharedLogic();
  const scrollRef:any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [studentDataPresent, setStudentDataPresent] = useState<any>({});
  
  const buildJSON = [
    {
      head: "Student Name",
      body: studentDataPresent && studentDataPresent?.student?.name,
    },
    {
      head: "Phone Number",
      body: studentDataPresent && studentDataPresent?.student?.phoneNumber,
    },
    {
      head: "Email",
      body: studentDataPresent && studentDataPresent?.student?.email,
    },
    {
      head: "Student Type",
      body: studentDataPresent && studentDataPresent?.student?.type,
    },
    {
      head: "Registration Date",
      body: studentDataPresent && dateFormat(studentDataPresent?.student?.created_at, 'DD MMM YYYY'),
    },
  ];

      useEffect(() => {
        (async () => {
          setIsLoading(true)
          const studentData = await getStudentInformation(id, true);
          setStudentDataPresent(studentData);
          setIsLoading(false)
        })();
      }, []);

  const studentCourseProgress = (course_id:any) => {
    navigate({
      pathname: `/${routeNameForHome}`,   ///${id}/${mode}
      search: createSearchParams({courseId: `${course_id}`}).toString()
    });
  }

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-9/12"} />
      ) : (
        <CardLayoutOne>
          <div className="space-y-4">
            <Headline cardHeading={"Student Information"} />
            <div className="w-11/12 mx-auto py-6 flex">
              <div className="w-1/2 space-y-3">
                {buildJSON.map((read) => {
                  return <ReadData head={read.head} body={read.body} />;
                })}
              </div>
              {studentDataPresent && studentDataPresent?.courses && (
                  <div className="w-1/2 flex items-center gap-4">
                    <img
                        src={dropArrow}
                        alt="ScrollX"
                        className="cursor-pointer rotate-180 w-4 h-4"
                        onClick={() => (scrollRef.current.scrollLeft -= 1000)}
                    />
                    <ScrollCard
                      courseData={studentDataPresent?.courses}
                      studentCourseProgress={studentCourseProgress}
                      scrollRef={scrollRef}
                    />
                    <img
                      src={dropArrow}
                      alt="ScrollY"
                      className="cursor-pointer w-4 h-4"
                      onClick={() => (scrollRef.current.scrollLeft += 1000)}
                    />  
                </div>
              )}
            </div>
          </div>
          {studentDataPresent && studentDataPresent?.courses && (
            <CourseDetails
              courseData={studentDataPresent?.courses}
              studentCourseProgress={studentCourseProgress}
            />
          )}
        </CardLayoutOne>
      )}
    </>
  );
}

const ScrollCard = ({ scrollRef, courseData, studentCourseProgress }: any) => {
  return (
    <div className="w-3/4 xl:w-3/5 2xl:w-1/2 flex space-x-4 relative overflow-y-scroll snap-y snap-mandatory scrollBar scroll-smooth" ref={scrollRef}>
      {courseData.map((data: any, index: number) => {
        return (
          <div
            key={index}
            className="bg-[#F9FCFF] rounded-[9px] p-2 py-3 flex-shrink-0 w-[190px] xl:w-[242px] 2xl:w-[250px] space-y-1"
          >
            <div className="ml-1">
              <p className="text-[#686868] text-[16px] font-Roboto font-normal">
                Course
              </p>
              <h1 className="text-[#000000] text-[18px] font-Roboto font-medium -mt-1">
                {data?.course_name}
              </h1>
            </div>
            <img src={certificateSample} alt="certificateSample" />
            <div className="ml-1 shadow-md shadow-[#00000029] rounded-[4px] flex items-center justify-between px-2 py-2">
              <p className="text-[#000000] text-[16px] font-Roboto font-normal">
                Course Progress
              </p>
              <Progressbar
                percentage={data?.overAllProgress}
                mainDivStyle={"w-[30px] h-[30px]"}
              />
              <img
                src={dropArrow}
                alt="dropArrow"
                className="cursor-pointer"
                onClick={() => studentCourseProgress(data?.id)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CourseDetails = ({ courseData, studentCourseProgress }: any) => {
  return (
    <div className="mt-10 space-y-2">
      <h1 className="px-8 text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-medium">
        Course Details
      </h1>
      <div className="w-11/12 mx-auto space-y-4">
        {courseData.map((data: any, index: number) => {
          return (
            <div
              key={index}
              className="w-full flex items-center justify-between px-6 py-2 rounded-[9px] shadow-md shadow-[#00000029]"
            >
              <div className="flex items-center gap-10 w-[50%]">
                <img
                  src={certificateSample}
                  alt="certificateSample"
                  className="w-[110px]"
                />
                <ReadData head={"Course"} body={data?.course_name} />
              </div>
              <div className="flex items-center w-[30%]">
                <ReadData
                  head={"Registration Date"}
                  body={dateFormat(data?.created_at, "DD MMM YYYY")}
                />
              </div>
              <div className="flex items-center justify-between gap-8 w-[20%]">
                <Progressbar
                  percentage={data?.overAllProgress}
                  mainDivStyle={"w-[80px] h-[80px]"}
                />
                <img
                  src={dropArrow}
                  alt="dropArrow"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => studentCourseProgress(data?.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
