import { ViewEditDeleteActionComponent } from "../../Components/Content Management/Resources/ActionComponentForCategory";
import { ResourceIcon } from "../../Components/Content Management/Resources/ResourceIcon";
import { getResourceCategoryTableData } from "../../functions/admin/Resources/ResourceCategory";

export const resourcesCategory = {
  headers: [
    {
      name: "Resource Category",
      apiKey: "category_name",
      headerClass: "text-start w-[150px] px-2",
      className: "text-start break-words w-[150px] px-2",
    },
    {
      name: "Thumbnail",
      apiKey: "thumbnail",
      Comp: ResourceIcon,
      isComponent: true,
      headerClass: "text-center w-[100px]",
      className: "text-center w-[100px]",
    },
    {
      name: "Order",
      apiKey: "order",
      headerClass: "text-center w-[100px]",
      className: "text-center w-[100px]",
    },

    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent,
      headerClass: "text-center w-[100px]",
      className: "text-center w-[100px]",
    },
  ],
  BackendFunctionCall: getResourceCategoryTableData,
  filters: {
    searchBox: [
      {
        matchFields: ["category_name"],
        searchKey: "resource_category",
        name: "Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
};
