import React from 'react'
import { useState, useEffect } from "react";
import { viewApplicant } from '../../../utils/json/ViewApplicants'
import { CommonTableComp } from '../../CommonFunctions/CommonFunc'
import { useSharedLogic } from '../../CommonFunctions/CommonFunc'
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { State } from '../../../Redux/Store'
import { viewApplicants } from '../../../functions/admin/HR Management/hr_management';
import JSZip from "jszip";
import toast, { Toaster } from "react-hot-toast";
export default function JoinedApplicantTabs() {
  
  const [applicantData,setApplicantData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
 // console.log("ID Data", applicantData);
    const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
      
    useEffect(() => {
      (async () => {
      setIsLoading(true)
      const viewApplicantData: any = await viewApplicants(id, ["applied","not shortlisted", "shortlisted", "placed", "not placed", "not joined"]);
      
      setApplicantData(viewApplicantData);
      setIsLoading(false)
    })();
  }, [id]);  
 //   console.log("Fetch ID", mode);
 const handleDownload = async() => {
  if (applicantData.length === 0) {
    toast.error('No files to download');
    return;
  }
  else{
    const zip = new JSZip();

    for (let i = 0; i < applicantData.length; i++) {
      const file = applicantData[i];
      const response = await fetch(file.student_resume?.url);
      const blob = await response.blob();
      zip.file(`${i + 1}_${file.student_resume?.fileName}`, blob,{binary: true});
    }
 
    const zipBlob = await zip.generateAsync({ type: "blob" });
 
    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(zipBlob);
    anchor.download = "Resumes.zip";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

  }
  
 };
    
    const navigatePreviewData = () => {
      navigate({
        pathname: `/${routeNameForHome}`,
        search: createSearchParams({id: `${id}`,  mode: "joined-student" }).toString(),
      });
    };
    return (
      <div> 
        <div>
      
        <div className="space-y-4">
        <div className='flex items-center w-11/12 justify-end mx-auto'>
          <h1 className="text-[30px] text-white  w-11/12 font-Roboto font-medium">View Applicants</h1>
            <button onClick={handleDownload} className="flex items-center w-80 gap-2 p-2.5 h-[45px] bg-gradient-to-r from-[#E91F00] to-[#E95900] rounded-[6px] shadow-md shadow-[#00000029] cursor-pointer "><p className="text-white text-[20px] font-Roboto font-normal">Download All Resumes</p><img src="/static/media/downloadImg.c450f43b69a7d5bb6b6c6cb6d41c2c92.svg" alt="/static/media/downloadImg.c450f43b69a7d5bb6b6c6cb6d41c2c92.svg" className="w-[20px]" /></button>

          </div>
         
        </div>
        </div>
        <CommonTableComp
        TABLE_JSON={viewApplicant}
        allowCondition={true}
        backEndCallParams={id}
      //  backEndCallParamsStatus={"placed"}
      backEndCallParamsStatus={["placed", "not joined"]}
        allowTabs={true}
      />
      <div className='text-center pt-3'>
      <button type='submit' onClick={navigatePreviewData} className="cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 text-white text-[17px] font-Roboto font-normal">Preview</button>
      </div>
     
    </div>
    )
}