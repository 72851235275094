import { dateFormat } from "../../../Services/CommonFunctions";
import { createStudent } from "../../Student/SignUp/Student";
import {
  CreateUserWithEmailAndPassword,
  generateRandomPassword,
  CreateUserByAdminAuth,
} from "../../auth";
import {
  calculatePercentage,
  calculateTimeDifference,
  errorNotifier,
} from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { BulkUpdate, FetchData, InsertData, UpdateData } from "../../crud";
import { calculatePaidCourseCompletion } from "../Action center/helper";
import {
  assignCoursesToStudent,
  calculateGSTFromAmount,
  progressByModuleCalculation,
  separateUpdateAndInsertDatas,
  studentAndCoursesDataFormatting,
} from "./helper";
import {
  BatchFollowUpInterface,
  GSTInterface,
  PaymentDetailsInterface,
  RegisterStudentFromManagementInterface,
  StudentDetailInterface,
  StudentManagementData,
  StudentManagementInterface,
  trainingDetails,
} from "./interface";

export const getAllStudentsTableData = async () => {
  try {
    const { data, error } = await FetchData(TableNames.students, [
      "*",
      "city(city_name)",
    ]).order("id", { ascending: false });
    const { data: courses, error: coursesError } = await FetchData(
      TableNames.courses,
      ["id", "course_name", "mode"]
    );
    const { data: city, error: cityError } = await FetchData(
      TableNames.cities,
      ["id", "city_name"]
    );
    const { data: branches, error: branchError } = await FetchData(
      TableNames.branches,
      ["id", "branch_name"]
    );
    const { data: trainings, error: trainingError } = await FetchData(
      TableNames.trainings,
      ["id", "student_id, city_id, branch_id"]
    );
    if (error || coursesError || branchError)
      throw error || coursesError || cityError || branchError || trainingError;
    return assignCoursesToStudent(data, courses, city, branches, trainings);
  } catch (error) {
    return errorNotifier(error);
  }
};

export const updateTrainingData = async (trainingData: trainingDetails) => {
  try {
    const { id, ...rest } = trainingData;
    const { data: update_student, error: update_error }: any = await UpdateData(
      TableNames.trainings,
      rest,
      { conditionKey: "id", conditionValue: id }
    );
    if (update_error) {
      throw update_error;
    }
    return true;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getStudentInformation = async (
  uid: string,
  isFreeStudent: boolean = false
) => {
  try {
    let result;
    if (isFreeStudent) {
      result = await FetchData(TableNames.trainings, [
        "student:student_id(name,phoneNumber,email,type,created_at,uid)",
        "course:course_id(course_name,thumbnail,videos)",
        "progress_by_module",
        "created_at",
        "id",
      ])
        .eq("student_id", uid)
        .order("id", { ascending: false });
    } else {
      result = await FetchData(TableNames.students, [
        "name",
        "phoneNumber",
        "email",
        "type",
        "created_at",
        "uid",
        "training:trainings(id)",
      ])
        .eq("uid", uid)
        .order("id", { ascending: false });
    }

    const { data, error } = result;
    if (error) throw error;
    return isFreeStudent
      ? studentAndCoursesDataFormatting(data)
      : { student: data[0] };
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getTrainingInformationById = async (id: number) => {
  try {
    const { data, error }: any = await FetchData(TableNames.trainings, [
      "student:student_id(name)",
      "course:course_id(course_name,videos)",
      "progress_by_module",
    ]).eq("id", id);
    if (error) throw error;
    return {
      ...data[0],
      ...progressByModuleCalculation(
        data[0].course,
        data[0].progress_by_module
      ),
    };
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getManageStudentsTableData = async (UsersDetails: any) => {
  try {
    const { data: studentsData, error }: any = await FetchData(
      TableNames.trainings,
      [
        "id",
        "mode",
        "created_at",
        "city_id",
        "branch_id",
        "course_id",
        "course_schedule",
        "batch:batches(start_date,course:courses(course_name,duration),sessions:sessions(*))",
        "student:students(name, email, phoneNumber)",
        "course:courses(course_name, id, type, mode)",
        "status",
        "total_fee",
        "balance_due",
        "branch:branches(id,branch_name)",
        "city:cities(id,city_name)",
        "batch_follow_up_date",
      ]
    ).order("id", { ascending: false });
    const { data: users, error: userError } = await FetchData(
      TableNames.users,
      ["first_name", "last_name", "courses", "cities", "branches", "user_type"]
    );
    let { data: adjust_payment, error: adjust_payment_error }: any =
      await FetchData(TableNames.adjust_payment, [
        "created_at",
        "from_branch:from_branch_id(branch_name)",
        "to_branch:to_branch_id(branch_name)",
        "from_training:from_training_id(id, student:students(name, phoneNumber), course:courses(course_name))",
        "to_training:to_training_id(id, student:students(name, phoneNumber), course:courses(course_name))",
        "amount",
      ])
    if (userError || adjust_payment_error) throw userError || adjust_payment_error;
    const check_CRM_User = studentsData?.map((bt: any) => {
      // Use find to check if there is a matching user
      const matchingUser: any = users?.find(
        (dt: any) =>
          dt?.cities?.includes(bt?.city_id) &&
          dt?.branches?.includes(bt?.branch_id) &&
          dt?.courses?.includes(bt?.course_id) &&
          dt?.user_type === "BC"
      );
      // Return the full name of the matching user, or an empty string if no match is found
      return (
        matchingUser && {
          id: bt?.id,
          name: `${matchingUser?.first_name} ${matchingUser?.last_name}`,
        }
      );
    });
    const trainingData = studentsData
      ?.filter(
        (stu: any) =>
          stu?.course?.type === "Paid" &&
          UsersDetails?.cities?.includes(stu?.city?.id) &&
          UsersDetails?.branches?.includes(stu?.branch?.id)
      )
      .map((student: any) => {
        const crmUserResponse =
          check_CRM_User?.length > 0
            ? check_CRM_User
                ?.filter((user: any) => user?.id === student?.id)
                .map((filteredUser: any) => filteredUser.name)
            : "";

        const filterAdjustedData = adjust_payment?.filter((ft:any)=> ft?.from_training?.id === Number(student?.id));
        const mapAdjustedAmountFrom =
          filterAdjustedData?.map((amt: any) => Number(amt?.amount));
        const sumAdjustedAmountFrom =
          mapAdjustedAmountFrom &&
          mapAdjustedAmountFrom.reduce(
            (accumulator: any, currentValue: any) => accumulator + currentValue,
            0
          );

        return {
          ...student,
          batch_follow_up_date:
            (student?.status === "Unallocated" || student?.status === "Hold") ?
            dateFormat(student?.batch_follow_up_date, "DD MMM YYYY") : '-',
          total_fee: Math.round(Number(student?.total_fee)),
          balance_due: Math.round(Number(student?.balance_due)) === 0 ? '-' : Math.round(Number(student?.balance_due)+Number(sumAdjustedAmountFrom)),
          registration_date: dateFormat(student?.created_at, "DD MMM YYYY"),
          course_name: student?.course?.course_name,
          mode: student?.mode,
          course_id: student?.course.id,
          branch: student?.branch?.branch_name,
          student_name: student?.student?.name,
          email: student?.student?.email,
          phone_number: student?.student?.phoneNumber,
          city: student?.city?.city_name,
          courseProgress: (student?.batch !== null && student?.batch?.sessions?.length>0)
            ? calculatePaidCourseCompletion(
                student?.batch?.course?.duration,
                student?.batch?.sessions
              )
            : '0',
          check_CRM_User: crmUserResponse?.[0],
        };
      });
      // .replace(/\s?%/, '')
    // console.log(trainingData, "trainingData")
    if (error) throw error;
    return trainingData;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getStudentsData = async () => {
  try {
    const { data: student, error }: any = await FetchData(TableNames.students, [
      "id",
      "name",
      "email",
      "phoneNumber",
      "uid",
    ]);
    if (error) throw error;
    return student;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getPreloadData = async (UsersDetails: any) => {
  try {
    const { data: student, error }: any = await FetchData(TableNames.students, [
      "id",
      "name",
      "email",
      "phoneNumber",
      "uid",
    ]).overlaps("branches", UsersDetails.branches);
    const { data: courses, error: course_error }: any = await FetchData(
      TableNames.courses,
      ["id", "course_name"]
    )
      .eq("type", "Paid")
      .overlaps("branches", UsersDetails.branches);
    const { data: gst, error: gst_error }: any = await FetchData(
      TableNames.gsts,
      ["id", "sgst", "cgst"]
    );
    const { data: cities, error: city_error } = await FetchData(
      TableNames.cities,
      ["id", "city_name"]
    ).in("id", UsersDetails.cities);
    const { data: branches, error: branch_error } = await FetchData(
      TableNames.branches,
      ["id", "branch_name", "city"]
    ).in("id", UsersDetails.branches);

    if (error || course_error || gst_error || city_error || branch_error)
      throw error || course_error || gst_error || city_error || branch_error;
    return { student, courses, gst, cities, branches };
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getBranchForGST = async (id: any) => {
  try {
    const { data, error } = await FetchData(TableNames.branches, [
      "id",
      "branch_name",
      "fee_type",
      "igst",
      "cgst",
      "sgst",
    ]).eq("id", id);
    if (error) throw error;
    return data;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getCourseById = async (courseId: number | string) => {
  try {
    const { data: course, error } = await FetchData(TableNames.courses, [
      "id",
      "city_based_course_fee",
      "schedule",
      "course_name",
      "duration",
      "mode",
    ]).eq("id", courseId);
    if (error) throw error;
    return course[0];
  } catch (error) {
    return errorNotifier(error);
  }
};

export const feeAfterDiscountCalculation = (
  course_fee: any,
  discountPercent?: number | any
) => {
  const discountedFee =
    discountPercent === undefined
      ? course_fee
      : (course_fee * discountPercent) / 100;
  const finalDiscountedFee =
    discountPercent === undefined ? course_fee : course_fee - discountedFee;
  return finalDiscountedFee;
};

export const gstCalculation = (
  course_fee: any,
  discountPercent?: number | any
) => {
  let fee = feeAfterDiscountCalculation(course_fee, discountPercent);
  let gstAmount = (fee * 9) / 100;
  return gstAmount;
  // && typeof gstAmount === "number"
  //   ? gstAmount.toFixed(1)
  //   : Number(gstAmount)?.toFixed(2)
};

export const totalGstCalculation = (
  course_fee: any,
  discountPercent?: number | any
) => {
  let fee = feeAfterDiscountCalculation(course_fee, discountPercent);
  let withSGST = (fee * 9) / 100;
  let withCGST = (fee * 9) / 100;
  let total: any = fee + withCGST + withSGST;
  return total;
  // && typeof total === "number"
  //   ? total.toFixed(1)
  //   : Number(total)?.toFixed(2)
};

export const gstCalculationForStudent = (
  sendPercentage: any,
  course_fee: any,
  discountPercent?: number | any
) => {
  let fee = feeAfterDiscountCalculation(course_fee, discountPercent);
  let gstAmount = (fee * sendPercentage) / 100;
  return gstAmount;
};

export const totalGstCalculationForStudent = (
  check_feeType: any,
  sendPercentage: any,
  course_fee: any,
  discountPercent?: number | any
) => {
  let fee = feeAfterDiscountCalculation(course_fee, discountPercent);
  let withIGST = 0;
  let withCGST = 0;
  let withSGST = 0;
  let total = 0;

  if (check_feeType === "IGST") {
    withIGST = (fee * sendPercentage) / 100;
    total = fee + withIGST;
  } else if (check_feeType === "SGST") {
    withCGST = (fee * sendPercentage?.cgst) / 100;
    withSGST = (fee * sendPercentage?.sgst) / 100;
    total = fee + withCGST + withSGST;
  } else {
    total = fee;
  }
  // let total:any = fee + withCGST + withSGST;
  return total;
  // && typeof total === "number"
  //   ? total.toFixed(1)
  //   : Number(total)?.toFixed(2)
};

export const createTrainingForStudent = async (
  studentCourseData: StudentManagementInterface,
  email: string,
  phone_number: string,
  name: string
) => {
  try {
    // const { data: emailData }: any = await FetchData(TableNames.students, ["id", "uid"]).eq("email", email);
    const { data: emailData }: any = await FetchData(TableNames.students, [
      "id",
      "uid",
    ]).eq("phoneNumber", phone_number);
    if (emailData?.length > 0) {
      studentCourseData["student_id"] = emailData[0].uid;
      if (studentCourseData["student_id"] === emailData[0].uid) {
        await UpdateData(
          TableNames.students,
          { name: name, email: email, type: "Paid Student" },
          { conditionKey: "uid", conditionValue: emailData[0].uid }
        );
      }
    } else {
      if (!studentCourseData?.student_id) {
        // console.log("Enters_IN")
        const registerUser = await registerStudentFromManagement({
          email,
          phoneNumber: phone_number,
          city: studentCourseData.city_id,
          branches: [studentCourseData.branch_id],
          courses:[studentCourseData.course_id],
          name,
        });
        studentCourseData["student_id"] = registerUser?.uid;
      }
    }
    const { data: stu_data }: any = await FetchData(TableNames.students, [
      "courses",
      "branches", "training:trainings(course_id, branch_id)"
    ]).eq("uid", studentCourseData.student_id);
    // console.log(stu_data, "stu_data")
    if (!stu_data?.length) {
      let error = {
        message: "student not found",
      };
      throw error;
    }
    let training_list = stu_data[0]?.training;
    let course_list = stu_data[0]?.courses;
    let branch_list = stu_data[0]?.branches;
    const checkExistingTrainings = training_list.filter((fil:any)=> ((fil?.course_id===studentCourseData?.course_id) && (fil?.branch_id===studentCourseData?.branch_id)))
    if (
      checkExistingTrainings?.length>0
    ) {
      return {
        message: "Course already enrolled for this student",
        status: false,
      };
    } else {
      const { payment_details, ...rest } = studentCourseData;
      const { data, error }: any = await InsertData(
        TableNames.trainings,
        studentCourseData
      );
      if (error) throw error;
      if (studentCourseData.payment_details?.length) {
        let paymentRecordInsertion = await addStudentPayments(
          studentCourseData.payment_details,
          studentCourseData.branch_id,
          studentCourseData.total_fee,
          data[0].id
        );
        if (!paymentRecordInsertion) {
          return paymentRecordInsertion;
        }
      }
      if (!course_list.includes(studentCourseData?.course_id)) {
        course_list.push(studentCourseData?.course_id)
      }
      if (!branch_list.includes(studentCourseData?.branch_id)) {
        branch_list.push(studentCourseData?.branch_id);
      }
      // course_list.push(studentCourseData?.course_id);
      // branch_list.push(studentCourseData?.branch_id);
      // console.log(studentCourseData?.branch_id, studentCourseData?.course_id, "studentCourseData", stu_data[0]?.branches, "branch", stu_data[0]?.courses, "course");
      await UpdateData(
        TableNames.students,
        { courses: course_list, branches: branch_list, type: "Paid Student" },
        { conditionKey: "uid", conditionValue: studentCourseData.student_id }
      );
      return { message: data, status: true };
    }
  } catch (error) {
    return errorNotifier(error);
  }
};

export const addBatchFollowUp = async (
  batch_follow_up_data: BatchFollowUpInterface
) => {
  try {
    const { data, error } = await InsertData(
      TableNames.batch_follow_up,
      batch_follow_up_data
    );
    if (error) throw error;
    return data;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const calculateTotalBalanceDueAmount = (
  total_fee: number,
  paid_fee: number
) => {
  try {
    return total_fee - paid_fee;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const registerNewStudent = async (
  email: string,
  name: string,
  phone_number: string
) => {
  try {
    const tempPass = name + phone_number.slice(5);
    const register = await CreateUserWithEmailAndPassword(email, tempPass);
    let studentData = {
      uid: register?.user?.id,
      name: name,
      email: email,
      phoneNumber: phone_number,
    };
    const data: any = await createStudent(studentData);
    return data[0];
  } catch (error) {
    return errorNotifier(error);
  }
};

export const registerStudentFromManagement = async (
  studentInformation: RegisterStudentFromManagementInterface
) => {
  try {
    const tempPass =
      studentInformation.name + studentInformation?.phoneNumber?.slice(5);
    const password = generateRandomPassword(10);
    // const register = await CreateUserWithEmailAndPassword(studentInformation.email, tempPass)
    const register = await CreateUserByAdminAuth({
      phone: studentInformation.phoneNumber,
      phone_confirm: true,
      password: password,
    });
    // let studentData = {
    //     uid: register?.user?.id,
    //     name: studentInformation.name,
    //     email: studentInformation.email,
    //     phoneNumber: studentInformation.phone_number,
    // }
    const data: any = await createStudent({
      ...studentInformation,
      uid: register?.user?.id,
      type: "Paid Student",
      password: password,
      is_email_verified: true,
      details_filled: true,
    });
    return data[0];
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getStudentManagementDetailsById = async (
  trainingId: number | string
) => {
  try {
    const { data: studentData, error }: any = await FetchData(
      TableNames.trainings,
      [
        "*",
        "student_id:students(name, email, phoneNumber, uid), invoice_details, city_id:cities(city_name), branch_id:branches(id, branch_name, fee_type, gstin, hsn, igst, sgst, cgst), course_id:courses(id, course_name, mode, category(category_name, id)), expected_start_date",
      ]
    ).eq("id", trainingId);

    if (studentData[0].batch_id !== null) {
      const { data: student_sessions, error: session_error }: any =
        await FetchData(TableNames.sessions, [
          "id",
          "batch_id",
          "attendees",
          "title",
          "batch:batches(course:courses(course_name), branch:branches(branch_name), duration)",
          "details",
          "duration",
          "session_date",
        ])
          .eq("batch_id", studentData[0]?.batch_id || "")
          .order("id", { ascending: false });
      const sessions = student_sessions?.map((session: any) => ({
        ...session,
        is_present: session?.attendees?.includes(studentData[0].student_id.uid),
      }));
      const filtered_students = sessions?.filter((stu: any) => {
        return stu?.is_present === true;
      });
      const total_session_time = filtered_students?.reduce(
        (acc: any, currVal: any) => Number(acc) + Number(currVal?.duration),
        0
      );
      const { data: batch_data, error: batch_error } = await FetchData(
        TableNames.batches,
        ["duration", "batch_code"]
      ).eq("id", studentData[0].batch_id);
      var batchData = batch_data?.map((batch: any) => ({
        ...batch,
        student_progress_in_hrs: total_session_time,
        student_progress:
          calculatePercentage(total_session_time, studentData[0]?.duration)
      }));

      var courseData: any = calculatePercentage(
        total_session_time,
        studentData[0]?.duration
      );
    }

    const { data: batch_follow_up_date, error: batch_follow_up_error }: any =
      await FetchData(TableNames.batch_follow_up, ["follow_up_date"])
        .in("id", studentData[0].batch_follow_up)
        .order("id", { ascending: true });
    const batch_start_date = dateFormat(
      batch_follow_up_date[batch_follow_up_date?.length - 1],
      "DD MMM YYYY"
    );

    // let balance = studentData[0]?.total_fee
    let { data: paymentDetails, error: PaymentDetailsError } = await FetchData(
      TableNames.transactions
    )
      .eq("training_id", trainingId)
      .order("created_at", {
        ascending: true,
      });
    if (PaymentDetailsError) {
      throw PaymentDetailsError;
    }

    // const alterPaymentDetails = paymentDetails?.map((payment: any) => ({
    //     ...payment,
    //     payment_date: dateFormat(payment?.payment_date, "DD MM YYYY")
    // }))


    // let { data: gstData }:any = await FetchData(TableNames.gsts);
    // if (!gstData?.length) {
    //     let errorMessage = {
    //         message:"No GST records found"
    //     }
    //     throw errorMessage;
    // }
    // let alteredPaymentDetails = paymentDetails?.map((payment: any) => ({
    //     ...payment,
    //     ...calculateGSTFromAmount({
    //         cgst: gstData[0].cgst,
    //         sgst:gstData[0].sgst
    //     }, payment.paid_amount),
    //     balance:studentData?.total_fee-payment?.paid_amount
    // }));

    // const paymentDetails = studentData[0]?.payment_details?.map((datum: any) => {
    //     let total_amt = (datum.paid_amount) + ((datum.paid_amount * 9) / 100) * 2
    //     balance -= (datum.paid_amount) + ((datum.paid_amount * 9) / 100) * 2;
    //     return { ...datum, balance: balance, total_amt: total_amt }
    // })

    const updatedStudentData: any = studentData.map((student: any) => ({
      ...student,
      registration_date: dateFormat(student?.created_at, "DD MMM YYYY"),
    }));
    let { data: refund_data, error: refund_error } = await FetchData(
      TableNames.refunds,
      ["*", "training_id:trainings(id, total_fee)"]
    ).eq("training_id", trainingId);

    let { data: adjust_payment, error: adjust_payment_error }: any =
      await FetchData(TableNames.adjust_payment, [
        "created_at",
        "from_branch:from_branch_id(branch_name)",
        "to_branch:to_branch_id(branch_name)",
        "from_training:from_training_id(id, student:students(name, phoneNumber), course:courses(course_name))",
        "to_training:to_training_id(id, student:students(name, phoneNumber), course:courses(course_name))",
        "amount",
      ])
    const filterAdjustedData = adjust_payment?.filter((ft:any)=>((ft?.from_training?.id === Number(trainingId)) || (ft?.to_training?.id === Number(trainingId))))
    const mapAdjustedData = filterAdjustedData?.map((dt: any) => ({
      ...dt,
      total_paid_amount: updatedStudentData[0]?.total_fee - updatedStudentData[0]?.balance_due,
      total_amount_after_adjusted: updatedStudentData[0]?.total_fee - adjust_payment[0]?.amount,
      idIsFromOrTo: (dt?.from_training?.id === Number(trainingId) ? "from" : "to")
    }))

    let { data: approval_data, error: approval_error } = await FetchData(
      TableNames.approval_queue
    ).eq("training_id", trainingId);

    const filter_approval_data = approval_data?.filter((fil:any)=>fil?.latest_training);

    if (error || batch_follow_up_error) throw error || batch_follow_up_error;
    const { data: appliedJobsOfStudent, error: AppliedJobsQueryError } =
      await FetchData(TableNames.student_jobs, [
        "id",
        "job:jobs(position,company,location,inserted_at)",
        "shortlisted",
        "placed",
        "joined",
        "created_at",
      ]).eq("student_id", studentData[0].student_id.uid);
    if (AppliedJobsQueryError) throw AppliedJobsQueryError;

    return {
      updatedStudentData: updatedStudentData[0],
      paymentDetails,
      batch_start_date: batch_start_date,
      course_progress: Math.round(courseData || 0),
      batch_data: batchData || null,
      appliedJobs: appliedJobsOfStudent,
      refund_data,
      approval_data: filter_approval_data,
      adjustedPayment: mapAdjustedData,
    };
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getTrainerAndSessionDetails = async (
  trainingId: number | string
) => {
  try {
    const { data, error }: any = await FetchData(TableNames.trainings, [
      "id",
      "batch_id",
      "student_id",
    ]).eq("id", trainingId);
    if (data[0].batch_id !== null) {
      const { data: sessions, error: session_error }: any = await FetchData(
        TableNames.sessions,
        [
          "trainer:users(uid,first_name, last_name, phone_number, email)",
          "duration",
          "attendees",
          "session_date",
          "start_time",
          "end_time",
          "details",
        ]
      )
        .eq("batch_id", data[0].batch_id)
        .order("id", { ascending: false });
      const { data: batch_trainers, error: trainer_error } = await FetchData(
        TableNames.batch_trainers,
        [
          "trainer:users(uid, first_name, last_name, phone_number, email)",
          "start_date",
          "end_date",
        ]
      ).eq("batch_id", data[0].batch_id);
      const trainers_data = batch_trainers?.map((trainer: any) => ({
        ...trainer,
        start_date: dateFormat(trainer?.start_date, "DD MMM YYYY"),
        end_date: dateFormat(trainer?.end_date, "DD MMM YYYY"),
        trainer_name:
          trainer.trainer.first_name + " " + trainer.trainer.last_name,
        trainer_email: trainer.trainer.email,
        trainer_phone_number: trainer.trainer.phone_number,
        duration: sessions
          .filter((session: any) => session.trainer.uid === trainer.trainer.uid)
          .reduce(
            (acc: any, curr_val: any) =>
              Number(acc) + Number(curr_val.duration),
            0
          ),
      }));
      const current_student_sessions = sessions?.map((session: any) => ({
        ...session,
        is_present: session?.attendees?.includes(data[0].student_id),
        trainer_name:
          session?.trainer?.first_name + " " + session?.trainer?.last_name,
        trainer_email: session.trainer.email,
        trainer_phone_number: session.trainer.phone_number,
        session_date: dateFormat(session.session_date, "DD MMM YYYY"),
      }));
      if (error || trainer_error || session_error)
        throw error || trainer_error || session_error;
      return { trainers_data, current_student_sessions };
    } else return { trainers_data: [], current_student_sessions: [] };
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getBatchFollowUp = async (follow_up_ids: number[] | string[]) => {
  try {
    const { data: follow_up, error } = await FetchData(
      TableNames.batch_follow_up
    ).in("id", follow_up_ids);
    if (error) throw error;
    return follow_up.map((datum: any) => ({
      ...datum,
      created_date: dateFormat(datum.created_at, "DD MMM YYYY"),
      new_follow_up_date: dateFormat(datum.follow_up_date, "DD MMM YYYY"),
    }));
  } catch (error) {
    return errorNotifier(error);
  }
};

export const updateStudentPayments = async (
  paymentData: Partial<PaymentDetailsInterface> & { id: number }
) => {
  try {
    const { id, ...rest } = paymentData;
    return UpdateData(TableNames.transactions, rest, {
      conditionKey: "id",
      conditionValue: id,
    });
  } catch (error) {
    return errorNotifier(error);
  }
};

export const bulkUpsertTransactions = async (
  paymentData: PaymentDetailsInterface[] | any[],
  branch_id: any,
  trainingId: string | number,
  totalFee: number
) => {
  try {
    // let { data: gstData }: any = await FetchData(TableNames.gsts);
    // if (!gstData?.length) {
    //     let errorMessage = {
    //         message: "No GST records found"
    //     }
    //     throw errorMessage;
    // }
    // let balance = totalFee;
    const { updateData, insertData }: any = await separateUpdateAndInsertDatas(
      paymentData,
      branch_id,
      trainingId
    ); // Await the result of separateUpdateAndInsertDatas
    const { data: PaymentsData, error: TrasactionUpdateError } =
      await BulkUpdate(TableNames.transactions, updateData, "id");
    await InsertData(TableNames.transactions, insertData);
    if (TrasactionUpdateError) throw TrasactionUpdateError;
    return true;
  } catch (error) {
    return errorNotifier(error);
  }
};

const handleData = (data:any) => {
  if (Array.isArray(data)) {
    return data;
  } else {
    return [data];
  }
}

export const addStudentPayments = async (
  paymentData: PaymentDetailsInterface[],
  branch_id: number,
  balance: number,
  training_id: number
) => {
  try {
    const checkGSTwithBranch: any = await getBranchForGST(branch_id);
    if (checkGSTwithBranch?.length > 0) {
      const paymentInsertionData = paymentData.map((payment:any) => {
        balance -= payment.paid_amount;
        const paymentTypeCheck = handleData(payment?.payment_type)
          return {
            ...payment,
            payment_type: paymentTypeCheck,
            training_id,
            balance,
            payment_date: new Date(
              new Date(payment.payment_date).toISOString()
            ),
            ...(checkGSTwithBranch[0]?.fee_type === "IGST"
              ? { igst: Number(checkGSTwithBranch[0]?.igst) }
              : { igst: null }),
            ...(checkGSTwithBranch[0]?.fee_type === "SGST"
              ? { sgst: Number(checkGSTwithBranch[0]?.sgst) }
              : { sgst: null }),
            ...(checkGSTwithBranch[0]?.fee_type === "SGST"
              ? { cgst: Number(checkGSTwithBranch[0]?.cgst) }
              : { cgst: null }),
            paid_amount_after_gst:
              checkGSTwithBranch[0]?.fee_type === "IGST"
                ? Number(payment.paid_amount) -
                  payment.paid_amount *
                    (Number(checkGSTwithBranch[0]?.igst) / 100)
                : Number(payment.paid_amount) -
                  payment.paid_amount *
                    (Number(checkGSTwithBranch[0]?.sgst) / 100) -
                  payment.paid_amount *
                    (Number(checkGSTwithBranch[0]?.cgst) / 100),
          };
        })
      const { data: PaymentsData, error } = await InsertData(
        TableNames.transactions, paymentInsertionData
      );
      if (error) throw error;
      return PaymentsData;
    }
  } catch (error) {
    return errorNotifier(error);
  }
};

// * changes required
export const updateStudentManagementDetails = async (
  studentData: Partial<StudentManagementInterface>,
  trainingId: number | string,
  studentDetails: StudentDetailInterface
) => {
  try {
    const { data: student, error: student_error }: any = await FetchData(
      TableNames.students
    )
      .eq("phoneNumber", studentDetails.phone_number)
      .not("uid", "eq", studentDetails.uid);
    if (student !== null && student.length > 0) {
      throw new Error("Phone number already exists for other user");
    } else {
      const { data: update_student, error: update_error }: any =
        await UpdateData(
          TableNames.students,
          {
            name: studentDetails.name,
            phoneNumber: studentDetails.phone_number,
            email: studentDetails.email,
          },
          { conditionKey: "uid", conditionValue: studentDetails.uid }
        );
      const { data, error } = await UpdateData(
        TableNames.trainings,
        studentData,
        { conditionKey: "id", conditionValue: trainingId }
      );
      if (error || student_error) throw error || student_error;
      const transactionBulkUpsertionResponse = await bulkUpsertTransactions(
        studentData.payment_details || [],
        studentData?.branch_id,
        trainingId,
        studentData.total_fee || 0
      );
      return true;
    }
  } catch (error) {
    return errorNotifier(error);
  }
};

// export const getPendingPaymnets = async () => {
//     try {
//         const { data, error }: any = await FetchData(TableNames.trainings,).gt("balance_due", 0);
//         if (error) throw error;
//         return { data };
//     } catch (error) {
//         return errorNotifier(error);
//     }
// }
