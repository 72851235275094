import { dateFormat } from "../../../Services/CommonFunctions";
import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { DeleteData, FetchData, UpdateData, InsertData } from "../../crud";
import { ResourceInterface } from "./interface";

export const getResourcesTableData = async () => {
    try {
        const { data: resources, error } = await FetchData(TableNames.resources, ["id", "title", "resource:resource_category(category_name)", "created_at"]).order("id", { ascending: false });
        if (error) throw error; 
        return resources?.map((resource: any) => ({...resource, category_name: resource.resource?.category_name, date: dateFormat(resource.created_at, "DD MMM YYYY")}))
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const getResourceById = async (resourceId: number | string) => {
    try {
        const { data: resource, error } = await FetchData(TableNames.resources).eq("id", resourceId)
        if (error) throw error; 
        return resource[0]
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const getResourcesPreloadData = async () => {
    try {
        const { data: resource_category } = await FetchData(TableNames.resource_category, ["id", "category_name"]) 
        const { data: technologies, error } = await FetchData(TableNames.technologies, ["id", "technology"])
        if (error) throw error
        return {resource_category, technologies}
    } catch (error) {
        return errorNotifier(error)
    }
}

export const createResources = async (resourceData: ResourceInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.resources, resourceData)
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateResource = async (resourceData: Partial<ResourceInterface>, resourceId: number | string) => { 
    try {
        const { data, error } = await UpdateData(TableNames.resources, resourceData, {conditionKey: "id", conditionValue: resourceId})
        if (error) throw error; 
        return true
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const deleteResource = async (   resourceId: number | string) => {
    try {
        const { data, error } = await DeleteData(TableNames.resources, {conditionKey: "id", conditionValue: resourceId})
        if (error) throw error; 
        return true
    } catch (error) {
        return errorNotifier(error); 
    }
}