import { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";
import { RadioInput } from "../../Elements/RadioInput";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { SubmitButton } from "../../Elements/SubmitButton";
import { ContentType } from "../../Elements/ContentType/ContentType";
import { add_pushContent } from "../../Formik/InitialValues";
import { InputBox } from "../../Elements/InputBox";
import { DropdownInputWithCheckbox } from "../../Elements/DropdownInputWithCheckbox";
import { useSharedLogic, FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import {
  getPushContentTableData,
  createPushContent,
  getPreloadData,
  getPushContentByStudentUid,
  getPushContentById,
} from "../../../functions/admin/Push Content/PushContent";
import { DropdownSingleInputWithCheckbox } from "../../Elements/DropdownSingleInputWithCheckbox";
import { onKeyDown } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { UploadOrUpdateBucket } from "../../CommonFunctions/CommonFileFunction";
import { AddPushData } from "../../Formik/FormikValidation";
import { useFormik } from "formik";
import { useAppSelector } from "../../..";

export const AddPushContent = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic()
  const [branchData, setBranchData] = useState([] as any)
  const [techData, setTechData] = useState([] as any)
  const [branchNames, setBranchNames] = useState([] as any)
  const [techNames, setTechNames] = useState([] as any)
  const [isLoading, setIsLoading] = useState(false);
  const PushContentType = ["Predefined Push Content", "Instant Push Content"];
  const CourseCompletionForTable = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100']
  const StudentStatusTable = ["Allocated","Unallocated","Hold","Dropped","Review","Completed"]
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  // 6b0ce075-fa7e-4c82-8b90-82494c25149c

  useEffect(() => {
    (async () => {
      if (signInData?.data?.length > 0) {
        const readData: any = await getPreloadData({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities
        });
        setBranchData(readData?.branches)
        setTechData(readData?.technologies)
        if (readData) {
          const mapBranches: any = readData && readData?.branches.map((branch: any) => branch.branch_name)
          setBranchNames(mapBranches)
          const mapTech: any = readData && readData?.technologies.map((tech: any) => tech.technology)
          setTechNames(mapTech)
        }
        // const TestData = await getPushContentByStudentUid("6b0ce075-fa7e-4c82-8b90-82494c25149c");
        // console.log(TestData, "TEST_DATA");
        if (mode === "view") {
          const viewData: any = await getPushContentById(id);
          // console.log(viewData, "viewData");
          if (viewData) {
            const {
              content_type,
              course_completion,
              external_link,
              student_status,
              sub_type,
              text_message,
              thumbnail,
              title,
              video,
              branch,
              technology
            } = viewData?.[0];
            const fetchBranches = readData?.branches?.filter((ft: any) => branch?.includes(ft?.id));
            const mapBranches = fetchBranches?.map((data: any) => data?.branch_name);
            const fetchTechnologyNames = readData?.technologies?.filter((ft: any) => technology?.includes(ft?.id));
            const mapTechnologyNames = fetchTechnologyNames?.map((tech:any)=>tech?.technology);
            setValues({
              ...values,
              content_type,
              course_completion,
              external_link,
              student_status,
              sub_type,
              text_message,
              thumbnail,
              title,
              video,
              technology: technology,
              tech_name: mapTechnologyNames,
              branch: branch,
              branch_names: mapBranches,
            }, true)
          }
        }
      }
    })()
  },[])

  const forAddPushContent = async () => {
    setIsLoading(true)
    const {content_type, sub_type, external_link, video, text_message, student_status, branch, tech_name, branch_names, ...rest} = values
    const body: any = {
      ...(content_type === "Instant Push Content" && { student_status }),
      ...(content_type === "Instant Push Content" && { branch }),
      ...(sub_type === "External Link" && { external_link }),
      ...(sub_type === "Upload Video" && {video}),
      ...(sub_type === "Text" && { text_message }),
      content_type,
      sub_type,
      ...rest
    }
    // console.log(body, "body")
    const response = await createPushContent(body)
    // console.log(response, "response")
    if (response) {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({ id: "created-success" }).toString(),
      });
    }
    setIsLoading(false)    
  }
  
  const formik = useFormik({
    initialValues: add_pushContent,
    validationSchema: ()=>AddPushData(values.content_type, values.sub_type),
    onSubmit: () => {
      forAddPushContent();
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  } = formik;  
  
  const typeSelect = (name: string, label: string) => {
        console.log(name, label, "NAME")
        setFieldValue(name, label);
    };

  const handleValues:any = (response: any, Method: string, index:number, subBucket:string, file?: any, name?: any, randomKey?: string) => {
    if (['Upload', 'Update'].includes(Method)) {
      const cacheBuster = Date.now();
      setFieldValue(index === 1 ? "thumbnail" : "video", {
        url: mode !== "edit" ? response?.publicUrl : `${response?.publicUrl}?cb=${cacheBuster}`,
        fileName: file?.name, key: mode!=="edit" && randomKey
      });
    } else {
        setFieldValue(index===1 ? "thumbnail" : "video", { fileName: "", url: "", key: "" });
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-[95%]"} />
      ) : (
        <CardLayoutTwo>
          <form onSubmit={handleSubmit} onKeyDown={onKeyDown} className="py-6">
            <div className="flex items-center justify-around w-8/12 mx-auto">
              {PushContentType.map((text: any, index: number) => {
                return (
                  <RadioInput
                    index={index}
                    label={text}
                    name={"content_type"}
                    value={values.content_type}
                    classStyle="2xl:text-[23px] lg:text-[20px] text-[19px] font-[400]"
                    onChange={typeSelect}
                    onBlur={handleBlur}
                    disabled={mode === "view"}
                  />
                );
              })}
            </div>
            <div className="rounded-[6px] shadow-md shadow-[#00000029] w-11/12 mx-auto space-y-6 py-8">
              <ContentType
                classStyle={"w-8/12"}
                typeSelect={typeSelect}
                formik={formik}
                disabled={mode === "view"}
              />
              <div className="rounded-[6px] shadow-md shadow-[#00000029] w-11/12 mx-auto py-8 space-y-8 flex flex-col items-center justify-center">
                <div className="space-y-6 w-full">
                  <div className="w-11/12 mx-auto flex items-center gap-4">
                    <InputBox
                      label="Title"
                      placeholder="Title"
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      name={"title"}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.title}
                      isTouched={touched.title}
                      disabled={mode === "view"}
                    />
                    <DropdownInputWithCheckbox
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      label="Technology"
                      placeholder="Technology"
                      options={techNames}
                      name={"tech_name"}
                      forId={"technology"}
                      value={values.tech_name}
                      // onBlur={handleBlur}
                      // error={errors.tech_name}
                      // isTouched={touched.tech_name}
                      mapData={techData}
                      mapThrough={"technology"}
                      formik={formik}
                      disabled={mode === "view"}
                      zIndex={"z-20"}
                    />
                    <DropdownSingleInputWithCheckbox
                      topDivStyle={"flex flex-col w-full"}
                      classNameInput={"w-full"}
                      label="Course Completion %"
                      placeholder="Course Completion %"
                      options={CourseCompletionForTable}
                      name={"course_completion"}
                      forId={"course_completion"}
                      value={values.course_completion}
                      // onBlur={handleBlur}
                      // error={errors.course_completion}
                      // isTouched={touched.course_completion}
                      mapThrough={"course_completion"}
                      formik={formik}
                      zIndex={"z-20"}
                      disabled={mode === "view"}
                    />
                  </div>
                  {values.content_type === "Instant Push Content" && (
                    <div className="w-11/12 mx-auto flex items-center gap-10 z-10 relative">
                      <DropdownSingleInputWithCheckbox
                        topDivStyle={"flex flex-col w-full"}
                        classNameInput={"w-full"}
                        label="Student Status"
                        placeholder="Student Status"
                        options={StudentStatusTable}
                        name={"student_status"}
                        forId={"student_status"}
                        value={values.student_status}
                        onBlur={handleBlur}
                        error={errors.student_status}
                        isTouched={touched.student_status}
                        // mapData={branchData}
                        mapThrough={"student_status"}
                        formik={formik}
                        disabled={mode === "view"}
                        zIndex={"z-20"}
                      />
                      <DropdownInputWithCheckbox
                        topDivStyle={"flex flex-col w-full"}
                        classNameInput={"w-full"}
                        label="Branch"
                        placeholder="Branch"
                        options={branchNames}
                        name={"branch_names"}
                        forId={"branch"}
                        value={values.branch_names}
                        onBlur={handleBlur}
                        error={errors.branch_names}
                        isTouched={touched.branch_names}
                        mapData={branchData}
                        mapThrough={"branch_name"}
                        formik={formik}
                        disabled={mode === "view"}
                      />
                    </div>
                  )}
                  {values.sub_type !== "" && (
                    <div className="w-11/12 mx-auto flex items-end gap-10 z-0 relative">
                      {values.sub_type === "External Link" && (
                        <InputBox
                          label="External URL"
                          placeholder="URL"
                          topDivStyle={"flex flex-col w-full"}
                          classNameInput={"w-full"}
                          name={"external_link"}
                          value={values.external_link}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.external_link}
                          isTouched={touched.external_link}
                          disabled={mode === "view"}
                        />
                      )}
                      {values.sub_type === "Upload Video" && (
                        <div
                          className={`w-full min-h-[100px] lg:min-h-[130px] rounded-[5px] border-[1px] border-[#707070] border-dashed mx-auto flex flex-col items-center justify-center space-y-1`}
                        >
                          <UploadOrUpdateBucket
                            mode={mode}
                            id={"video"}
                            labelText={"Upload Video"}
                            accept={"video/*"}
                            fileSize={5}
                            sizeInText={"Image Size 5Mb"}
                            Method={
                              id === "new-pushcontent" ? "Upload" : "Update"
                            }
                            bucketName={"push_content"}
                            forValue={handleValues}
                            fileNameData={values.video.fileName}
                            fileNameKey={values.video.key}
                            preview={values.video.url}
                            index={2}
                            name={`video.url`}
                            error={errors.video && errors.video?.url}
                            isTouched={touched.video && touched.video?.url}
                            disableBasedOnMode={mode !== "view"}
                          />
                        </div>
                      )}
                      {values.sub_type === "Text" && (
                        <div className="relative w-full">
                          <textarea
                            placeholder="Text"
                            name="text_message"
                            value={values.text_message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={mode === "view"}
                            className="w-full min-h-[100px] outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 resize-none"
                          >
                            {values.text_message}
                          </textarea>
                          {errors.text_message && touched.text_message && (
                            <span className="text-red-500 absolute -bottom-6 right-0">
                              {errors.text_message}
                            </span>
                          )}
                        </div>
                      )}
                      <div
                        className={`w-full min-h-[100px] lg:min-h-[130px] rounded-[5px] border-[1px] border-[#707070] border-dashed mx-auto flex flex-col items-center justify-center space-y-1`}
                      >
                        <UploadOrUpdateBucket
                          mode={mode}
                          id={"thumbnail"}
                          labelText={"Upload Thumbnail"}
                          accept={"image/*"}
                          fileSize={100 * 100}
                          sizeInText={"Image Size 100x100"}
                          Method={
                            id === "new-pushcontent" ? "Upload" : "Update"
                          }
                          bucketName={"push_content"}
                          forValue={handleValues}
                          fileNameData={values.thumbnail.fileName}
                          fileNameKey={values.thumbnail.key}
                          preview={values.thumbnail.url}
                          index={1}
                          name={`thumbnail.url`}
                          error={errors.thumbnail && errors.thumbnail?.url}
                          isTouched={
                            touched.thumbnail && touched.thumbnail?.url
                          }
                          disableBasedOnMode={mode !== "view"}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {!mode && <SubmitButton />}
              </div>
            </div>
          </form>
        </CardLayoutTwo>
      )}
    </>
  );
};
