import { Components } from "./ModuleImports";

export const RoleName = ({ formik, searchRole, setSearchRole, setTriggerSearch, mode }: any) => {
    const { searchIconDark }: any = Components;
  return (
          <div className="w-[95%] py-4 px-4 mx-auto bg-white rounded-[6px] flex items-center justify-between">
            <input
              autoFocus
              required
              name="role"
              type="text"
              value={formik.values.role}
              onChange={formik.handleChange}
              placeholder="Enter New Role"
              disabled={mode==="view"}
              className="h-[46px] w-[30%] shadow-md shadow-[#00000029] rounded-[2px] px-2 outline-none"
            />
            <div className="w-[20%] flex items-center justify-center">
              <input
                name="searchrole"
                value={searchRole}
                onChange={(e: any) => setSearchRole(e.target.value)}
                placeholder="Search..."
                className="bg-[#F9F9F9] rounded-l-full h-[46px] w-full rounded-[2px] px-4 outline-none placeholder:text-[#036AD1]"
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === "Enter") {
                    setTriggerSearch(true);
                  }
                }}
              />
              <button
                type="button"
                className="bg-[#036AD1] w-[20%] h-[46px] rounded-r-full flex items-center justify-center"
                onClick={() => setTriggerSearch(true)}
              >
                <img src={searchIconDark} alt="Button Icon" />
              </button>
            </div>
          </div>
  )
}
