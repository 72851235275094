import { Components } from "./ModuleImports";

export const SessionDetails = ({Sessions}:any) => {
    const { SessionData }: any = Components;
    const SessionHeader = ['Session Date', 'Trainer Name', 'Trainer Phone Number', 'Topic', 'Attendance', 'Start Time', 'End Time', 'Duration'];
    // const mapArray = SessionData();
    const SessionClassName=(text?:any)=>`${text?'w-[20%] text-[#036AD1] cursor-pointer':'w-[12%] text-[#686868]'} py-1 text-[14px] font-Roboto font-[400] text-center`

  return (
    <div className="space-y-4 w-11/12 mx-auto">
          <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">Session Details</h1>
          <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2.5">
              <div className='flex items-center justify-between bg-[#EFF7FF] py-1.5 px-4'>
                  {SessionHeader.map((head: any) => <h1 className={`${head==='Topic'?'w-[20%]':'w-[12%]'} text-[#000000] text-[16px] font-Roboto font-[500] text-center`}>{head}</h1>)}
              </div>
              <div>
                  {Sessions.map((arr:any, index:number)=>{
                    return (
                      <div
                        key={index}
                        className={`flex items-center justify-between py-1.5 px-4 ${
                          index % 2 !== 0 && "bg-[#EFF7FF]"
                        }`}
                      >
                        <p className={SessionClassName()}>{arr.session_date}</p>
                        <p className={SessionClassName()}>{arr.trainer_name}</p>
                        <p className={SessionClassName()}>
                          {arr.trainer_phone_number}
                        </p>
                        <p className={SessionClassName(arr.details?.topic)}>
                          {arr.details?.topic}
                        </p>
                        <p className={SessionClassName()}>{arr.is_present?'Present':'Absent'}</p>
                        <p className={SessionClassName()}>{arr.start_time}</p>
                        <p className={SessionClassName()}>{arr.end_time}</p>
                        <p className={SessionClassName()}>{`${arr.duration} ${
                          Number(arr.duration) > 1 ? "Hrs" : "Hr"
                        }`}</p>
                      </div>
                    );
                  })}
              </div>
          </div>
    </div>
  )
}
