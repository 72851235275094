import { Subsection } from "../../SubSections/Subsection";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";

export const ActionCenter = () => {
  const {params}: any = useSharedLogic();
  const routeName: any = params.subsection;
  return (
    <div>
      <Subsection routeName={routeName} />
    </div>
  );
};
