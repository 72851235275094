import { useState, useEffect } from "react";
import { Batchmanagement } from "../../utils/json/batchManagement";
import buttonWithRightArrow from "../../Assets/buttonWithRightArrow.svg";
import { BackButton } from "../Elements/BackButton";
import { AddBatch } from "./Create Batch/AddBatch";
import { BatchInfo } from "./Batch Information/BatchInfo";
import { useSharedLogic, CommonTableComp } from "../CommonFunctions/CommonFunc";
import { MessageCard } from "../Elements/MessageCard";
import { FunctionForPrivileges } from "../CommonFunctions/Privileges";
import { useAppSelector } from "../..";
import { NewBatchCreation } from "./Create Batch/NewBatchCreation";
import { getBatchTableData } from "../../functions/admin/Batches/Batches";

export const BatchManagement = () => {
  const {
    navigate,
    routeNameForHome,
    id,
    mode,
    courseId,
    createFuncWithoutSubSection,
  }: any = useSharedLogic();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const { MainMenu, allowAllActions }: any = FunctionForPrivileges();

  return (
    <div>
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Batch Created Successfully"
                : "Batch Updated Successfully"
            }
            buttonText={"Batch Management"}
            handleClick={() => navigate(`/${routeNameForHome}`)}
            closeFunc={() => navigate(`/${routeNameForHome}`)}
          />
        </div>
      )}
      {mode === "view" && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-[95%] justify-end"} />
          <BatchInfo id={id} mode={mode} />
        </div>
      )}
      {(id === "new-batch" || mode === "edit") && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-[95%] justify-end"} />
          {/* <AddBatch id={id} mode={mode} /> */}
          <NewBatchCreation id={id} mode={mode} />
        </div>
      )}
      {allowAllActions() && (
        <CommonTableComp
          id={id}
          head={"Batch Management"}
          buttonType={"right"}
          buttonText={"Create Batch"}
          onClick={() => createFuncWithoutSubSection("new-batch")}
          buttonImg={buttonWithRightArrow}
          TABLE_JSON={Batchmanagement}
          backEndCallParams={signInData?.data[0]}
          allowCondition={MainMenu?.includes("create")}
        />
      )}
    </div>
  );
};