import { useState } from "react";
import certificateSample from "../../../../Assets/certificateSample.png";
import { ReadData } from "../../../Elements/ReadData";
import { BuildJson } from "./FileJson";
import { DropdownSingleInputWithCheckbox } from "../../../Elements/DropdownSingleInputWithCheckbox";
import { updateStatusandPriority } from "../../../../functions/admin/Action center/Session Feedback/sessionFeedback";

export const BasicInfo = ({id, mode, sessionData, selectedStatus, setSelectedStatus, selectedIssue, setSelectedIssue}: any) => {
  const BasicInfo = BuildJson(sessionData);
  const StatusTypes = [
    "Resolved",
    "Open",
    "In-progress",
    "Re-open",
    "Thanks for the feedback",
  ];
  const IssuePriority = ["Critical", "High", "Low", "Medium"]

  const handleOut = async (name: string, val: string, Id: any) => {
    if (name === "status") {
      setSelectedStatus(val) 
      await updateStatusandPriority({ status: val }, id)
    } else {
      setSelectedIssue(val)
      await updateStatusandPriority({issue_priority: val}, id)
    }
  }

  return (
    <div className="space-y-4 w-11/12 mx-auto py-6">
      <div className="w-11/12 mx-auto flex">
        <div className="w-1/2 space-y-3">
          {BasicInfo.map((read: any) => {
            return <ReadData head={read.head} body={read.body} />;
          })}
        </div>
        <div className="w-1/2 space-y-4">
          <Trainings StatusTypes={StatusTypes} IssuePriority={IssuePriority} selectedStatus={selectedStatus} selectedIssue={selectedIssue} handleOut={handleOut} sessionData={sessionData} />
        </div>
      </div>
    </div>
  );
};

const Trainings = ({StatusTypes, IssuePriority, selectedStatus, selectedIssue, handleOut, sessionData}:any) => {
    return(
        <div className='w-[60%]'>
          <ReadData head={'Course'} body={sessionData?.training?.course?.course_name} />
          <img src={certificateSample} alt="Sample" />
          <ReadData head={'Trainer Name'} body={`${sessionData?.training?.batch?.user?.first_name.concat(' ', sessionData?.training?.batch?.user?.last_name)}`} />
          <ReadData head={'Trainer Phone Number'} body={sessionData?.training?.batch?.user?.phone_number} />
          <div className={`min-h-[46px] pt-2 space-y-2`}>
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-9/12"}
            classNameInput={"w-full"}
            label={"Status"}
            options={StatusTypes}
            name={"status"}
            value={selectedStatus}
            handleOut={handleOut}
            // onBlur={handleBlur}
            // error={errors.nullType}
            // isTouched={touched.nullType}
            mapThrough={"status"}
            // formik={formik}
            zIndex={"z-30"}
            disabled={false}
          />
          {(sessionData?.response !== "Excellent" && sessionData?.response !== "Good") &&
            <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-9/12"}
            classNameInput={"w-full"}
            placeholder="Issue Priority"
            options={IssuePriority}
            name={"issue"}
            value={selectedIssue}
            handleOut={handleOut}
            // onBlur={handleBlur}
            // error={errors.nullType}
            // isTouched={touched.nullType}
            mapThrough={"issue"}
            // formik={formik}
            zIndex={"z-20"}
            disabled={false}
          />}
          </div>
        </div>
    )
}