import { courseFeature } from "../../../utils/json/courseFeature";
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { AddNewFeature } from "./AddNewFeature";
import { BackButton } from "../../Elements/BackButton";
import { MessageCard } from "../../Elements/MessageCard";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const CourseFeatures = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  return (
    <div className="w-11/12 mx-auto">
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Course Features Created Successfully"
                : "Course Features Updated Successfully"
            }
            buttonText={"Manage Features"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
      {(id === "new-feature" || mode === "edit") && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-10/12 justify-end"}
          />
          <AddNewFeature id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions('view') &&
      <CommonTableComp
        id={id}
        head={"Course Features"}
        buttonType={"right"}
        buttonText={"Add New Features"}
        onClick={()=>createFunc("new-feature")}
        buttonImg={buttonWithRightArrow}
        TABLE_JSON={courseFeature}
        allowCondition={allowOnlyActions("create")}
      />}
    </div>
  );
};
