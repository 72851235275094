import { errorNotifier } from "../commonHelper";
import { TableNames } from "../config/Tables"
import { FetchData, InsertData } from "../crud";

export interface CityInterface{
    city_name?: string,
    created_by?:string
}

export const getAllCities = async (UsersDetails: any, requiredFields: string[] = []) => {
    const { data: cities, error } = await FetchData(TableNames.cities, requiredFields)
    // console.log(cities, "cities")
    return cities;
}

export const createCities = async (cityData:CityInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.cities, cityData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}

// export const sampleCityData:CityInterface = {
//     city_name: "Madurai",
//     created_by: "b45e3497-84e0-4a8e-8d87-caa57f13757b"
// }