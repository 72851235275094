import {useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';
import { TableComponent } from '../../Elements/Table/TableComponent'
import { cancellationsessionForVerified } from '../../../utils/json/cancellationsessionForVerified';
import { cancellationsessionForPending } from '../../../utils/json/cancellationsessionForPending';
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from '../../..';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { BackButton } from '../../Elements/BackButton';
import { CancelledSessionFollowUp } from "./CancelledSessionFollowUp";
import { AddNew } from "./AddNew";
import { getCancelledSessionsForPending, getCancelledSessionsForVerified } from '../../../functions/admin/Course Progress/courseProgress';

export const CancellationSession = () => {
  const { id, mode, by }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
    const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "Pending comments",
      len: null
    },
    {
      approval: "Verified comments",
      len: null
    }
  ] as any);

  useEffect(()=>{
    setSearchParams({
      id: "pending_comments",
    });
  },[])

  useEffect(() => {
    (async () => { 
      if (signInData?.data?.length > 0) {
        const verifiedResponse: any = await getCancelledSessionsForVerified(signInData?.data[0]);
        const pendingResponse: any = await getCancelledSessionsForPending(signInData?.data[0]);
        const VerifiedLength: any = verifiedResponse?.length;
        const PendingLength: any = pendingResponse?.length;

        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: PendingLength
          },
          {
            ...prevQueue[1],
            len: VerifiedLength
          }
        ]);
      }
    })() 
  }, [])

  return (
    <div>
      {mode === "add_new_comment" && (
        <div className="space-y-2">
          <CancelledSessionFollowUp id={id} />
        </div>
      )}
      {allowOnlyActions("view") && mode === "edit" && (
        <div className="space-y-2">
          <AddNew id={id} />
        </div>
      )}
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Cancellation Session
            </h1>
            {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )}
          </div>
          {id === "verified_comments" && (
            <TableComponent
              TABLE_JSON={cancellationsessionForVerified}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "pending_comments" && (
            <TableComponent
              TABLE_JSON={cancellationsessionForPending}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
    </div>
  );
}

const QueueType = ({ queue, setQueue }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const linkOnclick = (q: any) => {
    const lowercaseString = q?.toLowerCase();
      setSearchParams({
        id: lowercaseString?.replace(/ /g, "_"),
      }); 
    }

  return (
    <div className="flex items-center gap-6"> 
      {queue?.map((que: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center ${index === 0 && "gap-8"}`}
            onClick={() => linkOnclick(que?.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {que?.approval}{" "}
                <span className="ml-1">{`(${que?.len ? que?.len : 0})`}</span>
              </p>
              {id === que?.approval?.toLowerCase().replace(/\s+/g, "_") ? (
                <hr />
              ) : (
                <p></p>
              )}
            </div>
            {index === 0 && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        );
      })}
    </div>
  );
}
