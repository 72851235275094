import { BackButton } from "../../Elements/BackButton";
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { AddPushContent } from "./AddPushContent";
import { pushcontent } from "../../../utils/json/pushcontent";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { MessageCard } from "../../Elements/MessageCard";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const PushContent = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  return (
    <div className="w-11/12 mx-auto">
      {(id === "created-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={"Push Content Created Successfully"}
            buttonText={"Manage Push Content"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
      {(id === "new-pushcontent" || mode === "view") && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-[95%] justify-between"}
            header={"Push Content"}
          />
          <AddPushContent id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions('view') &&
      <CommonTableComp
        id={id}
        head={"Push Content"}
        buttonType={"right"}
        buttonText={"Add Push Content"}
        onClick={() => createFunc("new-pushcontent")}
        buttonImg={buttonWithRightArrow}
        TABLE_JSON={pushcontent}
        allowCondition={allowOnlyActions("create")}
      />}
    </div>
  );
};
