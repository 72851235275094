import React from 'react'

export const Headline = ({cardHeading}:any) => {
  return (
    <div className="space-y-4">
      <h1 className="px-8 text-[#707070] text-[20px] 2xl:text-[22px] font-Roboto font-medium">
        {cardHeading}
      </h1>
      <hr />
    </div>
  );
}
