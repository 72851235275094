import ViewEditDeleteActionComponent from "../../Components/AdminManagement/RoleManagement/ActionComponent";
// import { sampleFunc } from "../../Services/Supabase/databaseOperations";
import { getAllRoles } from "../../functions/admin/Role Management/roleMangement";

export const RoleTable = {
  headers: [
    {
      name: "Role",
      apiKey: "role",
      headerClass: "w-[40%] text-start",
      className: "w-[40%]",
    },
    {
      name: "Status",
      apiKey: "status",
      headerClass: "w-[40%] text-start",
      className: "w-[40%]",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "text-center w-[20%]",
      className: "text-center w-[20%]",
    },
  ],
  BackendFunctionCall: getAllRoles,
  filters: {
    checkBox: [
      {
        apiKey: "role",
        name: "Role",
        isSearchNeeded: true,
        className: "w-[190px]",
      },
      {
        apiKey: "status",
        name: "Status",
        isSearchNeeded: true,
        className: "w-[190px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["role", "status"],
        searchKey: "roleStatus",
        name: "Search....",
        searchStyle: "w-[220px]",
      },
    ],
  },
  // ActionComponent: ViewEditDeleteActionComponent,
};
