import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchData, FetchDataById, InsertData } from "../../crud";
import { UserLogInterface } from "./interface";

export const submitUserLogs = async (userLogData: UserLogInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.user_logs, userLogData)
        if (error) throw error
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}

// export const getUserLogs = async () => {
//     try {
//         const {data, error} = await FetchData(TableNames.user_logs, ["*", "user:users(first_name, last_name, cities, branches)"])
//         if (error) throw error
//         return data
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

export const getUserLogs = async () => {
    try {
      const [userLogs, branchData, cityData] = await Promise.all([
        FetchData(TableNames.user_logs, ["*", "user:users(first_name, last_name, cities, branches)"]),
        FetchData(TableNames.branches, ["id", "branch_name"]),
        FetchData(TableNames.cities, ["id", "city_name"]),
      ]);
   
      if (userLogs.error || branchData.error || cityData.error) {
        throw userLogs.error || branchData.error || cityData.error;
      }
   
      const mapUserLogs = userLogs.data.map((dt:any) => {
        const matchedBranchNames = branchData.data
          .filter((branch:any) => dt.user?.branches?.includes(branch.id))
          .map((data:any) => data.branch_name);
        const matchedCityNames = cityData.data
          .filter((city:any) => dt.user?.cities?.includes(city.id))
          .map((data:any) => data.city_name);
  
        return {
          ...dt,
          admin_name: dt?.user ? `${dt.user.first_name} ${dt.user.last_name}` : null,
          branch: matchedBranchNames,
          city: matchedCityNames,
        };
      });
      console.log("mapUserLogs", mapUserLogs)
      return mapUserLogs;
    
    } catch (error) {
      return errorNotifier(error);
    }
  };

export const getUserLogsById = async (userLogId: number | string) => {
    try {
        const { data, error } = await FetchDataById(TableNames.user_logs, userLogId)
        if (error) throw error
        return data[0]
    } catch (error) {
        return errorNotifier(error)
    }
}