import { AddItemsButton } from "../../../Elements/AddItemsButton";
import { InputBox } from "../../../Elements/InputBox";

export const AddFAQ = ({
  addMoreFAQ,
  deleteFAQ,
  scrollRef,
  name,
  dataSetup,
  formik,
  disabled
}: any) => {
  return (
    <div className="w-full" ref={scrollRef}>
      <div>
        {formik.values.faqs?.length > 0 &&
          <p className="text-[#707070] text-[20px] font-Roboto font-[500]">
          FAQs *:
        </p>}
        {formik.values.faqs?.map((data: any, i: number) => {
          return (
            <div key={i} className="space-y-2 mt-4 relative">
              <InputBox
                placeholder={`Title ${i + 1}`}
                topDivStyle={"flex flex-col w-full"}
                classNameInput={"w-full"}
                name={name}
                value={data?.title}
                onChange={dataSetup}
                onBlur={formik.handleBlur}
                index={i}
                check={"title"}
                disabled={disabled}
              />
              {!disabled && (
                <div
                  onClick={() => deleteFAQ(i)}
                  className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-9 top-7"
                >
                  x
                </div>
              )}
              <textarea
                name={name}
                value={data?.description}
                disabled={disabled}
                onChange={(e: any) =>
                  dataSetup(i, e.target.value, "description")
                }
                onBlur={formik.handleBlur}
                // index={i}
                placeholder={`Description ${i + 1}`}
                className="w-full min-h-[138px] px-2 py-2 border-[#707070] border-[0.5px] rounded-[3px]"
              >
                {data?.description}
              </textarea>
            </div>
          );
        })}
      </div>
      {/* <div className="flex items-center justify-between"> */}
        {/* <p className="text-[#707070] text-[20px] font-Roboto font-[500]">
          FAQs *:
        </p> */}
        <AddItemsButton
          text={"Add FAQ"}
          classNameInput={"flex justify-end w-full"}
          onClick={addMoreFAQ}
          disabled={disabled}
        />
      {/* </div> */}
    </div>
  );
};