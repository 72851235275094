import { Components } from './ModuleImports';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import { EnterComments } from './EnterComments';
import { getBatchByIdForTrainerPay } from '../../../functions/admin/Action center/Trainer Payments/trainerPayment';
import { StudentDetailsPreviewForTrainerPayment } from './StudentDetailsPreviewForTrainerPayment';
import { ModalForComment } from './ModalForComment';

export const handleSubmitDetail = (arrayValue:any, routePath:string) => {
    const newEntries = arrayValue?.map((innerArray: any) =>
    innerArray.map((stu: any) => {
      // console.log(stu, "stu")
      const paymentValue = parseFloat(
        stu?.trainer_payment?.toString()?.replace(/,/g, "")
      );
      // console.log(paymentValue, "paymentValue");
      return {
        id: stu?.id,
        trainer_payment:
          stu?.student_payment_status === "Partially Processed" ||
          stu?.student_payment_status === "Processed"
            ? 0
            : (paymentValue === null
            ? 0
            : paymentValue),
        existing_trainer_payment:
          stu?.student_payment_status === "Partially Processed" ||
          stu?.student_payment_status === "Processed"
            ? (paymentValue === null
              ? (0 + stu?.existing_trainer_payment)
              : (stu?.existing_trainer_payment + paymentValue))
            : stu?.existing_trainer_payment,
        student_payment_status: stu?.student_payment_status,
        trainer_payment_comment: stu?.trainer_payment_comment,
        trainer_transactions: {
          student_id: stu?.student?.uid,
          student_payment_status: stu?.student_payment_status,  
          trainer_payment: stu?.trainer_payment,
          batch_id: stu?.batch?.id,
          batch_code: stu?.batch?.batch_code,
          branch_id: stu?.batch?.branch_id,
          trainer_name: `${stu?.batch?.trainer?.first_name} ${stu?.batch?.trainer?.last_name}`,
          trainer_phone_number: stu?.batch?.trainer?.phone_number,
          total_trainer_payment: stu?.batch?.total_trainer_payment
        }
      };
    })
  );
  // console.log(newEntries, "newEntries");

  const mapStudentStatusBased = arrayValue?.map((innerArray: any) =>
    innerArray.filter(
      (stu: any) =>
        stu?.student_payment_status !== "Processed" &&
        stu?.student_payment_status !== "Partially Processed"
    )
  );

  // const mapStudentPartialStatusBased = trainerBasedStudents?.map((innerArray: any) =>
  //   innerArray.filter(
  //     (stu: any) => stu?.student_payment_status === "Partially Processed"
  //   )
  // );

  const mapStudentCalculatedStatusBased = arrayValue?.map(
    (innerArray: any) =>
      innerArray.filter(
        (stu: any) =>
          stu?.student_payment_status !== "Calculated" &&
          stu?.student_payment_status !== "Partially Calculated"
      )
  );

  // const mapStudentPartialCalculatedStatusBased = trainerBasedStudents?.map((innerArray: any) =>
  //   innerArray.filter(
  //     (stu: any) => stu?.student_payment_status === "Partially Calculated"
  //   )
  // );

  const newEntriesLength = newEntries.reduce(
    (accumulator: any, currentArray: any) =>
      accumulator + currentArray.length,
    0
  );
  const mapStudentStatusBasedLength = mapStudentStatusBased.reduce(
    (accumulator: any, currentArray: any) =>
      accumulator + currentArray.length,
    0
  );
  const mapStudentCalculatedStatusBasedLength =
    mapStudentCalculatedStatusBased.reduce(
      (accumulator: any, currentArray: any) =>
        accumulator + currentArray.length,
      0
    );

  var conditionForCalculated: any = "";
  var conditionForProcessed: any = "";

  if (routePath && routePath === "process_trainer_pay") {
    conditionForProcessed =
      mapStudentStatusBasedLength === 0
        ? "Fully Processed"
        : newEntriesLength === mapStudentStatusBasedLength
        ? "Calculated"
        : mapStudentStatusBasedLength !== 0 &&
          newEntriesLength !== mapStudentStatusBasedLength &&
          "Partially Processed";
    // if (mapStudentStatusBasedLength === 0) {
    //     setBatchStatus("Fully Processed");
    // } else if (newEntriesLength === mapStudentStatusBasedLength) {
    //     setBatchStatus("Calculated")
    // } else if ((mapStudentStatusBasedLength !== 0) && (newEntriesLength !== mapStudentStatusBasedLength)) {
    //     setBatchStatus("Partially Processed")
    // } else console.log("No Condition Verified");
  } else {
    conditionForCalculated =
      mapStudentCalculatedStatusBasedLength === 0
        ? "Fully Calculated"
        : newEntriesLength === mapStudentCalculatedStatusBasedLength
        ? ""
        : mapStudentCalculatedStatusBasedLength !== 0 &&
          newEntriesLength !== mapStudentCalculatedStatusBasedLength &&
          "Partially Calculated";
    //   if (mapStudentCalculatedStatusBasedLength === 0) {
    //     setBatchStatus("Fully Calculated");
    //   } else if (newEntriesLength === mapStudentCalculatedStatusBasedLength) {
    //     setBatchStatus("")
    //   } else if ((mapStudentCalculatedStatusBasedLength !== 0) && (newEntriesLength !== mapStudentCalculatedStatusBasedLength)) {
    //     setBatchStatus("Partially Calculated")
    //   } else console.log("No Condition Verified");
  }
  return {
    newEntries, conditionForCalculated, conditionForProcessed
  }
}

export const BatchInfo = ({ id, mode }: any) => {
  const { useState, useEffect, Loader, CardLayoutThree, BasicInfo, ReadData, TrainerDetails, ChangeTrainerReason, StudentDetails, getBatchById, getStudentBasedOnTrainer, toast, StudentDetailsForTrainerPayment }: any = Components;
  const { routePath }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [viewStudent, setViewStudent] = useState([]);
  const [batch, setBatch] = useState({} as any);
  const [trainerData, setTrainerData] = useState([] as any);
  const [studentsData, setStudentsData] = useState([] as any);
  const [trainingStudent, setTrainingStudent] = useState([] as any);
  const [batchStatus, setBatchStatus] = useState("")
  const [total_trainer_payment_comment, setTotal_trainer_payment_comment] = useState("");
  const [trainerBasedStudents, setTrainerBasedStudents] = useState([] as any);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ newTrainerPayment, setNewTrainerPayment] = useState([] as any);
  const [switchComp, setSwitchComp] = useState({
    ele: "sd",
    id: 0
  } as any);
  const [progress, setProgress] = useState({
    batch_progress_in_percent: '',
    progress_in_hrs: '',
    total_duration: ''
  } as any);
  const [preview, setPreview] = useState(false);
  const isId: any = id !== "new-student-course" && id;

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const response = await(
        routePath &&
          (routePath === "cal_trainer_pay" ||
            routePath === "process_trainer_pay")
          ? getBatchByIdForTrainerPay(isId)
          : getBatchById(isId)
      );
      setBatch(response?.batch);
      setProgress({
        batch_progress_in_percent: response?.batch_progress_in_percent,
        progress_in_hrs: response?.progress_in_hrs,
        total_duration: response?.total_duration,
      });
      if (!routePath && (routePath !== "cal_trainer_pay" || routePath !== "process_trainer_pay")) {
        setTrainerData(response?.updatedTrainerData)
      }
      if (routePath && (routePath === "cal_trainer_pay" || routePath === "process_trainer_pay")) {
        // console.log(response, "response")
        setTrainerData(response?.updatedTrainerData);
        setBatchStatus(response?.batch?.batch_payment_status);
        // setStudentsData(response?.student_data);
      }
      setIsLoading(false)
    })()
  },[])

  const ViewStudentDetails = async (index: number, uid: any) => {
    if(viewStudent.includes(uid)){
      const newArray = [...viewStudent];
      newArray.splice(index, 1)
      setViewStudent(newArray);
    } else {
      const studentDetails:any = await getStudentBasedOnTrainer(Number(isId), uid);
      if (studentDetails?.Allocated?.length !== 0 || studentDetails?.Dropped?.length !== 0 || studentDetails?.Hold?.length !== 0) {
        // console.log(studentDetails, "studentDetails");
        setViewStudent([uid]);
        setStudentsData(studentDetails)
      } else toast.error("No Student Details Present")
    }
  }

  const handleSelectChange = (event: any, id: number, keyChange: any) => {
    const numericValue = event.target.value?.replace(/[^0-9]/g, '');
    // Format the number with comma separation
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const updatedData = trainerBasedStudents.map((innerArray: any) =>
      innerArray.map((item: any) =>
        item.id === id
          ? {
              ...item,
              [keyChange === "trainer_payment" ? "trainer_payment" : (keyChange === "student_payment_status" ? "student_payment_status" : "trainer_payment_comment")]:
                keyChange === "trainer_payment" ? (formattedValue) : event.target.value
            }
          : item
      )
    );
    // console.log(updatedData, "updatedData")
    setTrainerBasedStudents(updatedData);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const filterIndividualComment = trainerBasedStudents && trainerBasedStudents?.map((innerArray: any) =>
    innerArray?.filter((fil: any) => fil?.id === switchComp.id)
  );
  const index = trainerBasedStudents.findIndex((innerArray:any) =>
    innerArray?.find((obj:any) => obj?.id === switchComp.id)
  );

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-11/12"} />
      ) : isModalOpen ? (
        <ModalForComment
          onClose={handleCloseModal}
          comment={
            filterIndividualComment?.[index]?.[0]?.trainer_payment_comment
          }
        />
      ) : (
        // switchComp.ele === "comment" ? (
        //   <div className="w-full flex items-center justify-center py-16">
        //     <EnterComments
        //       trainerBasedStudents={trainerBasedStudents}
        //       setTrainerBasedStudents={setTrainerBasedStudents}
        //       switchComp={switchComp}
        //       setSwitchComp={setSwitchComp}
        //       preview={preview}
        //     />
        //   </div>
        // ) :
        <CardLayoutThree>
          <div className="space-y-4">
            <div className="text-left border-b-2 p-2 flex items-center justify-between">
              <p className="font-Roboto font-medium text-[22px] 2xl:text-[24px] text-[#707070] my-3 ml-4">
                Batch Information
              </p>
              <div className="pr-10 flex items-center gap-8">
                <ReadData
                  head={"Batch Code"}
                  body={batch?.batch_code ? batch?.batch_code : "-"}
                />
                <ReadData
                  head={"Batch Status"}
                  body={batch?.status ? batch?.status : "-"}
                />
              </div>
            </div>
            <BasicInfo
              batch={batch}
              progress={progress}
              batchStat={batchStatus}
            />
            <div className="space-y-6">
              <TrainerDetails
                viewStudent={viewStudent}
                ViewStudentDetails={ViewStudentDetails}
                trainerData={trainerData}
                id={id}
                setSwitchComp={setSwitchComp}
                handleSelectChange={handleSelectChange}
                preview={preview}
                trainerBasedStudents={trainerBasedStudents}
                setTrainerBasedStudents={setTrainerBasedStudents}
                setIsModalOpen={setIsModalOpen}
                newTrainerPayment={newTrainerPayment}
                setNewTrainerPayment={setNewTrainerPayment}
                total_trainer_payment_comment={total_trainer_payment_comment}
              />
              {!routePath && routePath !== "cal_trainer_pay" && (
                <ChangeTrainerReason batch={batch} />
              )}
              {!routePath && (
                <StudentDetails
                  studentsData={studentsData}
                  viewStudent={viewStudent}
                />
              )}
              {trainerData?.length > 0 &&
                routePath &&
                (routePath === "cal_trainer_pay" ||
                  routePath === "process_trainer_pay") && (
                  <StudentDetailsPreviewForTrainerPayment
                    trainerBasedStudents={trainerBasedStudents}
                    id={id}
                    batch={batch}
                    batchStat={batchStatus}
                    setBatchStatus={setBatchStatus}
                    preview={preview}
                    setPreview={setPreview}
                    setIsLoading={setIsLoading}
                    total_trainer_payment_comment={
                      total_trainer_payment_comment
                    }
                    setTotal_trainer_payment_comment={
                      setTotal_trainer_payment_comment
                    }
                    newTrainerPayment={newTrainerPayment}
                  />
                )}
            </div>
          </div>
        </CardLayoutThree>
      )}
    </>
  );
}
