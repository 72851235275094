import React from 'react'

export const PendingPayments = () => {
  return (
    <div className='flex items-center gap-6 w-11/12 mx-auto'>
        <h1 className='text-[#000000] text-[28px] font-Roboto font-[500]'>Pending Payment Comments</h1>
        <button className='shadow-md shadow-[#035700] bg-[#035700] font-Roboto font-[400] text-[16px] text-white p-1 px-3 rounded-[3px]'>View</button>
    </div>
  )
}
