import { useState, useEffect } from "react";
import { AddItemsButton } from '../../Elements/AddItemsButton';
import { InputBox } from '../../Elements/InputBox';
import { DropdownInputWithCheckbox } from '../../Elements/DropdownInputWithCheckbox';
import { DropdownSingleInputWithCheckbox } from "../../Elements/DropdownSingleInputWithCheckbox";
import toast from "react-hot-toast";
import { setSignInData } from "../../../Redux/Reducers/signInUserData";
import { useAppSelector } from "../../..";

export const Payment = ({
  formik,
  mode,
  Payment_Type,
  Payment_Mode,
}: any) => {

  const addMorePayments = () => {
    const add = [
      ...formik.values?.payment_details,
      {
        payment_type: [],
        paid_amount: null,
        payment_date: "",
        mode_of_payment: "",
        transaction_id: "",
      },
    ];
    formik.setFieldValue("payment_details", add);
  };

  const deleteAddedPayment = (i: number) => {
    const newArray = [...formik.values?.payment_details];
    newArray.splice(i, 1);
    formik.setFieldValue("payment_details", newArray);
  };

  const handleChangeForPayments = (
    index: number,
    value: string,
    data: string
  ) => {
    // if (data === "paid_amount") {
    //   const checkPaymentMoreThanFee = formik.values.payment_details?.reduce((sum: any, trans: any) => sum + trans?.paid_amount, 0)
    //   console.log(
    //     checkPaymentMoreThanFee,
    //     "checkPaymentMoreThanFee",
    //     Number(checkPaymentMoreThanFee) + Number(formik.values.total_fee)
    //   );
    //   if (
    //     Number(value) <= (Number(checkPaymentMoreThanFee) + Number(formik.values.total_fee))
    //   ) {
    //     formik.setFieldValue(
    //       `payment_details[${index}][${data}]`,
    //       Number(value)
    //     );
    //   } else formik.setFieldValue(`payment_details[${index}][${data}]`, null);
    // } else {
    //   formik.setFieldValue(`payment_details[${index}][${data}]`, data === 'payment_type' ? [`...payment_details[${index}][${data}]`, value] : value);
    formik.setFieldValue(
      `payment_details[${index}][${data}]`,
      data === "payment_type"
        ? [`...payment_details[${index}][${data}]`, value]
        : data === "paid_amount"
        ? Number(value)
        : value
    );
    // }
  };

  const handleChangeForMode = (name: any, option: any, trainerId: any) => {
    const modifiedString = name.replace("mode_of_payment", "transaction_id");
    if (option === "Cash") {
      formik.setFieldValue(name, option);
      formik.setFieldValue(modifiedString, "");
    } else formik.setFieldValue(name, option);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
          Payment
        </h1>
        <AddItemsButton
          text={"Add New Payment"}
          classNameInput={"w-11/12 mx-auto flex items-center justify-end"}
          onClick={addMorePayments}
        />
      </div>
      <div className="space-y-2">
        <PaymentModes
          handleChangeForMode={handleChangeForMode}
          onChange={handleChangeForPayments}
          formik={formik}
          mode={mode}
          Payment_Type={Payment_Type}
          Payment_Mode={Payment_Mode}
          handleChangeForPayments={handleChangeForPayments}
        />
        {formik.values.payment_details?.map((pay: any, index: number) => {
          if (index !== 0) {
            return (
              <PaymentModes
                handleChangeForMode={handleChangeForMode}
                index={index}
                pay={pay}
                deleteAddedPayment={deleteAddedPayment}
                onChange={handleChangeForPayments}
                formik={formik}
                mode={mode}
                Payment_Type={Payment_Type}
                Payment_Mode={Payment_Mode}
                handleChangeForPayments={handleChangeForPayments}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

const PaymentModes = ({ handleChangeForMode, index, pay, deleteAddedPayment, mode, onChange, formik, Payment_Type, Payment_Mode, handleChangeForPayments }: any) => {
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const firstObject: any = pay ? formik.values?.payment_details?.[index] : formik.values?.payment_details?.[0];
  const DisablePaymentInput = ((signInData?.data[0]?.user_type === "BC" || signInData?.data[0]?.user_type === "None") && mode==="edit" && firstObject?.id ? true : false)
    //  || ((signInData?.data[0]?.user_type === "BC" || signInData?.data[0]?.user_type === "None") && (firstObject?.id ? true : false) && mode==="edit")
  
  useEffect(() => {
    index && formik.setFieldValue(`payment_details[${index}][payment_type]`, 'Installment');
  },[])

  // console.log(DisablePaymentInput, "009");
  
  return (
    <div className="flex items-center gap-6 shadow-md shadow-[#00000029] p-3 py-6 relative">
      {index && !pay?.id && (
        <div
          onClick={() => deleteAddedPayment(index)}
          className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-9 bottom-7"
        >
          x
        </div>
      )}
      {!index && (
        <DropdownInputWithCheckbox
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          label="Payment Type"
          placeholder="Payment Type"
          options={Payment_Type}
          name={`${
            pay
              ? `payment_details[${index}]["payment_type"]`
              : 'payment_details[0]["payment_type"]'
          }`}
          value={firstObject?.payment_type}
          onBlur={formik.handleBlur}
          formik={formik}
          disabled={mode === "view"}
        />
      )}
      {index && (
        <InputBox
          label="Payment Type"
          placeholder="Payment Type"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={`${
            pay
              ? `payment_details[${index}]["payment_type"]`
              : 'payment_details[0]["payment_type"]'
          }`}
          value={firstObject?.payment_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={true}
        />
      )}
      <InputBox
        label="Paid Amount (₹)"
        placeholder="Paid Amount"
        type={"number"}
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={`payment_details[${index ? index : 0}].paid_amount`}
        value={firstObject?.paid_amount}
        onChange={handleChangeForPayments}
        onBlur={formik.handleBlur}
        error={
          formik.errors.payment_details &&
          formik.errors?.payment_details[`${index ? index : 0}`] &&
          formik.errors?.payment_details[`${index ? index : 0}`].paid_amount
        }
        isTouched={
          formik.touched.payment_details &&
          formik.touched?.payment_details[`${index ? index : 0}`] &&
          formik.touched?.payment_details[`${index ? index : 0}`].paid_amount
        }
        disabled={mode === "view" || DisablePaymentInput}
        index={`${index ? index : 0}`}
        check={"paid_amount"}
      />
      <InputBox
        type={"date"}
        label="Payment Date"
        placeholder="Payment Date"
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={`payment_details[${index ? index : 0}].payment_date`}
        value={firstObject?.payment_date}
        onChange={handleChangeForPayments}
        onBlur={formik.handleBlur}
        error={
          formik.errors.payment_details &&
          formik.errors?.payment_details[`${index ? index : 0}`] &&
          formik.errors?.payment_details[`${index ? index : 0}`].payment_date
        }
        isTouched={
          formik.touched.payment_details &&
          formik.touched?.payment_details[`${index ? index : 0}`] &&
          formik.touched?.payment_details[`${index ? index : 0}`].payment_date
        }
        disabled={mode === "view"}
        index={`${index ? index : 0}`}
        check={"payment_date"}
        max={
          mode === "edit"
            ? firstObject?.payment_date ||
              new Date().toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0]
        }
      />
      <DropdownSingleInputWithCheckbox
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        label="Mode of Payment"
        placeholder="Mode of Payment"
        options={Payment_Mode}
        name={`${
          pay
            ? `payment_details[${index}]["mode_of_payment"]`
            : 'payment_details[0]["mode_of_payment"]'
        }`}
        forId={"batch_status"}
        value={firstObject?.mode_of_payment}
        handleOut={handleChangeForMode}
        onBlur={formik.handleBlur}
        mapThrough={"mode_of_payment"}
        formik={formik}
        disabled={mode === "view"}
        zIndex={`z-[${index ? 20 + 1 + index : 20}]`}
      />
      <InputBox
        label="Transaction ID"
        placeholder="Transaction ID"
        topDivStyle={"flex flex-col w-full"}
        classNameInput={"w-full"}
        name={"transaction_id"}
        value={firstObject?.transaction_id}
        onChange={handleChangeForPayments}
        onBlur={formik.handleBlur}
        // error={formik.errors.transaction_id}
        // isTouched={formik.touched.course_name}
        disabled={(firstObject?.mode_of_payment === "Cash") || (firstObject?.mode_of_payment === "Adjusted")}
        index={`${index ? index : 0}`}
        check={"transaction_id"}
      />
    </div>
  );
} 