import { Components } from "./ModuleImports"

export const TrainerDetails = ({Trainers}:any) => {
    const { DetailsOfTrainers, ReadData }: any = Components;
    // const mapTrainers = DetailsOfTrainers();
    // const TrainerHistory = (text: any) => `${text === 'Past Trainer' ? 'text-white bg-[#FF0000]' : 'text-black bg-[#FFCC00]'} text-[10px] p-0.5 px-2 font-Roboto font-[400] rounded-[1px]`;
  return (
    <div className="w-11/12 mx-auto space-y-2">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Trainer Details
      </h1>
      <div className="w-[97%] mx-auto">
        {Trainers.map((val: any, index: number) => {
          return (
            <div className={`shadow-md shadow-[#00000029]`}>
              <div className="flex items-center justify-between">
                <div className="w-[20%]">
                  <ReadData
                    head={"Trainer Name"}
                    body={val.trainer_name}
                    mainDiv={"bg-[#FFE4E4] p-4"}
                  />
                  {/* {details.trainerHistory && (
                            <span className={TrainerHistory(details.trainerHistory)}>
                              {details.trainerHistory}
                            </span>
                        )} */}
                </div>
                <ReadData
                  head={"Trainer Phone Number"}
                  body={val.trainer_phone_number}
                  mainDiv={"bg-[#E6E4FF] p-4 w-[20%]"}
                />
                <ReadData
                  head={"Start Date"}
                  body={val.start_date}
                  mainDiv={"bg-[#FFE4E4] p-4 w-[20%]"}
                />
                <ReadData
                  head={"End Date"}
                  body={val.end_date}
                  mainDiv={"bg-[#E6E4FF] p-4 w-[20%]"}
                />
                <ReadData
                  head={"Duration"}
                  body={`${val.duration} ${
                    Number(val.duration) > 1 ? "Hrs" : "Hr"
                  }`}
                  mainDiv={"bg-[#FFE4E4] p-4 w-[20%]"}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}