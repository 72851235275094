import React from 'react'

export const ButtonOnImageLeft = ({ text, onClick, buttonImg }: any) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center gap-2 p-2.5 h-[45px] bg-gradient-to-r from-[#E91F00] to-[#E95900] rounded-[6px] shadow-md shadow-[#00000029] cursor-pointer"
    >
      <img src={buttonImg} alt={`${buttonImg}`} className="w-[20px]" />
      <p className="text-white text-[20px] font-Roboto font-normal">{text}</p>
    </div>
  );
};
