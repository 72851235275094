import React from 'react'

export const Comments = ({updatedStudentData}:any) => {
  return (
    <div className="w-11/12 mx-auto space-y-4">
      <span className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        Comments
      </span>
      {/* <div className='min-h-[80px] p-1 px-2'> */}
      <p className="min-h-[80px] shadow-md shadow-[#00000029] p-1 px-3 text-[#000000] text-[15px] font-Roboto font-[400]">
        {updatedStudentData?.comments}
      </p>
      {/* </div> */}
    </div>
  );
}

// 'shadow-sm shadow-[#00000029] min-h-[80px] p-1 px-2 rounded-[3px] space-y-1'