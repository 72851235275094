import { Components } from './ModuleImports';

export const TrainerDetails = ({
  formik,
  mode,
  isTrainerChange,
  setIsTrainerChange,
  trainerList
}: any) => {
  const { useState, ReadData }: any =
    Components;
  const [searchResults, setSearchResults] = useState([]);

  const TrainerChange = () => {
    setIsTrainerChange(true)
    formik.setValues(
      {
        ...formik.values,
        trainer_id: "",
        trainer_name: "",
        trainer_phone_number: "",
        trainer_email: "",
      },
      true
    );
  };

  const handleSearch = (inputValue:any, fieldName:any) => {
    const filteredResults = trainerList.filter((item:any) =>
      item[fieldName]?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleSuggestionSelect = (selectedObject: any, setIsOpen: any) => {
    setIsOpen(false)
    formik.setFieldValue('trainer_name', selectedObject?.name);
    formik.setFieldValue('trainer_phone_number', selectedObject?.phone_number);
    formik.setFieldValue('trainer_email', selectedObject?.email);
    formik.setFieldValue('trainer_id', selectedObject?.uid);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
          Trainer Details
        </h1>
        {mode === "edit" && (
          <button
            type="button"
            onClick={TrainerChange}
            className="bg-[#DF0952] p-1.5 px-2 rounded-[4px] font-Roboto font-[500] text-[17px] text-white"
          >
            Change Trainer
          </button>
        )}
      </div>
      {mode === "edit" && (
        <h1 className="text-[#000000] text-[20px] font-Roboto font-[500]">
          {formik.values.trainer_id!=="" && "Current Trainer"}
        </h1>
      )}
      <div className="flex items-center gap-10">
        <TrainerDetailsSearch
          formik={formik}
          label={"Trainer Name"}
          name={"trainer_name"}
          type={"text"}
          arrayKey={"name"}
          placeholder="Trainer Name"
          value={formik.values.trainer_name}
          onBlur={formik.handleBlur}
          error={formik.errors.trainer_name}
          isTouched={formik.touched.trainer_name}
          handleSearch={handleSearch}
          searchResults={searchResults}
          handleSuggestionSelect={handleSuggestionSelect}
        />
        <TrainerDetailsSearch
          formik={formik}
          label={"Trainer Phone Number"}
          name={"trainer_phone_number"}
          type={"text"}
          arrayKey={"phone_number"}
          placeholder="Trainer Phone Number"
          value={formik.values.trainer_phone_number}
          onBlur={formik.handleBlur}
          error={formik.errors.trainer_phone_number}
          isTouched={formik.touched.trainer_phone_number}
          handleSearch={handleSearch}
          searchResults={searchResults}
          handleSuggestionSelect={handleSuggestionSelect}
        />
        <TrainerDetailsSearch
          formik={formik}
          label={"Trainer Email"}
          name={"trainer_email"}
          type={"email"}
          arrayKey={"email"}
          placeholder="Trainer Email"
          value={formik.values.trainer_email}
          onBlur={formik.handleBlur}
          error={formik.errors.trainer_email}
          isTouched={formik.touched.trainer_email}
          handleSearch={handleSearch}
          searchResults={searchResults}
          handleSuggestionSelect={handleSuggestionSelect}
        />
      </div>
      {mode === "edit" && isTrainerChange && (
        <div className="flex flex-col space-y-2">
          <label>Reason for Changing the Trainer</label>
          <textarea
            className="border-[#707070] border-[0.5px] rounded-[3px] min-h-[80px] p-1 w-[30%] resize-none"
            name="trainer_change_reason"
            value={formik.values.trainer_change_reason}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Write here.."
          >
            {formik.values.trainer_change_reason}
          </textarea>
        </div>
      )}
      {mode === "edit" && formik.values?.past_trainers?.length && (
        <div className="space-y-2 w-[80%]">
          <h1 className="text-[#000000] text-[20px] font-Roboto font-[500]">
            {formik.values.past_trainers.length > 1
              ? "Past Trainers"
              : "Past Trainer"}
          </h1>
          <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2">
            { formik.values?.past_trainers.map((past: any, index: number) => {
                return (
                  <div key={index} className="p-2 flex justify-between w-[85%] mx-auto">
                    <ReadData
                      head={"Trainer Name"}
                      body={`${past.trainer?.first_name} ${past.trainer?.last_name}`}
                    />
                    <ReadData
                      head={"Trainer Phone Number"}
                      body={past.trainer?.phone_number}
                    />
                    <ReadData
                      head={"Trainer Email"}
                      body={past.trainer?.email}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

const TrainerDetailsSearch = ({formik, isTouched, error, onBlur, label, name, type, arrayKey, placeholder, value, handleSearch, searchResults, handleSuggestionSelect}:any) => {
  const { useState, useEffect, useRef }: any =
    Components;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef:any = useRef(null);
  const checkIsError = isTouched && error;

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  };

  const handleDropdownClick = (event:any) => {
    event.stopPropagation(); // Prevent event bubbling
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <label htmlFor="name" className="text-[#707070] 2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500]">
        {label}
      </label>
      <div
        className={`relative z-10 cursor-pointer`}
        onClick={toggleDropdown}
      >
        <input
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={(e) => {
            handleSearch(e.target.value, arrayKey);
            formik.setFieldValue(name, e.target.value);
            setIsOpen(true)
          }}
          className={`capitalize outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full`}
        />
        {isOpen && <div onClick={handleDropdownClick} className={`z-20 shadow-md shadow-[#00000029] absolute w-full bg-white rounded-[3px] outline-none border-[0.5px] border-[#707070] pt-2 min-h-[100px] max-h-[260px] scroll-smooth overflow-y-scroll`}>
          {searchResults?.map((item:any) => (
            <div key={item[arrayKey]} onClick={() => handleSuggestionSelect(item, setIsOpen)} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
              <span className="ml-2 text-gray-900 font-Roboto select-none">
                {item[arrayKey]}
              </span>
            </div>
          ))}
        </div>}
        {checkIsError && (
          <span className="text-red-500 absolute -bottom-6 right-0">{error}</span>
        )}
      </div>
    </div>
  )
}
