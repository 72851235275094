import { CloseIcon } from "../../Elements/CloseIcon";

export const IndividualCardForCompany = ({ existingCompanies, currentCompanies, closeFunc, acceptDelete }: any) => {
  return (
    <div
      className={`w-[95%] mx-auto flex justify-evenly flex-wrap gap-4 xl:gap-6 2xl:gap-8`}
    >
      {existingCompanies.map((hire: any, index: number) => {
        return (
          <div
            key={index}
            className="shadow-md shadow-[#00000029] rounded-[6px] flex items-center justify-center w-[206px] h-[86px] relative"
          >
            {acceptDelete && <CloseIcon
              closeFunc={() => closeFunc("Available", hire)}
              classStyle="text-[13px] w-[24px] h-[24px] absolute -top-3 -right-3"
            />}
            <img
              src={hire["company_logo"]}
              alt="Companies"
              className="object-fill max-w-[206px] max-h-[80px]"
            />
          </div>
        );
      })}
      {currentCompanies.map((hire: any, index: number) => {
        return (
          <div
            key={index}
            className="shadow-md shadow-[#00000029] rounded-[6px] flex items-center justify-center w-[206px] h-[86px] relative"
          >
            {acceptDelete && <CloseIcon
              closeFunc={() => closeFunc("New", hire)}
              classStyle="text-[13px] w-[24px] h-[24px] absolute -top-3 -right-3"
            />}
            <img
              src={hire["company_logo"]}
              alt="Companies"
              className="object-fill max-w-[206px] max-h-[80px]"
            />
          </div>
        );
      })}
    </div>
  );
};