import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables"
import { FetchData, InsertData, UpdateData } from "../../crud"
import { ContactInfoInterface } from "./interface"

export const getContactInfo = async () => {
    try {
        const { data: contact, error } = await FetchData(TableNames.contact_info)
        if (error) throw error
        return contact[0]
    }
    catch (error) {
        return errorNotifier(error  )
    }
}

export const getContactInfoById = async (contactId: number | string) => {
    try {
    const { data: contact, error } = await FetchData(TableNames.contact_info).eq("id", contactId)
    if (error) throw error
        return contact[0]
    }
    catch (error) {
        return errorNotifier(error)
    }
}

export const createContactInfo = async (contactData: ContactInfoInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.contact_info, contactData)
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateContactInfo = async (contactData: Partial<ContactInfoInterface>, contactId: number | string) => {
    try {
        const { data, error } = await UpdateData(TableNames.contact_info, contactData, { conditionKey: "id", conditionValue: contactId })
        if (error) throw error
        return true
    } catch (error) {
        return errorNotifier(error)
    }
}