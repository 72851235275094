import ViewEditDeleteActionComponent from "../../Components/Content Management/Technology/ActionComponent";
import { getTechnologyTableData } from "../../functions/admin/Technologies/Technologies";

export const ManageTechnology = {
  headers: [
    {
      name: "Technology",
      apiKey: "technology",
      headerClass: "w-[200px] text-start px-2",
      className: "w-[200px] text-start break-words px-2",
    },
    {
      name: "Courses",
      apiKey: "no_of_courses",
      headerClass: "w-[200px] text-center",
      className: "w-[200px] text-center",
    },
    {
      name: "Status",
      apiKey: "status",
      headerClass: "w-[100px] text-start",
      className: "w-[100px] text-start",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "text-center w-[100px]",
      className: "text-center w-[100px]",
    },
  ],
  BackendFunctionCall: getTechnologyTableData,
  bodyData: [
    {
      id: 1,
      technology: "Software Testing",
      courses: "10",
      status: "Active",
    },
  ],
  filters: {
    searchBox: [
      {
        matchFields: ["technology", "no_of_courses"],
        searchKey: "Courses",
        name: "Search",
        searchStyle: "w-[220px]",
      },
    ],
  },
};