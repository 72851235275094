import { createClient } from "@supabase/supabase-js";

const DEV = {
  projectUrl: process.env.REACT_APP_SUPABASE_PUBLIC_URL,

  publicAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY,
};

const TEST = {
  projectUrl: process.env.REACT_APP_SUPABASE_PUBLIC_URL_TEST,
  publicAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY_TEST,
};

const UAT = {
  projectUrl: process.env.REACT_APP_SUPABASE_PUBLIC_URL_UAT,

  publicAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY_UAT,
};

const PROD = {
  projectUrl: process.env.REACT_APP_SUPABASE_PUBLIC_URL_PROD,

  publicAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY_PROD,
};

const Keys: any = {
  DEV,
  TEST,
  UAT,
  PROD,
};

const ENVFinder = () => {
  let env;
  switch (true) {
    case document.location.hostname.includes("uat"):
      env = "UAT";
      break;
    case document.location.hostname.includes("test"):
      env = "DEV";
      // env = "PROD"
      break;
    case document.location.hostname.includes("oneapp"):
      env = "PROD";
      break;
    default:
      // env = "DEV";
      // env="UAT"
      env="PROD"
      break;
  }
  return env;
};
// const publicAnonKey: string|any = process.env.REACT_APP_ENV === "TEST" ? process.env.REACT_APP_SUPABASE_ANON_KEY : process.env.REACT_APP_SUPABASE_ANON_KEY_PROD;
// const projectURL: string | any = process.env.REACT_APP_ENV === "TEST" ? process.env.REACT_APP_SUPABASE_PUBLIC_URL : process.env.REACT_APP_SUPABASE_PUBLIC_URL_PROD;
const { projectUrl, publicAnonKey } =
  Keys[process.env.REACT_APP_ENV || ENVFinder()];
export const SUPABASE_CLIENT = createClient(projectUrl, publicAnonKey);

// {
//     "projects": {
//         "default": "fita-2798c"
//     },
//     "targets": {
//         "fita-2798c": {
//             "hosting": {
//                 "fita-production": [
//                     "fita-2798c".
//                 ]
//             }
//         }
//     },
//     "etags": {}
// }

// context/SupabaseProvider.tsx
// import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
// import { createClient, SupabaseClient, Session, User } from '@supabase/supabase-js';

// interface SupabaseContextType {
//   supabase: SupabaseClient | null;
//   user: User | null;
// }

// const SupabaseContext = createContext<SupabaseContextType | undefined>(undefined);

// interface SupabaseProviderProps {
//   children: ReactNode;
// }

// // Declare variables for export
// let projectUrl: string;
// let publicAnonKey: string;
// let SUPABASE_CLIENT: SupabaseClient | null = null;

// const SupabaseProvider: React.FC<SupabaseProviderProps> = ({ children }) => {
//   const [user, setUser] = useState<User | null>(null);

//   useEffect(() => {
//     // Your environment detection and Supabase client creation logic
//     const ENVFinder = () => {
//       let env;
//       switch (true) {
//         case document.location.hostname.includes("uat"):
//           env = "UAT";
//           break;
//         case document.location.hostname.includes("test"):
//           env = "DEV";
//           break;
//         default:
//           env = "DEV";
//           break;
//       }
//       return env;
//     };

//     const keys: any = {
//       DEV: {
//         projectUrl: process.env.REACT_APP_SUPABASE_PUBLIC_URL,
//         publicAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY,
//       },
//       TEST: {
//         projectUrl: process.env.REACT_APP_SUPABASE_PUBLIC_URL_TEST,
//         publicAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY_TEST,
//       },
//       UAT: {
//         projectUrl: process.env.REACT_APP_SUPABASE_PUBLIC_URL_PROD,
//         publicAnonKey: process.env.REACT_APP_SUPABASE_ANON_KEY_PROD,
//       },
//     };

//     // Assign values to exported variables
//     ({ projectUrl, publicAnonKey } = keys[process.env.REACT_APP_ENV || ENVFinder()]);
//     SUPABASE_CLIENT = createClient(projectUrl, publicAnonKey) as SupabaseClient;

//     // Check if the user is already authenticated
//     const session: Session | null = SUPABASE_CLIENT.auth.session();
//     setUser(session?.user || null);

//     // Subscribe to auth state changes
//     const authListener = SUPABASE_CLIENT.auth.onAuthStateChange((event, session) => {
//       setUser(session?.user || null);
//     });

//     // Unsubscribe on component unmount
//     return () => {
//       if (authListener) {
//         authListener?.data?.unsubscribe();
//       }
//     };

//   }, []); // Empty dependency array means this useEffect runs once on mount

//   return (
//     <SupabaseContext.Provider value={{ supabase: SUPABASE_CLIENT, user }}>
//       {children}
//     </SupabaseContext.Provider>
//   );
// };

// const useSupabase = (): SupabaseContextType => {
//   const context = useContext(SupabaseContext);
//   if (!context) {
//     throw new Error('useSupabase must be used within a SupabaseProvider');
//   }
//   return context;
// };

// export { SupabaseProvider, useSupabase, projectUrl, publicAnonKey, SUPABASE_CLIENT };
