import { useState } from "react";
import buttonWithRightArrow from "../../Assets/buttonWithRightArrow.svg";
import { BackButton } from "../Elements/BackButton";
import { studentManagement } from "../../utils/json/studentManagement";
import { CreateStudentCourse } from "./CreateStudentCourse/CreateStudentCourse";
import { useSharedLogic, CommonTableComp } from "../CommonFunctions/CommonFunc";
import { StudentCourseProgress } from "./StudentCourseProgress/StudentCourseProgress";
import { MessageCard } from "../Elements/MessageCard";
import { FunctionForPrivileges } from "../CommonFunctions/Privileges";
import { useAppSelector } from "../..";

export const StudentManagement = () => {
  const {
    navigate,
    routeNameForHome,
    id,
    mode,
    courseId,
    routePath,
    createFuncWithoutSubSection,
  }: any = useSharedLogic();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const [swapComp, setSwapComp] = useState([] as any);
  const { MainMenu, allowAllActions }: any = FunctionForPrivileges();
  const currentDate: any = new Date();
  
  return (
    <div>
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Student Course Created Successfully"
                : "Student Course Updated Successfully"
            }
            buttonText={"Student Management"}
            handleClick={() => navigate(`/${routeNameForHome}`)}
            closeFunc={() => navigate(`/${routeNameForHome}`)}
          />
        </div>
      )}
      {mode === "view" && (
        <div className="space-y-4">
          {courseId ? (
            <BackButton
              header={"Student Course Progress"}
              topDivStyle={"w-[95%] justify-between"}
            />
          ) : (
            // <BackButton topDivStyle={"w-9/12 justify-end"} />
            <div className={`flex items-center w-[95%] justify-end mx-auto`}>
              <button
                className="p-2.5 px-6 h-[45px] bg-gradient-to-r from-[#E91F00] to-[#E95900] rounded-[6px] shadow-md shadow-[#00000029] cursor-pointer text-white text-[20px] font-Roboto font-normal"
                onClick={() =>
                  navigate(
                    routePath === "student_approval_queue"
                      ? `/action_center/student_actions_approval_queue?id=current_approval_queue`
                      : -1
                  )
                }
              >
                Back
              </button>
            </div>
          )}
          <StudentCourseProgress id={id} mode={mode} />
        </div>
      )}
      {(id === "new-student-course" || mode === "edit") && mode !== "pdf" && (
        <div className="space-y-4">
          {!swapComp.length && (
            <div className={`flex items-center w-[95%] justify-end mx-auto`}>
              <button
                className="p-2.5 px-6 h-[45px] bg-gradient-to-r from-[#E91F00] to-[#E95900] rounded-[6px] shadow-md shadow-[#00000029] cursor-pointer text-white text-[20px] font-Roboto font-normal"
                onClick={() => navigate({ pathname: `/${routeNameForHome}` })}
              >
                Back
              </button>
            </div>
          )}
          <CreateStudentCourse
            id={id}
            mode={mode}
            swapComp={swapComp}
            setSwapComp={setSwapComp}
          />
        </div>
      )}
      {allowAllActions() && (
        <CommonTableComp
          id={id}
          head={"Student Management"}
          buttonType={"right"}
          buttonText={"Create Student"}
          onClick={() => createFuncWithoutSubSection("new-student-course")}
          buttonImg={buttonWithRightArrow}
          TABLE_JSON={studentManagement}
          backEndCallParams={signInData?.data[0]}
          allowCondition={MainMenu?.includes("create")}
        />
      )}
    </div>
  );
};
