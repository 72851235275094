import { tab } from "@testing-library/user-event/dist/tab";
import _ from "lodash";

export const filterBasedOnDateRange = (startDate: any, endDate: any, data: any, apiKey: string) => {
    const filteredData = data.filter((item: any) => {
        const itemTimestamp = Date.parse(item[apiKey]);
        const startTimestamp = Date.parse(startDate);
        const endTimestamp = Date.parse(endDate) + 86400000;
        return itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp;
        // if (startTimestamp && endTimestamp) {
        //   return itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp;
        // } else if (startTimestamp) {
        //   return itemTimestamp >= startTimestamp;
        // } else if (endTimestamp) {
        //   return itemTimestamp <= endTimestamp;
        // } else if (!startTimestamp && !endTimestamp) {
        //   return true;
        // }
      });

      return filteredData;
};

export const filterWithMultipleRecords = (filters: any, data: any, searchFilters: any = {}) => {
    const isDropDownFilterApplies = Object.keys(filters).length;
    const isSearchFilterApplies = Object.keys(searchFilters).length;
    if (!isDropDownFilterApplies && !isSearchFilterApplies) return data;
    const filteredData = data.filter((individualRecord: any) => (isDropDownFilterApplies ?isPassingThroughAllFilters(filters, individualRecord):true) && (isSearchFilterApplies?isPassingAllSearchFilters(searchFilters,individualRecord):true));
    return filteredData;
}

const isPassingThroughAllFilters = (filters: any, indvidualRecord: any) => {
    const passArray = Object.keys(filters).map((filter: any) => (
        indvidualRecord[filter] && filterConditionBasedOnStringOrArray(filters[filter],indvidualRecord[filter])
    ));
    return passArray.every((item: boolean) => item === true);
}
// filters[filter].includes(indvidualRecord[filter])
const filterConditionBasedOnStringOrArray = (filterArray: string[], value: string | string[]) => {
    return Array.isArray(value) ? filterArray.some(filter => value.includes(filter)) : filterArray.includes(value);
}


const isPassingAllSearchFilters = (searchFilters: any, individualRecord: any) => {
    let finalResult = Object.keys(individualRecord).some((indRec: any,index:number) => {
        let result = true;
        for (let j in searchFilters) {
            if (searchFilters[j].value.length) {
                result=result && matchingSomeFields(searchFilters[j], indRec, individualRecord);
            }
        }
        return result;
    }
    )
    return finalResult;

}

const matchingSomeFields = (searchFilter: any, indRec: any, individualRecord: any) => {
    return searchFilter.matchFields.some((rec:any)=>individualRecord[rec]?.toString()?.toLowerCase()?.includes(searchFilter.value?.toLowerCase()))
}



const isPassingThroughSomeFilters = (filters: any, indvidualRecord: any) => {
    const passArray = Object.keys(filters).map((filter: any) => (
        indvidualRecord[filter] && filterConditionBasedOnStringOrArray(filters[filter],indvidualRecord[filter])
    ));
    return passArray.some((item: boolean) => item === true);
}

export const getOptionsFromData = (filterJSONData: any, originalData: any, tableData: any, seletedFilters: any, checkBoxFilters: any, changedFilterKey: any, dateRangeFilters: any) => {
    const selectedFilterKeys = Object.keys(seletedFilters);
    
    const primaryFilteApirKey = selectedFilterKeys[0];

    const filterJSONWithOptionArray = filterJSONData.map((filData: any) => {;
        const apiKey = filData.apiKey
        let options = []
        if (_.isEmpty(selectedFilterKeys)) {
            options = originalData.map((d: any) => {
                const data = d[apiKey];
                return data;
            });
        } else if (primaryFilteApirKey === apiKey) {
            options = originalData.map((d: any) => {
                const data = d[apiKey];
                return data;
            });
        } else if (primaryFilteApirKey !== apiKey && seletedFilters[apiKey]) {
            const changedFilterKeyIndex = selectedFilterKeys.findIndex(s => s === changedFilterKey);
            const apiKeyIndex = selectedFilterKeys.findIndex(s => s === apiKey);
            if (apiKeyIndex > changedFilterKeyIndex) {
                options = originalData.map((d: any) => {
                    if(isPassingThroughSomeFilters(seletedFilters, d)) {
                        return d[apiKey];
                    }
                    return null
                });
            } else {
                options = checkBoxFilters.find((c: { apiKey: any; }) => c.apiKey === apiKey).options;
            }
        }
        else if (primaryFilteApirKey !== apiKey && !seletedFilters[apiKey]) {
            options = tableData.map((d: any) => {
                const data = d[apiKey];
                return data;
            });
        }
        
        // const options = dataForOptions.map((d: any) => {
        //     const data = d[apiKey];
        //     return data;
        // });
        return {
            ...filData,
            options: _.uniq(_.flatten(options)),
        }
    });

    return filterJSONWithOptionArray;
};
export const insertOptionsForFiltersFromAvailableData = (filterJSONData: any, data: any, filters: any) => {
    // console.log(filters, data)
    const selectedFilterKeys = Object.keys(filters);
    const apiKeyArray = filterJSONData.map((filData: any) => filData.apiKey);
    const optionsWithoutDuplicatesForAPIKeys:any = {};
    for (let i = 0; i < data.length; i++){
        let individualRecord = data[i];
        for (let j in individualRecord) {
            if (apiKeyArray.includes(j)) {
                if (!optionsWithoutDuplicatesForAPIKeys[j]) {
                    // let value=Array.isArray(individualRecord[j])?
                    optionsWithoutDuplicatesForAPIKeys[j] = dataManipulationByArrayOrString(individualRecord[j])
                }
                else if (!optionsWithoutDuplicatesForAPIKeys[j].includes(individualRecord[j])) {
                    let removedDuplicatedData:string[] = [...optionsWithoutDuplicatesForAPIKeys[j], ...dataManipulationByArrayOrString(individualRecord[j])]
                    optionsWithoutDuplicatesForAPIKeys[j] = [...new Set(removedDuplicatedData)]
                }
            }
            else {
                continue;
            }
        }
    }
    const filterJSONWithOptionArray = filterJSONData.map((filData: any) => ({ ...filData, options: optionsWithoutDuplicatesForAPIKeys[filData.apiKey] }));
    return filterJSONWithOptionArray;
}

const dataManipulationByArrayOrString = (data:string[]|string) => {
    return Array.isArray(data) ? data : [data];
}

export const makeArraySpecificKeyAsObject = (arrayData: any[], value: string = "") => {
    const resultObject: any = {};
    if (!arrayData) {
        return resultObject;
    }
    for (let i of arrayData) {
        resultObject[i.searchKey] = {value,matchFields:i.matchFields};
    }
    return resultObject;
}