import { ReadData } from "../../Elements/ReadData";
import { Summary } from "../../StudentManagement/StudentCourseProgress/CommonJson";
import { formatNumberWithCommas } from "../../../Services/CommonFunctions";

export const PaymentSummary = ({ basicInfoDetails, totalVal }: any) => {
    // const list = Summary(updatedStudentData);
    const mapPaidAmount = basicInfoDetails?.paymentDetails?.map((amt: any) => amt?.paid_amount);
    const sum = mapPaidAmount&&mapPaidAmount.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);
    const details: any = [
      {
        head: "Course",
        body: basicInfoDetails?.updatedStudentData?.course_id?.course_name,
      },
      {
        head: "Fees",
        body: totalVal?(totalVal):0,
      },
      {
        head: "Paid Amount",
        body: sum?(Math.round(Number(sum.toString().replace(/^0+/, '')))):0,
      },
      {
        head: "Balance Due",
        body: basicInfoDetails?.updatedStudentData?.balance_due?(Math.round(Number(basicInfoDetails?.updatedStudentData?.balance_due))):"-",
      },
    ];
  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Payment Summary
      </h1>
      <div className="shadow-md shadow-[#00000029] rounded-[3px] flex items-center">
        {details?.map((list: any, index: number, array: any) => {
          return (
            <div
              key={index}
              className={`${
                index === 0 ? "w-[55%] bg-[#FFFFFF]" : "w-[15%] bg-[#F9F9F9]"
              } ${
                index === array.length - 1
                  ? ""
                  : "border-r-[#036AD1] border-[0.5px]"
              } px-4 py-3`}
            >
              <ReadData
                head={list.head}
                body={formatNumberWithCommas(list.body)}
                addSpan={list.head==="Course"?"-":list.body}
                color={list.head === "Balance Due" && "text-[#FF0000]"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
