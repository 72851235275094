// import React, { useState } from "react";
// import { ReportJsonData } from "./ReportJsonData";
// import { renderContent } from "./TableFetchDetails";

// export const ReportTable = ({ selectedTab, response }: any) => {
//   const [searchQuery, setSearchQuery] = useState("");

//   const HeaderStat = ReportJsonData[selectedTab] || [];
//   const body_style = `font-Roboto font-[500] py-4 text-[16px] text-center text-[#000000] border-2 border-blue-100`;

//   // Filter content based on search query
//   const filteredContent = renderContent(selectedTab, response, body_style, searchQuery);

//   return (
//     <div className="w-11/12 mx-auto relative overflow-x-auto scrollbar-show">
//       <div className="py-4">
//         <input
//           type="text"
//           placeholder="Search..."
//           className="border p-2 rounded"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//       </div>
//       <table className="table-fixed w-full">
//         <thead>
//           <tr className="px-10 bg-[#EFF7FF] text-[20px]">
//             {HeaderStat?.map((stat: any, index: number) => (
//               <th
//                 className={`font-Roboto font-[500] py-4 text-[16px] text-[#000000] px-10 border-2 border-blue-100`}
//                 key={index}
//               >
//                 {stat}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody className="bg-[#EFF7FF] text-[20px]">
//           {filteredContent}
//         </tbody>
//       </table>
//     </div>
//   );
// };


import React, { useState } from "react";
import { ReportJsonData } from "./ReportJsonData";
import { renderContent } from "./TableFetchDetails";
import Pagination from "../../../Components/Elements/Table/Pagination";

export const ReportTable = ({ selectedTab, response }: any) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of rows per page

  const HeaderStat = ReportJsonData[selectedTab] || [];
  const body_style = `font-Roboto font-[500] py-4 text-[16px] text-center text-[#000000] border-2 border-blue-100`;

  // Render content based on search query and selected tab
  const allContent = renderContent(selectedTab, response, body_style, searchQuery);

  if (!Array.isArray(allContent)) {
    console.error("renderContent did not return an array. Please check the implementation.");
    return null;
  }

  // Calculate total pages
  const totalPages = Math.ceil(allContent.length / itemsPerPage);

  // Paginate the content
  const paginatedContent = allContent.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Handle pagination
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="w-11/12 mx-auto relative overflow-x-auto scrollbar-show">
      <div className="py-4">
        <input
          type="text"
          placeholder="Search..."
          className="border p-2 rounded"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setCurrentPage(1);
          }}
        />
      </div>
      <table className="table-fixed w-full">
        <thead>
          <tr className="px-10 bg-[#EFF7FF] text-[20px]">
            {HeaderStat?.map((stat: any, index: number) => (
              <th
                className={`font-Roboto font-[500] py-4 text-[16px] text-[#000000] px-10 border-2 border-blue-100`}
                key={index}
              >
                {stat}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-[#EFF7FF] text-[20px]">
          {paginatedContent}
        </tbody>
      </table>
      <Pagination
        totalPages={totalPages}
        totalItems={allContent.length}
        currentPage={currentPage}
        // paginatorFunc={handlePageChange}
        onPageChange={handlePageChange}
      />
    </div>
  );
};
