import React, { useState, useEffect } from 'react';

export const YoutubeThumbnail = ({ url }:any) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  useEffect(() => {
    if (url) {
      // Extract video ID from the YouTube URL
      const videoIdMatch:any = url?.match(/[?&]v=([^&#]*)/);
      if (videoIdMatch && videoIdMatch[1]) {
        // Construct the thumbnail URL
        const thumbnailUrl = `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`;
        setThumbnailUrl(thumbnailUrl);
      }
    }
  }, [url]);
  return (
    <div className="w-full h-full">
      {thumbnailUrl && (
        <img
          src={thumbnailUrl}
          alt="YouTube Thumbnail"
          className="w-full h-full rounded-tl-xl rounded-br-xl"
        />
      )}
    </div>
  )
}
