import { SUPABASE_CLIENT } from "../../../Services/Supabase";
import { CreateUserWithEmailAndPassword, DeleteUser, UpdateUser, isEmailIdAlreadyExists } from "../../auth";
import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchData, FetchDataById, InsertData, UpdateData } from "../../crud"
import { getAllBranches } from "../Branches/branches";
import { getAllCities } from "../Cities";
import { getAllCourses } from "../Courses/Courses";
import { getAllRoles } from "../Role Management/roleMangement";
import { menuAccesConversion } from "./helper";
import { EditUserInterface, UserInterface } from "./interface";

// Provides data of all users - (D-8)   
export const getAllUsers = async () => {
    try {
        const { data: users, error } = await FetchData(TableNames.users).order("id", { ascending: false });
        if (error) return [];
        const { data: branches, error: BranchError } = await FetchData(TableNames.branches);
        const { data: Roles, error: RoleError } = await FetchData(TableNames.roles);
        const {data:Cities,error:CitiesError}=await FetchData(TableNames.cities);
        if (error || BranchError || RoleError || CitiesError) throw error || BranchError || RoleError || CitiesError;
        const roleTrainerFilter = Roles && Roles.filter((dt: any) => dt?.role === "Trainer");
        const roleTrainerIdsMapping = roleTrainerFilter && roleTrainerFilter?.map((dt: any) => dt?.id);
        const usersWithoutTrainers = roleTrainerIdsMapping && users && users.filter((obj:any) =>
            obj.roles_array.every((item:any) => !roleTrainerIdsMapping.includes(item))
        );
        const userData = usersWithoutTrainers && usersWithoutTrainers?.map((user: any) => ({
            ...user,
            roles:Roles?.flatMap((role:any)=>user.roles_array?.includes(role.id)?[role.role]:[]),
            branches: branches?.filter((branch: any) => user.branches?.includes(branch.id)).map((filteredBranch: any) => filteredBranch.branch_name),
            name: user.first_name + " " + user.last_name,
            // city: user.cities?.city_name,
            cities: Cities?.flatMap((city: any) => user.cities?.includes(city.id) ? [city.city_name] : [])
            // city:
            // courses: courses?.filter((course: any) => user.courses.includes(course.id))
        }
        ))
        return userData;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getAllUsersForTrainers = async () => {
    try {
        const { data: Roles, error: RoleError } = await FetchData(TableNames.roles);
        const [checkTrainer]: any = Roles?.filter((role: any) => role?.role === "Trainer");
        if (!checkTrainer) return [];
        const { data: users, error } = await FetchData(TableNames.users).overlaps('roles_array', [checkTrainer?.id]);
        // console.log({error})
        if (error) return [];
        const { data: branches, error: BranchError } = await FetchData(TableNames.branches);
        const {data:Cities,error:CitiesError}=await FetchData(TableNames.cities);
        if (error || BranchError ||RoleError || CitiesError) throw error || BranchError || RoleError ||CitiesError;
        const userData = users.map((user: any) => ({
            ...user,
            roles:Roles?.flatMap((role:any)=>user.roles_array?.includes(role.id)?[role.role]:[]),
            branches: branches?.filter((branch: any) => user.branches?.includes(branch.id)).map((filteredBranch: any) => filteredBranch.branch_name),
            name: user.first_name + " " + user.last_name,
            cities: Cities?.flatMap((city: any) => user.cities?.includes(city.id) ? [city.city_name] : [])
        }))
        // console.log(userData, "userData")
        return userData;
    } catch (error) {
        return errorNotifier(error)
    }
}

// Gives the data of all cities, branches, courses, roles while creating user - (D-9)
export const createUserPreLoadData = async (UsersDetails: any) => {
    return {
        cities: await getAllCities(UsersDetails, ["id", "city_name"]),
        branches: await getAllBranches(UsersDetails, ["id", "branch_name","city:cities(id,city_name)"]),
        courses: await getAllCourses(UsersDetails, ["id", "course_name", "type", "user_type"]),
        roles: await getAllRoles(["id", "role", "status"])
    }
}

export const coursesPreLoadData = async (UsersDetails: any) => {
    try {
        const { data, error }: any = await FetchData(TableNames.courses, ["id", "course_name", "type", "user_type"]).in("id",UsersDetails?.branches)
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

// Creates New User - (D-9)
export const CreateNewUser = async (userData: UserInterface) => {
    try {
        const { password, ...rest } = userData;
        const data = await CreateUserWithEmailAndPassword(userData.email, password);
        const { error: UserCreationError } = await InsertData(TableNames.users, {...rest,uid:data?.user?.id});
        if (UserCreationError) {
            await DeleteUser(data.user.id);
            throw UserCreationError
        };
        const responses = await Promise.all(rest?.courses.map(async courseId => {
            return UpdateData(TableNames.courses, {
                user_type: rest?.user_type
            }, { conditionKey: "id", conditionValue: courseId });
        }));
        console.log(responses, "responses")
        const errors = responses.filter((response:any) => response.error);
        if (errors.length > 0) {
            console.error("Errors occurred while updating courses:", errors);
        } else {
            console.log("Courses updated successfully");
        }
        await UpdateUser( { user_metadata: rest, uid: data.user.id });
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

// Gets user Information by user UID - (D-10)
export const getUserById = async (uid: number | string) => {
    try {
        const { data: user, error }: any = await FetchData(TableNames.users,["*","cities"]).eq("uid", uid);
        if (error) throw error;
        const { data: branches, error: BranchError } = await FetchData(TableNames.branches).in("id", user[0]?.branches || []);
        const { data: roles, error: RolesError } = await FetchData(TableNames.roles).in("id", user[0]?.roles_array || []);
        const { data: courses, error: CourseError } = await FetchData(TableNames.courses).in("id", user[0]?.courses || []);
        const { data: cities, error: CitiesError } = await FetchData(TableNames.cities).in("id", user[0]?.cities || []);

        if (CourseError || BranchError || CitiesError || RolesError) throw CourseError || BranchError || CitiesError || RolesError;
        return {
            ...user[0],
            branchesNames: branches?.map((branch: any) => branch.branch_name)||[],
            coursesNames: courses?.map((course: any) => course.course_name) || [],
            cityNames: cities?.map((city: any) => city.city_name) || [],
            rolesName: roles?.map((role: any)=>role.role)||[]
            // city:user[0].city.city_name
        };
    } catch (error) {
        return errorNotifier(error)
    }
}

export const filterUserTypeBasedCourses = async (city:number[], branch:number[], usertype: any, id:any) => {
    try {
        if(id) {
            const { data, error } = await FetchData(TableNames.users).eq("id", id).eq('usertype', usertype);
        }
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getLoggedUserDetails = async (uid: string) => {
    try {
        const { data: user, error }: any = await FetchData(TableNames.users).eq("uid", uid);
        if (error) throw error;
        if(user?.length > 0) {
            const { data: Roles, error: RolesError } = await FetchData(TableNames.roles).in("id", user[0]?.roles_array);
            if (RolesError) throw RolesError;
            return {...user?.[0],menuAccess:menuAccesConversion(Roles)};
        }
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateUser = async (uid: string, updateData:Partial<UserInterface>) => {
    try {
        const { password, ...rest } = updateData;
        if (updateData.email || password) {
            const { email } = updateData;
            const authUpdateData = {
                ...email && { email },
                ...password && { password },
                ...Object.keys(rest).length && { user_metadata: rest }
            }
            const result = await UpdateUser({...authUpdateData,uid});
            console.log({result});
        }
        const { error } = await UpdateData(TableNames.users, rest, { conditionKey: "uid", conditionValue: uid });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}