import { Components } from './ModuleImports';
import certificateSample from "../../../Assets/certificateSample.png";
import dropArrow from "../../../Assets/dropArrow.svg";
import { updateTrainingData } from '../../../functions/admin/Students/students';

export const BasicInfo = ({
  updatedStudentData,
  setPaymentDetails,
  setUpdatedStudentData,
  batchStartDate,
  batch_data,
  isPlacementNeeded,
  setIsPlacementNeeded
}: any) => {
  const {
    useState,
    useEffect,
    ReadData,
    BuildJSON,
    useSharedLogic,
    getBatchFollowUp,
    getStudentManagementDetailsById,
    updateStudentManagementDetails,
    toast,
  }: any = Components;
  const { id, courseId }: any = useSharedLogic();
  // const [checked, setChecked] = useState("Yes");
  const isId: any = id !== "new-student-course" && id;
  //   const [batchDate, setBatchDate] = useState("");
  const BasicInfo = BuildJSON(updatedStudentData, batchStartDate);

  //   useEffect(()=>{},[
  //     (async()=>{
  //         const pullData:any = await getBatchFollowUp(updatedStudentData?.batch_follow_up)
  //         console.log(pullData, "pullData");
  //         const mapPullData:any = pullData && pullData.map((pull:any)=> pull?.created_date);
  //         console.log(mapPullData[mapPullData?.length-1], "12")
  //         setBatchDate(mapPullData[mapPullData?.length-1]);
  //     })()
  //   ])

  const isPlacementNeed = async (label: any) => {
    const updatePlacementResponse = await updateTrainingData({
      id: isId,
      placement: label === "Yes" ? true : false
    });
    // console.log(updatePlacementResponse, "updatePlacementResponse")
    if (updatePlacementResponse) {
      setIsPlacementNeeded(label === "Yes" ? true : false);
      toast.success("Placement Details Updated");
    }
  };

  return (
    <div className="space-y-4 w-11/12 mx-auto py-6">
      <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        Basic Info
      </h1>
      <div className="w-11/12 mx-auto flex">
        <div className="w-1/2 space-y-3">
          {BasicInfo.map((read: any) => {
            return <ReadData head={read.head} body={read.body} />;
          })}
        </div>
        <div className="w-1/2 space-y-4">
          {courseId && <HoursOutOfTotal batch_data={batch_data} />}
          <Certificate updatedStudentData={updatedStudentData} />
          {!courseId && (
            <CourseProgress
              updatedStudentData={updatedStudentData}
              batch_data={batch_data}
            />
          )}
          {!courseId && (
            <Placement
              // checked={checked}
              // setChecked={setChecked}
              isPlacementNeed={isPlacementNeed}
              updatedStudentData={updatedStudentData}
              isPlacementNeeded={isPlacementNeeded}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const HoursOutOfTotal = ({ batch_data }: any) => {
    const { ReadData, Progressbar }: any = Components;
    return (
      <div className="shadow-md shadow-[#00000029] bg-[#F9FCFF] rounded-[5px] p-2 py-5 flex items-center justify-between w-[60%]">
        <ReadData
          head={"Course Progress"}
          body={`${
            batch_data?.student_progress_in_hrs
              ? batch_data?.student_progress_in_hrs
              : 0
          }/${batch_data?.duration ? batch_data?.duration : 0}`}
        />
        <Progressbar
          percentage={batch_data?.student_progress ? batch_data?.student_progress : 0}
          mainDivStyle={"w-[60px] h-[60px]"}
        />
      </div>
    );
}

const Certificate = ({updatedStudentData}:any) => {
    const { ReadData }: any = Components;
    return(
        <div className='w-[60%]'>
            <ReadData head={'Course'} body={updatedStudentData?.course_id?.course_name} />
            <img src={certificateSample} alt="Sample" />
        </div>
    )
}

const CourseProgress = ({ batch_data}:any) => {
    const { Progressbar, useSharedLogic, createSearchParams }: any = Components;
    const { navigate, params, routeNameForHome, routeNameForSubSection, id, mode }: any = useSharedLogic();
    const studentCourseProgress = (course_id:any) => {
        navigate({
            pathname: `/${routeNameForHome}`,
            search: createSearchParams({id:`${id}`,mode:`${mode}`,courseId:`${1}`}).toString()
        });
    }
    return (
      <div className="w-[60%] flex items-center justify-around shadow-md shadow-[#00000029] py-1.5 rounded-[4px]">
        <p className="text-[#000000] text-[16px] font-Roboto font-[400]">
          Course Progress
        </p>
        <Progressbar
          percentage={
            batch_data?.student_progress ? batch_data?.student_progress : 0
          }
          mainDivStyle={"w-[40px] h-[40px]"}
        />
        <img
          src={dropArrow}
          alt={"Arrow"}
          onClick={studentCourseProgress}
          className="cursor-pointer"
        />
      </div>
    );
}

const Placement = ({isPlacementNeed, updatedStudentData, isPlacementNeeded}:any) => {
    const { Checkbox, useState }: any = Components;
    return (
      <div className="space-y-1 w-[60%]">
        <h1 className="text-[18px] 2xl:text-[19px] font-Roboto font-[500]">
          Looking for Placement ?
        </h1>
        <div className="flex gap-5">
          <Checkbox
            show={true}
            checked={isPlacementNeeded && "Yes"}
            label={"Yes"}
            CheckValue={isPlacementNeed}
            textStyle={"text-[#707070] text-[15px]"}
            boxSize={"w-4 h-4"}
          />
          <Checkbox
            show={true}
            checked={!isPlacementNeeded && "No"}
            label={"No"}
            CheckValue={isPlacementNeed}
            textStyle={"text-[#707070] text-[15px]"}
            boxSize={"w-4 h-4"}
          />
        </div>
      </div>
    );
}