import {useState} from 'react';
import { Checkbox } from './Checkbox';

export const StatusButton = ({ show, type, buttonText, CheckValue, checked, buttonWidth }: any) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center space-y-4">
        <div className="flex items-center gap-2 px-2">
          <p className="text-[#707070] text-[18px] font-Roboto font-medium">
            Status:
          </p>
          <div className="flex items-center justify-around gap-2">
            <Checkbox
              label={"Active"}
              checked={checked}
              CheckValue={CheckValue}
              textStyle={"text-[#707070] text-[15px]"}
              show={show}
            />
            <Checkbox
              label={"Inactive"}
              checked={checked}
              CheckValue={CheckValue}
              textStyle={"text-[#707070] text-[15px]"}
              show={show}
            />
          </div>
        </div>
        {show && (
          <button
            type={type}
            className={`${buttonWidth} cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 py-3 text-white text-[17px] font-Roboto font-normal`}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}
