import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Subsection } from '../SubSections/Subsection';
import ShortlistedView from './View Applicants/ShortlistedView';
import { useSharedLogic } from '../CommonFunctions/CommonFunc';
import { useSelector } from 'react-redux';
//import { State } from '../../../Redux/Store'
import { State } from '../../Redux/Store';
import { getHrStudentPreloadData } from '../../functions/admin/HR Management/hr_management';
import { useAppSelector } from '../..';
import { BackButton } from '../Elements/BackButton';
import { MessageCard } from '../Elements/MessageCard';
import PlacedStudentView from './View Applicants/PlacedStudentView';
import PlacedView from './View Applicants/PlacedView';

export const HrStudentPlacement = () => {
    const params = useParams();
    const routeName: any = params.subsection;
    const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
   // const selectedRows = useSelector((state : State) => state.applicants.selectedRows);
   const selectedRows = useAppSelector((state) => state.applicants.applicantData.applicantView);
    const [localSelectedRows, setLocalSelectedRows] = useState([]);
 //   console.log("Fetches ID", selectedRows);
  //  console.log("Data View", id);
    useEffect(() => {
      (async () => {
    
      const technologyResponse: any = await getHrStudentPreloadData();
  //  console.log("Tech Data", userData);
      const mapCityForId:any = technologyResponse &&
      technologyResponse.filter((item : any) => selectedRows.includes(item.id));
         
      
      setLocalSelectedRows(mapCityForId)
    })();
  }, []);
  return (
    <div>
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Applicant Details Updated Successfully"
                : "Applicant Details Updated Successfully"
            }
            buttonText={"Manage Jobs"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/manage_jobs`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/manage_jobs`)
            }
          />
        </div>
      )}
       {(mode === "shortlisted") && (
        <div className="space-y-4">
           <div className='flex items-center w-11/12 justify-end mx-auto'>
          <h1 className="text-[30px] text-white  w-11/12 font-Roboto font-medium">Shortlisted Students</h1>
          <BackButton
            topDivStyle={"w-11/12 justify-end"}
          />
          </div>
         
          {/* <AddNewJobs id={id} mode={mode} /> */}
          <ShortlistedView selectedRows={selectedRows} />
        </div>
      )}
      {(mode === "placed-student") && (
        <div className="space-y-4">
           <div className='flex items-center w-11/12 justify-end mx-auto'>
          <h1 className="text-[30px] text-white  w-11/12 font-Roboto font-medium">Placed Students</h1>
          <BackButton
            topDivStyle={"w-11/12 justify-end"}
          />
          </div>
         
          {/* <AddNewJobs id={id} mode={mode} /> */}
          <PlacedStudentView selectedRows={selectedRows} />
        </div>
      )}
      {(mode === "joined-student") && (
        <div className="space-y-4">
           <div className='flex items-center w-11/12 justify-end mx-auto'>
          <h1 className="text-[30px] text-white  w-11/12 font-Roboto font-medium">Joined the Company</h1>
          <BackButton
            topDivStyle={"w-11/12 justify-end"}
          />
          </div>
         
          {/* <AddNewJobs id={id} mode={mode} /> */}
          <PlacedView selectedRows={selectedRows} />
        </div>
      )}
      <Subsection routeName={routeName} />
    </div>
  )
}