import React from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import { matchBatchCode } from "../../../functions/admin/Batches/Batches";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

interface TableHeadersProps {
  name: string;
  apiKey: string;
  isComponent: boolean;
  Comp: any,
  headerClass?: string,
  className?: string
}

interface TableProps {
  headers: TableHeadersProps[];
  bodyData: any[];
  currentPage: number;
  indexStyle?: string;
  cellStyle?: string;
  ActionComponent?: any;
  cellActionHeaderStyle?: string;
  cellTableHeaderStyle?: string
  isLoading?: boolean
}

const Table = ({
  cellTableHeaderStyle,
  cellActionHeaderStyle,
  headers,
  bodyData,
  indexStyle,
  cellStyle,
  currentPage,
  ActionComponent,
  isLoading,
}: TableProps) => {
  // console.log(headers, "TABLE_VIEW", bodyData, "bodyData");
  const navigate = useNavigate();
  const { MainMenu, SubMenu, MainMenuOnlyForBatch }: any =
    FunctionForPrivileges();
  const fieldConversionBasedOnArrayOrString = (
    fieldData: string[] | string
  ) => {
    return Array.isArray(fieldData) ? fieldData.join(", ") : fieldData;
  };

  const handleBatchCode = async (key: any) => {
    const getData = await matchBatchCode(key);
    if (getData) {
      navigate({
        pathname: `/batch_management`,
        search: createSearchParams({ id: getData, mode: 'view' }).toString(),
      })
    }
  }

  return (
    <div className="relative overflow-x-auto scrollbar-show">
      {isLoading ? (
        <div className="flex items-center justify-center min-h-[400px]">
          <ClipLoader color="#036AD1" />
        </div>
      ) : (
        // <Table
        //   // columns={columns}
        //   // dataSource={data}
        //   scroll={{ x: 1500, y: 300 }}
        // />
        <table className="table-fixed w-full">
          <thead className="bg-[#EFF7FF] text-[20px]">
            <tr>
              <th className="w-[50px] text-center text-[#000000] font-Roboto font-[500]">
                #
              </th>
              {headers.map(
                ({ name, headerClass }: TableHeadersProps, index: number) => (
                  <th
                    className={`font-Roboto font-[500] py-4 text-[14px] 2xl:text-[15px] text-[#000000] ${headerClass}`}
                    key={index}
                  >
                    {name}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {bodyData.map((row: any, index: number) => (
              <tr
                key={index}
                className={`${index % 2 !== 0 ? "bg-[#F8FBFF]" : ""}`}
              >
                <td className="w-[50px] text-center text-[#686868]">
                  {(currentPage - 1) * 10 + index + 1}
                </td>
                {headers.map(
                  (
                    { apiKey, isComponent, Comp, className }: TableHeadersProps,
                    index: number
                  ) => (
                    <td
                      key={index}
                      className={`${className} ${
                        (apiKey ?? "").replace(/[_\s]/g, "").toLowerCase() ===
                          "batchcode" && "cursor-pointer"
                      } py-4 text-[#686868] text-[14px] 2xl:text-[15px]`}
                      onClick={() =>
                        (apiKey ?? "").replace(/[_\s]/g, "").toLowerCase() ===
                          "batchcode" &&
                        (MainMenuOnlyForBatch?.includes("all") ||
                          MainMenuOnlyForBatch?.includes("view")) &&
                        handleBatchCode(row[apiKey])
                      }
                    >
                      {isComponent ? (
                        <Comp data={row[apiKey]} recordData={row} />
                      ) : (
                        fieldConversionBasedOnArrayOrString(row[apiKey]) || null
                      )}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Table;