import {useState} from 'react';
import OpenEye from "../../Assets/OpenEye.svg";
import CloseEye from "../../Assets/CloseEye.svg";

export const InputBox = ({ onCopy, onPaste, openEyeStyle, closeEyeStyle, labelText, value, check, disabled, type, index, label, topDivStyle, classNameInput, placeholder, mandatory, mandatoryClass, onChange, error, isTouched, ...rest }: any) => {
  const checkIsError = isTouched && error;
  // console.log(isTouched, error, "FFFEEE")
  const [passwordType, setPasswordType] = useState(false);
  
  return (
    <div className={`${topDivStyle} space-y-0.5 relative`}>
      <label
        className={`2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500] ${
          labelText ? labelText : "text-[#707070]"
        }`}
      >
        {label} {mandatory && <span className={`${mandatoryClass}`}>*</span>}
      </label>
      <input
        value={value}
        type={
          type === "password"
            ? passwordType
              ? "text"
              : type
            : type
            ? type
            : "text"
        }
        max={rest?.max}
        min={rest?.min}
        disabled={disabled ? disabled : false}
        placeholder={placeholder}
        // onCopy={type === "password" && onCopy}
        // onPaste={type === "password" && onPaste}
        onChange={
          index || index === 0
            ? (e: any) => {
                onChange(index, e.target.value, check);
              }
            : onChange
        }
        // defaultChecked={""}
        {...rest}
        className={`numberClass outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 ${classNameInput}`}
      />
      {type === "password" &&
        (passwordType ? (
          <img
            src={OpenEye}
            alt="OpenEye"
            className={`w-7 h-7 absolute right-4 ${
              openEyeStyle && openEyeStyle ? openEyeStyle : "bottom-2"
            } cursor-pointer`}
            onClick={() => setPasswordType(false)}
          />
        ) : (
          <img
            src={CloseEye}
            alt="OpenEye"
            className={`w-7 h-7 absolute right-4 ${
              closeEyeStyle && closeEyeStyle ? closeEyeStyle : "bottom-2"
            } cursor-pointer`}
            onClick={() => setPasswordType(true)}
          />
        ))}
      {checkIsError && (
        <span className="text-red-500 absolute -bottom-6 right-0">{error}</span>
      )}
    </div>
  );
};
