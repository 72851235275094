import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { CardDesign } from "./CardDesign";

export const Batch = ({ DashboardData }: any) => {
    const options = {
        responsive: true,
        plugins: {
        legend: {
          display:false
        },
        },
        scales: {
        x: {
            grid: {
              display: false,
            },
          },
        
        y: {
            grid: {
            display: false,
          },
        },
        },
    };


    const batchChart = {
        labels: (DashboardData[0].batchChart || []).map((d: any) => d.name),
        datasets: [{
        label: "Count",
        backgroundColor: "#036AD1",
        data: (DashboardData[0].batchChart || []).map((d: any) => d.count)
        }],
    }

  return (
        <div className="bg-[#F9FCFF] w-11/12 mx-auto">
          {DashboardData.map((item: any) => {
            return (
              <div>
                <h1 className="font-Roboto font-[400] text-[18px] bg-[#FBFBFB] py-4 px-2">
                  {item.batchTitle}
                </h1>
                <div className="flex items-center gap-4 p-4">
                  <div className="w-[30%]">
                    <div className="flex items-center justify-center w-8/12 mx-auto py-2">
                      <Bar data={batchChart} options={options} height="200px" />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-2 w-[70%]">
                    {item.Batch.map((data: any) => {
                      return (
                        <div>
                          <CardDesign data={data} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
  )
}
