import { AddItemsButton } from "../../../Elements/AddItemsButton";
import { InputBox } from "../../../Elements/InputBox";
import { UploadOrUpdateBucket } from "../../../CommonFunctions/CommonFileFunction";

export const AddCourseMaterials = ({
  addMoreMaterials,
  deleteMaterials,
  scrollRef,
  name,
  dataSetup,
  formik,
  disabled,
  handleValues,
  mode,
  id
}: any) => {
  const firstObject: any = formik.values?.course_materials?.[0];
  const firstError: any = formik.errors?.course_materials?.[0];
  const firstTouched: any = formik.touched?.course_materials?.[0]
  return (
    <div className="flex flex-col items-end space-y-2" ref={scrollRef}>
      {formik.values?.material && (
        <div className="shadow-[#00000029_0px_3px_8px] rounded-[5px] py-4 flex items-end gap-1 w-full mt-4 px-3">
          <div className="flex items-end gap-4 w-full">
            <InputBox
              label={"Course Material 1"}
              placeholder={"Course Material 1"}
              topDivStyle={"flex flex-col w-4/12"}
              classNameInput={"w-full"}
              name={`course_materials[${0}].title`}
              value={firstObject?.title}
              onChange={dataSetup}
              onBlur={formik.handleBlur}
              error={
                formik.errors?.course_materials &&
                firstError &&
                firstError?.title
              }
              isTouched={
                formik.touched?.course_materials &&
                firstTouched &&
                firstTouched?.title
              }
              index={0}
              check={"title"}
              disabled={disabled}
            />
            <InputBox
              placeholder="Course Completion %"
              topDivStyle={"flex flex-col w-3/12"}
              classNameInput={"w-full"}
              name={`course_materials[${0}].show_percentage`}
              value={firstObject?.show_percentage}
              onChange={dataSetup}
              onBlur={formik.handleBlur}
              error={
                formik.errors?.course_materials &&
                firstError &&
                firstError?.show_percentage
              }
              isTouched={
                formik.touched?.course_materials &&
                firstTouched &&
                firstTouched?.show_percentage
              }
              index={0}
              check={"show_percentage"}
              disabled={disabled}
            />
          </div>
          <UploadOrUpdateBucket
            mode={mode}
            id={"coursematerial0"}
            labelText={"Upload"}
            accept={"application/pdf"}
            fileSize={20}
            sizeInText={"Image Size 20Mb"}
            Method={firstObject?.url==="" ? "Upload" : "Update"}
            bucketName={"courses"}
            subBucket={"coursematerials"}
            forValue={handleValues}
            fileNameData={firstObject?.fileName}
            fileNameKey={firstObject?.key}
            preview={firstObject?.url}
            name={`course_materials[${0}].url`}
            error={
              formik.errors?.course_materials && firstError && firstError?.url
            }
            isTouched={
              formik.touched?.course_materials &&
              firstTouched &&
              firstTouched?.url
            }
            index={0}
            containerStyle={"absolute top-0 -left-40"}
          />
        </div>
      )}
      {formik.values?.course_materials?.length > 0 &&
        formik.values?.course_materials?.map((data: any, indexNumber: number) => {
          if (indexNumber > 0) {
            return (
              <div className="relative shadow-md shadow-[#00000029] rounded-[5px] py-4 flex items-end gap-1 w-full mt-4 px-3">
                <div className="flex items-end gap-4 w-full">
                  <InputBox
                    label={`Course Material ${indexNumber + 1}`}
                    placeholder={`Course Material ${indexNumber + 1}`}
                    topDivStyle={"flex flex-col w-4/12"}
                    classNameInput={"w-full"}
                    name={`course_materials[${indexNumber}].title`}
                    value={data?.title}
                    onChange={dataSetup}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.course_materials &&
                      formik.errors?.course_materials?.[indexNumber] &&
                      formik.errors?.course_materials?.[indexNumber].title
                    }
                    isTouched={
                      formik.touched?.course_materials &&
                      formik.touched?.course_materials?.[indexNumber] &&
                      formik.touched?.course_materials?.[indexNumber].title
                    }
                    index={`${indexNumber}`}
                    check={"title"}
                    disabled={disabled}
                  />
                  <InputBox
                    placeholder="Course Completion %"
                    topDivStyle={"flex flex-col w-3/12"}
                    classNameInput={"w-full"}
                    name={`course_materials[${indexNumber}].show_percentage`}
                    value={data?.show_percentage}
                    onChange={dataSetup}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.course_materials &&
                      formik.errors?.course_materials?.[indexNumber] &&
                      formik.errors?.course_materials?.[indexNumber]
                        .show_percentage
                    }
                    isTouched={
                      formik.touched?.course_materials &&
                      formik.touched?.course_materials?.[indexNumber] &&
                      formik.touched?.course_materials?.[indexNumber]
                        .show_percentage
                    }
                    index={`${indexNumber}`}
                    check={"show_percentage"}
                    type={"number"}
                    disabled={disabled}
                  />
                </div>
                <UploadOrUpdateBucket
                  mode={mode}
                  id={`coursematerial ${indexNumber + 1}`}
                  labelText={"Upload"}
                  accept={"application/pdf"}
                  fileSize={20}
                  sizeInText={"Image Size 20Mb"}
                  Method={data?.url === "" ? "Upload" : "Update"}
                  bucketName={"courses"}
                  subBucket={"coursematerials"}
                  forValue={handleValues}
                  fileNameData={data?.fileName}
                  fileNameKey={data?.key}
                  preview={data?.url}
                  // buttonContainer={'w-4/12 mx-auto'}
                  // previewImgStyle={'w-full'}
                  index={indexNumber}
                  containerStyle={"absolute top-0 -left-40"}
                  name={`course_materials[${indexNumber}].url`}
                  error={
                    formik.touched?.course_materials &&
                    formik.errors?.course_materials?.[indexNumber] &&
                    formik.errors?.course_materials?.[indexNumber].url
                  }
                  isTouched={
                    formik.touched?.course_materials &&
                    formik.touched?.course_materials?.[indexNumber] &&
                    formik.touched?.course_materials?.[indexNumber].url
                  }
                />
                {!disabled && (
                  <div
                    onClick={() => deleteMaterials(indexNumber)}
                    className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-9 bottom-7"
                  >
                    x
                  </div>
                )}
              </div>
            );
          }
        })}
      <AddItemsButton
        text={"Course Materials"}
        //   classNameInput={"absolute right-0 -top-4 border"}
        onClick={addMoreMaterials}
        disabled={disabled}
      />
    </div>
  );
};