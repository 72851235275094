import { useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from "react-hot-toast";
import { navigationComponents } from './importFiles';
import { TableComponent } from './Components/Elements/Table/TableComponent';
import {Sample} from './Components/Sample/Sample';
import { SignIn } from './Components/LoginScreen/Signin';
import { ProtectSite } from './ProtectedRoutes/ProtectedSite';
import UnAuthorized from './Components/Elements/UnAuthorized';
// import { PrivateRoute } from './Services/PrivateRoute';

function App() {
  const { MainComponent }: any = navigationComponents;

  if (!window.navigator.onLine) {
    return <div className="p-4 text-xl text-red-600">Please check your internet connection.</div>;
  }

  return (
    <div>
      <Toaster />
      <Routes>
        <Route path="/" element={<SignIn />} />
        {/* <Route element={<ProtectSite />}> */}
        <Route path="/:dashboardScreen" element={<MainComponent />} />
        <Route path="/:dashboardScreen/:subsection" element={<MainComponent />} />
        {/* <PrivateRoute path="/dashboardScreen" element={<MainComponent />} /> */}
        {/* </Route> */}
        {/* <Route path='/unauthorized_call' element={<UnAuthorized />} /> */}
        {/* <Route path="/test" element={<Sample />} /> */}
      </Routes>
    </div>
  );
}

export default App;
