import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import _ from "lodash";

export function Separator(props: any) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
}

export function RadialSeparators(props: any) {
  const turns = 1 / props.count;
  return (
    <>
      {_.range(props.count).map((index: number) => (
        <Separator turns={index * turns} style={props.style} />
      ))}
    </>
  );
}

export const Progressbar = ({ percentage,mainDivStyle }: any) => {
  return (
    <div className={`${mainDivStyle} flex items-center`}>
      <CircularProgressbarWithChildren
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={15}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: `#55AB33`,
          textColor: "#000000",
        })}
      >
        <RadialSeparators
          count={12}
          style={{
            background: "#fff",
            width: "2px",
            height: `${15}%`,
          }}
        />
      </CircularProgressbarWithChildren>
    </div>
  );
};
