import shareIcon from "../../../Assets/shareIcon.svg";
import { ReadData } from "../../Elements/ReadData";
// import { TotalFee } from "../../StudentManagement/StudentCourseProgress/PaymentDetails";
import { formatNumberWithCommas } from "../../../Services/CommonFunctions";
import { dateFormat } from "../../../Services/CommonFunctions";
import {
  feeAfterDiscountCalculation,
  gstCalculationForStudent,
} from "../../../functions/admin/Students/students";

export const PaymentDetails = ({formik, basicInfoDetails, totalVal}:any) => {
  // const Payment = Pay_Details();

  const check_feeType = formik.values?.branch_based_GST && formik.values?.branch_based_GST?.fee_type;
  const sendPercentageIGST =
    check_feeType === "IGST" && formik.values?.branch_based_GST?.igst;
  const sendPercentageSGST =
    check_feeType === "SGST" && formik.values?.branch_based_GST?.sgst;
  const sendPercentageCGST =
    check_feeType === "SGST" && formik.values?.branch_based_GST?.cgst
  const sendPercentage =
    check_feeType === "SGST"
    ? {
        cgst: formik.values?.branch_based_GST?.cgst,
        sgst: formik.values?.branch_based_GST?.sgst,
      }
    : check_feeType === "IGST"
    ? formik.values?.branch_based_GST?.igst
      : null;

  const paymentObject = {
    check_feeType: check_feeType,
    sendPercentageIGST: sendPercentageIGST,
    sendPercentageSGST: sendPercentageSGST, 
    sendPercentageCGST: sendPercentageCGST,
    sendPercentage: sendPercentage
  }
  
  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[27px] font-Roboto font-[500]">
        Payment Details
      </h1>
      <div>
        {basicInfoDetails?.paymentDetails?.map((pay: any, index: number) => {
          // console.log(pay, "pay", paymentObject, formik.values?.branch_based_GST)
          return (
            <div key={index} className="shadow-md shadow-[#00000029] rounded-[3px] p-3 flex justify-between">
              <div className="flex justify-between w-[97%]">
                <div className="flex flex-col justify-between items-center">
                  <ReadData head={"Payment Date"} body={dateFormat(pay.payment_date, 'DD MMM YYYY')} />
                  <div className="flex flex-col gap-1 items-center">
                    {pay.payment_type?.map((type: any) =>
                    <span
                      className={`text-[12px] font-Roboto font-[400] px-1.5 ${
                        pay.type === "Registration"
                          ? "bg-[#036AD1] text-white"
                          : "bg-[#FFDD03] text-black"
                      }`}
                    >
                      {type}
                    </span>)}
                  </div>
                </div>
                <ReadData head={"Mode of Payment"} body={pay.mode_of_payment} />
                <ReadData head={"Transaction ID"} body={pay.transaction_id?pay.transaction_id:'-'} />
                <ReadData
                  head={"Balance Due"}
                  body={formatNumberWithCommas(Math.round(Number(pay.balance)))}
                  addSpan={pay.balance}
                  color={"text-[#FF0000]"}
                />
                {/* <TotalFee pay={Math.round(Number(pay.paid_amount))} /> */}
                <TotalFee
                  pay={pay?.paid_amount}
                  branchDetails={formik.values?.branch_based_GST}
                  paymentObject={paymentObject}
                />
              </div>
              {/* <div>
                <img src={shareIcon} alt="shareIcon" className="bg-[#036AD1]" />
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const TotalFee = ({ pay, branchDetails, paymentObject }: any) => {
  const feeInNumber = pay;
  
    const TotalExclGST = feeAfterDiscountCalculation(
      feeInNumber
    );
    const Sgst = gstCalculationForStudent(
      paymentObject?.sendPercentageSGST,
      feeInNumber
    );
    const Cgst = gstCalculationForStudent(
      paymentObject?.sendPercentageCGST,
      feeInNumber
      );
    const Igst = gstCalculationForStudent(
      paymentObject?.sendPercentageIGST,
      feeInNumber
    );
      
      const mappingForTotal: any = [
        {
          left: "Total (Excl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalExclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(TotalExclGST)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `SGST (${branchDetails?.sgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Sgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : parseFloat(Number(Sgst)?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `CGST (${branchDetails?.cgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Cgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : Number(Cgst)
                      ?.toFixed(1)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        paymentObject?.check_feeType === "IGST" && {
          left: `IGST (${branchDetails?.igst}%)`,
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(Igst?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(Igst)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        {
          left: "Total (Incl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalExclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : parseFloat(Number(TotalExclGST)?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
      ];

  return (
    <div className="w-[40%] flex flex-col justify-between">
      {mappingForTotal.map((text: any, index: number) => {
        return (
          <div key={index}
            className={`flex justify-around w-full ${
              index === 4 && "bg-[#EFF7FF] py-1.5"
            }`}
          >
            <p className={`w-[40%] text-end`}>{text.left}</p>
            <p className={`w-[20%]`}>
              {text.left && <span className="px-1">&#8377;</span>}
              {text.right}
            </p>
          </div>
        );
      })}
    </div>
  );
};
