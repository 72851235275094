//basic Info
export const BuildJSON = (data: any, batchStartDate: string) => {
    const Details: any = [
      {
        head: "Student Name",   
        body: data?.student_id?.name,
      },
      {
        head: "Phone Number",
        body: data?.student_id?.phoneNumber,
      },
      {
        head: "Email",
        body: data?.student_id?.email,
      },
      {
        head: "Schedule",
        body: data?.course_schedule,
      },
      {
        head: "Registration Date",
        body: data?.registration_date,
      },
      {
        head: "Status",
        body: data?.status,
      },
      {
        head: "Branch & City",
        body: `${data?.branch_id?.branch_name}, ${data?.city_id?.city_name}`,
      },
      {
        head: "Batch Start Date",
        body: batchStartDate
      },
      {
        head: "Expected Start Date",
        body: data?.expected_start_date?data?.expected_start_date:'-'
      },
      {
        head: "Course Mode",
        body: data?.mode?data?.mode:'-'
      }
    ];
    return Details;
}

export const DetailsOfTrainers = () => {
    const Details = [
        {
            data: [
                {
                    head: "Trainer Name",
                    body: "Jeevan",
                    trainerHistory: "Current Trainer"
                },
                {
                    head: "Trainer Phone Number",
                    body: "987654321",
                },
                {
                    head: "Start Date",
                    body: "23 Dec 22",
                },
                {
                    head: "End Date",
                    body: "24 Dec 22",
                },
                {
                    head: "Duration",
                    body: "2 Hrs",
                },
            ]
        },
        {
            data: [
                {
                    head: "Trainer Name",
                    body: "Arun",
                    trainerHistory: "Past Trainer"
                },
                {
                    head: "Trainer Phone Number",
                    body: "987654321",
                },
                {
                    head: "Start Date",
                    body: "23 Dec 22",
                },
                {
                    head: "End Date",
                    body: "24 Dec 22",
                },
                {
                    head: "Duration",
                    body: "2 Hrs",
                },
            ]
        }
    ];
    return Details
}

export const SessionData = () => [
    {
        session_date: '24 Dec 22',
        trainer_name: 'Jeevan',
        trainer_number: '9876543210',
        topic: 'Java oops Concepts',
        attendance: 'Present',
        start_time: '7:30 AM',
        end_time: '9:30 AM',
        duration: '2 Hrs'
    },
    {
        session_date: '23 Dec 22',
        trainer_name: 'Arun',
        trainer_number: '9871142310',
        topic: 'Data Types',
        attendance: 'Absent',
        start_time: '7:30 AM',
        end_time: '9:30 AM',
        duration: '0 Hrs'
    },
]

export const Summary = (data:any) => {
    const details: any = [
      {
        head: "Course",
        body: data?.course_id?.course_name,
      },
      {
        head: "Fees",
        body: "21,240",
      },
      {
        head: "Paid Amount",
        body: "21,240",
      },
      {
        head: "Balance Due",
        body: "-",
      },
    ];
    return details;
}

export const Jobs = () => [
    {
        date: '03 Dec 2022',
        company_name: 'CTS',
        role: 'Java Developer',
        location: 'Chennai',
        Shortlisted_status: 'Not Shortlisted',
        placed_status: 'No',
        company_joining_status: '-'
    },
    {
        date: '03 Dec 2022',
        company_name: 'Jeevan Tech',
        role: 'Support Engineer',
        location: 'Bangalore',
        Shortlisted_status: 'Shortlisted',
        placed_status: 'Yes',
        company_joining_status: 'No'
    },
]

export const Pay_Details: any = () => {
    const Details = [
    {
        date: "02 Dec 2022",
        mode: "Swiping Machine",
        transaction_id: "0003567895",
        balance_due: "9440",
        amount: "10000",
        type: "Registration"
    },
        {
        date: "02 Dec 2022",
        mode: "Swiping Machine",
        transaction_id: "0003567895",
        balance_due: "3540",
        amount: "5000",
        type: "Installment"
    },
    {
        date: "02 Dec 2022",
        mode: "Swiping Machine",
        transaction_id: "0003567895",
        balance_due: "-",
        amount: "2902.8",
        type: "Installment"
    },
    ]
    return Details;
} 