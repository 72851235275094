import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { ManageTechnology } from "../../../utils/json/technology";
import { BackButton } from '../../Elements/BackButton';
import { AddNewTechnology } from './AddNewTechnology';
import { MessageCard } from '../../Elements/MessageCard';
import { ViewTechnology } from './ViewTechnology';
import { useSharedLogic, CommonTableComp } from '../../CommonFunctions/CommonFunc';
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const Technology = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  
  return (
    <div className="w-11/12 mx-auto">
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Technology Created Successfully"
                : "Technology Updated Successfully"
            }
            buttonText={"Manage Technology"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
      {mode === "view" && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-10/12 justify-end"}
          />
          <ViewTechnology id={id} mode={mode} />
        </div>
      )}
      {(id === "new-technology" || mode === "edit") && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-10/12 justify-end"}
          />
          <AddNewTechnology id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions('view') &&
      <CommonTableComp
        id={id}
        head={"Manage Technology"}
        buttonType={"right"}
        buttonText={"Add New Technology"}
        onClick={()=>createFunc("new-technology")}
        buttonImg={buttonWithRightArrow}
        TABLE_JSON={ManageTechnology}
        allowCondition={allowOnlyActions("create")}
      />}
    </div>
  );
}