import { RoleTable } from "../../../utils/json/roleTable";
import { AddNewRole } from "./CreateRole/AddNewRole";
import { BackButton } from "../../Elements/BackButton";
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import {
  useSharedLogic,
  CommonTableComp,
} from "../../CommonFunctions/CommonFunc";
// import { getAllRoles } from "../../../../functions/src/functions/admin/Role Management/roleMangement";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const RoleManagement = () => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  return (
    <div>
      {(id === "add-role" || mode === "edit" || mode === "view") && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-[95%] justify-between"}
            header={
              (id === "add-role" && "Add New Role") ||
              (mode === "edit" && "Edit Role") ||
              (mode === "view" && "View Role")
            }
          />
          <AddNewRole id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions('view') &&
        <CommonTableComp
          id={id}
          head={"Role Management"}
          buttonType={"right"}
          buttonText={"Add New Role"}
          onClick={() => createFunc("add-role")}
          buttonImg={buttonWithRightArrow}
          TABLE_JSON={RoleTable}
          allowCondition={allowOnlyActions("create")}
          />}
    </div>
  );  
};  
          