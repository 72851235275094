import { dateFormat } from "../../../../Services/CommonFunctions";
import { createStudent } from "../../../Student/SignUp/Student";
import { CreateUserWithEmailAndPassword } from "../../../auth";
import { calculatePercentage, calculateTimeDifference, errorNotifier } from "../../../commonHelper"
import { TableNames } from "../../../config/Tables";
import { FetchData, InsertData, UpdateData, UpdateDataWithMatchParameters } from "../../../crud";
import { assignCoursesToStudent, progressByModuleCalculation, studentAndCoursesDataFormatting } from "./helper";
import { DateRange } from "./interface";


// export const getUnVerifiedTransactions = async ({startDate,endDate}: DateRange) => {
//     try {
//         const { data: TransactionsData, error } = await FetchData(TableNames.transactions, []).eq("is_verified", false).gte("payment_date", startDate.toISOString()).lte("payment_date",endDate.toISOString())
//         if (error) throw error;
//         return TransactionsData;
//     } catch (error) {
//         return errorNotifier(error);
//     }
// }

export const getUnVerifiedTransactions = async (UsersDetails: any) => {
    try {
        const currentDate:any = new Date();
        const { data: TransactionsData, error } = await FetchData(TableNames.transactions, ["mode_of_payment","id","is_verified","paid_amount","created_at","payment_date","training:trainings(city:cities(city_name),branch:branches(id,branch_name),student:students(name,email,phoneNumber,type),course:courses(course_name))"]).eq("is_verified", false);
        if (error) throw error;
        return TransactionsData?.filter((trans: any) =>  UsersDetails?.branches.includes(trans?.training?.branch.id)).map((transaction: any) => ({
            ...transaction,
            created_at: dateFormat(transaction?.payment_date, "DD-MM-YYYY"),
            city_name: transaction?.training?.city?.city_name,
            branch_name: transaction?.training?.branch?.branch_name,
            student_name: transaction?.training?.student?.name,
            student_email: transaction?.training?.student?.email,
            student_number: transaction?.training?.student?.phoneNumber,
            student_type: transaction?.training?.student?.type,
            course_name: transaction?.training?.course?.course_name,
            training: null,
            unVerified_dates: dateFormat(transaction?.payment_date, 'DD-MMM-YYYY'),
            paid_amount: Math.round(Number(transaction?.paid_amount))
        }));
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getVerifiedTransactions = async (UsersDetails: any) => {
    try {
        const { data: TransactionsData, error } = await FetchData(TableNames.transactions, ["mode_of_payment","id","is_verified","paid_amount","created_at","payment_date","training:trainings(city:cities(city_name),branch:branches(id,branch_name),student:students(name,email,phoneNumber,type),course:courses(course_name))"]).eq("is_verified", true);
        if (error) throw error;
        
        return TransactionsData?.filter((trans: any) =>  UsersDetails?.branches.includes(trans?.training?.branch.id)).map((transaction: any) => ({
            ...transaction,
            created_at: dateFormat(transaction?.payment_date, "DD-MM-YYYY"),
            city_name: transaction?.training?.city?.city_name,
            branch_name: transaction?.training?.branch?.branch_name,
            student_name: transaction?.training?.student?.name,
            student_email: transaction?.training?.student?.email,
            student_number: transaction?.training?.student?.phoneNumber,
            student_type: transaction?.training?.student?.type,
            course_name: transaction?.training?.course?.course_name,
            training: null,
            paid_amount: Math.round(Number(transaction?.paid_amount))
        }));
    } catch (error) {
        return errorNotifier(error);
    }
}

export const verifyTransactions = async (transactionIds: number[]) => {
    try {
        await UpdateDataWithMatchParameters(TableNames.transactions, {
            is_verified: true
        }, {
            key: "id",
            value: transactionIds
        });
        return true
    } catch (error) {
        return errorNotifier(error);
    }
}

//Raja for Pending payments.
/* add column     payment_follow_up?:[
       {
        follow_up_date:string,
        follow_up_created:string,
        follow_up_comments:string
       } 
    ]
*/

// export const getNotVerifiedPaymnets= async () => {
//     try {
//         const { data:transactionsdata, error }: any = await FetchData(TableNames.trainings,
//                                     ["course:courses(course_name, id, type)","status","batches:(id,status)","balance_due","student:students(name, email, phoneNumber, created_at)",
//                                     "course_fee","total_fee","discount","customization_fee","discount_percentage","comments","branch:branches(branch_name)", "city:cities(city_name)",
//                                      "paid_amount","payment_date","mode_of_payment","transaction_id","transaction_verified"])
//                                     .eq("transaction_verified", false)
//                                     .order("payment_date", { ascending: true });

//      const transactiondata = transactionsdata?.filter((tra: any) => tra?.transaction_verified === false).map((trans: any) => (
//         {...trans, transaction_date:dateFormat(trans?.payment_date,"DD MMM YYYY"),
//             registration_date: dateFormat(trans?.student?.created_at, "DD MMM YYYY"),
//          course_name: trans.course.course_name,
//          course_id: trans.course.id, branch: trans?.branch?.branch_name, 
//          student_name: trans?.student?.name , 
//          email: trans?.student?.email, 
//          phone_number: trans?.student?.phoneNumber,
//          city: trans?.city?.city_name}))
                                    
//         if (error) throw error;
//         return { transactiondata };
//     } catch (error) {
//         return errorNotifier(error);
//     }
// }

// export const addPayments = async (TransactionData: Partial<TransactionInterface>) => {
//     try {
//         const { data, error } = await InsertData(TableNames.trainings, TransactionData)
//         if (error) throw error;
//         return true;
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

// export const updateVerifiedPayments = async (TransactionData: Partial<TransactionInterface>, uid: string) => {
//     try {
//         const { data, error } = await UpdateData(TableNames.trainings,TransactionData, { conditionKey: "id", conditionValue: uid });
//         if (error) throw error;
//         return true;
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }


    

