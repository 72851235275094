import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchData } from "../../crud";
import _ from "lodash";

export const getDashboardData = async (UsersDetails: any,dashBoardData:any) => {
  try {
    const { data: studentData, error } = await FetchData(TableNames.students, [
      "*",
    ]);

    const filterStudentsBranchBased = studentData?.filter((dt: any) =>
      dt.branches.some((element: any) =>
        UsersDetails?.branches.includes(element)
      )
    );

    dashBoardData.admission.paidStudent = filterStudentsBranchBased?.filter(
      (e: any) => e?.type === "Paid Student"
    ).length;
    dashBoardData.admission.freeStudent = studentData?.filter(
      (e: any) => e?.type === "Free Student"
    ).length;
    dashBoardData.admission.appStudent = studentData?.filter(
      (e: any) => e?.type === "App Student"
    ).length;

    const { data: CousrseData, error: courseError }: any = await FetchData(
      TableNames.trainings,
      ["course_fee", "branch_id", "status", "courses:courses(mode)"]
    ).in("branch_id", UsersDetails?.branches);

    const { data: transactionsData, error: transactionError }: any =
      await FetchData(TableNames.transactions, [
        "id",
        "training_id",
        "created_at",
        "paid_amount",
        "trainings!inner(branch_id)",
        "training:trainings(mode, total_fee)",
        // "training:trainings(id,courses:courses(mode))",
      ]).in("trainings.branch_id", UsersDetails?.branches);

    Object.entries(dashBoardData.operations).forEach(([status, count]) => {
      const matchingCourses = CousrseData.filter(
        (course: any) => course.status === status
      );
      dashBoardData.operations[status] = matchingCourses.length;
    });

    transactionsData.forEach((item: any) => {
      const {
        paid_amount,
        training: { total_fee,mode },
      } = item;
      dashBoardData.invoiceAnalysis.invoice.total += parseFloat(paid_amount);
      dashBoardData.invoiceAnalysis.revenue.total += parseFloat(total_fee);
      
      if (mode === "Classroom") {
        dashBoardData.invoiceAnalysis.invoice.classRoom +=
          parseFloat(paid_amount);
        dashBoardData.invoiceAnalysis.revenue.classRoom +=
          parseFloat(total_fee);
      } else if (mode === "Online") {
        dashBoardData.invoiceAnalysis.invoice.online += parseFloat(paid_amount);
        dashBoardData.invoiceAnalysis.revenue.online += parseFloat(total_fee);
      } else {
        dashBoardData.invoiceAnalysis.invoice.hybrid += parseFloat(paid_amount);
        dashBoardData.invoiceAnalysis.revenue.hybrid += parseFloat(total_fee);
      }

    });


    var { data: batchData, error: batchError }: any = await FetchData(
      TableNames.batches,
      ["branch_id", "status"]
    ).in("branch_id", UsersDetails?.branches);

    Object.entries(dashBoardData.batch).forEach(([status, count]) => {
      const matchingBatches = batchData.filter(
        (batch: any) => batch.status === status
      );
      dashBoardData.batch[status] = matchingBatches.length;
    });

    var { data: feedbacksData, error: feedbackError }: any = await FetchData(
      TableNames.feedbacks,
      ["status", "response", "batches!inner(branch_id)"]
    ).in("batches.branch_id", UsersDetails?.branches);

    Object.entries(dashBoardData.feedback).forEach(([status, count]) => {
      const matchingFeedbacks = feedbacksData.filter(
        (fb: any) => fb.status === status
      );
      dashBoardData.feedback[status] = matchingFeedbacks.length;
    });

    var { data: concernData, error: concernError }: any = await FetchData(
      TableNames.feedbacks,
      ["status"]
    );
    Object.entries(dashBoardData.feedback).forEach(([status, count]) => {
      const matchingConcern = concernData.filter(
        (fb: any) => fb.status === status
      );
      if (status === "In-progress") {
        dashBoardData.feedback.inprogress = matchingConcern.length;
      } else if (status === "Re-open") {
        dashBoardData.feedback.reopen = matchingConcern.length;
      } else if (status === "'Thanks for the feedback'") {
        dashBoardData.feedback.Thanks = matchingConcern.length;
      } else dashBoardData.feedback[status] = matchingConcern.length;
    });

    var { data: jobsData, error: jobsError }: any = await FetchData(
      TableNames.jobs,
      ["company", "number_of_openings"]
    );
    const uniquecompany: any = [
      ...new Set(jobsData?.map((item: any) => item.company)),
    ];
    dashBoardData.jobs.totalCompanies = uniquecompany.length;
    dashBoardData.jobs.totaljobs = jobsData.length;
    dashBoardData.jobs.totalOpenings = jobsData.map(
      (obj: any) => obj.number_of_openings
    ).length;
    var { data: jobsStatus, error: jobsError }: any = await FetchData(
      TableNames.student_jobs,
      ["status"]
    );
    Object.entries(dashBoardData.jobs).forEach(([status, count]) => {
      const matchingJobs = jobsStatus.filter((fb: any) => fb.status === status);
      if (status === "applied" || status === "not shortlisted") {
        dashBoardData.jobs.applied = matchingJobs.length;
      } else if (status === "shortlisted" || status === "not placed") {
        dashBoardData.jobs.shortlisted = matchingJobs.length;
      } else if (status === "placed" || status === "not joined") {
        dashBoardData.jobs.placed = matchingJobs.length;
      } else if (status === "joined") {
        dashBoardData.jobs.joined = matchingJobs.length;
      }
    });

    dashBoardData.jobs.placed += dashBoardData.jobs.joined;
    dashBoardData.jobs.shortlisted += dashBoardData.jobs.placed;
    dashBoardData.jobs.applied += dashBoardData.jobs.shortlisted;

    if (
      error ||
      courseError ||
      transactionError ||
      batchError ||
      feedbackError ||
      concernError ||
      jobsError ||
      jobsError
    )
      throw (
        error ||
        courseError ||
        transactionError ||
        batchError ||
        feedbackError ||
        concernError ||
        jobsError ||
        jobsError
      );

    return dashBoardData;
  } catch (error) {
    return errorNotifier(error);
  }
};
