import data_types_logo from "../../../Assets/data_types_logo.svg";
import sub_types from "../../../Assets/sub_topics.svg";
import { Components } from "./ModuleImports";

export const PopupContent = ({ content, title, modalClose, setContent }: any) => {
  const { useEffect, getStudentsOfSingleSession }: any = Components;
  const header = ["#", "Student Name", "Phone Number"];

  useEffect(() => {
    (async () => {
      if (title==="Participants") {
        const students = await getStudentsOfSingleSession(content);
        setContent(students)
      }
    })()
  },[])

  return (
    <div className="w-full space-y-1 shadow-md shadow-[#00000029] rounded-[12px] py-4 bg-white">
      <div className="flex justify-between items-center w-full py-2">
        <div className="flex gap-2 px-4">
          {title!=="Participants" && <img src={data_types_logo} alt="Title" />}
          <h1 className="text-[#707070] text-[22px] font-Roboto font-[500] select-none">{title}</h1>
        </div>
        <div
          onClick={()=>modalClose('cancel')}
          className={`bg-[#FF0000] px-1.5 mr-4 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center`}
        >
          X
        </div>
      </div>
      <div>
        {title === "Participants" &&
        <div className="w-full flex bg-[#EFF7FF] py-2">
          {header.map((head: any, i: number) =><h1 className={`${i===0?'w-[20%]':'w-1/2'} select-none text-center font-Roboto font-[500]`}>{head}</h1>)}
        </div>}
        {content &&
        content?.map((text: any, index: number) => {
          return (
            <>
              {title !== "Participants" ? (
                <div key={index}
                  className={`flex gap-2 px-10 py-2 ${
                    index % 2 === 0 ? "bg-[#EFF7FF]" : "bg-white"
                  }`}
                >
                  <img src={sub_types} alt="Types" />
                  <p className="text-[#686868] text-[18px] font-Roboto font-[400] select-none">
                    {text}
                  </p>
                </div>
              ) : (
                <div key={index}
                  className={`w-full flex py-2 ${
                  index % 2 !== 0 ? "bg-[#EFF7FF]" : "bg-white"
                }`}>
                  <p className={`w-[20%] text-center select-none`}>{index + 1}</p>
                  <p className={`w-1/2 text-center select-none`}>{text?.name}</p>
                  <p className={`w-1/2 text-center select-none`}>{text?.phoneNumber}</p>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
