import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  useDispatch,
  useSelector,
  Provider,
  TypedUseSelectorHook,
} from "react-redux";
import { State, Dispatch, store } from "./Redux/Store";
// import { SupabaseProvider } from "./Services/Supabase";

export const useAppDispatch: () => Dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <SupabaseProvider>
    <div className="bg-primary">
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </div>
  // </SupabaseProvider>
);
