import {useState} from 'react';
import { BatchDropdown } from "./BatchDropdown";
import { ReadData } from "../../Elements/ReadData";

interface User {
  uid: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  name: string;
}

export const TrainerData = ({mode, trainerList, formik, isTrainerChange, setIsTrainerChange}:any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const filteredUsers = trainerList.filter((user:any) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
    
  const handleSelect = (field: string, value: string | string[]) => {
      const user = trainerList.find((u: any) => u.name === value)!
      console.log(user, "user")
      setSelectedUser(user);
      formik.setValues({
          ...formik.values, trainer_id: user?.uid, trainer_name: user?.name, trainer_phone_number: user?.phone_number, trainer_email: user?.email
      }, true)
  }

  const TrainerChange = () => {
    setIsTrainerChange(true)
    formik.setValues(
      {
        ...formik.values,
        trainer_id: "",
        trainer_name: "",
        trainer_phone_number: "",
        trainer_email: "",
      },
      true
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
          Trainer Details
        </h1>
        {mode === "edit" && (
          <button
            type="button"
            onClick={TrainerChange}
            className="bg-[#DF0952] p-1.5 px-2 rounded-[4px] font-Roboto font-[500] text-[17px] text-white"
          >
            Change Trainer
          </button>
        )}
      </div>
      {mode === "edit" && (
        <h1 className="text-[#000000] text-[20px] font-Roboto font-[500]">
          {formik.values.trainer_id !== "" && "Current Trainer"}
        </h1>
      )}
      <div className="flex items-center gap-10">
        <BatchDropdown
          name="trainer_name"
          label="Trainer Name"
          showLabel={true}
          options={filteredUsers.map((user: any) => user.name)}
          placeholder="Trainer Name"
          value={formik.values.trainer_name}
          onChange={handleSelect}
          onBlur={formik.handleBlur}
          error={formik.errors.trainer_name}
          touched={formik.touched.trainer_name}
        />
        <div className={`flex flex-col w-full space-y-0.5 relative`}>
          <label
            htmlFor="email"
            className="text-[#707070] 2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500]"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="trainer_email"
            placeholder="Email"
            value={formik.values.trainer_email || ""}
            readOnly={true}
            className="numberClass outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2"
          />
          {formik.errors.trainer_email && formik.touched.trainer_email && (
            <span className="text-red-500 absolute -bottom-6 right-0">
              {formik.errors.trainer_email}
            </span>
          )}
        </div>
        <div className={`flex flex-col w-full space-y-0.5 relative`}>
          <label
            htmlFor="phone_number"
            className="text-[#707070] 2xl:text-[19px] xl:text-[18px] lg:text-[17px] md:text-[15px] font-Roboto font-[500]"
          >
            Phone Number
          </label>
          <input
            type="tel"
            id="phone_number"
            name="trainer_phone_number"
            placeholder="Phone Number"
            value={formik.values.trainer_phone_number || ""}
            readOnly={true}
            className="numberClass outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2"
          />
          {formik.errors.trainer_phone_number &&
            formik.touched.trainer_phone_number && (
              <span className="text-red-500 absolute -bottom-6 right-0">
                {formik.errors.trainer_phone_number}
              </span>
            )}
        </div>
      </div>
      {mode === "edit" && isTrainerChange && (
        <div className="flex flex-col space-y-2">
          <label>Reason for Changing the Trainer</label>
          <textarea
            className="border-[#707070] border-[0.5px] rounded-[3px] min-h-[80px] p-1 w-[30%] resize-none"
            name="trainer_change_reason"
            value={formik.values.trainer_change_reason}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Write here.."
          >
            {formik.values.trainer_change_reason}
          </textarea>
        </div>
      )}
      {mode === "edit" && formik.values?.past_trainers?.length > 0 && (
        <div className="space-y-2 w-[80%]">
          <h1 className="text-[#000000] text-[20px] font-Roboto font-[500]">
            {formik.values.past_trainers.length > 1
              ? "Past Trainers"
              : "Past Trainer"}
          </h1>
          <div className="shadow-md shadow-[#00000029] rounded-[7px] py-2">
            {formik.values?.past_trainers.map((past: any, index: number) => {
              return (
                <div
                  key={index}
                  className="p-2 flex justify-between w-[85%] mx-auto"
                >
                  <ReadData
                    head={"Trainer Name"}
                    body={`${past.trainer?.first_name} ${past.trainer?.last_name}`}
                  />
                  <ReadData
                    head={"Trainer Phone Number"}
                    body={past.trainer?.phone_number}
                  />
                  <ReadData head={"Trainer Email"} body={past.trainer?.email} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
