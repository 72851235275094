import React from 'react'
import { useParams } from 'react-router-dom';
import { TableComponent } from '../Elements/Table/TableComponent'
import { userLog } from '../../utils/json/userLog'
import { useSharedLogic, CommonTableComp } from "../CommonFunctions/CommonFunc"
import { BackButton } from "../Elements/BackButton";
import { ViewLogsInfo } from './UserLogs/ViewLogsInfo';
import { Subsection } from '../SubSections/Subsection';

export const Logs = () => {
  const params = useParams();
  const routeName: any = params.subsection;
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
    return (
      <div className="w-11/12 mx-auto">
        <Subsection routeName={routeName} />
         {/* {mode === "view" && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-[95%] justify-end"} />
          <ViewLogsInfo id={id} />
        </div>
      )}
        <CommonTableComp
                TABLE_JSON={userLog}
                id={id}
                head={"User Logs"}
              /> */}
      </div>
      )
}