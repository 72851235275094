import { useEffect, useState, Fragment } from 'react';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import { useAppSelector, useAppDispatch } from '../../..';
import { formatNumberWithCommas } from '../../../Services/CommonFunctions';
import { verifyTransactions } from '../../../functions/admin/Action center/Transactions/transactions';
import { setTransaction } from "../../../Redux/Reducers/transactionIDS";

export const SummaryForDT = ({setIsLoading}:any) => {
  const { id }: any = useSharedLogic();
  const { selectedIds } = useAppSelector(({ transaction }: any) => transaction);
  const [data, setData] = useState([] as any)
  const [btnColor, setBtnColor] = useState(false);
  const headData = ['#', 'Mode of Payment', 'Amount']
  const mapAmount = selectedIds?.transIds?.map((amt: any) => amt.amount);
  const dispatch = useAppDispatch();

    useEffect(() => {
      dataSetup()
    },[id])

    const dataSetup = () => {
        if (data.includes(id)) {
            setData(data.filter((item:any)=> item !== id))
        } else {
            setData([...data, id])
        }
    }

    const sumStringNumbers = (arr:any) => {
        let sum = 0;
        arr.forEach((num:any) => {
            const parsedNum = parseInt(num, 10); // Use parseFloat if you have floating-point numbers
            if (!isNaN(parsedNum)) {
            sum += parsedNum;
            }
        });
        return sum;
    };

    const result = sumStringNumbers(mapAmount);
    
  const verifyPayments = async () => {
    setIsLoading(true)
    const mapIds = selectedIds?.transIds?.map((dt: any) => parseInt(dt?.id)).flat();
      const response = await verifyTransactions(mapIds);
      if(response){
        setBtnColor(true)
        dispatch(setTransaction({transIds: []}));
      }
    setIsLoading(false);
    }

    const mergedPayments:any = {};

    selectedIds?.transIds?.forEach((payment:any) => {
        const { id, mode_of_payment, amount } = payment;
        if (mergedPayments[mode_of_payment]) {
            mergedPayments[mode_of_payment].id.push(id);
            mergedPayments[mode_of_payment].amount += amount;
        } else {
            mergedPayments[mode_of_payment] = {
                id: [id],
                amount: amount,
                mode_of_payment: mode_of_payment
            };
        }
    });
    // console.log(mergedPayments, "mergedPayments")

    const mergedPaymentsArray = Object.values(mergedPayments).map((payment:any) => ({
        mode_of_payment: payment.mode_of_payment,
        id: payment.id,
        amount: payment.amount
    }));

    // console.log(mergedPaymentsArray);

    return (
      <Fragment>
        {mergedPaymentsArray?.length > 0 && (
          <div className="w-11/12 mx-auto">
            <h1 className="text-[#000000] text-[26px] font-Roboto font-[500]">
              Summary
            </h1>
            <div className="pt-6 w-[94%] mx-auto space-y-2">
              <div className="py-2 shadow-[#00000029_0px_3px_8px] rounded-[6px] w-[40%]">
                <div className="flex items-center w-full bg-[#EFF7FF] py-2">
                  {headData.map((head: any, index: number) => (
                    <p
                      className={`text-[#000000] text-[15px] 2xl:text-[16px] font-Roboto font-[500] ${
                        index === 0 ? "w-[20%]" : "w-[40%]"
                      } text-center`}
                    >
                      {head}
                    </p>
                  ))}
                </div>
                <div>
                  {mergedPaymentsArray?.map((dt: any, index: number) => (
                    // console.log(dt, "dt"),
                    <div
                      className={`w-full ${
                        index % 2 !== 0 ? "bg-[#EFF7FF]" : "bg-white"
                      } flex items-center justify-around py-2`}
                    >
                      <p className="text-[#686868] w-[20%] text-center font-Roboto font-[400] text-[14px] 2xl:text-[15px]">
                        {index + 1}
                      </p>
                      <p className="text-[#686868] w-[40%] text-center font-Roboto font-[400] text-[14px] 2xl:text-[15px]">
                        {dt?.mode_of_payment}
                      </p>
                      <p className="text-[#686868] w-[40%] text-center font-Roboto font-[400] text-[14px] 2xl:text-[15px]">
                        <span className="px-0.5">&#8377;</span>{" "}
                        {formatNumberWithCommas(dt?.amount)}
                      </p>
                    </div>
                  ))}
                  {mergedPaymentsArray?.length !== 0 && (
                    <div className="w-full flex items-center justify-around py-2">
                      <p className="w-[20%]">{""}</p>
                      <p className="text-[#000000] w-[40%] text-center font-Roboto font-[500] text-[14px] 2xl:text-[15px]">
                        Total
                      </p>
                      <p className="text-[#000000] w-[40%] text-center font-Roboto font-[500] text-[14px] 2xl:text-[15px]">
                        <span className="px-0.5">&#8377;</span>{" "}
                        {formatNumberWithCommas(result)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-end w-[40%]">
                <button
                  onClick={verifyPayments}
                  className={`p-2 px-4 ${
                    btnColor ? "bg-[#006E16]" : "bg-[#FF0000]"
                  } rounded-[5px] text-white font-Roboto text-[14px] 2xl:text-[15px]`}
                >
                  {btnColor
                    ? "Verified the Total Transaction"
                    : "Verify the Total Transaction"}
                </button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
}
