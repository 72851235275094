import { hrStudentPlacement } from '../../../utils/json/HrStudentPlacement'
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { BackButton } from "../../Elements/BackButton";
import { MessageCard } from "../../Elements/MessageCard";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { AddNewJobs } from './AddNewJobs';
import ViewApplicants from '../View Applicants/View Applicants';
import { ViewJobsInfo } from './ViewJobsInfo';
import ViewApplicantTabs from '../View Applicants/ViewApplicantTabs';
import PlacedApplicantTab from '../View Applicants/PlacedApplicantTab';
import JoinedApplicantTabs from '../View Applicants/JoinedApplicantTabs';
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const HrStudentPlacement = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  
  return (
    <div className="w-11/12 mx-auto">
      {(id === "jobs-created-success" || id === "jobs-update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "jobs-created-success"
                ? "Jobs Created Successfully"
                : "Jobs Updated Successfully"
            }
            buttonText={"Manage Jobs"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
       {mode === "view" && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-[95%] justify-end"} />
          <ViewJobsInfo id={id} />
        </div>
      )}
      {(id === "new-jobs" || mode === "edit") && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"w-[95%] justify-between"}
            header={
              (id === "new-jobs" && "Create Jobs") ||
              (mode === "edit" && "Edit Jobs")

            }
          />
          <AddNewJobs  id={id} mode={mode}  />
        </div>
      )}
      {(mode === "viewApplicant") && (
        <div className="space-y-4">
         
          <ViewApplicants />
        </div>
      )}
       {(mode === "shortListed") && (
        <div className="space-y-4">
         
          <ViewApplicantTabs />
        </div>
      )}
       {(mode === "placed") && (
        <div className="space-y-4">
         
          <PlacedApplicantTab />
        </div>
      )}
       {(mode === "joined") && (
        <div className="space-y-4">
          <JoinedApplicantTabs />
        </div>
      )}
        {allowOnlyActions('view') &&
      <CommonTableComp
        id={id}
        head={"HR & Student Placement"}
        buttonType={"right"}
        buttonText={"Create Job"}
        onClick={()=>createFunc("new-jobs")}
        buttonImg={buttonWithRightArrow}
        TABLE_JSON={hrStudentPlacement}
      //  allowCondition={true}
      allowCondition={allowOnlyActions("create")}
      />
      }
    </div>
  );
}