import {useState} from "react";
import { useSearchParams } from "react-router-dom";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import tableDeleteIcon from "../../../Assets/tableDeleteIcon.svg";
import { deleteCourse } from "../../../functions/admin/Courses/Courses";
import toast from "react-hot-toast";
import { ModalBox } from "../../UI_Modal/UI_Modal";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const { allowOnlyActions }: any = FunctionForPrivileges();

  const navigateToViewPage = () => {
    setSearchParams({
      id: `${recordData.id}`,
      mode: `view`,
    });
  };

  const navigateToEditPage = () => {
    setSearchParams({
      id: `${recordData.id}`,
      mode: `edit`,
    });
  };
    
  const courseDeletion = async() => {
      const deleteResponse = await deleteCourse(recordData.id)
      if (deleteResponse) {
        window.location.reload();
        toast.success('Course Deleted Successfully')
      }
  }

      const deleteModal = (flag: any) => {
        setShow(false);
        if (flag === 'yes') {
            courseDeletion();
        } else {
            setShow(false);
        }
    }

    const openModal = (val:any) => {
        setShow(true);
    }
    
  return (
    <div className="flex items-center justify-center text-center space-x-4">
      <ModalBox show={show} fun={deleteModal} />
      {allowOnlyActions('view') && <div
        onClick={navigateToViewPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableViewEyeIcon}
          alt="viewIcon"
          className="w-[21px] h-[10px]"
        />
      </div>}
      {allowOnlyActions('edit') && <div
        onClick={navigateToEditPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img src={tableEditIcon} alt="editIcon" className="w-[14px] h-[14px]" />
      </div>}
      {allowOnlyActions('delete') && <div
        onClick={() => openModal(recordData.id)}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableDeleteIcon}
          alt="editIcon"
          className="w-[14px] h-[14px]"
        />
      </div>}
    </div>
  );
};
