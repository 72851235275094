import { Components } from "./ModuleImports";
import { CompModal } from "../../UI_Modal/CompModal";
import { PopupContent } from "./PopUpModal";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const SessionDetails = ({ id, sessionData }: any) => {
  const { useState, createSearchParams, AddItemsButton, useSharedLogic }: any =
    Components;
  const { MainMenu, allowAllActions }: any = FunctionForPrivileges();
  const { navigate, routeNameForHome, courseId }: any = useSharedLogic();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [componentState, setComponentState] = useState(null);
  const [content, setContent] = useState([] as any);
  const [title, setTitle] = useState("");
  const bodyTextStyle = (text?: any, color?: any) => {
    return `text-[14px] font-Roboto font-[400] ${
      text ? "w-[15%]" : "w-[10%]"
    } text-center`;
  };
  const SessionDetailsHead = [
    "S.No",
    "Session Date",
    "Trainer Name",
    "Trainer Phone Number",
    "Topic",
    "Start Time",
    "End Time",
    "Duration",
    "Participants",
    "",
  ];

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const addMoreSession = () => {
    navigate({
      pathname: `/${routeNameForHome}`,
      search: createSearchParams({
        id: id,
        mode: "new-session",
        routePath: "session",
      }).toString(),
    });
  };

  const editIndividualSession = (sessionId: any) => {
    navigate({
      pathname: `/${routeNameForHome}`,
      search: createSearchParams({
        id: id,
        mode: "edit",
        courseId: sessionId,
        routePath: "session",
      }).toString(),
    });
  };

  const showModal = (flag: any) => {
    setShow(false);
    if (flag === "yes") {
      // <PopupContent />
    } else {
      setShow(false);
    }
  };

  const openModal = (content: any, title: string) => {
    setContent(content);
    setTitle(title);
    // const dynamicProps:any = {
    //   content: content,
    //   title: title,
    // };
    // setComponentState(<PopupContent {...dynamicProps} />);
    setShow(true);
  };

  return (
    <div className="w-[97%] mx-auto">
      <CompModal
        show={show}
        fun={showModal}
        componentState={componentState}
        content={content}
        title={title}
        setContent={setContent}
      />
      <div className="flex items-center justify-between">
        <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-medium pb-6">
          Session Details
        </h1>
        {MainMenu?.includes('create') && <AddItemsButton
          text={"Add Session"}
          onClick={addMoreSession}
          disabled={false}
        />}
      </div>
      <div className="shadow-md shadow-[#00000029] rounded-[7px] py-3">
        <div className="bg-[#EFF7FF] flex items-center justify-between px-8">
          {SessionDetailsHead.map((details: any) => (
            <p
              className={`text-[#000000] text-[16px] py-1 font-Roboto font-medium text-center ${
                details === "S.No" ? "w-[6%]" : details === "Trainer Phone Number" ? "w-[15%]" : "w-[10%]"
              }`}
            >
              {details}
            </p>
          ))}
        </div>
        <div className="min-h-[100px] max-h-[450px] scroll-smooth overflow-y-scroll scrollbar-hide">
          {sessionData?.map((ses: any, i: number, arr: any) => {
            // console.log(ses, "ses");
            return (
              <div
                key={i}
                className={`flex items-center justify-between py-3 px-8 ${
                  i % 2 !== 0 && "bg-[#EFF7FF]"
                }`}
              >
                <p className={`text-[14px] font-Roboto font-[400] w-[6%] text-center text-[#686868]`}>
                  {arr.length - i}
                </p>
                <p className={`${bodyTextStyle()} text-[#686868]`}>
                  {ses.session_date}
                </p>
                <p className={`${bodyTextStyle()} text-[#686868]`}>
                  {ses.trainer_name}
                </p>
                <p
                  className={`${bodyTextStyle(
                    ses.trainer_mobile
                  )} text-[#686868]`}
                >
                  {ses.trainer_mobile}
                </p>
                <p
                  className={`${bodyTextStyle()} text-[#036AD1] select-none cursor-pointer`}
                  onClick={() => {
                    openModal(ses.details?.subtopics, "Data Types");
                  }}
                >
                  {ses?.details?.topic}
                </p>
                <p className={`${bodyTextStyle()} text-[#686868]`}>
                  {ses.start_time}
                </p>
                <p className={`${bodyTextStyle()} text-[#686868]`}>
                  {ses.end_time}
                </p>
                <p className={`${bodyTextStyle()} text-[#686868]`}>
                  {ses.duration}
                </p>
                <p
                  className={`${bodyTextStyle()} text-[#036AD1] select-none cursor-pointer`}
                  onClick={() => {
                    openModal(ses.attendees, "Participants");
                  }}
                >
                  {ses.participants}
                </p>
                {MainMenu?.includes('edit') && <div className="w-[10%] flex items-center justify-center">
                  <img
                    src={tableEditIcon}
                    alt="Edit"
                    onClick={() => editIndividualSession(ses.id)}
                    className={`w-6 h-6 p-1.5 rounded-[50%] bg-[#7E7E7E] cursor-pointer`}
                  />
                </div>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
