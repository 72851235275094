import { useState } from 'react';
import { updateStatusandPriority } from '../../../../functions/admin/Action center/Session Feedback/sessionFeedback';

export const Feedback = ({id, sessionData, showFb, setShowFb}:any) => {
    const [reply, setReply] = useState("");
    const result = sessionData?.training?.student?.name.charAt(0) || '';
  return (
    <div className="w-11/12 mx-auto space-y-4">
      <h1 className="text-[#000000] text-[26px] font-Roboto font-[500]">
        Feedback/Concerns
      </h1>
      <div className="w-9/12 ml-[6%] shadow-[#00000029_0px_3px_8px] py-3 space-y-2">
        <div className="flex items-center justify-between w-[96%] mx-auto">
          <div className="flex items-center gap-2">
            <div className="bg-[#036AD1] p-2 rounded-full w-[40px] h-[40px] flex items-center justify-center font-Roboto font-[400] text-white text-[20px]">
              {result}
            </div>
            <h1 className="text-[#000000] text-[22px] font-Roboto font-[500]">
              {sessionData?.training?.student?.name}
            </h1>
          </div>
          <p className="text-[#000000] text-[16px] font-Roboto font-[500] bg-[#FFD500] p-1 px-3 rounded-[6px]">
            {sessionData?.response}
          </p>
        </div>
        <div className="w-[92%] px-1 ml-auto space-y-4">
          <p className="font-Roboto font-[400] text-[#707070] text-[18px] break-words min-h-[30px]">
            {sessionData?.comment}
          </p>
          {showFb === "reply" ? (
            <div
              onClick={() => setShowFb("yourReply")}
              className="cursor-pointer flex items-center gap-1 border-[#036AD1] border-[0.5px] rounded-[2px] p-1.5 px-2 w-[10%]"
            >
              <img />
              <p className="text-[#036AD1] text-[16px] font-Roboto font-[400]">
                Reply
              </p>
            </div>
          ) : showFb === "yourReply" ? (
            <Reply
              id={id}
              reply={reply}
              setReply={setReply}
              setShowFb={setShowFb}
            />
          ) : (
            showFb === "replied" && (
              <div className="w-11/12 mx-auto px-4 py-2 bg-[#F9F9F9] rounded-[4px] space-y-1">
                <h1 className="text-[#036AD1] text-[17px] font-Roboto font-[400]">
                  Your reply
                </h1>
                <p className="font-Roboto font-[400] text-[#707070] text-[17px] break-words">
                  {sessionData?.reply?sessionData?.reply:reply}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

const Reply = ({ id, reply, setReply, setShowFb}:any) => {
    const PostFunction = async() => {
        if (reply !== "") {
            const updateReply = await updateStatusandPriority({ reply: reply }, id)
            if(updateReply) setShowFb("replied")
        }
    }
    return (
        <div className='w-11/12 space-y-1'>
            <h1 className='text-[#036AD1] text-[18px] font-Roboto font-[400]'>Your reply</h1>
            <div className='space-y-3'>
                <input name="inp" value={reply} onChange={(e:any)=>setReply(e.target.value)} className='outline-none border-b border-[#036AD1] w-full' autoFocus />
                <div className='flex items-center gap-5'>
                    <button onClick={PostFunction} className='bg-[#036AD1] p-1.5 px-4 text-white text-[16px] font-Roboto font-[400] rounded-[2px]'>
                        Post Reply</button>
                    <button className='text-[#000000] text-[16px] font-Roboto font-[400]' onClick={() => { setShowFb("reply"); setReply("")}}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
