import React from 'react';
import selectedCheckbox from "../../Assets/selectedCheckbox.svg";
import emptyCheckbox from "../../Assets/emptyCheckbox.svg";

export const Checkbox = ({ show, checked, label, CheckValue, textStyle, boxSize }: any) => {
  const allowChange = show??show;
  return (
    <div
      className={`flex items-center gap-2 cursor-pointer`}
      onClick={() => allowChange && CheckValue(label)}
    >
      <img
        src={checked === label ? selectedCheckbox : emptyCheckbox}
        alt="CheckType"
        className={`${boxSize?boxSize:''}`}
      />
      <label className={`${textStyle} font-Roboto cursor-pointer`}>
        {label}
      </label>
    </div>
  );
};
