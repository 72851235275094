import React, {useEffect, useState}  from 'react'
import { getRelevantJobsTableData } from '../../../functions/admin/HR Management/hr_management';
import { ClipLoader } from 'react-spinners';

export const StudentForHrRelevantJobs = ({id, mode}:any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [relevantJobs, setRelevantJobs] = useState([] as any);
  const heading = [
    {key:'1', name:'Date'},
    {key:'2', name:'Company Name'},
    {key:'3', name:'Job Role'},
    {key:'4', name:'Location'},
    {key:'5', name:'Technology'},
];

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const allRelevantJobs:any = await getRelevantJobsTableData(id);
      setRelevantJobs(allRelevantJobs);
      setIsLoading(false);   
  })()
  }, [])
  return (
    <div  className="w-11/12 mx-auto">
      
      <div className='bg-white rounded-lg py-4 flex'>
        <div className="relative overflow-x-auto scrollbar-show">
      {isLoading ? (
        <div className="flex items-center justify-center pl-96 min-h-[400px]">
          <ClipLoader color="#036AD1" />
        </div>
      ) : (
        <table className="table-fixed w-full">
          <thead className="bg-[#EFF7FF] text-[#686868] text-[20px]">
            <tr>
              <th className="w-[50px] text-center py-4">#</th>
             
              {heading.map(
                ({ name } : any, index: number) => (
                  <th
                    className={`font-bold py-4 text-[16px] text-[#686868]`}
                    key={index}
                  >
                    {name}
                  </th>
                )
              )}
              
            </tr>
          </thead>
          <tbody>
            
            {relevantJobs?.map((row: any, index: number) => (
              <tr key={index} className={index % 2 !== 0 ? "bg-[#F8FBFF]" : "bg-[#FFFFFF]"}>
                  <td className="w-[50px] py-3 text-center">
                  { index + 1}
                </td>
                <td className="w-[50px] py-3 text-center">
                  {row?.date}
                </td>
                <td className="w-[50px] py-3 text-center">
                  {row?.company}
                </td>
                <td className="w-[50px] py-3 text-center">
                  {row?.position}
                </td>
                <td className="w-[50px] py-3 text-center">
                  {row?.location}
                </td>
                <td className="w-[50px] py-3 text-center">
                  {row?.technology}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
    </div>
    </div>
  )
}