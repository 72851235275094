import { ViewEditDeleteActionComponent } from "../../Components/Content Management/PushContent/ActionComponent"
import { PushIcon } from "../../Components/Content Management/PushContent/PushIcon";
import { getPushContentTableData } from "../../functions/admin/Push Content/PushContent";

export const pushcontent = {
    headers: [{
        name: "Date",
        apiKey: "date",
        headerClass: "text-start w-[80px]",
        className: "text-start w-[80px]",
    },
    {
        name: "Push Content",
        apiKey: "icon",
        Comp: PushIcon,
        isComponent: true,
        headerClass: "text-center w-[100px]",
        className: "text-center w-[100px]",
    },
    {
        name: "Content Type",
        apiKey: "content_type",
        headerClass: "text-start w-[120px] px-2",
        className: "text-start w-[120px] break-words px-2",
    },
    {
        name: "Course Completion(%)",
        apiKey: "course_completion",
        headerClass: "text-center w-[100px]",
        className: "text-center w-[100px] ",
    },
    {
        name: "Action",
        apiKey: "Courses",
        isComponent: true,
        Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
        headerClass: "text-center w-[100px]",
        className: "text-center w-[100px]",
      },
    ],
    BackendFunctionCall: getPushContentTableData,
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "date",
                isSearchNeeded: true,
                className: "w-[180px] ",
            },
        ],
        checkBox: [
            // {
            //     name: "Date",
            //     apiKey: "date",
            //     isSearchNeeded: true,
            //     className: "w-[150px] ",
            // },
            {
                name: "Content Type",
                apiKey: "content_type",
                isSearchNeeded: true,
                className: "w-[240px]",
            },
            {
                name: "Course Completion %",
                apiKey: "course_completion",
                isSearchNeeded: true,
                className: "w-[240px] ",
            }
        ],
        // searchBox: [{
        //     matchFields: ["courseFeatures"],
        //     searchKey: "courseFeatures",
        //     name: "Search"
        // }
        // ]
    },
}