import React, {useState, useEffect} from 'react'
import { ViewApplicantsTest } from '../../../utils/json/ViewApplicantsTest'
import { CommonTableComp } from '../../CommonFunctions/CommonFunc'
import { useSharedLogic } from '../../CommonFunctions/CommonFunc'
//import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import downloadImg from "../../../Assets/downloadImg.svg"
import { ShortlistedApplicants } from '../../../utils/json/ShortlistedApplicants'
import { viewApplicants } from '../../../functions/admin/HR Management/hr_management'
import { ClipLoader } from 'react-spinners';
import { SubmitButton } from '../../Elements/SubmitButton'
import { Loader } from '../../Elements/Loader/Loader'
import { FormikCommonFunc } from '../../CommonFunctions/CommonFunc'
import { add_shorlisted } from '../../Formik/InitialValues'
import { ShortListedJobValidation } from '../../Formik/FormikValidation'
import { createSearchParams } from 'react-router-dom'
import { updateStudentJobStatus } from '../../../functions/admin/HR Management/hr_management'

export default function PlacedView({selectedRows} : any) {
    const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
    const [shorlistView,setShortlistView] = useState([] as any);
    const [shorlistRemainingView,setShortlistRemainingView] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
  //  console.log("Fetch Details.....", selectedRows)
    // console.log("Details ShortListed", shorlistView);
    const heading = [
      {key:'1', name:'Name'},
      {key:'2', name:'Phone Number'},
      {key:'3', name:'Email'},
      {key:'4', name:'Course'},
        
  ];
    
    useEffect(() => {
      (async () => {
      setIsLoading(true)
    //  const userData: any = await getjobId(id);
      const technologyResponse: any = await viewApplicants(id, ["placed", "not joined"]);
    //  console.log("Fetch Details Data View...", technologyResponse)
      const filteredData = technologyResponse?.filter((item: any) => selectedRows.includes(item.id));
    //  console.log("Fetch Details Data View...", filteredData)
      setShortlistView(filteredData);

      const remainingIds = technologyResponse
        .filter((item: any) => !selectedRows.includes(item.id))
        .map((item: any) => item.id);

        //console.log("remainingIds",remainingIds);
        setShortlistRemainingView(remainingIds);
    
 
       setIsLoading(false)
    })();
  }, []);
 
  const forNewShortlistedData = async () => {
    const shortlistedData = selectedRows.map((id: number) => ({
      id: id,
      status: "joined",
      interview_details: values.interview_details,
    }));

    const notShortlistedData = shorlistRemainingView.map((id: number) => ({
      id: id,
      status: "not joined", // Set the status as "not shortlisted"
   //   interview_details: values.interview_details,
    }));
   
      const body = [...shortlistedData, ...notShortlistedData];
      const response = await updateStudentJobStatus(body)
    //  console.log(response, "response");
      if (response) {
          navigate({
          pathname: `/${routeNameForHome}`,
          search: createSearchParams({ id: `${id === "new-jobs" ? "created-success" : "update-success"}` }).toString(),
          });
      }
     
  }

  const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(add_shorlisted, ShortListedJobValidation, forNewShortlistedData);
//  console.log("Value",values);
  return (
    
    <div  className="w-11/12 mx-auto">
      
    <div className='bg-white rounded-lg py-4 flex'>
      <div className="relative overflow-x-auto scrollbar-show">
    {isLoading ? (
      <div className="flex items-center justify-center pl-96 min-h-[400px]">
        <ClipLoader color="#036AD1" />
      </div>
    ) : (
      <table className="table-fixed w-full">
        <thead className="bg-[#EFF7FF] text-[#686868] text-[20px]">
          <tr>
            <th className="w-[50px] text-center py-4">#</th>
           
            {heading.map(
              ({ name } : any, index: number) => (
                <th
                  className={`font-bold py-4 text-[16px] text-[#686868]`}
                  key={index}
                >
                  {name}
                </th>
              )
            )}
            
          </tr>
        </thead>
        <tbody>
          
          {shorlistView.map((row: any, index: number) => (
            <tr key={index} className={index % 2 !== 0 ? "bg-[#F8FBFF]" : "bg-[#FFFFFF]"}>
                <td className="w-[50px] py-3 text-center">
                { index + 1}
              </td>
              
              <td className="w-[50px] py-3 text-center">
                {row.student_name}
              </td>
              <td className="w-[50px] py-3 text-center">
                {row.student_mobile}
              </td>
              <td className="w-[50px] py-3 text-center">
                {row.student_email}
              </td>
              <td className="w-[50px] py-3 text-center">
                {row.course_name}
              </td>
             
            </tr>
          ))}
          
        </tbody>
      </table>
    )}
    <form onSubmit={handleSubmit} >
        {isLoading ? (
          <Loader widthFix={"w-11/12"} />
        ) : (
          <>
            
              {/* <div className="text-left border-b-2 p-2">
                <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
                  {id === "new-branch" ? "Job Info" : "Job Info"}
                </p>
              </div> */}
              <div className="w-10/12 mx-auto  gap-12">  
               
              </div>
              <div className="w-10/12 mx-auto pt-3 gap-12 text-center">
                <SubmitButton />
               
              </div>
           
              
            
          </>
        )}
      </form>
  </div>
  </div>
  </div>
  )
}