import { useState, useEffect } from "react";
import { InputBox } from "../Elements/InputBox";
import { DropdownSingleInputWithCheckbox } from "../Elements/DropdownSingleInputWithCheckbox";
import { AddItemsButton } from "../Elements/AddItemsButton";
import { FormikCommonFunc } from "../CommonFunctions/CommonFunc";
import { bulkSessionUpdate } from "../Formik/InitialValues";
import { BulkSessionUpdate } from "../Formik/FormikValidation";
import { dateFormat } from "../../Services/CommonFunctions";
import { Time } from "../Batch Management/Create Batch/Time";
import { mapFunction } from "../../Services/CommonFunctions";
import { getStudentsByBatchid } from "../../functions/admin/Course Progress/courseProgress";
import selectedCheckbox from "../../Assets/selectedCheckbox.svg";
import emptyCheckbox from "../../Assets/emptyCheckbox.svg";
import { onKeyDown } from "../../Services/CommonFunctions";
import { Loader } from "../Elements/Loader/Loader";
import _ from "lodash";
import { createSession } from "../../functions/admin/Course Progress/courseProgress";
import toast from "react-hot-toast";
import { useSharedLogic } from "../CommonFunctions/CommonFunc";
import { toggleBulkUpdate } from "../../Redux/Reducers/courseProgressModal";
import { useAppSelector, useAppDispatch } from "../..";

export const CourseProgressSessionModal = ({ selectedPeople }: any) => {
  const { navigate, routeNameForHome }: any = useSharedLogic();
  const dispatch = useAppDispatch();
  const today: any = new Date();
  const maxDate = dateFormat(today, "YYYY-MM-DD");
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const LoginUserNames = signInData && signInData?.data[0]?.roles;
  const [mappedData, setMappedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [storeObject, setStoreObject] = useState([] as any);
  const data = Array.from(selectedPeople);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = Array.from(selectedPeople);
      const mapData = async () => {
        const mappedData = await Promise.all(
          data?.map(async (dt: any) => {
            const student_list = await getStudentsByBatchid(dt?.id);
            return {
              batch_id: dt?.id,
              trainer_id: dt?.trainer?.uid,
              course_name: dt?.course_name,
              batch_code: dt?.batch_code,
              mode: dt?.mode,
              student_list: student_list,
            };
          }) || []
        );
        return mappedData;
      };

      const result: any = await mapData();
      setMappedData(result);
      setIsLoading(false);
    };

    getData();
  }, []);

  const submitFunction = async () => {
    setIsLoading(true);
    const sessionArray = Object.values(storeObject);
    const filterAttendeesPresent: any =
      sessionArray?.length &&
      Object.values(sessionArray)?.filter(
        (fil: any) => fil?.attendees.length > 0
      );
    console.log(filterAttendeesPresent, "filterAttendeesPresent");
    let allSessionsSuccessful = true;

    if (filterAttendeesPresent.length) {
        for (const session of filterAttendeesPresent) {
            const response = await createSession(session);
            if (!response) {
                allSessionsSuccessful = false;
                break;
            }
        }

        if (allSessionsSuccessful) {
            dispatch(toggleBulkUpdate(false))
        } else toast.error('Something went wrong')
    } else toast.error('Please select atleast one student')
    setIsLoading(false);
  };

  const {
    formik,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  }: any = FormikCommonFunc(
    bulkSessionUpdate,
    BulkSessionUpdate,
    submitFunction
  );

  const handleSessionDate = async (
    index: number,
    value: string,
    check: string
  ) => {
    formik.setFieldValue(check, value);
  };

  return (
    <div className="w-[80%] py-4 flex flex-col justify-center">
      {isLoading ? (
        <Loader widthFix={"w-full"} heightIfGiven={"h-screen"} />
      ) : (
        <div className="space-y-6 w-[100%] py-4">
          <h1 className="text-start text-[24px] font-Roboto font-[500] text-blue-800">
            Add Session
          </h1>
          <form
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
            className="space-y-6 w-[95%]"
          >
            <div className="flex items-center gap-12">
              <InputBox
                label="Session Date"
                placeholder="Session Date"
                type={"date"}
                topDivStyle={"flex flex-col w-1/2"}
                classNameInput={"w-full"}
                name={"session_date"}
                value={formik.values.session_date}
                onChange={handleSessionDate}
                onBlur={formik.handleBlur}
                error={formik.errors.session_date}
                isTouched={formik.touched.session_date}
                // min={
                //   LoginUserNames.includes("Trainer") ||
                //   LoginUserNames.includes("trainer")
                //     ? minDate
                //     : startDate
                // }
                max={maxDate}
                index={0}
                check={"session_date"}
              />
              <DropdownSingleInputWithCheckbox
                topDivStyle={"flex flex-col w-full"}
                classNameInput={"w-full"}
                label="Start Time"
                placeholder="Start Time"
                options={
                  Time.includes(formik.values.end_time)
                    ? mapFunction(Time, formik.values.end_time)
                    : Time
                }
                name={"start_time"}
                value={formik.values.start_time}
                onBlur={formik.handleBlur}
                error={formik.errors.start_time}
                isTouched={formik.touched.start_time}
                mapThrough={"start_time"}
                formik={formik}
                disabled={false}
                zIndex={"z-30"}
              />
              <DropdownSingleInputWithCheckbox
                topDivStyle={"flex flex-col w-full"}
                classNameInput={"w-full"}
                label="End Time"
                placeholder="End Time"
                options={
                  Time?.includes(formik.values.start_time)
                    ? mapFunction(Time, formik.values.start_time)
                    : Time
                }
                name={"end_time"}
                value={formik.values.end_time}
                onBlur={formik.handleBlur}
                error={formik.errors.end_time}
                isTouched={formik.touched.end_time}
                mapThrough={"end_time"}
                formik={formik}
                disabled={false}
                zIndex={"z-30"}
              />
            </div>
            <div className="rounded-[17px] shadow-md shadow-[#00000029] py-4 pb-10">
              <div className="w-11/12 mx-auto space-y-6">
                <InputBox
                  label="Topic"
                  placeholder="Topic"
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={"details.topic"}
                  value={formik.values.details?.topic}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.details?.topic}
                  isTouched={formik.touched.details?.topic}
                />
                <SubTopics formik={formik} />
                <StudentDetails
                  formik={formik}
                  mappedData={mappedData}
                  storeObject={storeObject}
                  setStoreObject={setStoreObject}
                />
                <div className="flex justify-center items-center pt-4 w-4/12 mx-auto">
                  <button
                    type="submit"
                    className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-6 font-Roboto font-[400]"
                  >
                    Create Session
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const SubTopics = ({ formik }: any) => {
  const firstObject: any = formik.values?.details?.subtopics?.[0];

  useEffect(() => {
    formik.setFieldValue("title", formik.values.details?.topic);
  }, [formik.values.details?.topic]);

  const AddSubTopic = () => {
    const add = [...formik.values.details?.subtopics, ""];
    formik.setFieldValue("details.subtopics", add);
  };

  const deleteSubTopic = (index: number) => {
    const newArray = [...formik.values.details?.subtopics];
    newArray.splice(index, 1);
    formik.setFieldValue("details.subtopics", newArray);
  };

  const handleChangeForSubTopics = (
    index: number,
    value: string,
    data: any
  ) => {
    formik.setFieldValue(`details.subtopics[${index}]`, value);
  };

  return (
    <div>
      <div className="flex items-center justify-between pb-2">
        <h1 className="text-[#707070] text-[20px] font-Roboto font-[500]">
          Sub Topics (Optional)
        </h1>
        <AddItemsButton
          text="Add Sub Topic"
          onClick={AddSubTopic}
          disabled={false}
        />
      </div>
      <div className="space-y-2">
        <InputBox
          placeholder="Topic 1"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"details.subtopics"}
          value={firstObject}
          onChange={handleChangeForSubTopics}
          onBlur={formik.handleBlur}
          index={0}
          check={"details.subtopics"}
        />
        {formik.values.details?.subtopics.map((sub: any, index: number) => {
          if (index !== 0) {
            return (
              <div className="flex items-center relative">
                <InputBox
                  placeholder={`Topic ${[index + 1]}`}
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={`details.subtopics`}
                  value={sub}
                  onChange={handleChangeForSubTopics}
                  onBlur={formik.handleBlur}
                  index={`${index}`}
                  check={"details.subtopics"}
                />
                <div
                  onClick={() => deleteSubTopic(index)}
                  className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-7 bottom-2"
                >
                  x
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export const StudentDetails = ({
  formik,
  mappedData,
  storeObject,
  setStoreObject,
}: any) => {
  const [checked, setChecked] = useState();
  const bodyTextStyle = `w-[30%] text-[#686868] text-center text-[14px] font-Roboto font-[400]`;
  const Header = ["", "Name", "Phone Number", "Status"];
  console.log(storeObject, "storeObject");

  useEffect(() => {
    const newArray = mappedData?.map((dt: any) => ({
      title: dt?.course_name,
      trainer_id: dt?.trainer_id,
      start_time: formik.values.start_time,
      end_time: formik.values.end_time,
      details: {
        topic: formik.values.details?.topic,
        subtopics: formik.values.details?.subtopics,
      },
      session_date: formik.values.session_date,
      attendees: [],
      batch_id: dt?.batch_id,
      session_status: "Success",
    }));
    setStoreObject(newArray ? newArray : []);
  }, [formik.values]);

  const checkStatus = (body: any, uid: string, indexValue: number) => {
    if (indexValue < 0 || indexValue >= storeObject.length) {
      console.error(`Invalid index: ${indexValue}. Session object not found.`);
      return;
    }

    const updatedSession = { ...storeObject[indexValue] };

    if (!updatedSession.attendees.includes(uid)) {
      updatedSession.attendees.push(uid);
    } else {
      const attendeeIndex = updatedSession.attendees.indexOf(uid);
      updatedSession.attendees.splice(attendeeIndex, 1);
    }

    setStoreObject((prevState: any) => ({
      ...prevState,
      [indexValue]: updatedSession,
    }));
  };

  return (
    <div className="space-y-4">
      <h1 className="text-[#000000] text-[18px] 2xl:text-[20px] font-Roboto font-[500]">
        Student Details
      </h1>
      <div className="space-y-4">
        {mappedData?.map((body: any, indexValue: number) => (
          <div className="shadow-md shadow-[#00000029] rounded-[7px] py-3">
            <div className="flex justify-between px-2 pb-4">
              <div className="flex gap-1">
                <h1 className="text-[18px] text-blue-600">
                  {body?.course_name}
                </h1>
                <p className="text-[18px] text-green-500">{`(${body?.batch_code})`}</p>
              </div>
              <p className="text-[18px]">
                Mode:
                <span className="text-[18px] text-gray-500 px-1">
                  {body?.mode !== null ? body?.mode : "-"}
                </span>
              </p>
            </div>
            <div className="flex items-center justify-between bg-[#EFF7FF] py-3">
              {Header.map((head: any, i: number) => (
                <p
                  className={`text-[#686868] text-[16px] font-Roboto font-[500] text-center ${
                    i === 0 ? "w-[10%]" : "w-[30%]"
                  }`}
                >
                  {head}
                </p>
              ))}
            </div>
            {body?.student_list?.map((list: any, index: number) => (
              <div
                key={index}
                className={`flex items-center justify-between py-3 ${
                  index % 2 !== 0 && "bg-[#EFF7FF]"
                }`}
              >
                <div className="w-[10%] flex items-center justify-center">
                  <img
                    src={
                      storeObject[indexValue]?.attendees?.includes(
                        list?.student?.uid
                      )
                        ? selectedCheckbox
                        : emptyCheckbox
                    }
                    alt="CheckType"
                    className={`w-4 h-4 cursor-pointer`}
                    onClick={() =>
                      checkStatus(body, list?.student?.uid, indexValue)
                    }
                  />
                </div>
                <p className={bodyTextStyle}>{list?.student?.name}</p>
                <p className={bodyTextStyle}>{list?.student?.phoneNumber}</p>
                <p className={bodyTextStyle}>{list.status}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
