import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const routeNameForHome = params.dashboardScreen;
  const routeNameForSubSection = params.subsection;
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigateToViewPage = () => {
    setSearchParams({
      id: `${recordData.id}`,
      mode: `view`,
    });
  };
    
  return (
    <div
      className="flex items-center justify-center text-center space-x-4"
      onClick={navigateToViewPage}
    >
      {allowOnlyActions("view") && (
        <div className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]">
          <img
            src={tableViewEyeIcon}
            alt="viewIcon"
            className="w-[21px] h-[10px]"
          />
        </div>
      )}
    </div>
  );
};