import { useState, useEffect } from 'react';
import { CardLayoutThree } from '../../Elements/Cards/CardLayoutThree';
import { ReadData } from '../../Elements/ReadData';
import { Loader } from '../../Elements/Loader/Loader';
import { SessionDetails } from './SessionDetails';
import { TrainerDetails } from './TrainerDetails';
import { batchProgressById, getSessionsAndTrainersByBatchId, getStudentsOfSingleSession } from '../../../functions/admin/Course Progress/courseProgress';
import { basicDetails, Trainer } from './JSON';
import { Progressbar } from '../../Elements/Progressbar';
import {
  createSearchParams,
} from "react-router-dom";
import { AddItemsButton } from '../../Elements/AddItemsButton';
import { useSharedLogic, FormikCommonFunc } from '../../CommonFunctions/CommonFunc';
import { BasicInfo } from './BasicInfo';

export const Components = {
    useState, useEffect, CardLayoutThree, ReadData, Loader, SessionDetails, TrainerDetails, batchProgressById, getSessionsAndTrainersByBatchId, getStudentsOfSingleSession, basicDetails, Trainer, Progressbar, createSearchParams, AddItemsButton, useSharedLogic, FormikCommonFunc, BasicInfo 
}