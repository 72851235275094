import { getImports } from "./ModuleImports";
import { PhoneNumberInput } from "./PhoneNumberInput";

export const BasicInfo = ({
  formik,
  courseData,
  courseNames,
  mode,
  handleOutForCourse,
}: any) => {
  const { InputBox, DropdownInputWithCheckbox }: any = getImports;

  const handleSelectChange = (value:any) => {
    formik.setFieldValue("number_code", value)
  }

  return (
    <div className="space-y-2">
      <h1 className="text-[22px] font-Roboto font-[500]">Basic Info</h1>
      <div className="grid grid-cols-2 gap-4">
        {/* <InputBox
          label="Phone number"
          placeholder="Phone number"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"phone_number"}
          type={"number"}
          inputMode="numeric"
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.phone_number}
          isTouched={formik.touched.phone_number}
          disabled={mode === "view"}
        /> */}
        <PhoneNumberInput formik={formik} handleSelectChange={handleSelectChange} />
        <InputBox
          label="Student Name"
          placeholder="Student Name"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"student_name"}
          value={formik.values.student_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.student_name}
          isTouched={formik.touched.student_name}
          disabled={mode === "view"}
        />
        <InputBox
          label="Email"
          placeholder="Email"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          isTouched={formik.touched.email}
          disabled={mode === "view"}
        />
        <DropdownInputWithCheckbox
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          label="Course"
          placeholder="Course"
          options={courseNames}
          name={"course_name"}
          forId={"course_id"}
          value={formik.values.course_name}
          handleOut={handleOutForCourse}
          onBlur={formik.handleBlur}
          error={formik.errors?.course_name}
          isTouched={formik.touched?.course_name}
          mapData={courseData}
          mapThrough={"course_name"}
          formik={formik}
          disabled={((mode === "view")||(mode === "edit"))}
          zIndex={"z-20"}
        />
      </div>
    </div>
  );
};
