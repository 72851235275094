import { Components } from "./ModuleImports";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { StudentDetailsForTrainerPayment } from "./StudentDetailsForTrainerPayment";
import { getStudentBasedOnTrainerAndSession } from "../../../functions/admin/Batches/Batches";

export const TrainerDetails = ({
  viewStudent,
  ViewStudentDetails,
  trainerData,
  id,
  setSwitchComp,
  handleSelectChange,
  preview,
  trainerBasedStudents,
  setTrainerBasedStudents,
  setIsModalOpen,
  newTrainerPayment,
  setNewTrainerPayment,
  total_trainer_payment_comment
}: any) => {
  const { ReadData }: any = Components;
  const { routePath }: any = useSharedLogic();
  return (
    <div className="space-y-2 w-11/12 mx-auto py-6">
      <h1 className="text-[#000000] text-[20px] xl:text-[22px] font-Roboto font-[500]">
        Trainer Details
      </h1>
      <div className="space-y-3">
        {!trainerData?.length && (
          <div className="min-h-[100px] flex items-center justify-center text-red-600">
            No Trainers
          </div>
        )}
        {trainerData?.map((data: any, index: number) => {
          return (
            <div
              className={`${index === 0 ? `pt-8 xl:pt-10` : `py-8 xl:py-10`}`}
              key={index}
            >
              <div className="space-y-2">
                <h1
                  className={`${
                    data?.current_trainer ? "text-green-800" : "text-[#E93C00]"
                  } text-[18px] xl:text-[20px] font-Roboto font-[500]`}
                >
                  {data?.current_trainer ? "Current Trainer" : "Past Trainer"}
                </h1>
                <div className="shadow-md shadow-[#00000029] rounded-[7px] flex items-center justify-between w-[97%] p-3 px-4">
                  <ReadData
                    head={"Trainer Name"}
                    body={`${data?.trainer?.first_name} ${data?.trainer?.last_name}`}
                  />
                  <ReadData
                    head={"Trainer Phone Number"}
                    body={data?.trainer?.phone_number}
                  />
                  <ReadData
                    head={"Trainer Email"}
                    body={data?.trainer?.email}
                  />
                  {!routePath && (
                    <button
                      className={`${
                        viewStudent.includes(data?.trainer?.uid)
                          ? "bg-[#505050]"
                          : "bg-[#E92900]"
                      } text-white font-Roboto font-[400] text-[16px] p-0.5 px-2.5 rounded-[3px] cursor-pointer`}
                      onClick={() =>
                        ViewStudentDetails(index, data?.trainer?.uid)
                      }
                    >
                      View Student
                    </button>
                  )}
                </div>
              </div>
              {routePath &&
                (routePath === "cal_trainer_pay" ||
                  routePath === "process_trainer_pay") && (
                  <StudentDetailsForTrainerPayment
                    id={id}
                    setSwitchComp={setSwitchComp}
                    handleSelectChange={handleSelectChange}
                    preview={preview}
                    uid={data?.trainer?.uid}
                    index={index}
                    trainerBasedStudents={trainerBasedStudents}
                    setTrainerBasedStudents={setTrainerBasedStudents}
                    setIsModalOpen={setIsModalOpen}
                    newTrainerPayment={newTrainerPayment}
                    setNewTrainerPayment={setNewTrainerPayment}
                    total_trainer_payment_comment={total_trainer_payment_comment}
                  />
                )}
            </div>
          );
        })}
      </div>
      {/* )} */}
    </div>
  );
};
