import { dateFormat } from "../../../../Services/CommonFunctions";

export const BuildJson = (sessionData: any) => {
    const Field: any = [
      {
        head: "Created Date",
        body: dateFormat(sessionData?.created_at, "DD MMM YYYY"),
      },
      {
        head: "Student Name",
        body: sessionData?.training?.student?.name,
      },
      {
        head: "Student Phone Number",
        body: sessionData?.training?.student?.phoneNumber,
      },
      {
        head: "Email",
        body: sessionData?.training?.student?.email,
      },
      {
        head: "Branch & City",
        body: `${sessionData?.training?.branch?.branch_name}, ${sessionData?.training?.city?.city_name}`,
      },
      {
        head: "Schedule",
        body: sessionData?.training?.batch?.schedule,
      },
      {
        head: "Age",
        body: `${sessionData?.age && sessionData?.age>1?sessionData?.age?.toString().concat(' ', 'Days'):sessionData?.age?.toString().concat(' ', 'Day')}`,
      },
    ];
    return Field;
}
