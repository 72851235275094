import { useState, useEffect } from "react";
import boldArrow from "../../Assets/boldArrow.svg";

export const DropDownInputStatusBox = ({
  mapData,
  showData,
  setShowData,
  mapThrough,
  label,
  topDivStyle,
  classNameInput,
  placeholder,
  mandatory,
  mandatoryClass,
  dataSetup,
  name,
  value,
  zIndex,
  formik,
  ...rest
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSection, setFilterSection] = useState([] as any);

  useEffect(() => {
    setFilterSection(mapData);
  }, [mapData]);

  const check_newCity = (e: any) => {
    setSearchTerm(e);
    const filterOptions: any =
      mapData &&
      mapData.filter((option: any) =>
        option.toLowerCase().includes(e.toLowerCase())
      );
    if (filterOptions.length === 0) {
      if (label === "City:") formik.setFieldValue("city_name", e);
      setIsOpen(false);
    } else if (filterOptions.length > 0) {
      if (label === "City:") formik.setFieldValue("city_name", "");
      setFilterSection(filterOptions);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeByClickingOutside);
    return () => {
      document.removeEventListener("click", closeByClickingOutside);
    };
  }, []);

  const closeByClickingOutside = (event: any) => {
    if (
      (event?.target?.localName === "div" &&
        event?.target?.dataset?.elementname !== "close") ||
      (event?.target?.localName === "input" && event?.target?.id !== "close") ||
      (event?.target?.localName === "p" && event?.target?.id !== "close")
    ) {
      setIsOpen(false);
    }
  };

  return (
    <div className={`${topDivStyle} space-y-0.5`}>
      <label className="text-[#707070] 2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500]">
        {label} {mandatory && <span className={`${mandatoryClass}`}>*</span>}
      </label>
      <div
        className={`relative ${zIndex ? zIndex : "z-10"} cursor-pointer`}
        data-elementname="close"
        onClick={() => setIsOpen(!isOpen)}
      >
        <input
          //   {...rest}
          name={name}
          //   value={value}
          //   onChange={(e: any) => {
          //     onChange(name, e.target.value);
          //   }}
          value={showData ? showData : searchTerm}
          onChange={(e) => check_newCity(e.target.value)}
          onClick={() => setShowData(null)}
          placeholder={placeholder}
          className={`capitalize outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 ${classNameInput}`}
        />
        <img
          src={boldArrow}
          alt="boldArrow"
          className={`absolute top-5 right-4 ${isOpen && "rotate-180"}`}
        />
        {isOpen && (
          <div
            className={`z-20 end-1.5 w-11/12 shadow-md shadow-[#00000029] absolute bg-white rounded-[3px] outline-none border-[0.5px] border-[#707070] pt-2 min-h-[100px] max-h-[260px]`}
          >
            {filterSection &&
              filterSection.map((data: any, i: number) => (
                <p
                  key={i}
                  {...rest}
                  name={name}
                  value={value}
                  onClick={() => {
                    dataSetup(name, data, mapThrough);
                    setSearchTerm("");
                    // setIsOpen(false);
                  }}
                  className={`2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto px-2 py-1 ${classNameInput}`}
                >
                  {data}
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
