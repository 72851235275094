// import { ViewEditDeleteActionComponent } from "../../Components/AppEnquiries/ActionComponent";
import { getAppEnquiriesTableData } from "../../functions/admin/App Enquiries/app_enquiries";
import AppEnquiriesStatus from "../../Components/AppEnquiries/AppEnquiriesStatus";
export const appEnquiries = {
  headers: [
    {
      name: "Date",
      apiKey: "date",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center",
    },
    {
      name: "Name",
      apiKey: "student_name",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center break-words",
    },
    {
      name: "Phone Number",
      apiKey: "student_mobile",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center break-words",
    },
    {
      name: "Student Email",
      apiKey: "student_email",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center break-words",
    },
    {
      name: "Course",
      apiKey: "course_name",
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center",
    },
    {
      name: "Status",
      apiKey: "status",
      Comp: AppEnquiriesStatus,
      isComponent: true,
      headerClass: "w-[120px] text-center",
      className: "w-[120px] text-center",
    }
  ],
   BackendFunctionCall: getAppEnquiriesTableData,
  // bodyData: [
  //   {
  //    id:1,
  //    date:"03 Dec 2022",
  //    name:"Anu Radha",
  //    phone_number:"9876543210",
  //    student_email:"user1234567 @gmail.com",
  //    Course:"Java Level 1 Certification",
  //    status:""
  //   },
  //   {
  //     id:2,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:3,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:4,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:5,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:6,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:7,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:8,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:9,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    },
  //    {
  //     id:10,
  //     date:"03 Dec 2022",
  //     name:"Anu Radha",
  //     phone_number:"9876543210",
  //     student_email:"user1234567 @gmail.com",
  //     Course:"Java Level 1 Certification",
  //     status:""
  //    }
  // ],
  filters: {
    dateFilters: [
      {
          name: "Date",
          apiKey: "date",
          isSearchNeeded: true,
          className: "w-[180px] ",
      },
  ],
    checkBox: [
      // {
      //   name: "Date",
      //   apiKey: "date",
      //   isSearchNeeded: true,
      //   className: "w-[170px]",
      // },
      {
        name: "Course",
        apiKey: "course_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Status",
        apiKey: "status",
        isSearchNeeded: true,
        className: "w-[190px]",
      },

    ],
    searchBox: [

      {
          matchFields: ["student_name", "student_email", "student_mobile"],
          searchKey: "placedname",
          name: "Search...",
          searchStyle: "w-[200px]",
      }
  ]
  },
  // ActionComponent: ViewEditDeleteActionComponent,
};