import React from 'react';
import { useSharedLogic } from '../CommonFunctions/CommonFunc';
import { useParams } from 'react-router-dom';
import { Subsection } from '../SubSections/Subsection';

export const Reports = () => {
    const params = useParams();
    const routeName: any = params.subsection;
    const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  return (
    <div>
        <Subsection routeName={routeName} />
    </div>
  )
}
