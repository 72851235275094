import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  const navigateToViewPage = () => {
    setSearchParams({
      id: `${recordData.id}`,
      mode: `view`,
    });
  };

  const navigateToEditPage = () => {
    setSearchParams({
      id: `${recordData.id}`,
      mode: `edit`,
    });
  };

  const navigateToViewApplicatPage = () => {
    setSearchParams({
      id: `${recordData.id}`,
      mode: `viewApplicant`,
    });
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
     {allowOnlyActions('view') &&  <div
        onClick={navigateToViewPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableViewEyeIcon}
          alt="viewIcon"
          className="w-[21px] h-[10px]"
        />
      </div> }
      {allowOnlyActions('edit') && 
      <div
        onClick={navigateToEditPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img src={tableEditIcon} alt="editIcon" className="w-[14px] h-[14px]" />
      </div> }
      {allowOnlyActions('view') &&  <div>
        <p  onClick={navigateToViewApplicatPage} className="text-[#036AD1] cursor-pointer underline underline-offset-4">View Applicants</p>
      </div> }
    </div>
  );
};