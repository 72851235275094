import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchData, InsertData, UpdateData } from "../../crud";
import { ResourceCategoryInterface } from "./interface";

export const getResourceCategoryTableData = async () => {
    try {
        const { data: resource_category, error } = await FetchData(TableNames.resource_category, ["id" ,"category_name", "thumbnail", "order"]).order("order", { ascending: true })
        if (error) throw error; 
        return resource_category
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const getResourceCategoryById = async (categoryId: number | string) => {
    try {
        const { data: resource_category, error } = await FetchData(TableNames.resource_category).eq("id", categoryId)
        if (error) throw error; 
        return resource_category[0]
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const updateResourceCategory = async (categoryData: Partial<ResourceCategoryInterface>, categoryId: number | string) => {
    try {
        const { data, error } = await UpdateData(TableNames.resource_category, categoryData, {conditionKey: "id", conditionValue: categoryId})
        if (error) throw error; 
        return true
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const createResourceCategory = async (categoryData: ResourceCategoryInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.resource_category, categoryData)
        if (error) throw error; 
        return data
    } catch (error) {
        return errorNotifier(error); 
    }
}