import { useState, useEffect } from "react";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { dateFormat } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";

import { InformationLogsViewCard } from "./InformationLogsViewCard";
import { getUserLogs } from "../../../functions/admin/User Logs/userLogs";

export const ViewLogsInfo = ({id}:any) => {
  const [user, setUser] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

        const buildJSON = [
          {
            left: [
              {
                head: "Admin Name",
                body: user && user?.admin_name,
              },
              {
                head: "Module",
                body: user && user?.module,
              },
            ],
            right: [
              {
                head: "Changes",
                body: user && user?.changes,
              },
            
            ],
            center:[
              {
                  head: "Branch & City",
                  body: user && user?.branch?.join(', '),
                },
                {
                  head: "Date",
                  body: user && dateFormat(user?.created_at, "DD MMM YYYY"),
                },
            ]
            
          },
        ];
    
        useEffect(() => {
            (async () => {
            setIsLoading(true)
            const LogViewsResponse: any = await getUserLogs();
            const LogsViews:any = 
            LogViewsResponse.filter((data: any) => data.id === parseInt(id));
            setUser(LogsViews[0]);
            setIsLoading(false)
          })();
        }, []);

    return (
      <>
        {isLoading ? (
          <Loader widthFix={"w-9/12"} />
        ) : (
          <CardLayoutTwo>
            <InformationLogsViewCard
              cardHeading={"Logs Information"}
              values={user}
              buildJSON={buildJSON}
            />
          </CardLayoutTwo>
        )}
      </>
    );
}