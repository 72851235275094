import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { DropdownSingleInputWithCheckbox } from "../../../Elements/DropdownSingleInputWithCheckbox";
import { performActionData } from "../../../Formik/InitialValues";
import { AddPerformAction } from "../../../Formik/FormikValidation";
import { InputBox } from "../../../Elements/InputBox";
import { SubmitButton } from "../../../Elements/SubmitButton";
import { onKeyDown } from "../../../../Services/CommonFunctions";
import {
  createApprovalRequest,
  updateApprovalStatus,
  getCourseBasedOnTechnology,
} from "../../../../functions/admin/Action center/Student Actions/studentActions";
import { useSharedLogic } from "../../../CommonFunctions/CommonFunc";
import { CourseDetails } from "./CourseDetails";
import { DropdownInputWithCheckbox } from "../../../Elements/DropdownInputWithCheckbox";
import { Checkbox } from "../../../Elements/Checkbox";
import { useAppSelector } from "../../../..";

export const PerformAction = ({
  id,
  approval_data,
  updatedStudentData,
  paymentDetails,
  setIsLoading,
  paymentObject,
  branchDetails,
}: any) => {
  const { navigate, routePath }: any = useSharedLogic();
  const [feeTotal, setFeeTotal] = useState(null);
  const [courseData, setCourseData] = useState([] as any);
  const [courseName, setCourseName] = useState([] as any);
  const [checked, setChecked] = useState("Accept");
  const [approval_id, setApproval_Id] = useState(null as any);
  const [approvalStatus, setApprovalStatus] = useState("");
  const StudentStatus = [
    "Drop",
    "Hold",
    "Upgrade",
    "Downgrade",
    "Nullify Payment",
  ];
  const nullPayment = ["Bad Debt", "Discount"];
  const { userDetails } = useAppSelector(({ login }: any) => login);

  const totalPaidAmount = paymentDetails?.reduce((acc:any, transaction:any) => {
    return acc + parseFloat(transaction.paid_amount);
  }, 0);
  // console.log(paymentDetails, "paymentDetails");

  useEffect(() => {
    (async () => {
      if (Object.keys(updatedStudentData).length) {
        const courseResponse: any = await getCourseBasedOnTechnology(
          updatedStudentData?.course_id?.id,
          updatedStudentData?.course_id?.category?.category_name,
          updatedStudentData?.branch_id?.id
        );
        if ((approval_data?.length > 0) && routePath && routePath === "student_approval_queue") {
          const data = approval_data[0];
          const mapCourses = courseResponse?.filter(
            (fil: any) => fil?.id === data?.upgraded_or_downgraded_course_id
          );
          setApproval_Id(data?.id);
          setApprovalStatus(data?.approvalStatus);
          setValues(
            {
              ...values,
              status: data?.status,
              ...((data?.status === "Drop" || data?.status === "Hold") && {
                reason: data?.reason,
              }),
              ...(data?.status === "Nullify Payment" && {
                nullType: data?.nullify_payment_type,
              }),
              ...(data?.status === "Nullify Payment" && {
                nullReason: data?.reason,
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                course_ID: data?.upgraded_or_downgraded_course_id,
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                additional_amount: data?.additional_amount,
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                additional_duration: data?.additional_duration,
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                course: mapCourses?.[0]?.course_name,
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                course_ID: mapCourses?.[0]?.id
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                fee: data?.fee,
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                duration: updatedStudentData?.duration,
              }),
              ...((data?.status === "Upgrade" ||
                data?.status === "Downgrade") && {
                existing_action: data?.existing_action,
              }),
            },
            true
          );
        }
        setCourseData(courseResponse);
        const mapCourseData = courseResponse?.map(
          (course: any) => course?.course_name
        );
        setCourseName(mapCourseData);
      }
    })();
  }, [approval_data?.length>0]);

  const submitData = async () => {
    setIsLoading(true);
    const {
      status,
      reason,
      nullType,
      nullReason,
      course,
      course_ID,
      additional_duration,
      additional_amount,
      total_fee,
      duration,
      fee,
      existing_action,
    }: any = values;
    const body: any = {
      status: status,
      ...((status === "Drop" || status === "Hold") && { reason: reason }),
      ...(status === "Nullify Payment"
        ? { nullify_payment_type: nullType }
        : { nullify_payment_type: null }),
      ...(status === "Nullify Payment" && { reason: nullReason }),
      ...(status === "Upgrade" || status === "Downgrade"
        ? {
            upgraded_or_downgraded_course_id: course_ID,
          }
        : { upgraded_or_downgraded_course_id: null }),
      ...(status === "Upgrade" || status === "Downgrade"
        ? {
            additional_duration: additional_duration,
          }
        : { additional_duration: 0 }),
      ...(status === "Upgrade" || status === "Downgrade"
        ? {
            additional_amount: additional_amount,
          }
        : { additional_amount: 0 }),
      ...(status === "Upgrade" || status === "Downgrade"
        ? { fee: Number(fee) }
        : { fee: null }),
      ...(status === "Upgrade" || status === "Downgrade"
        ? {
            total_fee: parseInt(total_fee?.replace(/,/g, "")),
          }
        : { total_fee: null }),
      ...(status === "Upgrade" || status === "Downgrade"
        ? {
            duration:
              status === "Upgrade"
                ? Number(duration) + Number(additional_duration)
                : Number(duration) - Number(additional_duration),
          }
        : { duration: null }),
      training_id: Number(id),
      approvalStatus:
        routePath && routePath === "student_approval_queue"
          ? checked.toLowerCase()
          : null,
      user_id: userDetails,
      approved_date:
        routePath && routePath === "student_approval_queue" ? new Date() : null,
      latest_training:
        routePath && routePath === "student_approval_queue" ? false : true,
      ...(status === "Upgrade" || status === "Downgrade" ? {balance_due:
        Number(total_fee.replace(/[^0-9\.]/g, "")) >= totalPaidAmount
          ? Number(total_fee.replace(/[^0-9\.]/g, "")) - totalPaidAmount
          : 0} : {balance_due: 0}),
      ...(status === "Upgrade" || status === "Downgrade" ? {payment_details: paymentDetails?.reduce((acc: any[], transaction: any, index: number) => {
        let newTransaction;
        if (index === 0) {
          newTransaction = {
            ...transaction,
            balance: Number(total_fee.replace(/[^0-9\.]/g, ""))
              ? Number(total_fee.replace(/[^0-9\.]/g, "")) - Number(transaction?.paid_amount || 0)
              : Number(transaction?.balance || 0),
          };
        } else {
          const previousBalance = acc[index - 1].balance;
          newTransaction = {
            ...transaction,
            balance: Number(previousBalance || 0) - Number(transaction?.paid_amount || 0),
          };
        }
        acc.push(newTransaction);
        return acc;
      }, [])} : {payment_details: []})
    };
    // console.log(body, "body", total_fee, "total_fee")
    const response = await (routePath && routePath === "student_actions"
        ? createApprovalRequest(body)
      : updateApprovalStatus(body, approval_id));
    if (response) {
      navigate({
        pathname: `/action_center/${
          routePath && routePath === "student_actions"
            ? "student_actions"
            : "student_actions_approval_queue"
        }`,
      });
    } else console.log("false");
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: performActionData,
    validationSchema: () => AddPerformAction(values.status),
    onSubmit: () => {
      submitData();
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  } = formik;

  useEffect(() => {
    (async () => {
      if (
        (values.status === "Upgrade" || values.status === "Downgrade") && !approval_data?.length
      ) {
        setFieldValue("course", "");
        setFieldValue("course_ID", null);
        setFieldValue("duration", null);
        setFieldValue("additional_duration", null);
        setFieldValue("fee", null);
        setFieldValue("additional_amount", null);
        const courseResponse: any = await getCourseBasedOnTechnology(
          updatedStudentData?.course_id?.id,
          updatedStudentData?.course_id?.category?.category_name,
          updatedStudentData?.branch_id?.id
        );
        if (courseResponse) {
          setCourseData(courseResponse);
          const mapCourseData = courseResponse?.map(
            (course: any) => course?.course_name
          );
          setCourseName(mapCourseData);
        }
      }
    })();
  }, [values.status]);

  useEffect(() => {
    if ((!approval_data?.length)&&(values.course!=="")) {
      setFieldValue("fee", updatedStudentData?.course_fee);
      setFieldValue("duration", updatedStudentData?.duration);
    }
  }, [values.course]);

  const CheckValue = (label: any) => {
    setChecked(label);
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={onKeyDown}
      className="w-11/12 mx-auto py-10 flex flex-col items-center space-y-10"
    >
      <div className="flex flex-col items-end justify-between w-full space-y-10">
        <div className="flex items-end justify-between w-full">
          <DropdownSingleInputWithCheckbox
            topDivStyle={"flex flex-col w-[47%]"}
            classNameInput={"w-full"}
            label="Perform Action"
            placeholder="Perform Action"
            options={StudentStatus}
            name={"status"}
            value={values.status}
            onBlur={handleBlur}
            error={errors.status}
            isTouched={touched.status}
            mapThrough={"status"}
            formik={formik}
            zIndex={"z-20"}
            disabled={
              (routePath && routePath === "student_approval_queue")
            }
          />
          {(values.status === "Upgrade" || values.status === "Downgrade") && (
            <DropdownInputWithCheckbox
              topDivStyle={"flex flex-col w-[47%]"}
              classNameInput={"w-full"}
              label="Course"
              placeholder="Course"
              options={courseName}
              name={"course"}
              forId={"course_ID"}
              value={formik.values.course}
              // handleOut={handleOut}
              onBlur={formik.handleBlur}
              error={formik.errors?.course}
              isTouched={formik.touched?.course}
              mapData={courseData}
              mapThrough={"course_name"}
              formik={formik}
              disabled={
                (routePath && routePath === "student_approval_queue")
              }
              zIndex={"z-40"}
            />
          )}
          {(values.status === "Drop" || values.status === "Hold") && (
            <InputBox
              placeholder={`Enter the Reason for ${
                values.status === "Drop" ? "Dropping" : "Holding"
              } the Student from the Course`}
              topDivStyle={"flex flex-col w-[47%]"}
              classNameInput={"w-full"}
              name={"reason"}
              value={values.reason}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.reason}
              isTouched={touched.reason}
              disabled={
                (routePath && routePath === "student_approval_queue")
              }
            />
          )}
          {values.status === "Nullify Payment" && (
            <DropdownSingleInputWithCheckbox
              topDivStyle={"flex flex-col w-[47%]"}
              classNameInput={"w-full"}
              label="Nullify Payment"
              placeholder="Nullify Payment"
              options={nullPayment}
              name={"nullType"}
              value={values.nullType}
              onBlur={handleBlur}
              error={errors.nullType}
              isTouched={touched.nullType}
              mapThrough={"nullType"}
              formik={formik}
              zIndex={"z-20"}
              disabled={
                (routePath && routePath === "student_approval_queue")
              }
            />
          )}
        </div>
        {values.status !== "Drop" && values.status !== "Hold" && (
          <div className="w-full flex items-center justify-between">
            {values.status === "Nullify Payment" && (
              <div className="w-[47%] relative">
                <textarea
                  name="nullReason"
                  value={values.nullReason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={
                    (routePath && routePath === "student_approval_queue")
                  }
                  placeholder="Enter the Reason"
                  className="border-[#707070] border-[0.5px] p-1 w-full min-h-[100px] max-h-[150px] rounded-[3px] resize-none"
                >
                  {values.nullReason}
                </textarea>
                {touched?.nullReason && errors?.nullReason && (
                  <span className="text-red-500 absolute -bottom-6 right-0">
                    {errors?.nullReason}
                  </span>
                )}
              </div>
            )}
            {(values.status === "Upgrade" || values.status === "Downgrade") && (
              <div className="w-[47%] gap-2 flex items-center">
                <InputBox
                  label="Duration"
                  placeholder="Duration"
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={"duration"}
                  value={values.duration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.duration}
                  isTouched={touched.duration}
                  disabled={true}
                />
                <InputBox
                  label={`${
                    values.status === "Upgrade"
                      ? "Additional Duration"
                      : "New Duration"
                  }`}
                  placeholder={`${
                    values.status === "Upgrade"
                      ? "Additional Duration"
                      : "New Duration"
                  }`}
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={"additional_duration"}
                  type="number"
                  value={values.additional_duration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.additional_duration}
                  isTouched={touched.additional_duration}
                  disabled={
                    (routePath && routePath === "student_approval_queue")
                  }
                />
              </div>
            )}
            {(values.status === "Upgrade" || values.status === "Downgrade") && (
              <div className="w-[47%] gap-2 flex items-center">
                <InputBox
                  label="Fee (₹)"
                  placeholder="Fee"
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={"fee"}
                  value={values.fee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.fee}
                  isTouched={touched.fee}
                  disabled={true}
                />
                <InputBox
                  label={`${
                    values.status === "Upgrade"
                      ? "Additional Fee (₹)"
                      : "New Fee (₹)"
                  }`}
                  placeholder={`${
                    values.status === "Upgrade"
                      ? "Additional Fee (₹)"
                      : "New Fee (₹)"
                  }`}
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={"additional_amount"}
                  type="number"
                  value={values.additional_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.additional_amount}
                  isTouched={touched.additional_amount}
                  disabled={
                    (routePath && routePath === "student_approval_queue")
                  }
                />
              </div>
            )}
          </div>
        )}
        {(values.status === "Upgrade" || values.status === "Downgrade") && (
          <CourseDetails
            formik={formik}
            courseData={courseData}
            paymentObject={paymentObject}
            branchDetails={branchDetails}
          />
        )}
      </div>
      {routePath &&
        routePath === "student_approval_queue" &&
        approvalStatus !== "accept" &&
        approvalStatus !== "reject" && (
          <div className="flex items-center justify-around gap-4">
            <Checkbox
              label={"Accept"}
              checked={checked}
              CheckValue={CheckValue}
              textStyle={"text-[#707070] text-[15px]"}
              show={true}
            />
            <Checkbox
              label={"Reject"}
              checked={checked}
              CheckValue={CheckValue}
              textStyle={"text-[#707070] text-[15px]"}
              show={true}
            />
          </div>
        )}
      {routePath && routePath !== "student_approval_queue" && (
        <SubmitButton
          disable={updatedStudentData?.course_id?.course_name === values.course}
        />
      )}
      {routePath &&
        routePath !== "student_approval_queue" &&
        updatedStudentData?.course_id?.course_name === values.course && (
          <p className="text-red-300">{`Please choose different course`}</p>
        )}
      {routePath &&
        routePath === "student_approval_queue" &&
        approvalStatus !== "accept" &&
        approvalStatus !== "reject" && (
          <button
            type="submit"
            className="cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 text-white text-[17px] font-Roboto font-normal"
          >
            Update
          </button>
        )}
    </form>
  );
};
