import { TestimonialsUpdate } from "./TestimonialsUpdate";
import { HeadingSection } from "../../Elements/HeadingSection";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const Testimonials = () => {
  const {id, mode}:any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  return (
    <div className="w-11/12 mx-auto">
      {allowOnlyActions('view') && !id && (
        <>
          <HeadingSection head={"Testimonials"} />
          <TestimonialsUpdate id={id} mode={mode} />
        </>
      )}
    </div>
  );
};
