import { ClimbingBoxLoader } from "react-spinners";
import { dateFormat } from "../../../Services/CommonFunctions";
import { errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { BulkUpdate, FetchData, InsertData, UpdateData } from "../../crud";
import { JobsInterface, StudentJobStatusUpdateInterface } from "./interface";

// page no: 166
// export const getHrStudentTableData = async () => {
//     try {
//         const { data, error } = await FetchData(TableNames.jobs, ["*", "technology:technologies(id, technology)"],)
//         const job_data = data?.map((el: any) => ({ ...el, technology: el.technology.technology, date: dateFormat(el.inserted_at, "DD MMM YYYY")}))
//         if (error) throw error
//         return job_data
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

export const getHrStudentTableData = async () => {
  console.log("getHrStudentTableData init");
  try {
    // Fetch data from the jobs table and order by the inserted_at field in descending order
    // const { data, error } = await FetchData(
    //     TableNames.jobs,
    //     ["*", "technology:technologies(id, technology)"]
    // ).order("id", { ascending: false })
    const { data, error } = await FetchData(TableNames.jobs, ["*"]).order(
      "id",
      { ascending: false }
    );
    console.log(data, " = data");

    if (error) throw error;

    // Format the data with the desired structure
    const job_data = data?.map((el: any) => ({
      ...el,
      technology: el.technology.map((tech: any) => tech.technology).join(", "),
      date: dateFormat(el.inserted_at, "DD MMM YYYY"),
    }));

    return job_data;
  } catch (error) {
    return errorNotifier(error);
  }
};

export const getjobId = async (jobId: string | number) => {
  try {
    const { data: jobs, error } = await FetchData(TableNames.jobs).eq(
      "id",
      jobId
    );

    if (error) throw error;

    return jobs[0];
  } catch (error) {
    return errorNotifier(error);
  }
};
// page no: 167
export const createNewJob = async (jobData: JobsInterface) => {
  try {
    const { data, error } = await InsertData(TableNames.jobs, jobData);
    if (error) throw error;
    return data;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 168
export const updateJob = async (
  jobId: number,
  jobData: Partial<JobsInterface>
) => {
  try {
    const { data, error } = await UpdateData(TableNames.jobs, jobData, {
      conditionKey: "id",
      conditionValue: jobId,
    });
    if (error) throw error;
    return true;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 169
export const viewApplicants = async (jobId: number, status: any) => {
  try {
    const { data, error } = await FetchData(TableNames.student_jobs, [
      "id",
      "student:students(name, email, phoneNumber, resume)",
      "training:trainings(course:courses(course_name))",
    ])
      .eq("job_id", jobId)
      .in("status", status); //.match({job_id: jobId, status: status})
    const updatedData = data?.map((el: any) => ({
      ...el,
      student_name: el.student.name,
      student_mobile: el.student.phoneNumber,
      student_email: el.student.email,
      student_resume: el.student.resume,
      course_name: el.training.course.course_name,
    }));
    if (error) throw error;
    return updatedData;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 170 - 175
export const updateStudentJobStatus = async (
  studentJobStatusUpdateData: StudentJobStatusUpdateInterface[]
) => {
  try {
    const newStudentJob = studentJobStatusUpdateData?.map((el: any) => ({
      ...el,
      shortlisted: el.status === "shortlisted" ? true : false,
      placed: el.status === "placed" ? true : false,
      joined: el.status === "joined" ? true : false,
    }));
    for (const data of newStudentJob) {
      const { id, ...updatedData } = data;

      const { error } = await UpdateData(TableNames.student_jobs, updatedData, {
        conditionKey: "id",
        conditionValue: id,
      });

      if (error) {
        console.error(`Error updating row with ID ${id}:`, error);
      } else {
        console.log(`Row with ID ${id} updated successfully.`);
      }
    }
    return true;
  } catch (error) {
    return errorNotifier(error);
  }
};

// page no: 168 - for technology dropdown
export const getHrStudentPreloadData = async () => {
  try {
    const { data, error } = await FetchData(TableNames.technologies, [
      "id",
      "technology",
    ]);
    if (error) throw error;
    return data;
  } catch (error) {
    return errorNotifier(error);
  }
};

// Page no: 176
const calculateRelevantJobs = (jobData: any) => {
  const countObject: any = {};

  jobData?.forEach((job: any) => {
    countObject[job.technology] = countObject[job.technology]
      ? countObject[job.technology] + 1
      : 1;
  });

  return countObject;
};
const getTechName = (techData: any, techId: any) => {
  // console.log("techId",techId, techData, "12")
  const techRec: any = techData.filter((rec: any) => (rec.id = techId));
  // console.log("techRec",techRec)
  if (techRec.length > 0) return techRec[0].technology;
  else return "No rec found";
};

// export const getStudentForHRTableData = async () => {
//     try {
//         const { data, error } = await FetchData(TableNames.student_jobs,
//              ["student:students(uid, name, email, phoneNumber)",
//               "training:trainings(course:courses(id, course_name), batch:batches(branch:branches(branch_name), city:cities(city_name)))",
//                "created_at", "job:jobs(id, technology)", "shortlisted", "placed", "joined"])

//         const {data: jobs, error: jobs_error} = await FetchData(TableNames.jobs)

//         const {data: Techs, error: Tech_error} = await FetchData(TableNames.technologies)

//         const countObject = calculateRelevantJobs(jobs)

//         const updated_data = data?.map((el: any) => ({
//             ...el, technology: getTechName(Techs, el.job?.technology),
//             student_name: el.student?.name, student_email: el.student?.email, student_mobile: el.student?.phoneNumber,
//             student_id: el.student?.uid, course_name: el.training?.course?.course_name,
//             branch: el.training?.batch?.branch?.branch_name, city: el.training?.batch?.city?.city_name,
//             relevant_jobs: countObject[el.job?.technology] || 0 , applied_jobs: data.filter((ele: any) => (ele.student?.uid === el.student?.uid)).length, technology_id: el.job?.technology,shortlisted:el.shortlisted === true ? "Yes" : "No",joined:el.joined === true ? "Yes" : "No",placed:el.placed === true ? "Yes" : "No", date: dateFormat(el.created_at, "DD MMM YYYY")}))

//         if(error || jobs_error||Tech_error) throw error || jobs_error ||Tech_error

//         return updated_data

//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

export const getStudentForHRTableData = async () => {
  try {
    const { data, error } = await FetchData(TableNames.trainings, [
      "balance_due",
      "placement",
      "student:students(uid, name, email, phoneNumber)",
      "course:courses(id, course_name)",
      "batch:batches(batch_progress_in_hrs, duration, branch:branches(branch_name), city:cities(city_name))",
      "student_job:student_jobs(job:jobs(id, technology), created_at, shortlisted, placed, joined)",
    ]);

    const filterBasedCourseFee_CourseCompletion_Placement = data?.filter(
      (fil: any) =>
        Number(fil?.balance_due) === 0 &&
        Number(fil?.batch?.batch_progress_in_hrs) >
          Number(fil?.batch?.duration) - 11 &&
        fil?.placement
    );

    const { data: jobs, error: jobs_error } = await FetchData(TableNames.jobs);

    const { data: Techs, error: Tech_error } = await FetchData(
      TableNames.technologies
    );

    const { data: Student_Jobs, error: Student_Job_Error } = await FetchData(
      TableNames.student_jobs,
      ["student_id"]
    );

    const countObject = calculateRelevantJobs(jobs);
    const updated_data = filterBasedCourseFee_CourseCompletion_Placement?.map(
      (el: any) => ({
        ...el,
        technology: getTechName(Techs, el.student_job?.[0]?.job?.technology),
        student_name: el.student?.name,
        student_email: el.student?.email,
        student_mobile: el.student?.phoneNumber,
        student_id: el.student?.uid,
        course_name: el?.course?.course_name,
        branch: el?.batch?.branch?.branch_name,
        city: el?.batch?.city?.city_name,
        relevant_jobs: countObject[el.student_job?.[0]?.job?.technology] || 0,
        applied_jobs: Student_Jobs?.filter(
          (ele: any) => ele.student_id === el.student?.uid
        ).length,
        technology_id:
          el.student_job?.length > 0 ? el.student_job?.[0]?.job?.technology : 0,
        shortlisted: el.shortlisted === true ? "Yes" : "No",
        joined: el.joined === true ? "Yes" : "No",
        placed: el.placed === true ? "Yes" : "No",
        date: dateFormat(el.created_at, "DD MMM YYYY"),
      })
    );

    if (error || jobs_error || Tech_error)
      throw error || jobs_error || Tech_error || Student_Job_Error;

    return updated_data;
  } catch (error) {
    return errorNotifier(error);
  }
};

/*export const getStudentForHRTableData = async () => {
    try {
        const { data, error } = await FetchData(TableNames.student_jobs, ["student:students(uid, name, email, phoneNumber)", "training:trainings(course:courses(id, course_name), batch:batches(branch:branches(branch_name), city:cities(city_name)))", "created_at", "job:jobs(id, technology)", "shortlisted", "placed", "joined"])

        const {data: jobs, error: jobs_error} = await FetchData(TableNames.jobs)

        const countObject = calculateRelevantJobs(jobs)

        const updated_data = data?.map((el: any) => ({ ...el, student_name: el.student.name, student_email: el.student.email,
             student_mobile: el.student.phoneNumber, student_id: el.student.uid, course_name: el.training?.course?.course_name,
              branch: el.training?.batch?.branch?.branch_name, 
              city: el.training.batch?.city.city_name, 
              relevant_jobs: countObject[el.job.technology] || 0 , applied_jobs: data.filter((ele: any) => (ele.student.uid === el.student.uid)).length, technology_id: el.job.technology, date: dateFormat(el.created_at, "DD MMM YYYY")}))

        if(error || jobs_error) throw error || jobs_error
        return updated_data
    } catch (error) {
        console.log(error)
        return errorNotifier(error)
    }
}  */

// Page no: 177
export const getRelevantJobsTableData = async (
  technology_id: number | string
) => {
  try {
    const { data, error }: any = await FetchData(TableNames.jobs, [
      "updated_at",
      "company",
      "position",
      "location",
      "technology:technologies(technology)",
    ]).eq("technology", technology_id);
    const updated_data = data?.map((el: any) => ({
      ...el,
      date: dateFormat(el.updated_at, "DD MMM YYYY"),
      technology: el.technology.technology,
    }));
    if (error) throw error;
    return updated_data;
  } catch (error) {
    return errorNotifier(error);
  }
};

// Page no: 178
export const getAppliedJobsTableData = async (studentUid: string) => {
  try {
    const { data, error } = await FetchData(TableNames.student_jobs, [
      "created_at",
      "shortlisted",
      "joined",
      "placed",
      "job:jobs(company, location, position)",
    ]).eq("student_id", studentUid);
    const updated_data = data?.map((el: any) => ({
      ...el,
      company: el.job?.company,
      location: el.job?.company,
      position: el.job?.position,
    }));

    if (error) throw error;
    return updated_data;
  } catch (error) {
    return errorNotifier(error);
  }
};

// export const getAllCompaniesList = async() => {
//     try {
//         const { data, error } = await  FetchData(TableNames.jobs, ["id","company","location","position"])
//         for (let i = 0; i < data?.length; i++) {
//             console.log("i : ",i, data[i]?.company);
//         }
//         console.log(data, 'data' , data?.length)
//         console.log(error, 'error')
//         if (error) throw error
//         return data
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

// export const getAllCompaniesList = async () => {
//     try {
//         const { data, error } = await FetchData(TableNames.jobs, ["id", "company", "location", "position"]);
//         if (error) throw error;

//         let companyArr = [];

//         if (data && data.length > 0) {
//             for (let i = 0; i < data.length; i++) {
//                 const ID = data[i].id;
//                 const COMP = data[i].company;

//                 companyArr.push({
//                     id: ID,
//                     company: COMP
//                 })
//             }
//         } else {
//             console.log('No data found');
//         }

//         console.log(companyArr,"companyArr")
//         console.log(data, 'data', data?.length);
//         return data;
//     } catch (error) {
//         return errorNotifier(error);
//     }
// };

export const getAllCompaniesList = async () => {
  try {
    const { data, error } = await FetchData(TableNames.jobs, [
      "id",
      "company",
      "location",
      "position",
    ]);
    if (error) throw error;

    // Initialize companyArr with a map transformation
    const companyArr =
      data && data.length > 0
        ? data.map((item: any) => ({
            id: item.id,
            company: item.company,
            // location: item.location,
            // role: item.position
          }))
        : [];

    // Remove duplicates based on the company name
    const uniqueCompanies = Array.from(
      new Map(companyArr.map((item) => [item.company, item])).values()
    );

    // Initialize companyArr with a map transformation
    const locationArr =
      data && data.length > 0
        ? data.map((item: any) => ({
            id: item.id,
            location: item.location,
          }))
        : [];

    // Remove duplicates based on the location
    const uniquelocations = Array.from(
      new Map(locationArr.map((item) => [item.location, item])).values()
    );

    const positionArr =
      data && data.length > 0
        ? data.map((item: any) => ({
            id: item.id,
            position: item.position,
          }))
        : [];

    const uniquepositions = Array.from(
      new Map(positionArr.map((item) => [item.position, item])).values()
    );

    if (uniqueCompanies.length === 0) {
      console.log("No company found");
    }
    if (uniquelocations.length === 0) {
      console.log("No location found");
    }
    if (uniquepositions.length === 0) {
      console.log("No position found");
    }

    //Remove duplicates based on the position

    // console.log(uniqueCompanies, "uniqueCompanies");
    // console.log(uniquelocations, "uniquelocations");
    // console.log(uniquepositions, "uniquepositions");
    // console.log(data, 'data', data?.length);

    // return uniqueCompanies; // Return the transformed and deduplicated array
    return {
      company: uniqueCompanies,
      location: uniquelocations,
      roles: uniquepositions,
    };
  } catch (error) {
    return errorNotifier(error);
  }
};

// export const getAllCompaniesList = async () => {
//     try {
//         const { data, error } = await FetchData(TableNames.jobs, ["id", "company", "location", "position"]);
//         if (error) throw error;

//         // Initialize companyArr with a map transformation
//         const companyArr = (data && data.length > 0) ? data.map((item: any) => ({
//             id: item.id,
//             company: item.company,
//             location: item.location,
//             position: item.position
//         })) : [];

//         // Helper function to remove duplicates based on a given key
//         const removeDuplicates = (arr: any[], key: string) => {
//             return Array.from(new Map(arr.map(item => [item[key], item])).values());
//         };

//         // Remove duplicates based on company name
//         const uniqueCompanies = removeDuplicates(companyArr, 'company');

//         // Remove duplicates based on location
//         const uniqueLocations = removeDuplicates(companyArr, 'location');

//         // Remove duplicates based on position
//         const uniquePositions = removeDuplicates(companyArr, 'position');

//         // Optional: If you want to merge these results or return them separately
//         // This will keep entries unique per field but may lose other fields
//         // const mergedUnique = [
//         //     ...uniqueCompanies,
//         //     ...uniqueLocations,
//         //     ...uniquePositions
//         // ];

//         // Log results
//         console.log(uniqueCompanies, "uniqueCompanies");
//         console.log(uniqueLocations, "uniqueLocations");
//         console.log(uniquePositions, "uniquePositions");

//         console.log(data, 'data', data?.length);

//         // Return all unique entries or select as needed
//         return {
//             companies: uniqueCompanies,
//             locations: uniqueLocations,
//             positions: uniquePositions
//         };

//     } catch (error) {
//         return errorNotifier(error);
//     }
// };
