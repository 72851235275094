import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables";
import { FetchDataById, InsertData } from "../../crud";
import { FetchData, UpdateData } from "../../crud";
import { CourseFeatureInterface } from "./interface";

export const getCourseFeatureTableData = async () => {
    try {
        const { data, error } = await FetchData(TableNames.courseFeatures, ["id","feature", "icon", "storage_key"]).order("id", {ascending: false});
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getCourseFeatureById = async (courseFeatureId: number) => {
     try {
        const { data: courseFeature, error } = await FetchDataById(TableNames.courseFeatures, courseFeatureId);
        if (error) throw error; 
        return courseFeature[0];
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const createCourseFeatures = async (coursefeatureData: any) => {
     try {
        const { data, error } = await InsertData(TableNames.courseFeatures, coursefeatureData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateCourseFeatures = async (coursefeatureData: Partial<CourseFeatureInterface>,coursefeatureId: number) => {
    try {
        const { data, error } = await UpdateData(TableNames.courseFeatures, coursefeatureData, { conditionKey: "id", conditionValue: coursefeatureId }) 
        
        if (error) throw error
        
        return true;

    } catch (error) {

        return errorNotifier(error)

    }
}

// If needed

export const getAllDataAsArray = async () => {
    try {
        const { data, error } = await FetchData(TableNames.courseFeatures, ["id","feature", "icon", "storage_key"]);
        if (error) throw error;
        return data.map((datum) => (datum))
    } catch (error) {
        return errorNotifier(error);
    }
}