import x from "../../Assets/dashboard/X.svg";
import X1 from "../../Assets/dashboard/X1.svg";
import admin1 from "../../Assets/dashboard/admin1.svg";
import admission1 from "../../Assets/dashboard/admission1.svg";
import admission2 from "../../Assets/dashboard/admission2.svg";
import admission3 from "../../Assets/dashboard/admission3.svg";
import admission4 from "../../Assets/dashboard/admission4.svg";
import allocatestud from "../../Assets/dashboard/allocatestud.svg";
import allocatestud1 from "../../Assets/dashboard/allocatestud1.svg";
import app from "../../Assets/dashboard/app.svg";
import applied from "../../Assets/dashboard/applied.svg";
import applied1 from "../../Assets/dashboard/applied1.svg";
import average from "../../Assets/dashboard/average.svg";
import average1 from "../../Assets/dashboard/average1.svg";
import closed from "../../Assets/dashboard/closed.svg";
import closed1 from "../../Assets/dashboard/closed1.svg";
import completebatch from "../../Assets/dashboard/completebatch.svg";
import completebatch1 from "../../Assets/dashboard/completebatch1.svg";
import completestud from "../../Assets/dashboard/completestud.svg";
import completestud1 from "../../Assets/dashboard/completestud1.svg";
import droppedstud from "../../Assets/dashboard/droppedstud.svg";
import droppedstud1 from "../../Assets/dashboard/droppedstud1.svg";
import excellent from "../../Assets/dashboard/excellent.svg";
import excellent1 from "../../Assets/dashboard/excellent1.svg";
import free from "../../Assets/dashboard/free.svg";
import good from "../../Assets/dashboard/good.svg";
import good1 from "../../Assets/dashboard/good1.svg";
import holdbatch from "../../Assets/dashboard/holdbatch.svg";
import holdbatch1 from "../../Assets/dashboard/holdbatch1.svg";
import holdstud from "../../Assets/dashboard/holdstud.svg";
import holdstud1 from "../../Assets/dashboard/holdstud1.svg";
import inprog from "../../Assets/dashboard/inprog.svg";
import inprog1 from "../../Assets/dashboard/inprog1.svg";
import invoice1 from "../../Assets/dashboard/invoice1.svg";
import invoice2 from "../../Assets/dashboard/invoice2.svg";
import job from "../../Assets/dashboard/job.svg";
import job1 from "../../Assets/dashboard/job1.svg";
import joincmny from "../../Assets/dashboard/joincmny.svg";
import joincmny1 from "../../Assets/dashboard/joincmny1.svg";
import openning from "../../Assets/dashboard/openning.svg";
import openning1 from "../../Assets/dashboard/openning1.svg";
import paid from "../../Assets/dashboard/paid.svg";
import placed from "../../Assets/dashboard/placed.svg";
import placed1 from "../../Assets/dashboard/placed1.svg";
import poor from "../../Assets/dashboard/poor.svg";
import poor1 from "../../Assets/dashboard/poor1.svg";
import reopen from "../../Assets/dashboard/reopen.svg";
import reopen1 from "../../Assets/dashboard/reopen1.svg";
import shortlist from "../../Assets/dashboard/shortlist.svg";
import shortlist1 from "../../Assets/dashboard/shortlist1.svg";
import totalcmny from "../../Assets/dashboard/totalcmny.svg";
import totalcmny1 from "../../Assets/dashboard/totalcmny1.svg";
import totalinvoice from "../../Assets/dashboard/totalinvoice.svg";
import totalinvoice1 from "../../Assets/dashboard/totalinvoice1.svg";
import totalinvoice2 from "../../Assets/dashboard/totalinvoice2.svg";
import totalinvoice3 from "../../Assets/dashboard/totalinvoice3.svg";
import totalinvoice4 from "../../Assets/dashboard/totalinvoice4.svg";
import unallocatestud from "../../Assets/dashboard/unallocatestud.svg";
import unallocatestud1 from "../../Assets/dashboard/unallocatestud1.svg";
import joinedthecompany from "../../Assets/dashboard/joinedthecompany.svg";
import ongoingbatch from "../../Assets/dashboard/ongoingbatch.svg"
import upcomingbatch from "../../Assets/dashboard/upcomingbatch.svg"
import reviewbatch from "../../Assets/dashboard/reviewbatch.svg"

export const Components = {
    x,
    X1,
    admin1,
    admission1,
    admission2,
    admission3,
    admission4,
    allocatestud,
    allocatestud1,
    app,
    applied,
    applied1,
    average,
    average1,
    closed,
    closed1,
    completebatch,
    completebatch1,
    completestud,
    completestud1,
    droppedstud,
    droppedstud1,
    excellent,
    excellent1,
    free,
    good,
    good1,
    holdbatch,
    holdbatch1,
    holdstud,
    holdstud1,
    inprog,
    inprog1,
    invoice1,
    invoice2,
    job,
    job1,
    joincmny,
    joincmny1,
    openning,
    openning1,
    paid,
    placed,
    placed1,
    poor,
    poor1,
    reopen,
    reopen1,
    shortlist,
    shortlist1,
    totalcmny, 
    totalcmny1,
    totalinvoice,
    totalinvoice1,
    totalinvoice2,
    totalinvoice3,
    totalinvoice4,
    unallocatestud,
    unallocatestud1,
    joinedthecompany,
    ongoingbatch,
    upcomingbatch,
    reviewbatch
}