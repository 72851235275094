import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { navigate }:any = useSharedLogic()

  const navigateToViewPage = () => {
    // setSearchParams({
    //   id: `${recordData.id}`,
    //   mode: `view`,
    // });
    navigate({
      pathname: `/batch_management`,
      search: createSearchParams({
        id: `${recordData.batch_id}`,
        mode: `view`,
        routePath: 'cal_trainer_pay'
      }).toString(),
    })
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
       <div
        onClick={navigateToViewPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableViewEyeIcon}
          alt="viewIcon"
          className="w-[21px] h-[10px]"
        />
      </div> 
    </div>
  );
};
