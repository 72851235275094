import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { CardDesign } from "./CardDesign";

export const Placement = ({ DashboardData }: any) => {
    const options = {
        responsive: true,
        plugins: {
        legend: {
        display:false
        },
        },
    
        scales: {
        x: {
            grid: {
            display: false,
            },
                },
        
        y: {
            grid: {
            display: false,
            },
    
        },
    
        },
    };

    const placement = {
        labels: (DashboardData[0].placementChart || []).map((d: any) => d.name),
        datasets: [{
        label: "Count",
        backgroundColor: "#036AD1",
        data: (DashboardData[0].placementChart || []).map((d: any) => d.count)
        }],
    }

  return (
        <div className="bg-[#F9FCFF] w-11/12 mx-auto shadow-md shadow-[#00000029_0px_3px_8px] rounded-[8px]">
            {DashboardData.map((item: any) => {
                return (
                    <div>
                    <h1 className="font-Roboto font-[400] text-[18px] bg-[#FBFBFB] py-4 px-2">
                        {item.placementTitle}
                    </h1>
                    <div className="p-4 space-y-4">
                        <div className="w-11/12">
                        <Bar
                            data={placement}
                            options={options}
                            height="80px"
                        />
                        </div>
                        <div className="grid grid-cols-4 gap-2 justify-between w-[94%]">
                        {item.placement.map((data: any) => {
                            return <CardDesign data={data} />;
                        })}
                        </div>
                    </div>
                    </div>
                );
            })}
        </div>
  )
}
