import { useState } from "react";
import certificateSample from "../../../../Assets/certificateSample.png";
import { ReadData } from "../../../Elements/ReadData";
import { BuildJson } from "./FileJson";
import { DropdownSingleInputWithCheckbox } from "../../../Elements/DropdownSingleInputWithCheckbox";
import { updateStudentConcernStatusandPriority } from "../../../../functions/admin/Action center/Session Feedback/sessionFeedback";

export const BasicInfo = ({id, mode, studentData, selectedStatus, setSelectedStatus, selectedIssue, setSelectedIssue}: any) => {
  const BasicInfo = BuildJson(studentData);
  const StatusTypes = ["Resolved", "Open", "In-progress", "Re-open"];
  const IssuePriority = ["Critical", "High", "Low", "Medium"]

  const handleOut = async(name: string, val: string, Id: any) => {
    if (name === "status") {
      setSelectedStatus(val) 
      await updateStudentConcernStatusandPriority({ status: val }, id)
    } else {
      setSelectedIssue(val)
      await updateStudentConcernStatusandPriority({issue_priority: val}, id)
    }
  }

  return (
    <div className="space-y-4 w-11/12 mx-auto py-6">
      <div className="w-11/12 mx-auto flex">
        <div className="w-1/2 space-y-3">
          {BasicInfo?.map((read: any) => {
            return <ReadData head={read.head} body={read.body} />;
          })}
        </div>
        <div className="w-1/2 space-y-4">
          <Trainings StatusTypes={StatusTypes} IssuePriority={IssuePriority} selectedStatus={selectedStatus} selectedIssue={selectedIssue} handleOut={handleOut} studentData={studentData} />
        </div>
      </div>
    </div>
  );
};

const Trainings = ({StatusTypes, IssuePriority, selectedStatus, selectedIssue, handleOut, studentData}:any) => {
    return(
        <div className='w-[60%]'>
          <ReadData head={'Course'} body={studentData?.training?.course?.course_name} />
          <img src={certificateSample} alt="Sample" />
          <ReadData head={'Trainer Name'} body={`${studentData?.training?.batch?.user?.first_name.concat(' ', studentData?.training?.batch?.user?.last_name)}`} />
          <ReadData head={'Trainer Phone Number'} body={studentData?.training?.batch?.user?.phone_number} />
          <div className={`min-h-[46px] pt-2 space-y-2`}>
            <DropdownSingleInputWithCheckbox
              topDivStyle={"flex flex-col w-9/12"}
              classNameInput={"w-full"}
              label={"Status"}
              options={StatusTypes}
              name={"status"}
              value={selectedStatus}
              handleOut={handleOut}
              // onBlur={handleBlur}
              // error={errors.nullType}
              // isTouched={touched.nullType}
              mapThrough={"status"}
              // formik={formik}
              zIndex={"z-30"}
              disabled={false}
            />
            <DropdownSingleInputWithCheckbox
              topDivStyle={"flex flex-col w-9/12"}
              classNameInput={"w-full"}
              placeholder="Issue Priority"
              options={IssuePriority}
              name={"issue"}
              value={selectedIssue}
              handleOut={handleOut}
              // onBlur={handleBlur}
              // error={errors.nullType}
              // isTouched={touched.nullType}
              mapThrough={"issue"}
              // formik={formik}
              zIndex={"z-20"}
              disabled={false}
            />
          </div>
        </div>
    )
}