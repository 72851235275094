import { useSearchParams } from "react-router-dom";
import tableViewEyeIcon from "../../Assets/tableViewEyeIcon.svg";
import { getStudentInformation } from "../../functions/admin/Students/students";
import { useSharedLogic } from "../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";

const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const { navigate }: any = useSharedLogic();
  const checkStudentType = recordData.type && recordData.type==='Free Student'?'free':'paid';   
  const [searchParams, setSearchParams] = useSearchParams();

  const navigateToViewPage = async() => {
    if (recordData.type === "Paid Student") {
      const response:any = await getStudentInformation(recordData.uid);
      // console.log(response?.student?.training[0]['id'], "RESP_O_NSE")
      if (response) {
        navigate({
          pathname: `/student_management`,
          search: createSearchParams({ id: response?.student?.training[0]['id'], mode: 'view' }).toString(),
        });
      }
    } else {
      setSearchParams({
        id: `${recordData.uid}`,
        mode: `${checkStudentType}`,
      });
    }
  };
    
  return (
    <div className="flex items-center justify-center text-center space-x-4">
      <div
        onClick={navigateToViewPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableViewEyeIcon}
          alt="viewIcon"
          className="w-[21px] h-[10px]"
        />
      </div>
    </div>
  );
};

export default ViewEditDeleteActionComponent;
