import React,{useState} from 'react'
import { setApplicants } from '../../../Redux/Reducers/applicantsView';
import { useAppDispatch, useAppSelector } from "../../..";
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';

export default function JoinedApplicants({recordData, data} : any) {
   // const [selectedRows, setSelectedRows] = useState<number[]>([]);
   const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
   //console.log("Mode Chenages", mode.toString())
    const dispatch = useAppDispatch();
    const { applicantData } = useAppSelector(({ applicants }: any) => applicants);
  //  console.log("Selected Rows",selectedRows )
  const selectedRows = applicantData.applicantView; 
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const isChecked = event.target.checked;
    
        if (isChecked) {
        dispatch(setApplicants({ applicantView: [...selectedRows, id] }));
        } else {
        
        dispatch(setApplicants({ applicantView: selectedRows.filter((selectedId: number) => selectedId !== id) }));
        }
      };
      
  return (
    <div className="flex items-center justify-center text-[#036AD1] text-center space-x-4">
    <div
        
        className="cursor-pointer h-[32px] w-[32px] flex justify-center items-center"
    >
      { 
      (mode === "joined") ?
      <p> <input
                    type="checkbox"
                    checked={selectedRows.includes(recordData.id)}
                    onChange={(event) => handleCheckboxChange(event, recordData.id)}
                  /></p> : null }
    </div>     
</div>
  )
}