import { RoleManagement } from "../AdminManagement/RoleManagement/RoleManagement";
import { AdminUserManagement } from "../AdminManagement/AdminUserManagement/AdminUserManagement";
import { TrainerUserManagement } from "../AdminManagement/TrainerUserManagement/TrainerUserManagement";
import { Branches } from "../Content Management/Branches/Branches";
import { ManageCourses } from "../Content Management/ManageCourse/ManageCourses";
import { PushContent } from "../Content Management/PushContent/PushContent";
import { CourseCategory } from "../Content Management/CourseCategory/ManageCategory";
import { Technology } from "../Content Management/Technology/ManageTechnology";
import { CourseMapping } from "../Content Management/CourseMapping/CourseMapping";
import { CourseFeatures } from "../Content Management/Course Features/CourseFeatures";
import { HiringCompanies } from "../Content Management/HiringCompanies/HiringCompanies";
import { Certificate } from "../Content Management/Certificate/Certificate";
import { Testimonials } from "../Content Management/Testimonials/Testimonials";
import { ContactInfo } from "../Content Management/ContactInfo/ContactInfo";
import { Resources } from "../Content Management/Resources/Resources";
import { PendingPayment } from "../Action Center/Pending Payment/PendingPayment";
import { DailyTransaction } from "../Action Center/Daily Transactions/DailyTransactions";
import { StudentActions } from "../Action Center/Student Actions/StudentActions";
import { ApprovalQueue } from "../Action Center/Students Actions Approval Queue/Students ActionsApprovalQueue";
import { IssueRefundOrAdjustPayment } from "../Action Center/IssueRefundOrAdjustPayment/IssueRefundOrAdjustPayment";
import { CompletedBatches } from "../Action Center/Completed Batches/CompletedBatches";
import { CancellationSession } from "../Action Center/Cancellation Session/CancellationSession";
import { CalculateTrainerPayment } from "../Action Center/Calculate Trainer Payment/CalculateTrainerPayment";
import { ProcessTrainerPayment } from "../Action Center/Process Trainer Payment/ProcessTrainerPayment";
import { StudentFeedbackConcern } from "../Action Center/Student Feedback/StudentFeedbackConcern";
import { SessionFeedback } from "../Action Center/Session Feedback/SessionFeedback";
import { HrStudentPlacement } from "../HR & Student Placement/HR Student Placement/HR & Student Placement";
import StudentForHr from "../HR & Student Placement/Student for HR/Student for HR";
import { StudentAttendance } from "../Action Center/StudentAttendance/StudentAttendance";
import { StudentFeedbackNotGiven } from "../Action Center/StudentFeedbackNotGiven/StudentFeedbackNotGiven";
import { TrainerTransaction } from "../Action Center/TrainerTransaction/TrainerTransaction";
import { MISReports } from "../Reports/MIS Reports/MISReports";
import { OperationalReports } from "../Reports/Operational Reports/OperationalReports";
import { ViewLogsInfo } from "../Logs/UserLogs/ViewLogsInfo";
import { UserLogs } from "../Logs/UserLogs/UserLogs";

export const Subsection = ({ routeName }: any) => {
  const SubScreenJsonStructure: any = {
    role_management: <RoleManagement />,
    admin_user_management: <AdminUserManagement />,
    trainer_user_management: <TrainerUserManagement />,
    branches: <Branches />,
    manage_courses: <ManageCourses />,
    course_category: <CourseCategory />,
    technology: <Technology />,
    course_mapping: <CourseMapping />,
    course_features: <CourseFeatures />,
    hiring_companies: <HiringCompanies />,
    certificate: <Certificate />,
    testimonials: <Testimonials />,
    contact_info: <ContactInfo />,
    push_content: <PushContent />,
    resources: <Resources />,
    pending_payment: <PendingPayment />,
    daily_transactions: <DailyTransaction />,
    student_actions: <StudentActions />,
    student_actions_approval_queue: <ApprovalQueue />,
    issue_refund_adjust_payment: <IssueRefundOrAdjustPayment />,
    student_attendance: <StudentAttendance />,
    completed_batches: <CompletedBatches />,
    cancellation_session: <CancellationSession />,
    calculate_trainer_payment: <CalculateTrainerPayment />,
    process_trainer_payment: <ProcessTrainerPayment />,
    student_feedback_concerns: <StudentFeedbackConcern />,
    session_feedback: <SessionFeedback />,
    student_feedback_not_given: <StudentFeedbackNotGiven />,
    trainer_transaction: <TrainerTransaction />,
    manage_jobs: <HrStudentPlacement />,
    student_for_hr: <StudentForHr />,
    mis_report: <MISReports />,
    operational_report: <OperationalReports />,
    user_logs: <UserLogs />,
    student_edit_logs: "Student Edit Logs",
  };

  return <div>{SubScreenJsonStructure[routeName]}</div>;
};
