import { Components } from './ModuleImports';
import { ModalForNavigation } from '../../UI_Modal/ModalForNavigation';
import { dateFormat } from '../../../Services/CommonFunctions';
import { Time } from '../../Batch Management/Create Batch/Time';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { useAppSelector } from '../../..';

export const AddSession = ({ id, mode, routePath }: any) => {
  const {
    useState,
    useEffect,
    Loader,
    onKeyDown,
    ReadData,
    Course,
    StudentDetails,
    batchProgressById,
    getSessionById,
    createSession,
    updateSession,
    getStudentsByBatchid,
    getStudentsOfSingleSession,
    useSharedLogic,
    FormikCommonFunc,
    add_session,
    toast,
    AddSession,
    isTime1GreaterThanTime2
  }: any = Components;
  const { navigate, routeNameForHome, routeNameForSubSection, createSearchParams, courseId }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [batchDetail, setBatchDetail] = useState({} as any);
  const [studentData, setStudentData] = useState([] as any);
  const [continueSession, setContinueSession] = useState(false);
  const [existingDateCheck, setExistingDateCheck] = useState({
    start_time: "",
    end_time: ""
  });
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const LoginUserNames = signInData && signInData?.data[0]?.roles;
  const isId: any = id;

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const readData: any = await batchProgressById(isId);
      setBatchDetail(readData?.batch)
      // console.log(readData?.batch, "readData?.batch");
      setFieldValue('trainer_id', readData?.batch?.trainer_id)
      const studentsData = await getStudentsByBatchid(isId);
      setStudentData(studentsData);
      if (mode === "edit") {
        const sessionData = await getSessionById(courseId);
        const { attendees, batch, details, end_time, session_date, start_time }: any = sessionData;
        if (sessionData) {
          setValues({
            ...values,
            attendees,
            details,
            start_time,
            end_time,
            session_date,
            trainer_id: readData?.batch?.trainer_id
          }, true)
        }
      }
      setIsLoading(false)
    })()
  }, [])

  const forSession = async () => {
    // if (isTime1GreaterThanTime2(values.start_time, values.end_time)) {
    //   toast.error("End time must be later than start time");
    // } else
    if ((existingDateCheck?.start_time === values.start_time) && (existingDateCheck?.end_time === values.end_time)) {
      toast.error(
        `Session time already exists on ${dateFormat(
          values.session_date,
          "DD MMM"
        )}`
      );
    } else if (values.attendees.length===0) {
      toast.error(`Please Select Attendees`)
    } else {
      setIsLoading(true);
      const { batch_id, session_status, ...rest } = values;
        const body: any = {
          session_status: routePath==="session"?"Success":"Compensate",
          batch_id: Number(id),
          ...rest,
        } 
        const response = await(
          mode !== "edit" ? createSession(body) : updateSession(body, courseId)
        );
        if (mode !== "edit") {
          if (response.status) {
            navigate({
              pathname: `/${routeNameForHome}`,
              search: createSearchParams({
                id: id, mode: "view",
              }).toString(),
            });
          } else toast.error(response?.message);
        } else {
            navigate({
              pathname: `/${routeNameForHome}`,
              search: createSearchParams({
                id: id, mode: "view",
              }).toString(),
            });
        }
      setIsLoading(false)
    }
  }

  const TriggerModal = (flag: any) => {
    setContinueSession(false)
    if (flag === "Yes") {
      forSession()
    }
  }

  const submitRequest = () => {
    setContinueSession(true)
  }
  
  const {
    formik,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  }: any = FormikCommonFunc(add_session, AddSession, submitRequest);

  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="bg-white w-10/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6 pb-10"
        >
          <ModalForNavigation
            open={continueSession}
            setOpen={setContinueSession}
            func={TriggerModal}
          />
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[22px] 2xl:text-[24px] text-[#707070] my-3 ml-4">
              {mode === "new-session" || mode === "new-compensation-session"
                ? "Add Session"
                : "Edit Session"}
            </p>
          </div>
          <div className="w-11/12 mx-auto py-8 space-y-10">
            <ReadData
              head={"Batch Code"}
              body={batchDetail?.batch_code ? batchDetail?.batch_code : "-"}
            />
            <Course
              isId={isId}
              formik={formik}
              batchDetail={batchDetail}
              Time={Time}
              dateExists={setExistingDateCheck}
              existingDateCheck={existingDateCheck}
              LoginUserNames={LoginUserNames}
            />
            <StudentDetails formik={formik} studentData={studentData} />
          </div>
          <div className="flex justify-center items-center mt-4 w-4/12 mx-auto">
            <button
              type="submit"
              className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-6 font-Roboto font-[400]"
            >
              {mode === "new-session" || mode === "new-compensation-session"
                ? "Create Session"
                : "Update Session"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
