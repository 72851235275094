//basic Info
export const BuildJSON = (data: any) => {
    const Details: any = [
      {
        head: "Student Name",   
        body: data?.updatedStudentData?.student_id?.name,
      },
      {
        head: "Phone Number",
        body: data?.updatedStudentData?.student_id?.phoneNumber,
      },
      {
        head: "Email",
        body: data?.updatedStudentData?.student_id?.email,
      },
      {
        head: "Schedule",
        body: data?.updatedStudentData?.course_schedule,
      },
      {
        head: "Registration Date",
        body: data?.updatedStudentData?.registration_date,
      },
      {
        head: "Status",
        body: data?.updatedStudentData?.status,
      },
      {
        head: "Branch & City",
        body: `${data?.updatedStudentData?.branch_id?.branch_name}, ${data?.updatedStudentData?.city_id?.city_name}`,
      },
      {
        head: "Batch Start Date",
        body: data?.batch_start_date
      }
    ];
    return Details;
}