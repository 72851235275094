import { getBranchTableData } from '../../functions/admin/Branches/branches';
import ViewEditDeleteActionComponent from '../../Components/Content Management/Branches/ActionComponent';
export const branchData = {
  headers: [
    {
      name: "Ownership",
      apiKey: "ownership",
      headerClass: "w-[100px] text-start",
      className: "w-[100px] text-start break-words",
    },
    {
      name: "City",
      apiKey: "city",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[100px] text-start break-words px-2",
    },
    {
      name: "Branch",
      apiKey: "branch_name",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[100px] text-start break-words px-2",
    },
    {
      name: "Status",
      apiKey: "status",
      headerClass: "w-[80px] text-start",
      className: "w-[80px] text-start break-words",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: getBranchTableData,
  filters: {
    checkBox: [
      {
        name: "City",
        apiKey: "city",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
      {
        name: "Branch",
        apiKey: "branch_name",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
      {
        name: "Ownership",
        apiKey: "ownership",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
      {
        name: "Status",
        apiKey: "status",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
    ],
  },
};