import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = false;

const courseProgressSlice = createSlice({
  name: "bulkupdate",
  initialState,
  reducers: {
    toggleBulkUpdate: (state: boolean, action: PayloadAction<any>) => {
      return !state;
    },
  },
});

export const { toggleBulkUpdate } = courseProgressSlice.actions;
export default courseProgressSlice.reducer;
