import { ViewEditDeleteActionComponent } from "../../Components/Content Management/Course Features/ActionComponent";
import { CourseIcon } from "../../Components/Content Management/Course Features/CourseIcon";
import { getCourseFeatureTableData } from "../../functions/admin/Course Features/courseFeatures";

export const courseFeature = {
  headers: [
    {
      name: "Course Features",
      apiKey: "feature",
      headerClass: "w-[200px] text-start px-2",
      className: "w-[200px] text-start px-2",
    },
    {
      name: "Icon",
      apiKey: "icon",
      Comp: CourseIcon,
      isComponent: true,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center break-words",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "text-center w-[100px]",
      className: "text-center w-[100px]",
    },
  ],
  BackendFunctionCall: getCourseFeatureTableData,
  filters: {
    searchBox: [
      {
        matchFields: ["feature"],
        searchKey: "courseFeatures",
        name: "Search",
        searchStyle: "w-[220px]",
      },
    ],
  },
  // ActionComponent: ViewEditDeleteActionComponent,
};
