import { useRef } from "react";
import { AddItemsButton } from "../../../Elements/AddItemsButton";
import { InputBox } from "../../../Elements/InputBox";
import { UploadOrUpdateBucket } from "../../../CommonFunctions/CommonFileFunction";

export const AddVideo = ({ id, mode, formik }: any) => {
  // const videoRef = useRef(null as any);
  const firstObject: any = formik.values?.videos?.[0];

  const handleDuration = (e:any, index: number) => {
    const video = e.target;
    const durationInSeconds = video.duration;
    formik.setFieldValue(`videos[${index}]duration`, formatTime(durationInSeconds))
    // console.log(formatTime(durationInSeconds), "DURATION", durationInSeconds, "durationInSeconds")
    // formik.setFieldValue(`videos[${index}]duration`, videoRef.current.duration?.toString())
  };

  // Helper function to format time in HH:mm:ss format
  const formatTime = (seconds:any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    return formattedTime;
  };

  // for play video
  // const handleFileNameClick = () => {
  //   if (videoRef.current) {
  //     videoRef.current.play();
  //     // setPlayVideo(!true)
  //   }
  // };
  
    const addMoreVideos = () => {
      const add = [
        ...formik.values.videos,
        { title: "", link: "", duration: "", key: "" ,filename: ""},
      ];
      formik.setFieldValue("videos", add);
    };

    const deleteAddedVideos = (i: number) => {
      const newArray = [...formik.values.videos];
      newArray.splice(i, 1);
      formik.setFieldValue("videos", newArray);
    };
  
    const handleChangeForCourseMaterial = (
      index: number,
      value: string,
      data: string
    ) => {
      formik.setFieldValue(`videos[${index}][${data}]`, value);
    };
  
  const handleValues:any = (response: any, Method: string, index:number, subBucket:string, file?: any, name?: any, randomKey?: string) => {
    if (['Upload', 'Update'].includes(Method)) {
      const cacheBuster = Date.now();
      const updatedArray: any = [...formik.values.videos];
      updatedArray[index] = {
        ...updatedArray[index],
        link:
          mode !== "edit"
            ? response?.publicUrl
            : `${response?.publicUrl}?cb=${cacheBuster}`,
        key: randomKey,
        filename: file?.name
      };
      formik.setFieldValue("videos", updatedArray);
    } else {
      const updatedArray: any = [...formik.values.videos];
      updatedArray[index] = {
        ...updatedArray[index],
        link: "",
        key: "",
        filename: "",
        duration: ""
      };
      formik.setFieldValue("videos", updatedArray);
    }
  }
  
  return (
    <div className="flex flex-col items-end pt-10 space-y-2">
      <div className="shadow-[#00000029_0px_3px_8px] rounded-[5px] py-4 flex items-end justify-between gap-1 w-full mt-4 px-3">
        <div className="flex items-end gap-4 w-[45%]">
          <InputBox
            label={"Title 1"}
            placeholder={"Title 1"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={`videos[${0}].title`}
            value={firstObject?.title}
            onChange={handleChangeForCourseMaterial}
            onBlur={formik.handleBlur}
            error={
              formik.errors?.videos &&
              formik.errors?.videos[0] &&
              formik.errors?.videos[0].title
            }
            isTouched={
              formik.touched?.videos &&
              formik.touched?.videos[0] &&
              formik.touched?.videos[0].title
            }
            index={0}
            check={"title"}
          />
        </div>
        <UploadOrUpdateBucket
          mode={mode}
          id={"video0"}
          labelText={"Upload Video"}
          accept={"video/*"}
          // fileSize={200}
          // sizeInText={"Image Size 5Mb"}
          Method={firstObject?.link==="" ? "Upload" : "Update"}
          bucketName={"courses"}
          subBucket={"videos"}
          forValue={handleValues}
          fileNameData={firstObject?.filename}
          fileNameKey={firstObject?.key}
          preview={firstObject?.link}
          // buttonContainer={'w-4/12 mx-auto'}
          // previewImgStyle={'w-full'}
          name={`videos[${0}].link`}
          error={
            formik.errors?.videos &&
            formik.errors?.videos[0] &&
            formik.errors?.videos[0].link
          }
          isTouched={
            formik.touched?.videos &&
            formik.touched?.videos[0] &&
            formik.touched?.videos[0].link
          }
          index={0}
          containerStyle={"absolute top-0 -left-40"}
        />
        {firstObject?.link && (
          <video
            className="hidden"
            // ref={videoRef}
            controls
            onLoadedMetadata={(e:any) => handleDuration(e, 0)}
          >
            <source src={firstObject?.link} type="video/mp4" />
          </video>
        )}
      </div>
      {formik.values?.videos.map((data: any, i: number) => {
        if (i > 0) {
          return (
            <div className="relative shadow-[#00000029_0px_3px_8px] rounded-[5px] py-4 flex items-end justify-between gap-1 w-full mt-4 px-3">
              <div className="flex items-end gap-4 w-[45%]">
                <InputBox
                  label={`Title ${i + 1}`}
                  placeholder={`Title ${i + 1}`}
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  name={`videos[${i}].title`}
                  value={data?.title}
                  onChange={handleChangeForCourseMaterial}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors?.videos &&
                    formik.errors?.videos[i] &&
                    formik.errors?.videos[i].title
                  }
                  isTouched={
                    formik.touched?.videos &&
                    formik.touched?.videos[i] &&
                    formik.touched?.videos[i].title
                  }
                  index={`${i}`}
                  check={"title"}
                />
              </div>
              <UploadOrUpdateBucket
                mode={mode}
                id={`video ${i + 1}`}
                labelText={"Upload Video"}
                accept={"video/*"}
                // fileSize={200}
                // sizeInText={"Image Size 5Mb"}
                Method={data?.link==="" ? "Upload" : "Update"}
                bucketName={"courses"}
                subBucket={"videos"}
                forValue={handleValues}
                fileNameData={data?.filename}
                fileNameKey={data?.key}
                preview={data?.link}
                // buttonContainer={'w-4/12 mx-auto'}
                // previewImgStyle={'w-full'}
                name={`videos[${i}].link`}
                error={
                  formik.errors?.videos &&
                  formik.errors?.videos[i] &&
                  formik.errors?.videos[i].link
                }
                isTouched={
                  formik.touched?.videos &&
                  formik.touched?.videos[i] &&
                  formik.touched?.videos[i].link
                }
                index={i}
                containerStyle={"absolute top-0 -left-40"}
              />
              {formik.values?.videos[i]?.link && (
                <video
                  className="hidden"
                  // ref={videoRef}
                  controls
                  onLoadedMetadata={(e:any) => handleDuration(e, i)}
                >
                  <source
                    src={formik.values?.videos[i]?.link}
                    type="video/mp4"
                  />
                </video>
              )}
              <div
                onClick={() => deleteAddedVideos(i)}
                className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-9 bottom-7"
              >
                x
              </div>
            </div>
          );
        }
      })}
      <AddItemsButton
        text={"Add Videos"}
        classNameInput={"w-full mx-auto flex items-center justify-end"}
        onClick={addMoreVideos}
      />
    </div>
  );
};