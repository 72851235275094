import { RadioInput } from "../../Elements/RadioInput";

export const SelectModuleType = ({ disabled, text, JSON, name, value, onBlur, onChange, topDivStyle }: any) => {
  return (
    <div className={`flex items-center justify-center gap-4 ${topDivStyle}`}>
      <h1 className="text-[20px] font-Roboto font-[500]">{text}</h1>
      <div className="flex items-center gap-3">
        {JSON.map((type: any, i: number) => (
          <RadioInput
            name={name}
            onBlur={onBlur}
            index={i}
            label={type.label}
            value={value}
            classStyle="text-[19px] font-[400]"
            onChange={onChange}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};