import React from 'react'
import { useSearchParams } from "react-router-dom";

export const PageRouting = ({ recordData, data }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigateToViewPage = () => {
    setSearchParams({
      id: `${recordData.technology_id}`,
      mode: `View Relevant Jobs`,
    });
  };
      return (
       
            <div className="flex items-center justify-center text-[#036AD1] text-center space-x-4">
                <div
                    onClick={navigateToViewPage}
                    className="cursor-pointer h-[32px] w-[32px] flex justify-center items-center"
                >
                   <p>{data}</p> 
                </div>     
            </div>
            
      )
    }