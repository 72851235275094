// import { sampleFunc } from "../../Services/Supabase/databaseOperations";
import {ViewEditDeleteActionComponent} from "../../Components/StudentManagement/ActionComponent";
import { getManageStudentsTableData } from "../../functions/admin/Students/students";

export const studentManagement = {
  headers: [
    {
      name: "Registration Date",
      apiKey: "registration_date",
      headerClass: "text-start w-[120px] px-2",
      className: "text-start w-[120px] px-2",
    },
    {
      name: "Student Name",
      apiKey: "student_name",
      headerClass: "text-start w-[120px] px-2",
      className: "text-start w-[120px] break-words px-2",
    },
    {
      name: "Phone Number",
      apiKey: "phone_number",
      headerClass: "text-start w-[120px] px-2",
      className: "text-start break-words w-[120px] px-2",
    },
    {
      name: "Course",
      apiKey: "course_name",
      headerClass: "text-start w-[150px] px-2",
      className: "text-start break-words w-[150px] px-2",
    },
    {
      name: "Course Mode",
      apiKey: "mode",
      headerClass: "text-center w-[150px] px-2",
      className: "text-center w-[150px] break-words px-2",
    },
    {
      name: "Status",
      apiKey: "status",
      headerClass: "text-start w-[100px] px-1",
      className: "text-start break-words w-[100px] px-1",
    },
    {
      name: "Batch Follow-Up Date",
      apiKey: "batch_follow_up_date",
      headerClass: "text-center w-[120px] px-2",
      className: "text-center w-[120px] break-words px-2",
    },
    {
      name: "BC Name",
      apiKey: "check_CRM_User",
      headerClass: "text-start w-[100px] px-2",
      className: "text-start break-words w-[100px] px-2",
    },
    {
      name: "Course Progress(%)",
      apiKey: "courseProgress",
      headerClass: "text-start w-[100px] px-2",
      className: "text-center w-[100px] px-2",
    },
    {
      name: "Total Fee",
      apiKey: "total_fee",
      headerClass: "text-start w-[100px] px-2",
      className: "text-start w-[100px] px-2",
    },
    {
      name: "Balance Due",
      apiKey: "balance_due",
      headerClass: "text-start w-[100px] px-2",
      className: "text-center w-[100px] px-2",
    },
    {
      name: "Branch",
      apiKey: "branch",
      headerClass: "text-start w-[140px] px-2",
      className: "text-start w-[140px] break-words px-2",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return getManageStudentsTableData(params);
  },
  filters: {
    dateFilters: [
      {
        name: "Registration Date",
        apiKey: "registration_date",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
    ],
    checkBox: [
      // {
      //   name: "Registration Date",
      //   apiKey: "registration_date",
      //   isSearchNeeded: true,
      //   className: "w-[180px]",
      // },
      {
        name: "Course",
        apiKey: "course_name",
        isSearchNeeded: true,
        className: "w-[250px]",
      },
      {
        name: "Course Mode",
        apiKey: "mode",
        isSearchNeeded: true,
        className: "w-[230px]",
      },
      {
        name: "Course Schedule",
        apiKey: "course_schedule",
        isSearchNeeded: true,
        className: "w-[160px]",
      },
      {
        name: "City",
        apiKey: "city",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
      {
        name: "Branch",
        apiKey: "branch",
        isSearchNeeded: true,
        className: "w-[150px]",
      },
      {
        name: "Student Status",
        apiKey: "status",
        isSearchNeeded: true,
        className: "w-[160px]",
      },
      {
        name: "Batch Follow-Up Date",
        apiKey: "batch_follow_up_date",
        isSearchNeeded: true,
        className: "w-[230px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["phone_number", "email", "student_name"],
        searchKey: "course",
        name: "Search",
        searchStyle: "w-[220px]",
      },
    ],
  },
};
