import { SUPABASE_CLIENT } from "../../../Services/Supabase";
import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables";
import { DeleteData } from "../../crud";
import { FetchData, FetchDataById, InsertData, UpdateData } from "../../crud";
import { CategoryInterface } from "./interface";

export const getAllCategories = async () => {
    try {
        const { data: categories, error }: any = await FetchData(TableNames.categories);
        if (error) throw error;
        return categories;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getCategoryById = async (categoryId: string | number) => {
    try {
        const { data: category, error } = await FetchDataById(TableNames.categories, categoryId);
        if (error) throw error; 
        return category[0];
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const createCategory = async (categoryData: CategoryInterface) => {
    try {
        categoryData.name_key = categoryData?.category_name.toLowerCase()
        const { data, error } = await InsertData(TableNames.categories, categoryData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateCategory = async (categoryData: Partial<CategoryInterface>, categoryId: string | number) => {
    try {

        const { data, error } = await UpdateData(TableNames.categories, categoryData, { conditionKey: "id", conditionValue: categoryId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}


export const deleteCategory = async (categoryId: string | number) => {
    try {
        const { data, error } = await DeleteData(TableNames.categories, { conditionKey: "id", conditionValue: categoryId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

const calculateCourseForcategory = (courseData: any) => {
    const countObject: any = {}

    courseData?.forEach((course: any) => {
        countObject[course.category] = countObject[course.category] ? countObject[course.category]+1 : 1
    })

    return countObject;
}

export const getCategoryTableData = async () => {
    try {
        const { data: categories, error }: any = await FetchData(TableNames.categories).order("id", {ascending: false})
        const { data: courses, error: err }: any = await FetchData(TableNames.courses)

        let countObject = calculateCourseForcategory(courses)

        const categoriesData = categories.map((category: any) => ({ ...category, courses: countObject[category.id] || 0 }))
        
        return categoriesData
        
    } catch (error) {
        return errorNotifier(error)
    }
}
