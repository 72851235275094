import DB from "../../Assets/DB.svg";
import DBWhite from "../../Assets/DBWhite.svg";
import Admin from "../../Assets/Admin.svg";
import AdminWhite from "../../Assets/AdminWhite.svg";
import AllStudents from "../../Assets/AllStudents.svg";
import AllStudentsWhite from "../../Assets/AllStudentsWhite.svg";
import CM from "../../Assets/CM.svg";
import CMWhite from "../../Assets/CMWhite.svg";
import SM from "../../Assets/SM.svg";
import SMWhite from "../../Assets/SMWhite.svg";
import BM from "../../Assets/BM.svg";
import BMWhite from "../../Assets/BMWhite.svg";
import CP from "../../Assets/CP.svg";
import CPWhite from "../../Assets/CPWhite.svg";
import AC from "../../Assets/AC.svg";
import ACWhite from "../../Assets/ACWhite.svg";
import HR from "../../Assets/HR.svg";
import HRWhite from "../../Assets/HRWhite.svg"
import AE from "../../Assets/AE.svg";
import Reports from "../../Assets/Reports.svg";
import UserLogs from "../../Assets/UserLogs.svg";
import UserLogsWhite from "../../Assets/UserLogsWhite.svg";

export const ImageListForSelected: any = {
  dashboard: DB,
  admin_management: Admin,
  all_students: AllStudents,
  content_management: CM,
  student_management: SM,
  batch_management: BM,
  course_progress: CP,
  action_center: AC,
  hr_student_placement: HR,
  reports: Reports,
  logs: UserLogs,
  app_enquiries: UserLogs
};

export const ImageListForUnselected: any = {
  dashboard: DBWhite,
  admin_management: AdminWhite,
  all_students: AllStudentsWhite,
  content_management: CMWhite,
  student_management: SMWhite,
  batch_management: BMWhite,
  course_progress: CPWhite,
  action_center: ACWhite,
  hr_student_placement: HRWhite,
  reports: Reports,
  logs: UserLogsWhite,
  app_enquiries: UserLogs
};

export const List = [
  {
    asset: DB,
    text: "Dashboard",
    alt: "dashboard",
    menuaccess: "",
  },
  {
    asset: Admin,
    text: "Admin Management",
    alt: "adminmanagement",
    menuaccess: ["Role Management", "Admin User Management"],
  },
  {
    asset: AllStudents,
    text: "All Students",
    alt: "allstudents",
    menuaccess: "",
  },
  {
    asset: CM,
    text: "Content Management",
    alt: "contentmanagement",
    menuaccess: [
      "Branches",
      "Manage Courses",
      "Course Category",
      "Technology",
      "Course Features",
      "Hiring Companies",
      "Certificate",
      "Testimonials",
      "Contact Info",
      "Push Content",
      "Resources",
    ],
  },
  {
    asset: SM,
    text: "Student Management",
    alt: "studentmanagement",
    menuaccess: "",
  },
  {
    asset: BM,
    text: "Batch Management",
    alt: "batchmanagement",
    menuaccess: "",
  },
  {
    asset: CP,
    text: "Course Progress",
    alt: "courseprogress",
    menuaccess: "",
  },
  {
    asset: AC,
    text: "Action Center",
    alt: "actioncenter",
    menuaccess: [
      "Pending Payment",
      "Daily Transactions",
      "Student Actions",
      "Students Actions Approval Queue",
      "Issue Refund / Adjust Payment",
      "Completed Batches",
      "Calculate Trainer Payment",
      "Process Trainer Payment",
      "Student Feedback/Concerns",
      "Push Content",
      "Session Feedback",
    ],
  },
  {
    asset: HR,
    text: "HR & Student Placement",
    alt: "hr&studentplacement",
    menuaccess: ["Manage Jobs", "Student for HR"],
  },
  {
    asset: Reports,
    text: "Reports",
    alt: "reports",
    menuaccess: "",
  },
  {
    asset: UserLogs,
    text: "User Logs",
    alt: "userlogs",
    menuaccess: ["User Logs", "Student Edit Logs"],
  },
];

export const dropSectionList = [
  {
    text: [
      {
        name: "Role Management",
        key: "rolemanagement",
      },
      {
        name: "Admin User Management",
        key: "adminusermanagement",
      },
    ],
    alt: "adminmanagement",
  },
  {
    text: [
      {
        name: "Branches",
        key: "branches",
      },
      {
        name: "Manage Courses",
        key: "managecourses",
      },
      {
        name: "Course Category",
        key: "coursecategory",
      },
      {
        name: "Technology",
        key: "technology",
      },
      {
        name: "Course Features",
        key: "coursefeatures",
      },
      {
        name: "Hiring Companies",
        key: "hiringcompanies",
      },
      {
        name: "Certificate",
        key: "certificate",
      },
      {
        name: "Testimonials",
        key: "testimonials",
      },
      {
        name: "Contact Info",
        key: "contactinfo",
      },
      {
        name: "Push Content",
        key: "pushcontent",
      },
      {
        name: "Resources",
        key: "resources",
      },
    ],
    alt: "contentmanagement",
  },
  {
    text: [
      {
        name: "Pending Payment",
        key: "pendingpayment",
      },
      {
        name: "Daily Transactions",
        key: "dailytransactions",
      },
      {
        name: "Student Actions",
        key: "studentactions",
      },
      {
        name: "Students Actions Approval Queue",
        key: "studentsactionsapprovalqueue",
      },
      {
        name: "Issue Refund / Adjust Payment",
        key: "issuerefundadjustpayment",
      },
      {
        name: "Completed Batches",
        key: "completedbatches",
      },
      {
        name: "Calculate Trainer Payment",
        key: "calculatetrainerpayment",
      },
      {
        name: "Process Trainer Payment",
        key: "processtrainerpayment",
      },
      {
        name: "Student Feedback/Concerns",
        key: "studentfeedbackconcerns",
      },
      {
        name: "Session Feedback",
        key: "sessionfeedback",
      },
    ],
    alt: "actioncenter",
  },
  {
    text: [
      {
        name: "Manage Jobs",
        key: "managejobs",
      },
      {
        name: "Student for HR",
        key: "studentforhr",
      },
    ],
    alt: "hr&studentplacement",
  },
  {
    text: [
      {
        name: "User Logs",
        key: "userlogs",
      },
      {
        name: "Student Edit Logs",
        key: "studenteditlogs",
      },
    ],
    alt: "userlogs",
  },
];
