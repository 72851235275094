import { Components } from "./ModuleImports";
import { useAppSelector } from "../../../..";

export const AddNewRole = ({ id, mode }: any) => {
  const {
    useState,
    useEffect,
    Loader,
    RoleManagementJSON,
    AllRoles,
    getRoles,
    getPrivilegesData,
    getRoleById,
    createNewRole,
    updateRole,
    StatusButton,
    useSharedLogic,
    FormikCommonFunc,
    AddRoleValidation,
    toast,
    onKeyDown,
    searchMenus,
    SelectLogic,
    RoleName
  }: any = Components;
  const { navigate, routeNameForHome, routeNameForSubSection }: any =
    useSharedLogic();
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const [isLoading, setIsLoading] = useState(false);
  const [searchRole, setSearchRole] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [checked, setChecked] = useState("Active");
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [existingRoles, setExistingRoles] = useState([] as any);
  const [existingRole, setExistingRole] = useState('');
  const isId: any = id !== "add-role" && id;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const getAllRoleNames = await getRoles();
      // console.log(getAllRoleNames, "getAllRoleNames");
      setExistingRoles(getAllRoleNames);
      const getAllRoles = await getPrivilegesData();
      // console.log(getAllRoles, "getAllRoles")
      if (getAllRoles) {
        // setFieldValue("menuaccess", getAllRoles);
        setCheckboxValues(getAllRoles)
      }
      if (id !== "add-role") {
        getRole();
      }
      setIsLoading(false);
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const responseData = await searchMenus(values.menuaccess, searchRole);
  //     console.log(responseData, "responseData");
  //     // setDisplayRoles(data);
  //     // setFieldValue('menuaccess', responseData)
  //     setTriggerSearch(false);
  //   })();
  // }, [triggerSearch]);

  const searchFilter: any = (role: any) => {
    const SearchKeyToLowerCase = searchRole.toLowerCase();
    const filteredMenus = role.label
      ?.toLowerCase()
      .includes(SearchKeyToLowerCase);
    return filteredMenus;
  };

  const getRole = async () => {
    const response: any = await getRoleById(isId);
    const { menuaccess, role, status } = response[0];
    if (response) {
      setValues(
        {
          ...values,
          role,
          // menuaccess,
          status,
        },
        true
      );
      setExistingRole(role);
      setCheckboxValues(menuaccess)
      setChecked(status);
    }
  };

  const add_Role = {
    role: "",
    status: "Active",
    // menuaccess: [],
    createdby: userDetails,
  };

  const forNewRole = async () => {
    const roleExists = existingRoles.some((role:any) =>
      role.toLowerCase() === values.role.toLowerCase()
    );
    if (roleExists  && mode !== "edit") {
      toast.error("This Role already exists");
    } else if (roleExists && mode === 'edit' && existingRole !== values.role) {
      // console.log(roleExists!==values.role, "1", roleExists, "2", values.role)
      toast.error("This Role already exists");
    } else {
      setIsLoading(true);
      const body: any = {
        menuaccess: checkboxValues,
        ...values
      };
      const dataResponse = await (id === "add-role"
        ? createNewRole(body)
        : updateRole(isId, body));
      if (dataResponse) {
        navigate(`/${routeNameForHome}/${routeNameForSubSection}`);
        toast.success(
          mode === "edit"
            ? "User Updated Successfully"
            : "User Created Successfully"
        );
      }
      setIsLoading(false);
    }
  };

  // false

  const {
    formik,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  }: any = FormikCommonFunc(add_Role, AddRoleValidation, forNewRole);

  const { handleCheckboxChange, handleCheckboxChangeForSubMenu } = SelectLogic(formik, setCheckboxValues);

  const CheckValue = (label: any) => {
    setChecked(label);
    setFieldValue("status", label);
  };

  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-[95%]"} />
      ) : (
          <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
          <RoleName formik={formik} searchRole={searchRole} setSearchRole={setSearchRole} setTriggerSearch={setTriggerSearch} mode={mode} />
          <div className="w-[95%] mx-auto bg-white rounded-[6px]">
            <div className="py-4">
              <h1 className="text-[#686868] text-[20px] font-Roboto font-[500] bg-[#EFF7FF] px-4 py-4">
                Select Privileges
              </h1>
              <AllRoles
                roles={values.menuaccess}
                searchFilter={searchFilter}
                // CheckValue={checkFunction}
                // checkSubMenuValue={checkFunctionForSubMenus}
                checkboxValues={checkboxValues}
                handleCheckboxChange={handleCheckboxChange}
                handleCheckboxChangeForSubMenu={handleCheckboxChangeForSubMenu}
              />
            </div>
            <div className="py-8">
              <StatusButton
                type={"submit"}
                buttonText={id === "add-role" ? "Submit" : "Update Role"}
                CheckValue={CheckValue}
                checked={checked}
                buttonWidth={"w-8/12"}
                show={mode !== "view"}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
