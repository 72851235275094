import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedIds: {
    transIds: [],
  },
};

const userTransactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransaction: (state: any, action: PayloadAction<any>) => {
      state.selectedIds = {
        ...state.selectedIds,
        ...action.payload,
      };
    },
  },
});

export const { setTransaction } = userTransactionSlice.actions;
export default userTransactionSlice.reducer;
