import React from 'react'

interface InputBoxWithLabelProps{
  label?: React.ComponentProps<"label">,
  input: React.ComponentProps<"input">,
  wrapper?:React.ComponentProps<"div">
}
const InputBoxWithLabel = ({label,input,wrapper}:InputBoxWithLabelProps) => {
  return (
    <>
      {label && <label {...label} />}
      <input {...input} />
    </>
  )
}

export default InputBoxWithLabel