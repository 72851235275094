import { Components } from './ModuleImports';

const {
  x,
  X1,
  admin1,
  admission1,
  admission2,
  admission3,
  admission4,
  allocatestud,
  allocatestud1,
  app,
  applied,
  applied1,
  average,
  average1,
  closed,
  closed1,
  completebatch,
  completebatch1,
  completestud,
  completestud1,
  droppedstud,
  droppedstud1,
  excellent,
  excellent1,
  free,
  good,
  good1,
  holdbatch,
  holdbatch1,
  holdstud,
  holdstud1,
  inprog,
  inprog1,
  invoice1,
  invoice2,
  job,
  job1,
  joincmny,
  joincmny1,
  openning,
  openning1,
  paid,
  placed,
  placed1,
  poor,
  poor1,
  reopen,
  reopen1,
  shortlist,
  shortlist1,
  totalcmny,
  totalcmny1,
  totalinvoice,
  totalinvoice1,
  totalinvoice2,
  totalinvoice3,
  totalinvoice4,
  unallocatestud,
  unallocatestud1,
  joinedthecompany,
  ongoingbatch,
  upcomingbatch,
  reviewbatch
}: any = Components;  

export const DashboardData = (dashboardData:any) => {
  const Details: any = [
    {
      title: "Admissions",
      admissionDetails: [
        {
          name: "All students",
          count:
            dashboardData?.admission?.paidStudent +
            dashboardData?.admission?.freeStudent +
            dashboardData?.admission?.appStudent,
          img: admin1,
          logo: admission4,
          className: "#036AD1",
          height: "25%",
        },
        {
          name: "Paid students",
          count: dashboardData?.admission?.paidStudent,
          img: paid,
          logo: admission2,
          className: "#22B407",
          height: "25%",
        },
        {
          name: "Free students",
          count: dashboardData?.admission?.freeStudent,
          img: free,
          logo: admission3,
          className: "#D10378",
          height: "25%",
        },
        {
          name: "App students",
          count: dashboardData?.admission?.appStudent,
          img: app,
          logo: admission1,
          className: "#CF7C00",
          height: "25%",
        },
      ],
      graph: [
        {
          name: "Paid students",
          count: dashboardData?.admission?.paidStudent,
        },
        {
          name: "Free students",
          count: dashboardData?.admission?.freeStudent,
        },
        {
          name: "App students",
          count: dashboardData?.admission?.appStudent,
        },
      ],
      invoiceTitle: "Invoice & Revenue",
      invoiceAnalysis: "Invoice Analysis",
      invoice: [
        {
          name: "Total Invoice",
          count: dashboardData?.invoiceAnalysis?.invoice?.total,
          img: totalinvoice,
          logo: invoice1,
          className: "#036AD1",
          height: "25%",
        },
        {
          name: "Classroom Invoice",
          count: dashboardData?.invoiceAnalysis?.invoice?.classRoom,
          img: totalinvoice,
          logo: invoice1,
          className: "#D10378",
          height: "25%",
        },
        {
          name: "Online Invoice",
          count: dashboardData?.invoiceAnalysis?.invoice?.online,
          img: totalinvoice1,
          logo: invoice1,
          className: "#CF7C00",
          height: "25%",
        },
        {
          name: "Hybrid Invoice",
          count: dashboardData?.invoiceAnalysis?.invoice?.hybrid,
          img: totalinvoice,
          logo: invoice1,
          className: "#FF0000",
          height: "25%",
        },
        {
          name: "Total Revenue",
          count: dashboardData?.invoiceAnalysis?.revenue?.total,
          img: totalinvoice2,
          logo: invoice2,
          className: "#036AD1",
          height: "25%",
        },
        {
          name: "Classroom Revenue",
          count: dashboardData?.invoiceAnalysis?.revenue?.classRoom,
          img: totalinvoice3,
          logo: invoice2,
          className: "#D10378",
          height: "25%",
        },
        {
          name: "Online Revenue",
          count: dashboardData?.invoiceAnalysis?.revenue?.online,
          img: totalinvoice4,
          logo: invoice2,
          className: "#CF7C00",
          height: "25%",
        },
        {
          name: "Hybrid Revenue",
          count: dashboardData?.invoiceAnalysis?.revenue?.hybrid,
          img: totalinvoice3,
          logo: invoice2,
          className: "#FF0000",
          height: "25%",
        },
      ],
      data: [
        {
          name: "Classroom Invoice",
          value: 1,
          fill: "#D10378",
        },
        {
          name: "Online Invoice",
          value: 1,
          fill: "#CF7C00",
        },
        {
          name: "Hybrid Invoice",
          value: 1,
          fill: "#FF0000",
        },
      ],
      dataOp: [
        {
          name: "Allocated Students",
          value: dashboardData?.operations?.Allocated,
          fill: "#22B407",
        },
        {
          name: "Unallocated Students",
          value: dashboardData?.operations?.Unallocated,
          fill: "#FF0000",
        },
        {
          name: "Dropped Students",
          value: dashboardData?.operations?.Drop,
          fill: "#A5401A",
        },
        {
          name: "Hold Students",
          value: dashboardData?.operations?.Hold,
          fill: "#CF7C00",
        },
        {
          name: "Completed Students",
          value: dashboardData?.operations?.Completed,
          fill: "#127200",
        },
      ],

      operationTitle: "Operations",
      operations: [
        {
          name: "Unallocated Students",
          count: dashboardData?.operations?.Unallocated,
          img: unallocatestud1,
          logo: unallocatestud,
          className: "#FF0000",
          height: "25%",
        },
        {
          name: "Hold Students",
          count: dashboardData?.operations?.Hold,
          img: holdstud1,
          logo: holdstud,
          className: "#CF7C00",
          height: "25%",
        },
        {
          name: "Dropped Students",
          count: dashboardData?.operations?.Drop,
          img: droppedstud1,
          logo: droppedstud,
          className: "#A5401A",
          height: "25%",
        },
        {
          name: "Allocated Students",
          count: dashboardData?.operations?.Allocated,
          img: allocatestud1,
          logo: allocatestud,
          className: "#22B407",
          height: "25%",
        },
        {
          name: "Completed Students",
          count: dashboardData?.operations?.Completed,
          img: completestud1,
          logo: completestud,
          className: "#127200",
          height: "25%",
        },
      ],
      // operationsChart: [
      //   {
      //     name: "Allocated Students",
      //     value: 1200,
      //     fill: "#22B407",
      //   },
      //   {
      //     name: "Unallocated Students",
      //     value: 500,
      //     fill: "#FF0000",
      //   },
      //   {
      //     name: "Hold Students",
      //     value: 300,
      //     fill: "#CF7C00",
      //   },
      //   {
      //     name: "Dropped Students",
      //     value: 200,
      //     fill: "#A5401A",
      //   },
      // ],

      batchTitle: "Batch",
      Batch: [
        {
          name: "Ongoing Batch",
          count: dashboardData?.batch?.Ongoing,
          img: ongoingbatch,
          logo: ongoingbatch,
          className: "#22B407",
          height: "25%",
        },
        {
          name: "Upcoming Batch",
          count: dashboardData?.batch?.Upcoming,
          img: upcomingbatch,
          logo: upcomingbatch,
          className: "#9E9404",
          height: "25%",
        },
        {
          name: "Review Batch",
          count: dashboardData?.batch?.Review,
          img: reviewbatch,
          logo: reviewbatch,
          className: "#FFC400",
          height: "25%",
        },
        {
          name: "Dropped Batch",
          count: dashboardData?.batch?.Drop,
          img: x,
          logo: "x",
          className: "#FF0000",
          height: "25%",
        },
        {
          name: "Completed Batch",
          count: dashboardData?.batch?.Completed,
          img: completebatch,
          logo: completebatch1,
          className: "#127200",
          height: "25%",
        },
        {
          name: "Hold Batch",
          count: dashboardData?.batch?.Hold,
          img: holdbatch,
          logo: holdbatch1,
          className: "#CF7C00",
          height: "25%",
        },
      ],
      batchChart: [
        {
          name: "Completed Batch",
          count: dashboardData?.batch?.Completed,
          COLORS: "#000",
        },
        {
          name: "Ongoing Batch",
          count: dashboardData?.batch?.Ongoing,
          COLORS: "#CF7C00",
        },
        {
          name: "Upcoming Batch",
          count: dashboardData?.batch?.Upcoming,
          COLORS: "#A5401A",
        },
        {
          name: "Review Batch",
          count: dashboardData?.batch?.Review,
          COLORS: "#A5401A",
        },
        {
          name: "Dropped Batch",
          count: dashboardData?.batch?.Drop,
          COLORS: "#A5401A",
        },
        {
          name: "Hold Batch",
          count: dashboardData?.batch?.Hold,
          COLORS: "#A5401A",
        },
      ],
      sessionFTitle: "Session Feedback",
      Sessiondata: [
        {
          name: "Poor",
          count: dashboardData?.feedback?.Poor,
          img: excellent1,
          logo: poor,
          className: "#FF0303",
          height: "25%",
        },
        {
          name: "Average",
          count: dashboardData?.feedback?.Average,
          img: good1,
          logo: average,
          className: "#CF7C00",
          height: "25%",
        },
        {
          name: "Good",
          count: dashboardData?.feedback?.Good,
          img: average1,
          logo: good,
          className: "#22B407",
          height: "25%",
        },
        {
          name: "Excellent",
          count: dashboardData?.feedback?.Excellent,
          img: poor1,
          logo: excellent,
          className: "#127200",
          height: "25%",
        }
      ],
      SessionChart: [
        {
          name: "Poor",
          count: `${dashboardData?.feedback?.Poor + "%"}`,
          COLORS: "#FF0000 ",
        },
        {
          name: "Average",
          count: `${dashboardData?.feedback?.Average + "%"}`,
          COLORS: "#CF7C00",
        },
        {
          name: "Good",
          count: `${dashboardData?.feedback?.Good + "%"}`,
          COLORS: "#22B407",
        },
        {
          name: "Excellent",
          count: `${dashboardData?.feedback?.Excellent + "%"}`,
          COLORS: "#127200",
        },
      ],

      FeedbackTitle: "Feedback / Concern",
      Feedback: [
        {
          name: "Open",
          count: dashboardData?.feedback?.Open,
          img: x,
          logo: "x",
          className: "#FF0000",
          height: "25%",
        },
        {
          name: "In Progress",
          count: dashboardData?.feedback?.inprogress,
          img: closed1,
          logo: closed,
          className: "#CF7C00",
          height: "25%",
        },
        {
          name: "Re-open",
          count: dashboardData?.feedback?.reopen,
          img: reopen1,
          logo: reopen,
          className: "#036AD1",
          height: "25%",
        },
        {
          name: "Closed",
          count: dashboardData?.feedback?.Resolved,
          img: inprog1,
          logo: inprog,
          className: "#127200",
          height: "25%",
        },
        {
          name: "Thanks for the feedback",
          count: dashboardData?.feedback?.Thanks,
          img: poor1,
          logo: excellent,
          className: "#127200",
          height: "25%",
        },
      ],
      feedbackChart: [
        {
          name: "Open",
          count: `${dashboardData?.feedback?.Open + "%"}`,
          COLORS: "#FF0000 ",
        },
        {
          name: "In Progress",
          count: `${dashboardData?.feedback?.inprogress + "%"}`,
          COLORS: "#CF7C00",
        },
        {
          name: "Re-open",
          count: `${dashboardData?.feedback?.reopen + "%"}`,
          COLORS: "#036AD1",
        },
        {
          name: "Closed",
          count: `${dashboardData?.feedback?.Resolved + "%"}`,
          COLORS: "#127200",
        },
        {
          name: "Thanks for the feedback",
          count: `${dashboardData?.feedback?.Thanks + "%"}`,
          COLORS: "#127200",
        },
      ],

      placementTitle: "Placement",
      placement: [
        {
          name: "Total Companies",
          count: dashboardData?.jobs?.totalCompanies,
          img: totalcmny1,
          logo: totalcmny,
          className: "#036AD1",
          height: "25%",
        },
        {
          name: "Jobs",
          count: dashboardData?.jobs?.totaljobs,
          img: placed1,
          logo: job,
          className: "#FFF700",
          height: "25%",
        },
        {
          name: "Openings",
          count: dashboardData?.jobs?.totalOpenings,
          img: shortlist1,
          logo: openning,
          className: "#CF7C00",
          height: "25%",
        },
        {
          name: "Applied",
          count: dashboardData?.jobs?.applied,
          img: applied1,
          logo: applied,
          className: "#03D1B5",
          height: "25%",
        },
        {
          name: "Shortlisted",
          count: dashboardData?.jobs?.shortlisted,
          img: openning1,
          logo: shortlist,
          className: "#22B407",
          height: "25%",
        },
        {
          name: "Placed",
          count: dashboardData?.jobs?.placed,
          img: job1,
          logo: placed,
          className: "#127200",
          height: "25%",
        },
        {
          name: "Joined the Company",
          count: dashboardData?.jobs?.joined,
          img: joinedthecompany,
          logo: joincmny,
          className: "#BF0067",
          height: "25%",
        },
      ],
      placementChart: [
        {
          name: "Total Companies",
          count: dashboardData?.jobs?.totalCompanies,
        },
        {
          name: "Jobs",
          count: dashboardData?.jobs?.totaljobs,
        },
        {
          name: "Openings",
          count: dashboardData?.jobs?.totalOpenings,
        },
        {
          name: "Applied",
          count: dashboardData?.jobs?.applied,
        },
        {
          name: "Shortlisted",
          count: dashboardData?.jobs?.shortlisted,
        },
        {
          name: "Placed",
          count: dashboardData?.jobs?.placed,
        },
        {
          name: "Joined the Company",
          count: dashboardData?.jobs?.joined,
        },
      ],
    },
  ];
  return Details;
}