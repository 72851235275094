import {useState} from 'react';
import { BatchDropdown } from "./BatchDropdown";
import { InputBox } from '../../Elements/InputBox';
import { Time } from "./Time";

export const Course = ({
  formik,
  courseModes,
  handleFields,
  cityDetails,
  citiesData,
  handleCityValues,
  branchNameDetails,
  handleBranchValues,
  branchRooms,
  courseScheduleList,
  handleScheduleWithDays,
  scheduleDays,
  handleScheduleData
}: any) => {
  return (
    <div className="space-y-6">
      <div>
        <p className="text-[#686868] text-[21px] font-Roboto font-[400]">
          Course
        </p>
        <h1 className="text-[#000000] text-[24px] font-Roboto font-[500]">
          {formik.values.course ? formik.values.course : "-"}
        </h1>
      </div>
      <div className="grid grid-cols-3 gap-10">
        <BatchDropdown
          name="course_mode"
          label="Course Mode"
          showLabel={true}
          options={courseModes}
          placeholder="Batch Status"
          value={formik.values.course_mode}
          onChange={handleFields}
          onBlur={formik.handleBlur}
          error={formik.errors.course}
          touched={formik.touched.course}
        />
        <BatchDropdown
          name="city"
          label="City"
          showLabel={true}
          options={
            citiesData.length > 0 &&
            citiesData
              ?.filter((fil: any) => cityDetails[0]?.city_id?.includes(fil?.id))
              ?.map((mp: any) => mp?.city_name)
          }
          placeholder="City"
          value={formik.values.city}
          onChange={handleCityValues}
          onBlur={formik.handleBlur}
          error={formik.errors.city}
          touched={formik.touched.city}
        />
        <BatchDropdown
          name="branch"
          label="Branch"
          showLabel={true}
          options={branchNameDetails}
          placeholder="Branch"
          value={formik.values.branch}
          onChange={handleBranchValues}
          onBlur={formik.handleBlur}
          error={formik.errors.branch}
          touched={formik.touched.branch}
        />
        <BatchDropdown
          name="rooms"
          label="Rooms (Optional)"
          showLabel={true}
          options={branchRooms}
          placeholder="Classrooms"
          value={formik.values.rooms}
          onChange={handleFields}
          onBlur={formik.handleBlur}
        />
        <BatchDropdown
          name="schedule"
          label="Course Schedule"
          showLabel={true}
          options={courseScheduleList}
          placeholder="Course Schedule"
          value={formik.values.schedule}
          onChange={handleScheduleWithDays}
          onBlur={formik.handleBlur}
        />
        <BatchDropdown
          name="schedule_days"
          label="Schedule Days"
          showLabel={true}
          options={scheduleDays}
          placeholder="Schedule Days"
          value={formik.values.schedule_days}
          onChange={handleScheduleData}
          onBlur={formik.handleBlur}
          useCheckbox={true}
          multiSelect={true}
        />
        <InputBox
            label="Course Duration"
            placeholder="Course Duration"
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"duration"}
            value={formik.values.duration}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.duration}
            isTouched={formik.touched.duration}
        />
        <InputBox
            label="Start Date"
            placeholder="Start Date"
            type={"date"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"start_date"}
            value={formik.values.start_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.start_date}
            isTouched={formik.touched.start_date}
        />
        <BatchDropdown
          name="start_time"
          label="Start Time"
          showLabel={true}
          options={Time.includes(formik.values.end_time) ? Time.filter((item:any) => item !== formik.values.end_time) : Time}
          placeholder="Start Time"
          value={formik.values.start_time}
          onChange={handleFields}
          onBlur={formik.handleBlur}
        />
        <BatchDropdown
          name="end_time"
          label="End Time"
          showLabel={true}
          options={Time.includes(formik.values.start_time) ? Time.filter((item:any) => item !== formik.values.start_time) : Time}
          placeholder="End Time"
          value={formik.values.end_time}
          onChange={handleFields}
          onBlur={formik.handleBlur}
        />
        <InputBox
            label="Planned Date of Completion"
            placeholder="Planned Date of Completion"
            type={"date"}
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"planned_date_of_completion"}
            value={formik.values.planned_date_of_completion}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <InputBox
            label="End Date"
            placeholder="End Date"
            topDivStyle={"flex flex-col w-full"}
            classNameInput={"w-full"}
            name={"end_date"}
            value={formik.values.end_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={true}
        />
      </div>
    </div>
  );
};
