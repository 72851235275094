import React from 'react'

export const CloseIcon = ({ closeFunc, classStyle }: any) => {
  return (
    <div
      onClick={closeFunc}
      className={`bg-[#FF0000] cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center ${classStyle}`}
    >
      X
    </div>
  );
};
