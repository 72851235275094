export const ReadData = ({ head, body, addSpan, color, mainDiv }: any) => {
  return (
    <div className={`min-h-[46px] ${mainDiv}`}>
      <p className="text-[#686868] text-[15px] 2xl:text-[16px] font-Roboto font-[400]">
        {head}
      </p>
      <h1 className={`${color?color:'text-[#000000]'} text-[18px] 2xl:text-[19px] font-Roboto font-[500] break-words`}>
        {addSpan && addSpan!=="-" && <span className={`px-1 ${color}`}>&#8377;</span>}{body}
      </h1>
    </div>
  );
};
