export const TrendingTagComp = ({ data }: any) => {
  return (
    <div className="flex flex-col items-start">
      {data.shortNote && (
        <p className="text-[10px] text-white px-1.5 rounded-sm bg-blue-500 inline capitalize">
          {data.shortNote}
        </p>
      )}
      <p>{data.name}</p>
    </div>
  );
};
