import { Navigate, useNavigate } from "react-router-dom"

export const BackButton = ({topDivStyle, header}:any) => {
    const navigate = useNavigate();
  return (
    <div className={`flex items-center ${topDivStyle} mx-auto`}>
      {header && <h1 className="text-[#FFFFFF] text-[30px] font-Roboto font-medium">{header}</h1>}
      <button
        className="p-2.5 px-6 h-[45px] bg-gradient-to-r from-[#E91F00] to-[#E95900] rounded-[6px] shadow-md shadow-[#00000029] cursor-pointer text-white text-[20px] font-Roboto font-normal"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
    </div>
  );
}