import { CloseIcon } from "./CloseIcon";

export const MessageCard = ({ message, buttonText, handleClick, closeFunc }: any) => {
  return (
    <div className="bg-white flex flex-col items-center justify-center w-[631px] h-[324px] drop-shadow-lg rounded-[6px] shadow-md shadow-[#00000029] relative">
      <CloseIcon
        closeFunc={closeFunc}
        classStyle="text-[20px] w-[30px] h-[30px] absolute -top-3 -right-3"
      />
      <div className="flex flex-col items-center justify-center gap-10">
        <h1 className="text-[#707070] text-[25px] font-Roboto font-medium">
          {message}
        </h1>
        <button
          onClick={handleClick}
          className="bg-[#036AD1] rounded-[27px] p-1.5 px-4 text-white text-[20px] font-Roboto font-normal"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}
