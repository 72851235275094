import { dateFormat } from "../../../Services/CommonFunctions"
import { calculatePercentage, errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables"
import { FetchData, InsertData } from "../../crud"
import { PushContentInterface } from "./interface"

// For Admin
export const getPushContentTableData = async () => {
    try {
        const { data: pushContent, error } = await FetchData(TableNames.push_content, ["id", "created_at", "thumbnail", "content_type", "course_completion"]).order("id", {ascending: false});
        if (error) throw error
        
        return pushContent?.map((content: any) => ({...content, date: dateFormat(content?.created_at, "DD MMM YYYY"), icon: content?.thumbnail.url}))

    } catch (error) {
        return errorNotifier(error)
    }
}

export const getPreloadData = async (UsersDetails: any) => {
    try {
        const { data: technologies } = await FetchData(TableNames.technologies, ["id", "technology"])
        const { data: branches, error } = await FetchData(TableNames.branches, ["id", "branch_name"]).in("id", UsersDetails?.branches);
        
        if (error) throw error
        return {technologies, branches}
    } catch (error) {
        return errorNotifier(error)
    }
}

export const createPushContent = async (pushContentData: PushContentInterface) => {
    try {
        const { data, error } = await InsertData(TableNames.push_content, pushContentData)
        if(error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getPushContentById =  async(content_id:any) => {
    try {
        const { data, error } = await FetchData(TableNames.push_content, ["*"]).eq("id", content_id);
        if(error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

// For Student
export const getPushContentByStudentUid = async (studentUid: string) => {
    try {
        const { data:training , error }: any = await FetchData(TableNames.trainings,
             ["id", "duration", "course_id", "batch:batches(in_progress, branch_id)","courses:courses(duration)", "status", "batch_id"])
             .eq("student_id", studentUid).order("id", {ascending: false})
    //courses.duration
        if (!training.length) {
            return false;
        }
        const filtered_trainings = training?.filter((training: any) => training?.batch?.in_progress === true)
        // console.log(filtered_trainings[0]?.batch.branch_id)
        const { data: technology }: any = await FetchData(TableNames.technologies, ["id"]).contains("courses", [filtered_trainings[0]?.course_id])
        // console.log(technology)
        const { data: student_sessions }: any = await FetchData(TableNames.sessions, ["id", "batch_id", "attendees", "title", "batch:batches(course:courses(course_name), branch:branches(id, branch_name), duration)", "details", "duration", "session_date", "recent_session"]).eq("batch_id", filtered_trainings[0]?.batch_id).order("id", { ascending: false })
        const sessions = student_sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(studentUid) }))
        const filtered_students = sessions?.filter((stu: any) => { return stu?.is_present === true })
        const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
        const courseProgress = calculatePercentage(total_session_time, training[0]?.courses.duration)
        // console.log(courseProgress)
        const { data: pushContent } = await FetchData(TableNames.push_content).contains("technology", [technology[0].id]).or(`student_status.eq.${filtered_trainings[0]?.status}, branch.cs.{${filtered_trainings[0]?.batch.branch_id}}, content_type.eq.Predefined Push Content`).order("id", { ascending: false })
        // console.log(pushContent,'df')
        const filtered_push_content = pushContent?.filter((el: any) => (courseProgress <= el.course_completion))
        if(error) throw error
        return filtered_push_content
    } catch (error){
        return errorNotifier(error)
    }
}

