import { useState, useEffect, useRef } from "react";
import upload from "../../Assets/upload.svg";
import { uploadFile, updateFile, deleteFile } from "../../functions/storage/storage";
import { RandomString } from "../../Services/CommonFunctions";
import { Loader } from "../Elements/Loader/Loader";
import toast from "react-hot-toast";
interface MyComponentProps {
    mode: string;
    id: string;
    labelText: string;
    disable?: boolean;
    accept: string;
    fileSize?: number;
    sizeInText?: string;
    Method: string;
    bucketName: string;
    forValue: any;
    fileNameData?: string;
    fileNameKey: string;
    preview?: string;
    index?: number;
    containerStyle?: string;
    subBucket?: string;
    buttonContainer?: string;
    previewImgStyle?: string;
    error?: any;
    isTouched?: any;
    name?:string;
    disableBasedOnMode?: boolean;
}

export const UploadOrUpdateBucket: React.FC<MyComponentProps> = ({
  mode,
  id,
  labelText,
  disable,
  accept,
  fileSize,
  sizeInText,
  Method,
  bucketName,
  forValue,
  fileNameData,
  fileNameKey,
  preview,
  index,
  containerStyle,
  subBucket,
  buttonContainer,
  previewImgStyle,
  error,
  isTouched,
  name,
  disableBasedOnMode = true,
}) => {
  const MAX_FILE_SIZE = fileSize && fileSize * 1024 * 1024; // 5MB
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const fileInputRef: any = useRef(null);
  const checkUploadRef: any = useRef(null);
  const checkIsError = isTouched && error;

  // const fieldNames:any = Object.keys('');
  // console.log(fieldNames, "fieldNames")

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (loading) {
        event.preventDefault();
        // Prompt the user with a custom message before leaving the page
        event.returnValue =
          "Are you sure you want to leave? Your upload will be cancelled.";
      }
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [loading]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    // console.log(Method, "Method", subBucket, "subBucket")
    setLoading(true);
    const file = event.target.files[0];
    // console.log(file?.size, MAX_FILE_SIZE, "M_M")
    if (MAX_FILE_SIZE ? file?.size <= MAX_FILE_SIZE : true) {
      if (preview && fileNameKey !== "") {
        DeleteUploadedFile(fileNameKey, bucketName, forValue);
      }
      const randomKey = RandomString();
      const fileName =
        Method === "Upload"
          ? subBucket
            ? `${subBucket}/` + randomKey
            : randomKey
          : subBucket
          ? `${subBucket}/` + fileNameKey
          : fileNameKey;
      const Response: any = await (Method === "Upload"
        ? uploadFile
        : updateFile)({ file: file, fileName: fileName }, bucketName);
      if (Response) {
        forValue(
          Response,
          Method,
          index,
          subBucket,
          file,
          fileName,
          Method === "Upload" && randomKey
        );
      } else {
        console.log(Response);
      }
    } else {
      toast.error("File size exceeds the maximum allowed size");
      console.error("File size exceeds the maximum allowed size."); //Check if Clicked Cancel
    }
    setLoading(false);
  };

  const DeleteUploadedFile: any = async (
    fileNameKey: string,
    bucketName: string,
    forValue: any
  ) => {
    setLoading(true);
    const fileName = subBucket ? `${subBucket}/` + fileNameKey : fileNameKey;
    const Method = "Delete";
    const deleteResponse: any = await deleteFile(fileName, bucketName);
    if (deleteResponse) {
      if (checkUploadRef.current) {
        checkUploadRef.current.value = "";
      }
      setImageLoaded(false);
      forValue(deleteResponse, Method, index, subBucket);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } else {
      console.log(deleteResponse);
    }
    setLoading(false);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={`space-y-2 relative`}>
      <div className={`space-y-1 ${buttonContainer} relative`}>
        {disableBasedOnMode && <div className="relative flex items-center justify-evenly gap-2 bg-[#036AD1] rounded-[20px] cursor-pointer p-1 px-4">
          <label
            htmlFor={id}
            className="text-white cursor-pointer font-Roboto font-[500]"
          >
            {labelText}
          </label>
          <input
            id={id}
            name={name ? name : ""}
            disabled={disable}
            type="file"
            accept={accept} // "image/*, video/*"
            style={{ display: "none" }}
            ref={checkUploadRef}
            onChange={handleFileChange}
          />
          <img
            src={upload}
            alt="Upload Icon"
            onClick={handleUploadClick}
            style={{ cursor: "pointer" }}
          />
        </div>}
        {sizeInText && (
          <p className="font-Roboto font-[400] text-[10px] text-[#FF0000] text-center">{`(${sizeInText})`}</p>
        )}
        {checkIsError && (
          <span className="text-red-500 absolute -bottom-6 right-0">
            {error}
          </span>
        )}
      </div>
      {preview && (
        <div className={`w-full ${containerStyle}`}>
          {loading ? (
            <Loader widthFix={"w-10/12"} heightIfGiven={"h-8"} />
          ) : (
            fileNameKey && (
              <div
                className={`flex items-center justify-center relative ${
                  (subBucket && subBucket === "syllabus") ||
                  subBucket === "coursematerials"
                    ? "w-full"
                    : "w-6/12 mx-auto"
                }`}
              >
                {fileNameData ? (
                  <p className="font-Roboto font-[400] text-[14px] cursor-pointer">
                    {fileNameData}
                  </p>
                ) : (
                  <img
                    src={preview}
                    alt="File preview"
                    className={`${
                      previewImgStyle ? previewImgStyle : "w-[120px]"
                    }`}
                    onLoad={handleImageLoad}
                    ref={fileInputRef}
                  />
                )}
                {disableBasedOnMode && mode !== "edit" && fileNameData && (
                  <div
                    onClick={() =>
                      DeleteUploadedFile(fileNameKey, bucketName, forValue)
                    }
                    className="select-none bg-[#FF0000] w-5 h-5 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-7 -top-2"
                  >
                    x
                  </div>
                )}
                {disableBasedOnMode && mode !== "edit" && !fileNameData && imageLoaded && (
                  <div
                    onClick={() =>
                      DeleteUploadedFile(fileNameKey, bucketName, forValue)
                    }
                    className="select-none bg-[#FF0000] w-5 h-5 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-7 -top-2"
                  >
                    x
                  </div>
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};