import React from 'react'

export const AddItemsButton = ({ text, classNameInput, onClick, disabled }: any) => {
  return (
    <div className={`${classNameInput}`}>
      <button
        disabled={disabled}
        type="button"
        onClick={onClick}
        className="select-none bg-[#DF0952] flex items-center gap-1.5 rounded-[4px] text-white text-[20px] font-Roboto font-[500] px-3"
      >
        {text} <span className="text-4xl">+</span>
      </button>
    </div>
  );
};
