import { Components } from "./ModuleImports"
import { TotalFee } from "./TotalFee";

export const PaymentSummary = ({
  id,
  updatedStudentData,
  paymentDetails,
  feeTotal,
  adjustPaymentData
}: any) => {
  const { ReadData, Summary, formatNumberWithCommas }: any = Components;

  // const list = Summary(updatedStudentData);
  const mapPaidAmount =
    paymentDetails?.length > 0 && paymentDetails.map((amt: any) => Number(amt?.paid_amount));
  const sum =
    mapPaidAmount &&
    mapPaidAmount.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      0
    );

  const filterAdjustedAmount = paymentDetails?.length > 0 && adjustPaymentData?.filter((fil:any)=> Number(fil?.from_training?.id)===Number(id))
  const mapAdjustedAmount =
    paymentDetails?.length > 0 &&
    filterAdjustedAmount?.map((amt: any) => Number(amt?.amount));
  const sumAdjustedAmount =
    mapAdjustedAmount &&
    mapAdjustedAmount.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      0
    );
  
  const removeAdjustedFromPaidAmount = (sum
      ? typeof sum === "number"
        ? parseFloat(sum?.toFixed(1))
        : parseFloat(Number(sum)?.toFixed(1))
      : 0) -
      (sumAdjustedAmount
        ? typeof sumAdjustedAmount === "number"
          ? parseFloat(sumAdjustedAmount?.toFixed(1))
          : parseFloat(Number(sumAdjustedAmount)?.toFixed(1))
        : 0)

  const Balance_Amount = updatedStudentData?.balance_due;
  const addedAdjustedAmountInBalanceDue = (Balance_Amount
        ? typeof Balance_Amount === "number"
          ? parseFloat(Balance_Amount?.toFixed(1))
          : parseFloat(Number(Balance_Amount)?.toFixed(1))
        : 0) + (sumAdjustedAmount
        ? typeof sumAdjustedAmount === "number"
          ? parseFloat(sumAdjustedAmount?.toFixed(1))
          : parseFloat(Number(sumAdjustedAmount)?.toFixed(1))
    : 0)
  
  const details: any = [
    {
      head: "Course",
      body: updatedStudentData?.course_id?.course_name,
    },
    {
      head: "Fees",
      body: feeTotal
        ? parseFloat(Number(feeTotal?.toString().replace(/,/g, ""))?.toFixed(1))
        : 0,
    },
    {
      head: "Paid Amount",
      body: removeAdjustedFromPaidAmount,
    },
    {
      head: "Balance Due",
      body: addedAdjustedAmountInBalanceDue,
    },
    {
      head: "Adjusted",
      body: sumAdjustedAmount
        ? typeof sumAdjustedAmount === "number"
          ? parseFloat(sumAdjustedAmount?.toFixed(1))
          : parseFloat(Number(sumAdjustedAmount)?.toFixed(1))
        : 0,
    },
  ];
  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        Payment Summary
      </h1>
      <div className="shadow-md shadow-[#00000029] rounded-[3px] flex items-center">
        {details.map((list: any, index: number, array: any) => {
          return (
            <div
              key={index}
              className={`${
                index === 0 ? "w-[50%] bg-[#FFFFFF]" : "w-[20%] bg-[#F9F9F9]"
              } ${
                index === array.length - 1
                  ? ""
                  : "border-r-[#036AD1] border-[0.5px]"
              } px-4 py-3`}
            >
              <ReadData
                head={list.head}
                body={formatNumberWithCommas(list.body)}
                addSpan={list.head === "Course" ? "-" : list.body}
                color={list.head === "Balance Due" && "text-[#FF0000]"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
