import React, { useState, useEffect } from 'react';
import { OnlineBill } from './OnlineBill';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PulseLoader } from 'react-spinners';

export const ModalForInvoice = ({ onClose, values, paymentObject }: any) => {
  const [loading, setLoading] = useState(false);
  
  // const handleDownloadPDF = () => {
  //   const input = document.getElementById('modal-content');
  //   if (input) {
  //     setLoading(true)
  //     html2canvas(input).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF();
  //       const width = pdf.internal.pageSize.getWidth();
  //       const height = pdf.internal.pageSize.getHeight();
  //       pdf.addImage(imgData, 'PNG', 0, 0, width, height);
  //       pdf.save('invoice.pdf');
  //     });
  //     setLoading(false);
  //   }
  // };

  const handleDownloadPDF = () => {
    const input = document.getElementById('modal-content');
    if (input) {
        setLoading(true);
        // Get the scrollable height of the content
        const scrollHeight = input.scrollHeight;
        
        html2canvas(input, { height: scrollHeight }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, 'PNG', 0, 0, width, height);
            pdf.save('invoice.pdf');
            setLoading(false);
        });
    }
  };
  
  return (
    <div className="fixed z-auto inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-[65%] mx-auto">
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
            <button
              onClick={handleDownloadPDF}
              type="button"
              disabled={loading}
              className={`${loading && 'opacity-45'} w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm`}
            >
              Download PDF
            </button>
          </div>
          <div className="bg-white px-4 py-5 sm:p-6" id="modal-content">
            <OnlineBill values={values} paymentObject={paymentObject} />
          </div>
        </div>
      </div>
    </div>
  );
};
