import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  signInData: {
    data: [],
  },
};

const userSelectedUserSlice = createSlice({
  name: "signInInput",
  initialState,
  reducers: {
    setSignInData: (state: any, action: PayloadAction<any>) => {
      state.signInData = {
        ...state.signInData,
        ...action.payload,
      };
    },
  },
});

export const { setSignInData } = userSelectedUserSlice.actions;
export default userSelectedUserSlice.reducer;
