import { getImports } from "./ModuleImports";

export const Add_Batch = ({ formik, setSwapComp }: any) => {
    const {
      useState,
      CloseIcon,
      useSharedLogic,
      createSearchParams,
      addBatchFollowUp,
      toast
    }: any = getImports;
    const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
    const [follow_up_date, setFollow_up_date] = useState("");
    const [comment, setComment] = useState("");

    const BatchSwitch = async (val: any) => {
        if (val === "submit") {
            if (follow_up_date !== "" && comment !== "") {
                const sendBatchDate = await addBatchFollowUp({
                    follow_up_date: follow_up_date,
                    comment: comment
                })
                if (sendBatchDate) {
                    formik.setFieldValue('batch_follow_up_show', [...formik.values.batch_follow_up_show, {
                        follow_up_date: sendBatchDate[0]?.follow_up_date,
                        comment: sendBatchDate[0]?.comment
                    }])
                    formik.setFieldValue('batch_follow_up_date', sendBatchDate[0]?.follow_up_date)
                    formik.setFieldValue('batch_follow_up', [...formik.values.batch_follow_up, sendBatchDate[0]?.id])
                    setSwapComp(["batch-all"])
                }
            } else toast.error("Fill All the Fields")
        } else {
            navigate({
                pathname: `/${routeNameForHome}`,
                search: createSearchParams({id: `new-student-course`}).toString()
                });
            setSwapComp(["batch-all"])
        }
    }

    return (
      <form className="bg-white shadow-md shadow-[#00000029] rounded-[6px] w-4/12 mx-auto py-4 space-y-4 relative">
        <CloseIcon
          closeFunc={() => BatchSwitch("close")}
          classStyle="text-[33px] w-[40px] h-[40px] absolute -top-4 -right-4"
        />
        <div className="w-11/12 mx-auto space-y-4">
          <div>
            <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
              Batch Follow-up Date
            </label>
            <input
              autoFocus
              required
              type={"date"}
              name={"follow_up_date"}
              placeholder="01/01/2023"
              value={follow_up_date}
              onChange={(e: any) => setFollow_up_date(e.target.value)}
              min={new Date().toISOString().split("T")[0]}
              className="resize-none outline-none border-[0.5px] border-[#707070] 2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto rounded-[3px] px-2 py-2 w-full"
            />
          </div>
          <div>
            <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
              Comment
            </label>
            <textarea
              required
              name={"comment"}
              placeholder="Comment"
              value={comment}
              onChange={(e: any) => setComment(e.target.value)}
              className="resize-none outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full min-h-[130px]"
            >
              {comment}
            </textarea>
          </div>
        </div>
        <div className="flex justify-center items-center w-4/12 mx-auto">
          <button
            type="button"
            onClick={() => BatchSwitch("submit")}
            className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-8 font-Roboto font-[400]"
          >
            Submit
          </button>
        </div>
      </form>
    );
}