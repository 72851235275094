import { useEffect } from "react";
import { Components } from "./ModuleImports";
// import { TotalFee } from "./TotalFee";
import { getBranchForGST } from "../../../functions/admin/Students/students";

export const CourseDetails = ({updatedStudentData, setFeeTotal, feeTotal, branchDetails, paymentObject}:any) => {
    const { useState }: any = Components;
    const headData = ['Course', 'Course Fee', 'Discount', 'Final Course Fee'];
    const [checked, setChecked] = useState([] as any);
  
    const foundVariable = (variable:any) => checked.find((element:any) => element === variable);
    const CheckValue = (label: any) => {
        if (checked.includes(label)) {
            setChecked(checked.filter((item: any) => item !== label));
        } else setChecked([...checked, label])
    };
    return (
      <div className="space-y-4 w-11/12 mx-auto">
        <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
          Course Details
        </h1>
        <div className="rounded-[7px] shadow-md shadow-[#00000029] space-y-2 pb-4">
          <div className="flex items-center bg-[#EFF7FF] py-2 w-full">
            {headData.map((head: any, index: number) => (
              <p
                key={index}
                className={`text-[20px] font-Roboto font-[500] ${
                  index === 3 ? "text-center" : "text-start"
                } ${
                  index === 0
                    ? "w-[35%] pl-8"
                    : index === 3
                    ? "w-[40%] px-8"
                    : "w-[15%]"
                }`}
              >
                {head}
              </p>
            ))}
          </div>
          <div className="flex">
            <CourseInCourseDetails
              checked={checked}
              setChecked={setChecked}
              foundVariable={foundVariable}
              CheckValue={CheckValue}
              updatedStudentData={updatedStudentData}
            />
            <p className="w-[15%] px-6">
              {updatedStudentData?.course_fee && (
                <span className="px-1">&#8377;</span>
              )}
              {updatedStudentData?.course_fee
                ? updatedStudentData?.course_fee
                : "-"}
            </p>
            {/* {updatedStudentData?.discount_need ? ( */}
              <p className="w-[15%] px-6">
                {updatedStudentData?.discount_percentage
                  ? updatedStudentData?.discount_percentage
                  : ""}
                {updatedStudentData?.discount_percentage && (
                  <span className="px-0.5">%</span>
                )}
              </p>
            {/* ) : (
              <p className="w-[15%] px-6">-</p>
            )} */}
            <TotalFee
              pay={updatedStudentData?.course_fee}
              discount={updatedStudentData?.discount_percentage}
              setFeeTotal={setFeeTotal}
              feeTotal={feeTotal}
              branchDetails={branchDetails}
              paymentObject={paymentObject}
            />
          </div>
        </div>
      </div>
    );
}

const CourseInCourseDetails = ({ updatedStudentData, checked, setChecked, foundVariable, CheckValue }: any) => {
    const { Checkbox }: any = Components;
    return (
      <div className="w-[35%] space-y-1 pl-8">
        <h1 className="text-[18px] text-[#707070] font-Roboto font-[500]">
          {updatedStudentData?.course_id?.course_name}
        </h1>
        {updatedStudentData?.customization_fee
          // || updatedStudentData?.discount_need 
        ? (
          <textarea disabled={true} className="text-[#686868] border-[#707070] border-[0.5px] rounded-[3px] w-[90%] p-1 outline-none resize-none min-h-[60px] max-h-[70px]">
            {updatedStudentData?.cd_comment}
          </textarea>
        ) : (
          <div className="min-h-[60px] max-h-[70px]"></div>
        )}
        <Checkbox
          label={"Increase Fee Due to Customization?"}
          CheckValue={""}
          checked={updatedStudentData?.customization_fee && "Increase Fee Due to Customization?"}
          textStyle={"text-[#707070] text-[15px]"}
          show={false}
          boxSize={"w-4 h-4"}
        />
        {/* <Checkbox
          label={"Students Needs Discount?"}
          CheckValue={""}
          checked={updatedStudentData?.discount_need && "Students Needs Discount?"}
          textStyle={"text-[#707070] text-[15px]"}
          show={false}
          boxSize={"w-4 h-4"}
        /> */}
      </div>
    );
}

const TotalFee = ({ pay, discount, setFeeTotal, feeTotal, branchDetails, paymentObject }: any) => {
  const { useState, useEffect, formatNumberWithCommas, gstCalculationForStudent, totalGstCalculationForStudent, feeAfterDiscountCalculation, totalGstCalculation }: any = Components;
  const discount_value = discount ? discount : 0
  const feeInNumber = pay;
  const TotalExclGST = feeAfterDiscountCalculation(feeInNumber);

  const Sgst = gstCalculationForStudent(
    paymentObject?.sendPercentageSGST,
    feeInNumber,
    // discount_value
  );
  const Cgst = gstCalculationForStudent(
    paymentObject?.sendPercentageCGST,
    feeInNumber,
    // discount_value
  );
  const Igst = gstCalculationForStudent(
    paymentObject?.sendPercentageIGST,
    feeInNumber,
    // discount_value
  );
  const TotalInclGST = totalGstCalculationForStudent(
    paymentObject?.check_feeType,
    paymentObject?.sendPercentage,
    feeInNumber,
    // discount_value
  );

      const mappingForTotal: any = [
        {
          left: "Total (Excl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalExclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(TotalExclGST)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `SGST (${branchDetails?.sgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Sgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : parseFloat(Number(Sgst)?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `CGST (${branchDetails?.cgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Cgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : Number(Cgst)
                      ?.toFixed(1)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        paymentObject?.check_feeType === "IGST" && {
          left: `IGST (${branchDetails?.igst}%)`,
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(Igst?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(Igst)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        {
          left: "Total (Incl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalInclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : parseFloat(Number(TotalInclGST)?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
      ];

  useEffect(() => {
    const addValue = formatNumberWithCommas(totalGstCalculationForStudent(
      paymentObject?.check_feeType,
      paymentObject?.sendPercentage,
      pay,
      // discount_value
    ))
    setFeeTotal && setFeeTotal(pay ? addValue : 0);
  },[feeTotal && pay, branchDetails])

  return (
    <div className="w-[40%] flex flex-col justify-between">
      {mappingForTotal.map((text: any, index: number) => {
        return (
          <div key={index}
            className={`flex justify-around w-full ${
              index === 4 && "bg-[#EFF7FF] py-1.5"
            }`}
          >
            <p className={`w-[40%] text-end`}>{text.left}</p>
            <p className={`w-[20%]`}>
              {text.left && <span className="px-1">&#8377;</span>}
              {text.right}
            </p>
          </div>
        );
      })}
    </div>
  );
};