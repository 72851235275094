import { useState, useEffect } from "react";
import { getStudentInformation } from '../../functions/admin/Students/students';
import { CardLayoutOne } from "../Elements/Cards/CardLayoutOne";
import { InformationViewCard } from "../Elements/InformationViewCard/InformationViewCard";
import { dateFormat } from "../../Services/CommonFunctions";
import { Loader } from "../Elements/Loader/Loader";

export const PaidStudentInfo = ({ id }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [studentDataPresent, setStudentDataPresent] = useState<any>({});

    const buildJSON = [{
        left:[
            {
            head: "Student Name",
            body: studentDataPresent && studentDataPresent?.student?.name,
            },
            {
            head: "Phone Number",
            body: studentDataPresent && studentDataPresent?.student?.phoneNumber,
            },
            {
            head: "Email",
            body: studentDataPresent && studentDataPresent?.student?.email,
            },
        ],
        right:[
            {
            head: "Student Type",
            body: studentDataPresent && studentDataPresent?.student?.type,
            },
            {
            head: "Registration Date",
            body: studentDataPresent && dateFormat(studentDataPresent?.student?.created_at, 'DD MMM YYYY'),
            },
        ],
    }];

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const studentData = await getStudentInformation(
              id, false
            );
            setStudentDataPresent(studentData);
            setIsLoading(false);
        })()
    },[])

    return (
      <>
        {isLoading ? (
          <Loader widthFix={"w-9/12"} />
        ) : (
          <CardLayoutOne>
            <InformationViewCard
              cardHeading={"Student Information"}
              values={studentDataPresent}
              buildJSON={buildJSON}
            />
          </CardLayoutOne>
        )}
      </>
    );
}