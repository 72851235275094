import { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { StatusButton } from "../../Elements/StatusButton";
import { useFormik } from "formik";
import { onKeyDown } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { InputBox } from "../../Elements/InputBox";
import { DropDownInputBox } from "../../Elements/DropDownInputBox";
import { AddRooms } from "./AddRooms";
import { add_branch } from "../../Formik/InitialValues";
import { AddBranchValidation } from "../../Formik/FormikValidation";
import {
  createBranch,
  updateBranch,
  getBranchById
} from "../../../functions/admin/Branches/branches";
import { getAllCities } from "../../../functions/admin/Cities";
import { useAppSelector } from "../../..";
import { useSharedLogic, FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { DropdownSingleInputWithCheckbox } from "../../Elements/DropdownSingleInputWithCheckbox";
import toast from "react-hot-toast";
import { SelectModuleType } from "../ManageCourse/SelectModuleType";

export const AddNewBranch = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const [checked, setChecked] = useState<any>("Active");
  const [isLoading, setIsLoading] = useState(false)
  // const [roomsCount, setRoomsCount] = useState([] as any)
  const [filteredOwnership, setFilteredOwnership] = useState(null as any);
  const [filteredCity, setFilteredCity] = useState(null as any);
  const [cityNames, setCityNames] = useState([] as any)
  const [cityData, setCityData] = useState([] as any)
  const OwnershipType: string[] = ["Franchise", "Company Owned"];
  const isId: any = id !== "new-branch" && id;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  const feeType = [
    {
      label: "IGST",
      value: "IGST",
    },
    {
      label: "SGST",
      value: "SGST",
    },
    {
      label: "None",
      value: "None",
    },
];
    
    useEffect(() => {
      (async () => {
        if(signInData?.data?.length>0) {
          setIsLoading(true);
          const citiesResponse:any = await getAllCities({
            branches: signInData?.data[0]?.branches,
            cities: signInData?.data[0]?.cities
          });
          setCityData(citiesResponse);
          if (citiesResponse) {
            const mapCity =
              citiesResponse &&
              citiesResponse.map((city: any) => city.city_name);
            setCityNames(mapCity);
          }
          if (mode && mode === "edit") {
            const editResponse = await getBranchById(isId);
              const { branch_name, ownership, city, rooms, company_name, company_address, state, pincode, fee_type, gstin, hsn, igst, sgst, cgst, status }: any = editResponse;
              const mapCityForId:any = citiesResponse &&
                  citiesResponse?.filter((data: any) => data?.id === city);
              if (editResponse) {
                setValues(
                  {
                    ...values,
                    branch_name,
                    ownership,
                    city,
                    rooms,
                    company_name,
                    company_address,
                    state,
                    pincode,
                    fee_type,
                    gstin,
                    hsn,
                    igst,
                    sgst,
                    cgst,
                    status,
                  },
                  true
                );
                setChecked(status);
                setFilteredOwnership(ownership);
                setFilteredCity(mapCityForId?.[0]["city_name"]);
              }
          }
          setIsLoading(false);
        }
        })()
    },[])
    
    const forNewBranch = async () => {
      const { fee_type, gstin, hsn, igst, sgst, cgst, city, city_name, ...rest } = values;
      if (city === "" && city_name === "") {
        toast.error("Fill or Choose City")
      } else {
        setIsLoading(true);
        const body: any = {
          ...(city_name.length === 0 && { city: city }),
          ...(fee_type!=="None" && { gstin: gstin, hsn: hsn}),
          ...(fee_type==="IGST" && { igst: igst}),
          ...(fee_type==="SGST" && { sgst: sgst, cgst: cgst}),
          fee_type,
          ...rest
        }
        const addCity: any = {
          city_name: city_name,
          created_by: userDetails,
        };
        // console.log(body, addCity);
        const response = await(
          id === "new-branch"
            ? createBranch(body, city_name.length>0 && addCity)
            : updateBranch(body, isId, city_name.length>0 && addCity)
        );
        if (response) {
            navigate({
            pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
            search: createSearchParams({ id: `${id === "new-branch" ? "created-success" : "update-success"}` }).toString(),
            });
        }
        setIsLoading(false);
      }
    }

    const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(add_branch, ()=>AddBranchValidation(values.fee_type), forNewBranch);
    
    const addMoreRooms = () => {
      const add = [...values.rooms, ""];
        setFieldValue("rooms", add);
    }

    const deleteRooms = (i: number, value: any) => {
        let deletedOne = [...values.rooms];
        deletedOne.splice(i, 1);
        setFieldValue("rooms", deletedOne);
    }
    
    const CheckValue = (label: any) => {
        setChecked(label);
        setFieldValue("status", label);
    };
  
    const CheckValueForFee = (name: string, label: string) => {
      setFieldValue(name, label);
    };

    const handleChangeForDropValue = (
        name: string,
        value: string,
        mapThrough?: string
    ) => {
        if (mapThrough) {
            setFilteredCity(value);
            const data =
            cityData &&
            cityData.filter((data: any) => data["city_name"] === value);
            setFieldValue(name, data[0]['id']);
        } else {
            setFilteredOwnership(value);
            setFieldValue(name, value);
        }
    };

    const handleChangeForRooms = (index: number, value: any) => {
        const updatedInputValues:any = [...values.rooms];
        updatedInputValues[index] = value;
        setFieldValue("rooms", updatedInputValues);
    }

    return (
      <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
        {isLoading ? (
          <Loader widthFix={"w-[95%]"} />
        ) : (
          <>
            <CardLayoutTwo>
              <div className="text-left border-b-2 p-2">
                <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
                  {id === "new-branch" ? "New Branch" : "Edit Branch"}
                </p>
              </div>
              <div className="w-10/12 mx-auto py-12 flex gap-12">
                <div className="w-1/2 space-y-2">
                  {/* <DropDownInputBox
                    label="Ownership:"
                    placeholder="Ownership"
                    classNameInput={"w-full"}
                    name={"ownership"}
                    value={values.ownership}
                    onBlur={handleBlur}
                    error={errors.ownership}
                    isTouched={touched.ownership}
                    dataSetup={handleChangeForDropValue}
                    mapData={ownershipType}
                    showData={filteredOwnership}
                    setShowData={setFilteredOwnership}
                    /> */}
                  <DropdownSingleInputWithCheckbox
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    label="Ownership"
                    placeholder="Ownership"
                    options={OwnershipType}
                    name={"ownership"}
                    value={formik.values.ownership}
                    onBlur={formik.handleBlur}
                    error={formik.errors?.ownership}
                    isTouched={formik.touched?.ownership}
                    mapThrough={"ownership"}
                    formik={formik}
                    zIndex={"z-20"}
                    disabled={mode === "view"}
                  />
                  <DropDownInputBox
                    label="City:"
                    placeholder="City"
                    classNameInput={"w-full"}
                    name={"city"}
                    value={values.city}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForDropValue}
                    mapData={cityNames}
                    showData={filteredCity}
                    setShowData={setFilteredCity}
                    mapThrough={"city_name"}
                    formik={formik}
                  />
                  <InputBox
                    // label="Branch:"
                    // placeholder="Branch"
                    // classNameInput={"w-full"}
                    // name={"branch_name"}
                    // value={values.branch_name}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    // error={errors.branch_name}
                    // isTouched={touched.branch_name}

                    label="Branch:"
                    placeholder="Branch"
                    topDivStyle={"flex flex-col w-full"}
                    classNameInput={"w-full"}
                    name={"branch_name"}
                    // type={"number"}
                    // inputMode="numeric"
                    value={values.branch_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.branch_name}
                    isTouched={touched.branch_name}
                    disabled={false}
                  />
                  <AddRooms
                    addMoreRooms={addMoreRooms}
                    deleteRooms={deleteRooms}
                    formik={formik}
                    name={"rooms"}
                    dataSetup={handleChangeForRooms}
                  />
                </div>
                <div className="w-1/2 space-y-3">
                  <InputBox
                    label="Company Name:"
                    placeholder="Company Name"
                    type={"text"}
                    classNameInput={"w-full"}
                    name={"company_name"}
                    value={values.company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.company_name}
                    isTouched={touched.company_name}
                  />
                  <InputBox
                    label="Address:"
                    placeholder="Address"
                    classNameInput={"w-full"}
                    name={"company_address"}
                    value={values.company_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.company_address}
                    isTouched={touched.company_address}
                  />
                  <div className="w-full flex gap-1">
                    <InputBox
                      label="State:"
                      placeholder="State"
                      classNameInput={"w-full"}
                      name={"state"}
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.state}
                      isTouched={touched.state}
                    />
                    <InputBox
                      label="Pincode:"
                      placeholder="Pincode"
                      type="number"
                      classNameInput={"w-full"}
                      name={"pincode"}
                      value={values.pincode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.pincode}
                      isTouched={touched.pincode}
                    />
                  </div>
                  <div className="flex items-center gap-5 p-2 py-4">
                    <SelectModuleType
                      text={"Type:"}
                      name={"fee_type"}
                      value={values.fee_type}
                      onBlur={handleBlur}
                      onChange={CheckValueForFee}
                      JSON={feeType}
                      disabled={false}
                    />
                  </div>
                  {values.fee_type !== "None" && (
                    <>
                      <InputBox
                        label="GSTIN Number:"
                        placeholder="GSTIN Number"
                        // type="number"
                        classNameInput={"w-full"}
                        name={"gstin"}
                        value={values.gstin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.gstin}
                        isTouched={touched.gstin}
                      />
                      <InputBox
                        label="HSN:"
                        placeholder="HSN"
                        // type="number"
                        classNameInput={"w-full"}
                        name={"hsn"}
                        value={values.hsn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.hsn}
                        isTouched={touched.hsn}
                      />
                      {values.fee_type === "IGST" && (
                        <InputBox
                          label="IGST(%):"
                          placeholder="IGST"
                          // type="number"
                          classNameInput={"w-full"}
                          name={"igst"}
                          value={values.igst}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.igst}
                          isTouched={touched.igst}
                        />
                      )}
                      {values.fee_type === "SGST" && (
                        <div className="flex gap-1">
                          <InputBox
                            label="SGST(%):"
                            placeholder="SGST"
                            // type="number"
                            classNameInput={"w-full"}
                            name={"sgst"}
                            value={values.sgst}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.sgst}
                            isTouched={touched.sgst}
                          />
                          <InputBox
                            label="CGST(%):"
                            placeholder="CGST"
                            // type="number"
                            classNameInput={"w-full"}
                            name={"cgst"}
                            value={values.cgst}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.cgst}
                            isTouched={touched.cgst}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="py-8">
                <StatusButton
                  type={"submit"}
                  buttonText={
                    id === "new-branch" ? "Add New Branch" : "Update Branch"
                  }
                  CheckValue={CheckValue}
                  checked={checked}
                  buttonWidth={"w-9/12"}
                  show={mode !== "view"}
                />
              </div>
            </CardLayoutTwo>
          </>
        )}
      </form>
    );
}
