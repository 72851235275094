import { useState, useEffect } from "react";
import { Loader } from "../../Elements/Loader/Loader";
import { CardLayoutOne } from "../../Elements/Cards/CardLayoutOne";
import { ReadData } from "../../Elements/ReadData";
import {
  BuildJSON,
  DetailsOfTrainers,
  SessionData,
  Summary,
  Jobs,
  Pay_Details,
} from "./CommonJson";
import { BasicInfo } from "./BasicInfo";
import { TrainerDetails } from "./TrainerDetails";
import { SessionDetails } from "./SessionDetails";
import { Progressbar } from "../../Elements/Progressbar";
import { Checkbox } from "../../Elements/Checkbox";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";
import { CourseDetails } from "./CourseDetails";
import { PaymentSummary } from "./PaymentSummary";
import { PaymentDetails } from "./PaymentDetails";
import { AppliedJobs } from "./AppliedJobs";
import { formatNumberWithCommas } from "../../../Services/CommonFunctions";
import { PendingPayments } from "./PendingPayments";
import { Comments } from "./Comments";
import { Refund } from "./Refund";
import { AdjustPayment } from "./AdjustPayment";
import { NullifyPayment } from "./NullifyPayment";
import {
  getStudentManagementDetailsById,
  feeAfterDiscountCalculation,
  gstCalculationForStudent,
  totalGstCalculationForStudent,
  getBatchFollowUp,
  updateStudentManagementDetails,
  getTrainerAndSessionDetails,
  getBranchForGST
} from "../../../functions/admin/Students/students";
import toast from "react-hot-toast";
import { PerformAction } from "./ForActionCenter/PerformAction";

export const Components:any = {
  useState,
  useEffect,
  Loader,
  CardLayoutOne,
  ReadData,
  BuildJSON,
  DetailsOfTrainers,
  Summary,
  BasicInfo,
  TrainerDetails,
  SessionDetails,
  SessionData,
  Progressbar,
  Checkbox,
  useSharedLogic,
  createSearchParams,
  CourseDetails,
  PaymentSummary,
  PaymentDetails,
  AppliedJobs,
  Jobs,
  Pay_Details,
  formatNumberWithCommas,
  PendingPayments,
  Comments,
  Refund,
  AdjustPayment,
  NullifyPayment,
  getStudentManagementDetailsById,
  feeAfterDiscountCalculation,
  gstCalculationForStudent,
  totalGstCalculationForStudent,
  getBatchFollowUp,
  updateStudentManagementDetails,
  toast,
  getTrainerAndSessionDetails,
  PerformAction,
  getBranchForGST
};
