export const RoleManagementJSON = [
  {
    "id": 1,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "dashboard",
    "label": "Dashboard",
    "submenus": [],
    "mainmenuid": null,
    "updated_at": "2023-03-31T07:14:23.5008+00:00",
    "inserted_at": "2023-03-31T07:14:23.5008+00:00",
    "displayorder": 1
  },
  {
    "id": 2,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "admin_management",
    "label": "Admin Management",
    "subMenus": [
      {
        "id": 3,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "role management",
        "label": "Role management",
        "mainmenuid": 2,
        "updated_at": "2023-04-05T07:36:26.104388+00:00",
        "inserted_at": "2023-04-05T07:36:26.104388+00:00",
        "displayorder": 1
      },
      {
        "id": 4,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "Admin user management",
        "label": "Admin user management",
        "mainmenuid": 2,
        "updated_at": "2023-04-05T12:42:58.163724+00:00",
        "inserted_at": "2023-04-05T12:42:58.163724+00:00",
        "displayorder": 2
      }
    ],
    "submenus": [
      {
        "id": 3,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "role_management",
        "label": "Role management",
        "mainmenuid": 2,
        "updated_at": "2023-04-05T07:36:26.104388+00:00",
        "inserted_at": "2023-04-05T07:36:26.104388+00:00",
        "displayorder": 1
      },
      {
        "id": 4,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "admin_user_management",
        "label": "Admin user management",
        "mainmenuid": 2,
        "updated_at": "2023-04-05T12:42:58.163724+00:00",
        "inserted_at": "2023-04-05T12:42:58.163724+00:00",
        "displayorder": 2
      }
    ],
    "mainmenuid": null,
    "updated_at": "2023-03-31T07:46:43.890438+00:00",
    "inserted_at": "2023-03-31T07:46:43.890438+00:00",
    "displayorder": 2
  },
  {
    "id": 33,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "hr_student_placement",
    "label": "HR & Student Placement",
    "submenus": [
      {
        "id": 34,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "manage_jobs",
        "label": "Manage Jobs",
        "mainmenuid": 33,
        "updated_at": "2023-04-05T13:16:21+00:00",
        "inserted_at": "2023-04-05T13:16:21+00:00",
        "displayorder": 1
      },
      {
        "id": 35,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "student_for_hr",
        "label": "Student for HR",
        "mainmenuid": 33,
        "updated_at": "2023-04-05T13:17:19.44784+00:00",
        "inserted_at": "2023-04-05T13:17:19.44784+00:00",
        "displayorder": 2
      }
    ],
    "mainmenuid": null,
    "updated_at": "2023-04-05T13:15:50.380436+00:00",
    "inserted_at": "2023-04-05T13:15:50.380436+00:00",
    "displayorder": 9
  },
  {
    "id": 36,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "reports",
    "label": "Reports",
    "submenus": [],
    "mainmenuid": null,
    "updated_at": "2023-04-05T13:17:43.345817+00:00",
    "inserted_at": "2023-04-05T13:17:43.345817+00:00",
    "displayorder": 10
  },
  {
    "id": 37,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "logs",
    "label": "Logs",
    "submenus": [
      {
        "id": 38,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "user_logs",
        "label": "User Logs",
        "mainmenuid": 37,
        "updated_at": "2023-04-05T13:18:50.594434+00:00",
        "inserted_at": "2023-04-05T13:18:50.594434+00:00",
        "displayorder": 1
      },
      {
        "id": 39,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "student_edit_logs",
        "label": "Student Edit Logs",
        "mainmenuid": 37,
        "updated_at": "2023-04-05T13:19:31.678525+00:00",
        "inserted_at": "2023-04-05T13:19:31.678525+00:00",
        "displayorder": 2
      }
    ],
    "mainmenuid": null,
    "updated_at": "2023-04-05T13:18:25.77344+00:00",
    "inserted_at": "2023-04-05T13:18:25.77344+00:00",
    "displayorder": 11
  },
  {
    "id": 6,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "all_students",
    "label": "All Students",
    "submenus": [],
    "mainmenuid": null,
    "updated_at": "2023-04-05T12:57:03.781605+00:00",
    "inserted_at": "2023-04-05T12:57:03.781605+00:00",
    "displayorder": 3
  },
  {
    "id": 7,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "content_management",
    "label": "Content Management",
    "submenus": [
      {
        "id": 8,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "branches",
        "label": "Branches",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T12:58:13+00:00",
        "inserted_at": "2023-04-05T12:58:13+00:00",
        "displayorder": 1
      },
      {
        "id": 9,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "manage_courses",
        "label": "Manage Courses",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T12:58:37+00:00",
        "inserted_at": "2023-04-05T12:58:37+00:00",
        "displayorder": 2
      },
      {
        "id": 10,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "course_category",
        "label": "Course Category",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T12:59:02+00:00",
        "inserted_at": "2023-04-05T12:59:02+00:00",
        "displayorder": 3
      },
      {
        "id": 11,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "technology",
        "label": "Technology",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T12:59:41+00:00",
        "inserted_at": "2023-04-05T12:59:41+00:00",
        "displayorder": 4
      },
      {
        "id": 12,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "course_features",
        "label": "Course Features",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T13:00:06+00:00",
        "inserted_at": "2023-04-05T13:00:06+00:00",
        "displayorder": 5
      },
      {
        "id": 13,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "hiring_companies",
        "label": "Hiring Companies",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T13:00:40+00:00",
        "inserted_at": "2023-04-05T13:00:40+00:00",
        "displayorder": 6
      },
      {
        "id": 14,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "certificate",
        "label": "Certificate",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T13:01:07+00:00",
        "inserted_at": "2023-04-05T13:01:07+00:00",
        "displayorder": 7
      },
      {
        "id": 15,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "testimonials",
        "label": "Testimonials",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T13:01:34+00:00",
        "inserted_at": "2023-04-05T13:01:34+00:00",
        "displayorder": 8
      },
      {
        "id": 16,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "contact_info",
        "label": "Contact Info",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T13:01:58+00:00",
        "inserted_at": "2023-04-05T13:01:58+00:00",
        "displayorder": 9
      },
      {
        "id": 17,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "push_content",
        "label": "Push Content",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T13:02:24+00:00",
        "inserted_at": "2023-04-05T13:02:24+00:00",
        "displayorder": 10
      },
      {
        "id": 18,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "resources",
        "label": "Resources",
        "mainmenuid": 7,
        "updated_at": "2023-04-05T13:02:45+00:00",
        "inserted_at": "2023-04-05T13:02:45+00:00",
        "displayorder": 11
      }
    ],
    "mainmenuid": null,
    "updated_at": "2023-04-05T12:57:44.849338+00:00",
    "inserted_at": "2023-04-05T12:57:44.849338+00:00",
    "displayorder": 4
  },
  {
    "id": 19,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "student_management",
    "label": "Student Management",
    "submenus": [],
    "mainmenuid": null,
    "updated_at": "2023-04-05T13:03:17.252441+00:00",
    "inserted_at": "2023-04-05T13:03:17.252441+00:00",
    "displayorder": 5
  },
  {
    "id": 20,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "batch_management",
    "label": "Batch Management",
    "submenus": [],
    "mainmenuid": null,
    "updated_at": "2023-04-05T13:07:50.693238+00:00",
    "inserted_at": "2023-04-05T13:07:50.693238+00:00",
    "displayorder": 6
  },
  {
    "id": 21,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "course_progress",
    "label": "Course Progress",
    "submenus": [],
    "mainmenuid": null,
    "updated_at": "2023-04-05T13:08:36.340394+00:00",
    "inserted_at": "2023-04-05T13:08:36.340394+00:00",
    "displayorder": 7
  },
  {
    "id": 22,
    "data": [
      {
        "id": 1,
        "label": "All",
        "value": "all",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 2,
        "label": "View",
        "value": "view",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 3,
        "label": "Create",
        "value": "create",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 4,
        "label": "Edit",
        "value": "edit",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 5,
        "label": "Delete",
        "value": "delete",
        "checked": true,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      },
      {
        "id": 6,
        "label": "Not Applicable",
        "value": "not applicable",
        "checked": false,
        "updated_at": "2023-03-30T10:46:58.374035+00:00",
        "inserted_at": "2023-03-30T10:46:58.374035+00:00"
      }
    ],
    "idkey": "action_center",
    "label": "Action Center",
    "submenus": [
      {
        "id": 32,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "session_feedback",
        "label": "Session Feedback",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:15:16.806596+00:00",
        "inserted_at": "2023-04-05T13:15:16.806596+00:00",
        "displayorder": 10
      },
      {
        "id": 23,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "pending_payment",
        "label": "Pending Payment",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:09:45.294469+00:00",
        "inserted_at": "2023-04-05T13:09:45.294469+00:00",
        "displayorder": 1
      },
      {
        "id": 24,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "daily_transactions",
        "label": "Daily Transactions",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:10:30.292879+00:00",
        "inserted_at": "2023-04-05T13:10:30.292879+00:00",
        "displayorder": 2
      },
      {
        "id": 25,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "student_actions",
        "label": "Student Actions",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:11:08.35999+00:00",
        "inserted_at": "2023-04-05T13:11:08.35999+00:00",
        "displayorder": 3
      },
      {
        "id": 26,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "student_actions_approval_queue",
        "label": "Student Actions Approval Queue",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:12:03.479015+00:00",
        "inserted_at": "2023-04-05T13:12:03.479015+00:00",
        "displayorder": 4
      },
      {
        "id": 27,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "issue_refund_adjust_payment",
        "label": "Issue Refund / Adjust Payment",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:12:50.477186+00:00",
        "inserted_at": "2023-04-05T13:12:50.477186+00:00",
        "displayorder": 5
      },
      {
        "id": 28,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "completed_batches",
        "label": "Completed Batches",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:13:18.978694+00:00",
        "inserted_at": "2023-04-05T13:13:18.978694+00:00",
        "displayorder": 6
      },
      {
        "id": 29,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "calculate_trainer_payment",
        "label": "Calculate Trainer Payment",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:13:49.349301+00:00",
        "inserted_at": "2023-04-05T13:13:49.349301+00:00",
        "displayorder": 7
      },
      {
        "id": 30,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "process_trainer_payment",
        "label": "Process Trainer Payment",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:14:21.121448+00:00",
        "inserted_at": "2023-04-05T13:14:21.121448+00:00",
        "displayorder": 8
      },
      {
        "id": 31,
        "data": [
          {
            "id": 1,
            "label": "All",
            "value": "all",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 2,
            "label": "View",
            "value": "view",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 3,
            "label": "Create",
            "value": "create",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 4,
            "label": "Edit",
            "value": "edit",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 5,
            "label": "Delete",
            "value": "delete",
            "checked": true,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          },
          {
            "id": 6,
            "label": "Not Applicable",
            "value": "not applicable",
            "checked": false,
            "updated_at": "2023-03-30T10:46:58.374035+00:00",
            "inserted_at": "2023-03-30T10:46:58.374035+00:00"
          }
        ],
        "idkey": "student_feedback_concerns",
        "label": "Student Feedback / Concerns",
        "mainmenuid": 22,
        "updated_at": "2023-04-05T13:14:50.92224+00:00",
        "inserted_at": "2023-04-05T13:14:50.92224+00:00",
        "displayorder": 9
      }
    ],
    "mainmenuid": null,
    "updated_at": "2023-04-05T13:09:07.963432+00:00",
    "inserted_at": "2023-04-05T13:09:07.963432+00:00",
    "displayorder": 8
  }
]