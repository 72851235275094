import { dateFormat } from "../../../../Services/CommonFunctions"
import { errorNotifier } from "../../../commonHelper"
import { TableNames } from "../../../config/Tables"
import { FetchData, UpdateData, InsertData } from "../../../crud"
import { FeedbackStatusPriorityInterface, FeedbackFollowUpInterface } from "./interface"
import { submitUserLogs } from "../../User Logs/userLogs";
import { UserLogInterface } from "../../User Logs/interface";

export const getSessionFeedbackTableData = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.feedbacks, ["*", "training:trainings(student:students(name, phoneNumber),course:courses(id, course_name), user:users(first_name, last_name), batch:batches(schedule), city:cities(city_name), branch:branches(id, branch_name))"]).order("id", { ascending: false })
        if (error) throw error
        const altered_data = data?.map((el: any) => ({...el, issue_priority: el?.issue_priority===null?"-":el?.issue_priority, student_list: el?.training?.length > 0 && el?.training?.map((tr: any) => tr?.student?.name), student_name: el.training?.student?.name, student_phone: el.training?.student?.phoneNumber, course_name: el.training?.course?.course_name, admin_name: el.training?.user?.first_name + el.training?.user?.last_name, created_date: dateFormat(el.created_at, "DD MMM YYYY"), city: el.training?.city?.city_name, branch: el.training?.branch?.branch_name, schedule: el.training?.batch?.schedule}))
        return altered_data
    } catch (error) {
        return errorNotifier(error)
    }
}

function calculateAgeInDays(recordDate: Date): number {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - recordDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return Math.floor(daysDifference);
}

export const getSessionFeedbackById = async (feedbackId: number) => {
    try {
        const { data, error }: any = await FetchData(TableNames.feedbacks, ["*", "training:trainings(course:courses(course_name),student:students(name, phoneNumber, email), branch:branches(branch_name), city:cities(city_name), batch:batches(schedule, user:users(first_name, last_name, phone_number)))"]).eq("id", feedbackId)

        const feedbackAge = calculateAgeInDays(new Date(data[0].created_at))
        const alteredData = {...data[0], age: feedbackAge}
        if (error) throw error
        return alteredData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateStatusandPriority = async (feedbackData: FeedbackStatusPriorityInterface, feedbackId: number) => {
    try {
        const { data, error } = await UpdateData(TableNames.feedbacks, feedbackData, { conditionKey: "id", conditionValue: feedbackId })
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getStudentConcernTableData = async () => {
    try {
        const { data, error } = await FetchData(TableNames.student_concern_feedback, ["*", "training:trainings(id, student:students(name, phoneNumber), user:users(first_name, last_name), course:courses(course_name), city:cities(city_name), branch:branches(branch_name), batch:batches(schedule))"]).order("id", {ascending: false})

        const altered_data = data?.map((el: any) => ({...el, student_list: el?.training?.length > 0 && el?.training?.map((tr: any) => tr?.student?.name), student_name: el.training?.student?.name, student_phone: el.training?.student?.phoneNumber, course_name: el.training.course?.course_name, admin_name: el.training?.user?.first_name + el.training?.user?.last_name, created_date: dateFormat(el.created_at, "DD MMM YYYY"), city: el.training?.city?.city_name, branch: el.training?.branch?.branch_name, schedule: el.training?.batch?.schedule}))

        if (error) throw error
        return altered_data
    } catch (error) {
        return errorNotifier(error)
    }
}


export const getStudentConcernAndFeedbackById = async (concernFeedbackId: number) => {
    try {
        const { data, error }: any = await FetchData(TableNames.student_concern_feedback, ["*", "training:trainings(student:students(name, phoneNumber, email), branch:branches(branch_name), city:cities(city_name), batch:batches(schedule, user:users(first_name, last_name, phone_number)))"]).eq("id", concernFeedbackId)

        const feedbackAge = calculateAgeInDays(new Date(data[0].created_at))
        const alteredData = {...data[0], age: feedbackAge}
        if (error) throw error
        return alteredData
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateStudentConcernStatusandPriority = async (feedbackData: FeedbackStatusPriorityInterface, feedbackId: number) => {
    try {
        const { data, error } = await UpdateData(TableNames.student_concern_feedback, feedbackData, { conditionKey: "id", conditionValue: feedbackId })
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

// const checkSum = () => {
// const sum = numbers.reduce((acc, num) => acc + num, 0); // Calculate sum of numbers in the array
// return sum === targetNumber;
// };

export const StudentFeedbackNotGivenTableData = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.trainings, ["id", "feedback: feedbacks(response)", "students(name, phoneNumber)", "courses(course_name)", "batches(duration, sessions(*))", "student_concern_feedback(*)", "student_feedback_followup(*)"]).order("id", { ascending: false }).in("branch_id", UsersDetails?.branches)

        const altered_data = data?.map((el: any) => {
            if (el?.batches) {
                const totalHoursCompleted = el?.batches?.sessions.reduce((acc: number, session: any) => acc + Number(session.duration), 0);
                const courseCompletionPercentage = (Number(totalHoursCompleted) / Number(el?.batches?.duration)) * 100;
                const checkFollowUpDate =
                  el?.student_feedback_followup?.length > 0
                    ? el?.student_feedback_followup?.map((fl: any) =>
                        dateFormat(fl?.follow_up_date, "DD MMM YYYY")
                      )
                    : [];
                // console.log( checkFollowUpDate,
                //     checkFollowUpDate?.length>0 ? checkFollowUpDate[checkFollowUpDate?.length-1] : "",
                //   "checkFollowUpDate"
                // );
                if (((Math.round(courseCompletionPercentage) >= 10) && (el?.feedback?.length === 0)) ||
                    ((Math.round(courseCompletionPercentage) >= 60) && (el?.feedback?.length < 2))) {
                    return {
                        id: el?.id,
                        student_name: el?.students?.name,
                        phone_number: el?.students?.phoneNumber,
                        course_name: el?.courses?.course_name,
                        course_completed_percentage: Math.round(courseCompletionPercentage) ?? 0,
                        feedback: el?.feedback,
                        followup_date: checkFollowUpDate?.length>0 ? checkFollowUpDate[checkFollowUpDate?.length-1] : ""
                    };
                }
            }
            return null;
        }).filter((el: any) => el !== null);
        if (error) throw error
        console.log(altered_data, "altered_data")
        return altered_data
    } catch (error) {
        return errorNotifier(error)
    }
}

const courseCompletedPercentage = (sessions:any, totalDuration:any) => {
    const totalHoursCompleted = sessions.reduce((acc: number, session: any) => acc + Number(session.duration), 0);
    const courseCompletionPercentage = (Number(totalHoursCompleted) / Number(totalDuration)) * 100;
    return Math.round(courseCompletionPercentage);
}

export const addStudentFeedbackFollowUpDate = async (followUpData:FeedbackFollowUpInterface) => {
    try {
        const { data: userlogData }: any = await FetchData(TableNames.trainings, ["course:courses(course_name)", "student:students(name)"]).eq("id", followUpData.training_id);

        const userlogObj:UserLogInterface={
            user_id: followUpData.added_by, 
            changes: followUpData.comment  + followUpData.follow_up_date +"Course name "+ userlogData[0]?.course?.course_name + "Student name"+userlogData[0]?.student?.name,
            module: `StudentFeedbackFollowUp`
        }
        const { data: followUpInsertResponse, error: followUpInsertError } = await InsertData(TableNames.student_feedback_followup, followUpData);
        submitUserLogs(userlogObj);
        if (followUpInsertError) throw followUpInsertError;
        return followUpInsertResponse;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getAllStudentFeedbackFollowupsForTraining = async (Id: number) => {
    try {
        const { data: studentFollowData, error: QueryError } = await FetchData(TableNames.student_feedback_followup, ["created_at", "follow_up_date", "comment"]).eq(`training_id`, Id);
        if (QueryError) throw QueryError;
        return studentFollowData;
    } catch (error) {
        return errorNotifier(error);
    }
}