import { Components } from "./ModuleImports";
import shareIcon from "../../../Assets/shareIcon.svg";
import { dateFormat } from "../../../Services/CommonFunctions";
// import { TotalFee } from "./TotalFee";

export const PaymentDetails = ({paymentDetails, branchDetails, paymentObject, handleOpenModal}:any) => {
  const { Pay_Details, ReadData, formatNumberWithCommas }: any = Components;
  
  return (
    <div className="space-y-4 w-11/12 mx-auto">
      <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        Payment Details
      </h1>
      <div>
        {paymentDetails?.map((pay: any, index: number) => {
          // console.log(pay, "pay");
          return (
            <div
              className="shadow-md shadow-[#00000029] rounded-[3px] p-3 flex justify-between"
              key={index}
            >
              <div className="flex justify-between w-[97%]">
                <div className="flex flex-col justify-between items-center">
                  <ReadData
                    head={"Payment Date"}
                    body={dateFormat(pay.payment_date, "DD MMM YYYY")}
                  />
                  <div className="flex flex-col gap-1 items-center">
                    {/* {pay?.payment_type?.map((type: any) => (
                      <span
                        className={`text-[12px] font-Roboto font-[400] px-1.5 ${
                          pay.type === "Registration"
                            ? "bg-[#036AD1] text-white"
                            : "bg-[#FFDD03] text-black"
                        }`}
                      >
                        {type}
                      </span>
                    ))} */}
                  </div>
                </div>
                <ReadData head={"Mode of Payment"} body={pay.mode_of_payment} />
                <ReadData
                  head={"Transaction ID"}
                  body={pay.transaction_id ? pay.transaction_id : "-"}
                />
                <ReadData
                  head={"Balance Due"}
                  body={
                    typeof pay.balance === "number"
                      ? parseFloat(pay.balance?.toFixed(1))
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : parseFloat(Number(pay.balance)?.toFixed(1))
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addSpan={pay.balance}
                  color={"text-[#FF0000]"}
                />
                <TotalFee
                  pay={pay?.paid_amount}
                  // cgst={pay?.cgst}
                  // sgst={pay?.sgst}
                  branchDetails={branchDetails}
                  paymentObject={paymentObject}
                />
              </div>
              <div>
                <img
                  src={shareIcon}
                  alt="shareIcon"
                  className="bg-[#036AD1] cursor-pointer"
                  onClick={()=>handleOpenModal(pay?.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export const TotalFee = ({ pay, branchDetails, paymentObject }: any) => {
  const {
    feeAfterDiscountCalculation,
    gstCalculationForStudent,
  }: any = Components;
  const feeInNumber = pay;

    const TotalInclGST = feeAfterDiscountCalculation(
      feeInNumber
    );
    const TotalExclGST = TotalInclGST / (1 + (paymentObject?.check_feeType==="None" ? 0 : ((paymentObject?.check_feeType === "IGST" ? Number(branchDetails?.igst) : Number(branchDetails?.sgst) + Number(branchDetails?.cgst))/100)))
    const Sgst = gstCalculationForStudent(
      paymentObject?.sendPercentageSGST,
      TotalExclGST
    );
    const Cgst = gstCalculationForStudent(
      paymentObject?.sendPercentageCGST,
      TotalExclGST
      );
    const Igst = gstCalculationForStudent(
      paymentObject?.sendPercentageIGST,
      TotalExclGST
    );
      
      const mappingForTotal: any = [
        {
          left: "Total (Excl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalExclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(TotalExclGST)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `SGST (${branchDetails?.sgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Sgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : parseFloat(Number(Sgst)?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `CGST (${branchDetails?.cgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Cgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : Number(Cgst)
                      ?.toFixed(1)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        paymentObject?.check_feeType === "IGST" && {
          left: `IGST (${branchDetails?.igst}%)`,
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(Igst?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(Igst)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        {
          left: "Total (Incl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalInclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : parseFloat(Number(TotalInclGST)?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
      ];

  return (
    <div className="w-[40%] flex flex-col justify-between">
      {mappingForTotal.map((text: any, index: number) => {
        return (
          <div key={index}
            className={`flex justify-around w-full ${
              index === 4 && "bg-[#EFF7FF] py-1.5"
            }`}
          >
            <p className={`w-[40%] text-end`}>{text.left}</p>
            <p className={`w-[20%]`}>
              {text.left && <span className="px-1">&#8377;</span>}
              {text.right}
            </p>
          </div>
        );
      })}
    </div>
  );
};
