import { Components } from "./ModuleImports";
import { useState } from "react";
import { createSearchParams } from "react-router-dom";
import { updateTrainerPayment } from "../../../functions/admin/Action center/Trainer Payments/trainerPayment";
import { formatNumberWithCommas, dateFormat } from "../../../Services/CommonFunctions";
import {
  getStudentBasedOnTrainerAndSession,
  getStudentBasedOnTrainer,
} from "../../../functions/admin/Batches/Batches";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";

export const StudentDetailsForTrainerPayment = ({
  id,
  setSwitchComp,
  handleSelectChange,
  preview,
  uid,
  index,
  trainerBasedStudents,
  setTrainerBasedStudents,
  setIsModalOpen,
  newTrainerPayment,
  setNewTrainerPayment,
  total_trainer_payment_comment
}: any) => {
  const { useEffect, formatNumberWithCommas }: any = Components;
  const { routePath }: any = useSharedLogic();
  const headers = [
    "Name",
    "Phone Number",
    "Total Fee",
    "Amount Paid",
    "Balance Due",
    "Hours Attended",
    "Existing Trainer Payment(₹)",
    "Trainer Payment(₹)",
    "Status",
    "Payment Status",
    "Comments",
  ];
  const footerStyle = `text-[#686868] 2xl:text-[14px] lg:text-[12px] text-[10px] font-Roboto font-[400] w-[12%] text-center`;

  useEffect(() => {
    (async () => {
      if (uid !== "") {
        const studentDetails = await getStudentBasedOnTrainerAndSession(
          Number(id),
          uid
        );
        // console.log(studentDetails, "studentDetails")
        // const updatedNewTrainerPayment = studentDetails && studentDetails?.map((student:any) => {
        //   return student?.map((details:any) => {
        //     return { id: details.id, pay: '' };
        //   });
        // });
        // console.log(updatedNewTrainerPayment, "updatedNewTrainerPayment")
        setTrainerBasedStudents((prevArray: any) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = studentDetails;
          return updatedArray;
        });
      }
    })();
  }, [uid]);

  const commentPage = (comment_id: any) => {
    setSwitchComp({ ele: "comment", id: comment_id });
  };

  const limitWords = (text: any, limit: number) => {
    const words = text.split(" ");
    if (words.length <= limit) {
      return text;
    }
    return words.slice(0, limit).join(" ") + "...";
  };

  const handleFocus = (e: any) => {
    const { value } = e.target;
    if (value === "0") {
      e.target.value = ""; // Remove leading zero if the value is '0'
    }
  };

  return (
    <div className="space-y-4 w-full flex flex-col items-center">
      <div className="space-y-4 w-full py-6">
        <h1 className="text-[#036AD1] text-[17px] xl:text-[19px] font-Roboto font-[500] w-[96%] mx-auto">
          Student Details
        </h1>
        <div className="space-y-1 shadow-[#00000029_0px_3px_8px] py-3">
          <div className="flex items-center justify-evenly bg-[#EFF7FF] py-2">
            {headers.map((head: any, index: number) => (
              <h1
                key={index}
                className={`text-[#686868] 2xl:text-[14px] lg:text-[13px] text-[11px] font-Roboto font-[500] text-center ${
                  index === headers?.length - 1 ? "w-[15%] mx-2" : "w-[12%]"
                }`}
              >
                {head}
              </h1>
            ))}
          </div>
          <div className="min-h-[90px] max-h-[400px] scroll-smooth overflow-y-scroll scrollbar-hide">
            {trainerBasedStudents?.length > 0 &&
              trainerBasedStudents[index]?.map(
                (data: any, indexNum: number) => {
                  // console.log(data, "data")
                  return (
                    <div
                      key={indexNum}
                      className={`flex items-center justify-evenly ${
                        indexNum % 2 !== 0 && "bg-[#EFF7FF]"
                      } py-2`}
                    >
                      <p className={footerStyle}>{data?.student?.name}</p>
                      <p className={footerStyle}>
                        {data?.student?.phoneNumber}
                      </p>
                      <p className={footerStyle}>
                        <span className="px-1">&#8377;</span>
                        {formatNumberWithCommas(
                          Math.round(data?.total_fee * 10) / 10
                        )}
                      </p>
                      <p className={footerStyle}>
                        <span className="px-1">&#8377;</span>
                        {formatNumberWithCommas(
                          Math.round(data?.paid_amount * 10) / 10
                        )}
                      </p>
                      <p className={footerStyle}>
                        <span className="px-1">&#8377;</span>
                        {formatNumberWithCommas(
                          Math.round(data?.balance_due * 10) / 10
                        )}
                      </p>
                      <p className={`${footerStyle}`}>
                        {data?.hours_attended}
                        {/* {data.pending_hours !== "0" && (
                            <span className="bg-[#036AD1] rounded-full p-0.5 text-white px-1.5 ml-1">
                            {data.pending_hours}
                            </span>
                        )} */}
                      </p>
                      <p className={`${footerStyle}`}>
                        {data?.existing_trainer_payment}
                      </p>
                      <p className={`${footerStyle}`}>
                        {preview ||
                        (routePath && routePath === "process_trainer_pay") ? (
                          data?.trainer_payment === null ? (
                            0
                          ) : (
                            data?.trainer_payment
                          )
                        ) : (
                          <input
                            className="border-[#707070] border-[0.5px] rounded-[3px] w-10/12 mx-auto px-1"
                            name={`trainerBasedStudents[${index}][${indexNum}]["trainer_payment"]`}
                            type={"text"}
                            inputMode="numeric"
                            value={`${
                              data?.trainer_payment === null
                                ? 0
                                : data?.trainer_payment
                            }`}
                            onChange={(event: any) =>
                              handleSelectChange(
                                event,
                                data?.id,
                                "trainer_payment"
                              )
                            }
                            onFocus={handleFocus}
                          />
                        )}
                      </p>
                      <p className={`${footerStyle}`}>{data.status}</p>
                      <p className={`${footerStyle}`}>
                        {preview ? (
                          data?.student_payment_status === null ? (
                            "Status"
                          ) : (
                            data?.student_payment_status
                          )
                        ) : (
                          <select
                            disabled={
                              routePath &&
                              routePath === "process_trainer_pay" &&
                              data?.student_payment_status === "Status"
                            }
                            className="border-[#707070] border-[0.5px] rounded-[3px] w-11/12 mx-auto"
                            value={`${
                              (routePath &&
                              routePath === "cal_trainer_pay" &&
                              data?.student_payment_status === null
                                ? "Status"
                                : data?.student_payment_status) ||
                              (routePath &&
                              routePath === "process_trainer_pay" &&
                              data?.student_payment_status === "Status"
                                ? "Not Calculated"
                                : data?.student_payment_status)
                            }`}
                            defaultValue={`${
                              routePath && routePath === "cal_trainer_pay"
                                ? "Status"
                                : data?.student_payment_status
                            }`}
                            onChange={(event: any) =>
                              handleSelectChange(
                                event,
                                data?.id,
                                "student_payment_status"
                              )
                            }
                            name={`trainerBasedStudents[${index}][${indexNum}]["student_payment_status"]`}
                            id={`trainerBasedStudents[${index}][${indexNum}]["student_payment_status"]`}
                          >
                            {(routePath && routePath === "cal_trainer_pay" && (
                              <option value="Status">Status</option>
                            )) ||
                              (routePath &&
                                routePath === "process_trainer_pay" &&
                                data?.student_payment_status === "Status" && (
                                  <option value="Status">Not Calculated</option>
                                ))}
                            {((routePath && routePath === "cal_trainer_pay") ||
                              data?.student_payment_status ===
                                "Partially Calculated") && (
                              <option
                                disabled={
                                  routePath &&
                                  routePath === "process_trainer_pay"
                                }
                                value="Partially Calculated"
                              >
                                Partially Calculated
                              </option>
                            )}
                            {((routePath && routePath === "cal_trainer_pay") ||
                              data?.student_payment_status ===
                                "Calculated") && (
                              <option
                                disabled={
                                  routePath &&
                                  routePath === "process_trainer_pay"
                                }
                                value="Calculated"
                              >
                                Calculated
                              </option>
                            )}
                            {((routePath &&
                              routePath === "process_trainer_pay") ||
                              data?.student_payment_status ===
                                "Partially Processed") && (
                              <option
                                disabled={
                                  routePath && routePath === "cal_trainer_pay"
                                }
                                value="Partially Processed"
                              >
                                Partially Processed
                              </option>
                            )}
                            {((routePath &&
                              routePath === "process_trainer_pay") ||
                              data?.student_payment_status === "Processed") && (
                              <option
                                disabled={
                                  routePath && routePath === "cal_trainer_pay"
                                }
                                value="Processed"
                              >
                                Processed
                              </option>
                            )}
                          </select>
                        )}
                      </p>
                      <textarea
                        disabled={
                          routePath === "process_trainer_pay" &&
                          data?.trainer_payment_comment === null
                        }
                        onClick={() => {
                          if (
                            routePath === "process_trainer_pay" &&
                            data?.trainer_payment_comment !== null
                          ) {
                            setSwitchComp({ ele: "", id: data?.id });
                            setIsModalOpen(true);
                          }
                        }}
                        onChange={(event: any) =>
                          handleSelectChange(
                            event,
                            data?.id,
                            "trainer_payment_comment"
                          )
                        }
                        className={`${routePath === "process_trainer_pay" &&
                            data?.trainer_payment_comment !== null && 'cursor-pointer'} mx-2 border text-[#036AD1] text-[14px] font-Roboto font-[400] w-[15%] text-start px-1 break-words`}
                      >
                        {data?.trainer_payment_comment}
                      </textarea>
                      {/* ${
                          routePath === "process_trainer_pay" &&
                          "opacity-100 pointer-events-none cursor-pointer"
                        } */}
                      {/* <p
                      className={`text-[#036AD1] cursor-pointer text-[14px] font-Roboto font-[400] w-[12%] text-center break-words`}
                      onClick={() => commentPage(data?.id)}
                    >
                      {data?.trainer_payment_comment
                        ? limitWords(data?.trainer_payment_comment, 2)
                        : limitWords("Enter Comments here..", 2)}
                    </p> */}
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

// store existing array in new array state --> now show that in existing trainer payment.
// For making zero new payment and after entering data while submitting, current and existing payment payment should added
