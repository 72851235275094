import React from 'react';
import { CardDesign } from './CardDesign';
import { Doughnut } from 'react-chartjs-2';

export const Invoice = ({ DashboardData }: any) => {
    const dougnetoptions = {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: true,  
        plugins: {
            legend: {
            display: true,
            },
        },
    };

    const dougnetdata = {
        labels: (DashboardData[0].data || [])?.map((d: any) => d.name),
        datasets: [{
        label: "Count",
        backgroundColor: (DashboardData[0].data || [])?.map((d: any) => d.fill),
        data: (DashboardData[0].data || [])?.map((d: any) => d.value)
        }],
    }
  return (
        <div className="bg-[#F9FCFF] w-11/12 mx-auto rounded-[8px]">
          {DashboardData?.map((item: any) => {
            return (
              <div className="flex gap-4 p-4">
                <div className="w-[30%] shadow-[#00000029_0px_3px_8px]">
                  <div className="flex items-center justify-between p-4 bg-[#FBFBFB]">
                    <p className="font-[Roboto] font-medium text-[16px]">
                      {item.invoiceAnalysis}
                    </p>
                    <select className="font-[Roboto] text-[#707070] bg-[#FBFBFB] font-medium text-[16px] outline-none">
                      <option value="">Invoices</option>
                    </select>
                  </div>
                  <div className="flex items-center justify-center w-8/12 mx-auto py-2">
                    <Doughnut
                      options={dougnetoptions}
                      data={dougnetdata}
                      width={"50px"}
                    />
                  </div>
                </div>
                <div className="w-[70%] flex flex-col items-start justify-between space-y-2">
                  <h1 className="font-Roboto font-[500] text-[24px]">
                    {item.invoiceTitle}
                  </h1>
                  <div className="grid grid-cols-3 gap-2 w-full">
                    {item.invoice?.map((data: any) => {
                      return (
                        <div>
                          <CardDesign data={data} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
  )
}
