import { AddItemsButton } from "../../Elements/AddItemsButton";
import { InputBox } from "../../Elements/InputBox";

export const AddRooms = ({
  addMoreRooms,
  roomsCount,
  deleteRooms,
  formik,
  name,
  dataSetup,
}: any) => {
  const firstData: any = formik.values.rooms?.[0];
  return (
    <div className="w-full pt-4">
      <AddItemsButton
        text={"Add Rooms"}
        classNameInput={"flex justify-end"}
        onClick={addMoreRooms}
      />
      <div className="space-y-2">
        <InputBox
          label={"Rooms:"}
          placeholder={"Room 1"}
          classNameInput={"w-full"}
          name={`rooms[${0}]`}
          value={firstData}
          onChange={dataSetup}
          onBlur={formik.handleBlur}
          // error={
          //   formik.errors?.rooms?.[0]
          // }
          // isTouched={
          //   formik.touched?.rooms?.[0]
          // }
          index={0}
        />
        {formik.values.rooms?.map((room: any, i: number) => {
          if (i !== 0) {
            return (
              <div key={i} className="w-full flex items-center gap-2 relative">
                <InputBox
                  placeholder={`Room ${i+1}`}
                  classNameInput={"w-full"}
                  topDivStyle={"w-full"}
                  name={`rooms[${i}]`}
                  value={room}
                  onChange={dataSetup}
                  onBlur={formik.handleBlur}
                  // error={
                  //   formik.errors.rooms?.[i]
                  // }
                  // isTouched={
                  //   formik.touched.rooms?.[i]
                  // }
                  index={`${i}`}
                />
                <div
                  onClick={() => deleteRooms(i, formik.values.rooms[i])}
                  className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-9"
                >
                  X
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};