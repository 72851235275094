import React from 'react'

export const SubmitButton = ({disable}:any) => {
  return (
    <button
      type="submit"
      disabled={disable ? disable : false}
      className={`${disable && "opacity-50"} cursor-pointer bg-[#036AD1] rounded-[23px] p-1.5 px-6 text-white text-[17px] font-Roboto font-normal`}
    >
      Submit
    </button>
  );
}
