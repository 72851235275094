import { useState } from "react";
import { courseprogress } from "../../utils/json/courseProgress";
import { BackButton } from "../Elements/BackButton";
import { BatchInformation } from "./Batch Information/BatchInformation";
import { AddSession } from "./CreateSession/AddSession";
import { useSharedLogic, CommonTableComp } from "../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../CommonFunctions/Privileges";
import { useAppSelector, useAppDispatch } from "../..";
import { toggleBulkUpdate } from "../../Redux/Reducers/courseProgressModal";
import { CourseProgressModal } from "./CourseProgressModal";
import { CourseProgressSessionModal } from "./CourseProgressSessionModal";

export const CourseProgress = () => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    routePath,
  }: any = useSharedLogic();
  const dispatch = useAppDispatch();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const { allowAllActions }: any = FunctionForPrivileges();
  const courseProgressModalIsOpen = useAppSelector(
    (state) => state.courseprogress
  );

  return (
    <div>
      {(mode === "new-session" ||
        mode === "compensation-session" ||
        mode === "edit") && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-10/12 justify-end"} />
          <AddSession id={id} mode={mode} routePath={routePath} />
        </div>
      )}
      {mode === "view" && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-11/12 justify-end"} />
          <BatchInformation id={id} mode={mode} />
        </div>
      )}
      {allowAllActions() && (
        <CommonTableComp
          id={id}
          head={"Course Progress"}
          TABLE_JSON={courseprogress}
          backEndCallParams={signInData?.data[0]}
        />
      )}
      {courseProgressModalIsOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto px-4 sm:px-6 lg:px-8 d-flex justify-center items-center">
          <div
            className="fixed inset-0 bg-opacity-75 backdrop-filter blur(8px)"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            onClick={() => dispatch(toggleBulkUpdate(false))}
          >
            {/* Full screen blurred background */}
          </div>

          <div className="mx-auto relative w-90 h-90 bg-white rounded-xl shadow-lg overflow-hidden">
            <CourseProgressModal />
            <button
              type="button"
              onClick={() => dispatch(toggleBulkUpdate(false))}
              className="absolute top-4 right-4 z-50 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-indigo-500 p-2 rounded-md"
            >
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10L4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
