import { Fragment, useState, useRef } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { HeadingSection } from "../Elements/HeadingSection";
import { TableComponent } from "../Elements/Table/TableComponent";
import { useFormik } from "formik";

export const useSharedLogic = () => {
    const navigate = useNavigate(); 
    const params = useParams();
    const [searchParams] = useSearchParams();
    const routeNameForHome = params.dashboardScreen;
    const routeNameForSubSection = params.subsection;
    const id = searchParams.get("id") || "";
    const mode = searchParams.get("mode") || "";
    const courseId = searchParams.get("courseId") || "";
    const routePath = searchParams.get("routePath") || "";
    const by = searchParams.get("by") || "";

    const createFunc = (navigateTo:string) => {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({ id: navigateTo }).toString(),
      });
    };

    const createFuncWithoutSubSection = (navigateTo:string) => {
        navigate({
            pathname: `/${routeNameForHome}`,
            search: createSearchParams({ id: navigateTo }).toString(),
        });
    };

    return {
        navigate, params, routeNameForHome, routeNameForSubSection, id, mode, courseId, routePath, by, createFunc, createFuncWithoutSubSection, createSearchParams
    }
}

export const CommonTableComp = ({allowCondition, id, head, buttonType, buttonText, onClick, buttonImg, TABLE_JSON, backEndCallParams, backEndCallParamsStatus, allowTabs}:any) => {
    return (
      <Fragment>
        {!id && (
          <>
            <HeadingSection
              head={head}
              buttonType={buttonType}
              buttonText={buttonText}
              onClick={onClick}
              buttonImg={buttonImg}
              allowCondition={allowCondition}
            />
            <TableComponent
              TABLE_JSON={TABLE_JSON}
              backEndCallParams={backEndCallParams}
              backEndCallParamsStatus={backEndCallParamsStatus}
              allowTabs={allowTabs}
            />
          </>
        )}
      </Fragment>
    );
}

export const FormikCommonFunc = (initialValues:any, validationSchema:any, onSubmit:any) => {
        
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema ? validationSchema : "",
        onSubmit: () => {
          onSubmit();
        },
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setValues,
        setFieldValue,
    } = formik;

    return {
        formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue,
    };
}
