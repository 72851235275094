import { HeadingSection } from "../../Elements/HeadingSection";
import { AddCertificate } from "./AddCertificate";
import { MessageCard } from "../../Elements/MessageCard";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const Certificate = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  return (
    <div className="w-11/12 mx-auto">
      {id === "update-success" && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={"Certificate Updated Successfully"}
            buttonText={"Manage Certificate"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
      {allowOnlyActions('view') && !id && (
        <div className="space-y-4">
          <HeadingSection
            head={"Certificate"}
          />
          <AddCertificate id={id} mode={mode} />
        </div>
      )}
    </div>
  );
};
