import ViewEditDeleteActionComponent from "../../Components/AdminManagement/AdminUserManagement/ActionComponent";
import { getAllUsersForTrainers } from "../../functions/admin/User Mangement/userMangement";

export const TrainerUserTable = {
  headers: [
    {
      name: "Name",
      apiKey: "name",
      headerClass: "w-[100px] text-start",
      className: "w-[100px] text-start break-words",
    },
    {
      name: "Phone Number",
      apiKey: "phone_number",
      headerClass: "w-[80px] text-start px-2",
      className: "w-[80px] text-start px-2",
    },
    {
      name: "E-mail",
      apiKey: "email",
      headerClass: "w-[150px] text-center px-2",
      className: "w-[150px] break-words text-center px-2",
    },
    {
      name: "Role",
      apiKey: "roles",
      headerClass: "w-[150px] text-center px-2",
      className: "w-[150px] text-center break-words px-2",
    },
    {
      name: "Status",
      apiKey: "status",
      headerClass: "w-[100px] text-center",
      className: "w-[100px] text-center",
    },
    {
      name: "Branch",
      apiKey: "branches",
      headerClass: "w-[120px] text-center px-2",
      className: "w-[120px] text-center break-words px-2",
    },
    {
      name: "City",
      apiKey: "cities",
      headerClass: "w-[120px] text-center px-2",
      className: "w-[120px] text-center break-words px-2",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
      headerClass: "text-center w-[100px]",
      className: "text-center w-[100px]",
    },
  ],
  BackendFunctionCall: getAllUsersForTrainers,
  filters: {
    checkBox: [
      {
        apiKey: "cities",
        name: "City",
        isSearchNeeded: true,
        className: "w-[120px]",
      },
      {
        apiKey: "branches",
        name: "Branch",
        isSearchNeeded: true,
        className: "w-[120px]",
      },
      {
        apiKey: "roles",
        name: "Role",
        isSearchNeeded: true,
        className: "w-[120px]",
      },
      {
        apiKey: "status",
        name: "Status",
        isSearchNeeded: true,
        className: "w-[120px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["name", "phone_number", "email"],
        searchKey: "roleStatus",
        name: "Search....",
        searchStyle: "w-[220px]",
      },
    ],
  },
  // ActionComponent: ViewEditDeleteActionComponent,
};
