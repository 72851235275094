import { createSearchParams } from "react-router-dom";
import { manageCourse } from "../../../utils/json/managecourse";
import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { BackButton } from "../../Elements/BackButton";
import { AddCourse } from "./CreateCourse/AddCourse";
import { MessageCard } from "../../Elements/MessageCard";
import { AddVideo } from "./CreateCourse/AddVideos";
import {
  useSharedLogic,
  CommonTableComp,
} from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const ManageCourses = () => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  const createNewUser = () => {
    navigate({
      pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
      search: createSearchParams({ id: "new-course" }).toString(),
    });
  };

  return (
    <div>
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Course Created Successfully"
                : "Course Updated Successfully"
            }
            buttonText={"Manage Course"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
      {(id === "new-course" || mode === "edit" || mode === "view") &&
        mode !== "add-videos" &&
        mode !== "edit-videos" && (
          <div className="space-y-4">
            <BackButton
              topDivStyle={"w-10/12 justify-between"}
              header={
                (id === "new-course" && "Add Course") ||
                (mode === "edit" && "Edit Course") ||
                (mode === "view" && "View Course")
              }
            />
            <AddCourse id={id} mode={mode} />
          </div>
        )}
      {allowOnlyActions("view") && (
        <CommonTableComp
          id={id}
          head={"Manage Course"}
          buttonType={"right"}
          buttonText={"Create Course"}
          onClick={() => createFunc("new-course")}
          buttonImg={buttonWithRightArrow}
          TABLE_JSON={manageCourse}
          allowCondition={allowOnlyActions("create")}
        />
      )}
    </div>
  );
};
