import React from 'react'
import { studentForHr } from '../../../utils/json/StudentForHr'
import { BackButton } from "../../Elements/BackButton";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { StudentForHrRelevantJobs } from './StudentForHrRelevantJobs';
import { StudentAppliedJobs } from './StudentAppliedJobs';

export default function StudentForHr() {
    const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  
  return (
    <div className="w-11/12 mx-auto">
     {mode === "View Relevant Jobs" && (
      <div>
      
        <div className="space-y-4">
          <div className='flex items-center w-11/12 justify-end mx-auto'>
          <h1 className="text-[30px] text-white  w-11/12 font-Roboto font-medium">Student For HR</h1>
          <BackButton
            topDivStyle={"w-11/12 justify-end"}
          />
          </div>
        
          <StudentForHrRelevantJobs id={id} mode={mode} />
        </div>
        </div>
      )}
       {mode === "View Applied Jobs" && (
      <div>
      
        <div className="space-y-4">
          <div className='flex items-center w-11/12 justify-end mx-auto'>
          <h1 className="text-[30px] text-white  w-11/12 font-Roboto font-medium">Student For HR</h1>
          <BackButton
            topDivStyle={"w-11/12 justify-end"}
          />
          </div>
        
          <StudentAppliedJobs id={id} mode={mode} />
        </div>
        </div>
      )}
      <CommonTableComp
        id={id}
        head={"Student For HR"}
        TABLE_JSON={studentForHr}
      />
    </div>
  );
}