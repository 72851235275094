import { Components } from "./ModuleImports";
import { dateFormat } from "../../../Services/CommonFunctions";

export const Refund = ({refundData, updatedStudentData}:any) => {
  const { ReadData, formatNumberWithCommas }: any = Components;
  
  return (
    <div className="w-11/12 mx-auto space-y-2">
      <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        Refund
      </h1>
      <div className="space-y-2 w-[97%] mx-auto">
        {refundData?.map((pay: any, index: number) => {
          return (
            <div key={index} className="p-4 bg-[#F9F9F9]">
              <div className="flex items-center justify-between w-[90%] border-[#F9F9F9] border-2">
                <ReadData
                  head={"Refund Date"}
                  body={dateFormat(pay.created_at, "DD MMM YYYY")}
                />
                <ReadData head={"Mode of Payment"} body={pay.mode_of_payment} />
                <ReadData
                  head={"Course Fee (Incl. GST)"}
                  body={formatNumberWithCommas(pay?.training_id?.total_fee)}
                  addSpan={pay?.training_id?.total_fee}
                />
                <ReadData
                  head={"Refund Amount"}
                  body={formatNumberWithCommas(pay.amount)}
                  addSpan={pay.amount}
                />
                <ReadData
                  head={"Total Amount"}
                  body={formatNumberWithCommas(Number(pay?.training_id?.total_fee) - Number(pay.amount))}
                  addSpan={Number(pay?.training_id?.total_fee) - Number(pay.amount)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
