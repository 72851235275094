import {
  useSearchParams,
} from "react-router-dom";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  const navigateToViewPage = () => {
      setSearchParams({
        id: `${recordData.uid}`, mode: `view`
      });
  } 

    const navigateToEditPage = () => {
      setSearchParams({
        id: `${recordData.uid}`,
        mode: `edit`,
      });
    }
    
  return (
    <div className="flex items-center justify-center text-center space-x-4">
      {allowOnlyActions('view') && <div
        onClick={navigateToViewPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableViewEyeIcon}
          alt="viewIcon"
          className="w-[21px] h-[10px]"
        />
      </div>}
      {allowOnlyActions('edit') && <div
        onClick={navigateToEditPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img src={tableEditIcon} alt="editIcon" className="w-[14px] h-[14px]" />
      </div>}
    </div>
  );
};

export default ViewEditDeleteActionComponent;
