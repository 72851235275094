import React, { useState, useEffect } from "react";
import { getTrainerIdBasedStudents } from "../../../functions/admin/Action center/Trainer Payments/trainerPayment";
import { Loader } from "../../Elements/Loader/Loader";

export const ViewTrainerStudents = ({ id }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [viewStudent, setViewStudent] = useState([] as any);
  const headers = [
    "S.No",
    "Name",
    "Phone Number",
    "Trainer Payment",
    "Student Payment Status",
  ];
  const footerStyle = `text-[#686868] 2xl:text-[14px] lg:text-[12px] text-[10px] font-Roboto font-[400] w-[12%] text-center`;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response: any = await getTrainerIdBasedStudents(id);
      // console.log(response, "response");
      if (response && response?.length > 0) {
        // const mapAllStudent = response?.map((re: any) =>
        //   re?.student?.name === null ? "" : re?.student?.name
        // );
        setViewStudent(response);
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-9/12"} />
      ) : (
        <div className="bg-white w-9/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6 min-h-[400px] max-h-[500] scroll-smooth overflow-y-scroll scrollbar-hide">
          <div className="w-10/12 mx-auto py-6 space-y-6">
            <h1 className="2xl:text-[20px] lg:text-[19px] md:text-[18px] font-[500] text-[#036AD1]">
              Students List :
            </h1>
            <div className="space-y-1 border border-blue-100 py-2">
              <div className="flex items-center justify-evenly bg-[#EFF7FF] py-3">
                {headers.map((head: any, index: number) => (
                  <h1
                    key={index}
                    className={`text-[#686868] 2xl:text-[14px] lg:text-[13px] text-[11px] font-Roboto font-[500] text-center ${
                      index === headers?.length - 1 ? "w-[20%]" : "w-[12%]"
                    }`}
                  >
                    {head}
                  </h1>
                ))}
              </div>
              <div className="space-y-2 min-h-[200px] max-h-[400px] scroll-smooth overflow-y-scroll scrollbar-hide">
                {viewStudent?.length > 0 ? (
                  viewStudent?.map((data: any, index: number) => (
                    <div
                      key={index}
                      className={`flex items-center justify-evenly ${
                        index % 2 !== 0 && "bg-[#EFF7FF]"
                      } py-2}`}
                    >
                      <p className={footerStyle}>{index + 1}</p>
                      <p className={footerStyle}>{data?.student?.name}</p>
                      <p className={footerStyle}>
                        {data?.student?.phoneNumber}
                      </p>
                      <p className={footerStyle}>
                        {data?.existing_trainer_payment}
                        {/* {(data?.student_payment_status === "Processed" || data?.student_payment_status === "Partially Processed") ? data?.existing_trainer_payment : 0} */}
                      </p>
                      <p
                        className={`text-[#686868] 2xl:text-[14px] lg:text-[12px] text-[10px] font-Roboto font-[400] w-[20%] text-center`}
                      >
                        {data?.student_payment_status}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center min-h-[200px] text-[#686868] 2xl:text-[14px] lg:text-[12px] text-[10px] font-Roboto font-[400]">
                    No data Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
