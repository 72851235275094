import React, { useState, useEffect } from "react";

export const ModalForComment = ({ onClose, comment }: any) => {
  return (
    <div className="fixed z-auto inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="bg-white rounded overflow-hidden shadow-xl transform transition-all w-[30%] mx-auto">
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-3 py-1 bg-red-600 text-lg font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              x
            </button>
          </div>
          <div
            className="bg-white px-4 py-5 sm:p-6 text-[#036AD1] 2xl:text-[18px] lg:text-[16px] text-[14px] font-Roboto font-[400] text-start break-words"
            id="modal-content"
          >
            {comment}
          </div>
        </div>
      </div>
    </div>
  );
};
