import { HeadingSection } from '../../Elements/HeadingSection';
import { UpdateHiringCompanies } from './UpdateHiringCompanies';
import { MessageCard } from '../../Elements/MessageCard';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';

export const HiringCompanies = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  
  return (
    <div className="w-11/12 mx-auto">
      {(id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={"Hiring Companies Updated Successfully"}
            buttonText={"Manage Hiring Companies"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
      {allowOnlyActions('view') && !id && (
        <>
          <HeadingSection head={"Hiring Companies"} />
          <UpdateHiringCompanies id={id} mode={mode} />
        </>
      )}
    </div>
  );
}
