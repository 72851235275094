import { getImports } from './ModuleImports';

export const IsBalanceAvailable = ({formik, id, mode, adjustPaymentData}:any) => {
  const { useEffect, InputBox, calculateTotalBalanceDueAmount, formatNumberWithCommas }: any = getImports;

  useEffect(() => {
    (async () => {
      // if( mode && mode !== "edit") {
        const collectData = formik.values?.payment_details?.map((val: any) =>
          val.paid_amount ? parseFloat(val.paid_amount) : 0
        );
        const sum: any =
          collectData &&
          collectData.reduce(
            (accumulator: any, currentValue: any) => accumulator + currentValue,
            0
          );
        const readData: any = await calculateTotalBalanceDueAmount(
          Number(formik.values?.total_fee)?.toFixed(2),
          sum?.toFixed(2)
        );
        const finalValue = readData + Number(adjustPaymentData);
        formik.setFieldValue("balance_due", finalValue ? finalValue : 0);
      // }
    })();
  }, [
    formik.values.payment_details,
    formik.values.discount_percentage,
    formik.values?.total_fee,
    // formik.values?.branch_id,
  ]);

  return (
    <div className="w-full">
      <div className="flex flex-col w-[30%] xl:w-[40%]">
        <label className={`text-[23px] font-Roboto font-[500] text-[#FF0000]`}>
          Balance Due
        </label>
        <div className="relative min-h-[45px] w-[70%] xl:w-[80%] bg-[#C5C5C5] outline-none border-[0.5px] border-[#707070] 2xl:text-[16px] lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2">
          {formik.values.balance_due && formik.values?.balance_due !== 0 && (
            <span className="px-1">&#8377;</span>
          )}
          {typeof formik.values?.balance_due === "number"
            ? formatNumberWithCommas(
                parseFloat(formik.values?.balance_due?.toFixed(2))
              )
            : formatNumberWithCommas(formik.values?.balance_due)}
          {formik.errors.balance_due && formik.touched.balance_due && (
            <p className="text-red-500 absolute -bottom-6 right-0">
              {formik.errors.balance_due}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
