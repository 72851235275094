import { useState } from "react";
import { useSearchParams } from "react-router-dom";
// import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { navigate }:any = useSharedLogic()

  const navigateToEditPage = () => {
    setSearchParams({
      id: `${recordData.id}`,
      mode: `edit`,
      by: "verified"
    });
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
      {allowOnlyActions('edit') && <div
        onClick={navigateToEditPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableEditIcon}
          alt="editIcon"
          className="w-[21px] h-[10px]"
        />
      </div> }
    </div>
  );
};
