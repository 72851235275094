import { useState, useEffect } from "react";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { pendingPaymentForStudent } from "../../../utils/json/PendingPaymentForStudent";
import { absentStudentsList } from "../../../utils/json/absentStudent";
import {
  useSharedLogic,
} from "../../CommonFunctions/CommonFunc";
import { StudentAttendanceFollowUp } from "./StudentAttendanceFollowUp";
import { AddNew } from "./AddNew";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { useAppSelector } from "../../..";
import { useSearchParams } from "react-router-dom";
import { AbsentStudentsTableData } from "../../../functions/admin/Action center/Student Attendance/StudentAttendance";

export const StudentAttendance = () => {
  const { id, mode }: any = useSharedLogic();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    // {
    //   approval: "Present",
    //   len: null,
    // },
    {
      approval: "Absent",
      len: null,
    },
  ] as any);

  useEffect(() => {
    setSearchParams({
      id: "absent",
    });
  }, []);

  useEffect(() => {
    (async () => {
    //   const studentPendingResponse: any = await getPendingPaymentsTableData(
    //     signInData?.data[0]
    //   );
      const absentResponse: any = await AbsentStudentsTableData(
        signInData?.data[0]
      );
    //   const studentLength: any = studentPendingResponse?.length;
      const batchLength: any = absentResponse?.length;

      // Update queue state with the lengths
      setQueue((prevQueue: any) => [
        // {
        //   ...prevQueue[0],
        //   len: studentLength,
        // },
        {
          ...prevQueue[0],
          len: batchLength,
        },
      ]);
    })();
  }, []);

  return (
    <div>
      {mode === "add_new_comment" && (
        <div className="space-y-2">
          {/* <h1 className="w-11/12 mx-auto text-[30px] text-white font-Roboto font-medium">
            Student Attendance
          </h1> */}
          <StudentAttendanceFollowUp id={id} />
        </div>
      )}
      {allowOnlyActions("view") && mode === "edit" && (
        <div className="space-y-2">
          {/* <h1 className="w-11/12 mx-auto text-[30px] text-white font-Roboto font-medium">
            Student Attendance
          </h1> */}
          <AddNew id={id} />
        </div>
      )}
      {allowOnlyActions("view") && (
        <div className="space-y-2">
          <div className="w-[95%] mx-auto flex items-center justify-between">
            <h1 className="text-[30px] text-white font-Roboto font-medium">
              Student Attendance
            </h1>
            {/* {allowOnlyActions("view") && (
              <QueueType queue={queue} setQueue={setQueue} />
            )} */}
          </div>
          {id === "present" && (
            <TableComponent
              TABLE_JSON={pendingPaymentForStudent}
              backEndCallParams={signInData?.data[0]}
            />
          )}
          {id === "absent" && (
            <TableComponent
              TABLE_JSON={absentStudentsList}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
    </div>
  );
};

const QueueType = ({ queue, setQueue }: any) => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  const linkOnclick = (q: any) => {
    const lowercaseString = q?.toLowerCase();
    setSearchParams({
      id: lowercaseString?.replace(/ /g, "_"),
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue?.map((que: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center ${index === 0 && "gap-8"}`}
            onClick={() => linkOnclick(que?.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {que?.approval}{" "}
                <span className="ml-1">{`(${que?.len ? que?.len : 0})`}</span>
              </p>
              {id === que?.approval?.toLowerCase().replace(/\s+/g, "_") ? (
                <hr />
              ) : (
                <p></p>
              )}
            </div>
            {/* {index === 0 && (
              <span className="border-l-2 border-white h-8"></span>
            )} */}
          </div>
        );
      })}
    </div>
  );
};
