import { useState, useEffect } from 'react';
import { operationalReportsForBatchTable, operationalReportsForDiscountTable, operationalReportsForRefundTable, operationalReportsForNullifyTable } from '../../../functions/admin/Reports/Reports';
import { useAppSelector } from '../../..';
import { useSearchParams } from 'react-router-dom';
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';
import { TableComponent } from '../../Elements/Table/TableComponent';
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { batchReports } from '../../../utils/json/batchReports';
import { discountReports } from '../../../utils/json/discountReports';
import { refundReports } from '../../../utils/json/refundReports';
import { nullifyReports } from '../../../utils/json/nullifyReports';
import { Loader } from '../../Elements/Loader/Loader';

export const OperationalReports = () => {
  const { id, mode, by }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({} as any);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queue, setQueue] = useState([
    {
      approval: "Batch",
      len: null
    },
    {
      approval: "Discount",
      len: null
    },
    {
      approval: "Refund",
      len: null
    },
    {
      approval: "Nullify",
      len: null
    }
  ] as any);

  useEffect(() => {
    setSearchParams({
    id: "batch",
    });
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      if (signInData?.data?.length > 0) {
        const responseForBatch:any = await operationalReportsForBatchTable(signInData?.data[0]);
        const responseForDiscount:any = await operationalReportsForDiscountTable(signInData?.data[0]);
        const responseForRefund: any = await operationalReportsForRefundTable(signInData?.data[0]);
        const responseForNullify:any = await operationalReportsForNullifyTable(signInData?.data[0]);
        const batchLength = responseForBatch?.length;
        const discountLength = responseForDiscount?.length;
        const refundLength = responseForRefund?.length;
        const nullifyLength = responseForNullify?.length;

        setQueue((prevQueue: any) => [
          {
            ...prevQueue[0],
            len: batchLength,
          },
          {
            ...prevQueue[1],
            len: discountLength,
          },
          {
            ...prevQueue[2],
            len: refundLength,
          },
          {
            ...prevQueue[3],
            len: nullifyLength,
          },
        ]);
      }
      setIsLoading(false)
    })() 
  }, [])
  
  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-9/12"} />
      ) : (
        allowOnlyActions("view") && (
          <div className="space-y-2">
            <div className="w-[95%] mx-auto flex items-center justify-between">
              <h1 className="text-[30px] text-white font-Roboto font-medium">
                Operational Reports
              </h1>
              {allowOnlyActions("view") && (
                <QueueType queue={queue} setQueue={setQueue} />
              )}
            </div>
            {id === "batch" && (
              <TableComponent
                TABLE_JSON={batchReports}
                backEndCallParams={signInData?.data[0]}
              />
            )}
            {id === "discount" && (
              <TableComponent
                TABLE_JSON={discountReports}
                backEndCallParams={signInData?.data[0]}
              />
            )}
            {id === "refund" && (
              <TableComponent
                TABLE_JSON={refundReports}
                backEndCallParams={signInData?.data[0]}
              />
            )}
            {id === "nullify" && (
              <TableComponent
                TABLE_JSON={nullifyReports}
                backEndCallParams={signInData?.data[0]}
              />
            )}
          </div>
        )
      )}
    </div>
  );
}

const QueueType = ({ queue, setQueue }: any) => {
  const {
    navigate,
    routeNameForHome,
    routeNameForSubSection,
    id,
    mode,
    createFunc,
  }: any = useSharedLogic();
  const [searchParams, setSearchParams] = useSearchParams();

  const linkOnclick = (q: any) => {
    const lowercaseString = q?.toLowerCase();
    setSearchParams({
      id: lowercaseString?.replace(/ /g, "_"),
    });
  };

  return (
    <div className="flex items-center gap-6">
      {queue?.map((que: any, index: number) => {
        return (
          <div
            key={index}
            className={`flex items-center ${
              (index === 0 || index === 1 || index === 2) && "gap-8"
            }`}
            onClick={() => linkOnclick(que?.approval)}
          >
            <div className="space-y-1 cursor-pointer">
              <p className="text-[#FFFFFF] text-[17px] font-Roboto font-[400]">
                {que?.approval}{" "}
                <span className="ml-1">{`(${que?.len ? que?.len : 0})`}</span>
              </p>
              {id === que?.approval?.toLowerCase().replace(/\s+/g, "_") ? (
                <hr />
              ) : (
                <p></p>
              )}
            </div>
            {(index === 0 || index === 1 || index === 2) && (
              <span className="border-l-2 border-white h-8"></span>
            )}
          </div>
        );
      })}
    </div>
  );
};