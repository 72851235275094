import { getImports } from "./ModuleImports";
import shareIcon from "../../../Assets/shareIcon.svg";
import dropArrow from "../../../Assets/dropArrow.svg";
import current_date from "../../../Assets/current_date.svg";
import follow_up_date from "../../../Assets/follow_up_date.svg";

export const BatchFollowUp = ({ formik, setSwapComp, showBatches, setShowBatches }: any) => {
    const { useState, useEffect, CloseIcon, getBatchFollowUp }: any = getImports;
    
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
    (async () => {
        const Response = await getBatchFollowUp(formik.values.batch_follow_up);
        setShowBatches(Response);
    })()
    },[])

    const handleClickNext = (status:string) => {
        if (status === "prev") {
            setCurrentIndex(
              (prevIndex: any) =>
                (prevIndex - 1 + showBatches.length) % showBatches.length
            );
        } else setCurrentIndex((nextIndex:any) => (nextIndex + 1) % showBatches.length);
    };

    const currentObject = showBatches[currentIndex];

    const BatchSwitch = (val:any) => {
        if (val === "close") {
            setSwapComp([])
        } else {
            setSwapComp(["add-batch"])
        }
    }

    return (
      <form className="bg-white shadow-md shadow-[#00000029] rounded-[6px] w-7/12 mx-auto py-4 space-y-6 relative">
        <CloseIcon
          closeFunc={() => BatchSwitch("close")}
          classStyle="text-[33px] w-[40px] h-[40px] absolute -top-4 -right-4"
        />
        <div className="w-11/12 mx-auto border-[#707070] border-[0.2px] rounded-[3px] py-4">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="flex items-center gap-2">
              <img src={follow_up_date} alt="Date" className="" />
              <h1 className="text-[#000000] 2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[400]">
                Batch Follow-up Date:
                <span className="px-1">{currentObject?.follow_up_date}</span>
              </h1>
            </div>
            <div className="flex items-center gap-3">
              <img
                src={dropArrow}
                alt="ArrowLeft"
                className="rotate-180 cursor-pointer w-6 h-6"
                onClick={() => handleClickNext("prev")}
              />
              <img
                src={dropArrow}
                alt="ArrowRight"
                className="cursor-pointer w-6 h-6"
                onClick={() => handleClickNext("next")}
              />
            </div>
          </div>
          <hr />
          <div className="w-[97%] mx-auto py-3 break-words h-[90px] overflow-auto snap-y snap-mandatory scrollBar scrollbar-hide scroll-smooth">
            {currentObject?.comment}
          </div>
        </div>
        <div className="flex justify-center items-center w-11/12 mx-auto relative py-4">
          <div className="flex items-center gap-1 absolute left-0">
            <img src={current_date} alt="ArrowLeft" className="" />
            <p>{currentObject?.created_date ? (currentObject?.created_date) : 'DD-MM-YYYY'}</p>
          </div>
          <button
            type="button"
            onClick={() => BatchSwitch("add")}
            className="w-[30%] select-none bg-[#DF0952] flex items-center justify-center gap-2 rounded-[4px] text-white text-[20px] font-Roboto font-[500] px-3"
          >
            Add New Follow-up <span className="text-4xl">+</span>
          </button>
        </div>
      </form>
    );
}