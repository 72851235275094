import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { navigate }:any = useSharedLogic()

  const navigateToViewPage = () => {
    navigate({
      pathname: `/student_management`,
      search: createSearchParams({
        id: `${recordData.trainingId}`,
        mode: `view`,
        routePath: 'student_approval_queue'
      }).toString(),
    })
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
       {allowOnlyActions('view') && <div
        onClick={navigateToViewPage}
        className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
      >
        <img
          src={tableViewEyeIcon}
          alt="viewIcon"
          className="w-[21px] h-[10px]"
        />
      </div>}
    </div>
  );
};
