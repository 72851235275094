export const dashBoardData: any = {
  admission: {
    paidStudent: 0,
    freeStudent: 0,
    appStudent: 0,
  },
  invoiceAnalysis: {
    invoice: {
      total: 0,
      classRoom: 0,
      online: 0,
      hybrid: 0,
    },
    revenue: {
      total: 0,
      classRoom: 0,
      online: 0,
      hybrid: 0,
    },
  },
  operations: {
    Unallocated: 0,
    Hold: 0,
    Drop: 0,
    Allocated: 0,
    Completed: 0,
  },
  batch: {
    Ongoing: 0,
    Upcoming: 0,
    Review: 0,
    Drop: 0,
    Completed: 0,
    Hold: 0,
  },
  feedback: {
    Good: 0,
    Excellent: 0,
    Average: 0,
    Poor: 0,
    Open: 0,
    inprogress: 0,
    reopen: 0,
    Resolved: 0,
    Thanks: 0,
  },
  jobs: {
    totalCompanies: 0,
    totaljobs: 0,
    applied: 0,
    shortlisted: 0,
    placed: 0,
    joined: 0,
    totalOpenings: 0,
  },
};