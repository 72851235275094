import {useState, useEffect} from 'react';
import { DropdownInputWithCheckbox } from '../../../Elements/DropdownInputWithCheckbox';
import { DropdownWithHandleOut } from '../../../Elements/DropdownWithHandleOut';
import { getRefundPreloadData, getCoursesForStudent, getStudentTrainingBasedOnBranch, getTrainingFortheStudent, getCities, getStudentBasedOnBranch } from '../../../../functions/admin/Action center/Refund and Adjust Payment/issueRefundAndAdjustPayment';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../../..';

export const AdjustPayment = ({ formik, setIsLoading, setBasicInfoDetails }: any) => {
    const [studentsData, setStudentsData] = useState([] as any);
    const [studentsName, setStudentsName] = useState([] as any);
    const [coursesData, setCoursesData] = useState([] as any);
    const [coursesName, setCoursesName] = useState([] as any);
    const [citiesData, setCitiesData] = useState([] as any);
    const [citiesName, setCitiesName] = useState([] as any);
    const [branchesData, setBranchesData] = useState([] as any);
    const [branchesName, setBranchesName] = useState([] as any);
    const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

    useEffect(() => {
        (async () => {
        if (signInData?.data[0]?.branches?.length>0) {
            const cityResponse: any = await getCities(signInData?.data[0]?.cities);
            if (cityResponse) {
                setCitiesData(cityResponse);
                const mapCityNames = cityResponse?.map((city: any) => city?.city_name)
                setCitiesName(mapCityNames);
            }
        }
        })()
    },[])

    const handleInputChange = async (event: any) => {
        formik.setFieldValue("course", "")
        formik.setFieldValue("courseId", null);
        formik.setFieldValue("to_student", "")
        formik.setFieldValue("to_student_uid", "");
        formik.setFieldValue("to_course", "")
        formik.setFieldValue("to_course_id", null);
        const { name, value, checked } = event.target;
        const filterStudent = studentsData.filter((fil: any) => `${fil?.name} - ${fil?.phoneNumber}` === value);
        formik.setFieldValue("student", `${filterStudent?.[0]?.name} - ${filterStudent?.[0]?.phoneNumber}`);
        formik.setFieldValue("studentUid", filterStudent?.[0]?.uid);
        // console.log(filterStudent, "filterStudent")
        if (filterStudent?.length > 0) {
            const studentBasedCourseResponse: any = await getCoursesForStudent(filterStudent?.[0]?.uid);
            // console.log(studentBasedCourseResponse, "studentBasedCourseResponse")
            setCoursesData(studentBasedCourseResponse);
            const mapCourseNames = studentBasedCourseResponse?.map((cr: any) => cr?.course_name);
            setCoursesName(mapCourseNames)
        }
    }

    const handleEnteredCourse = async (
      option: any,
      forId: any,
      name: any,
      mapData: any,
      mapThrough: any,
    ) => {
      formik.setFieldValue(name, option);

      const filterForID = mapData?.filter(
        (data: any) => data[mapThrough] === option
      )?.[0];
      if (!filterForID) {
        return; // Exit early if filterForID is not found
      }

      const course_id = Number(filterForID["id"]);
      formik.setFieldValue(forId, course_id);

      try {
        setIsLoading(true);

        const [branchGSTDetails, response]:any = await Promise.all([
          getStudentTrainingBasedOnBranch(formik.values.studentUid, course_id),
          getTrainingFortheStudent(formik.values.studentUid, course_id, formik.values.from_branch_id),
        ]);

        if (Object.keys(branchGSTDetails).length) {
          formik.setFieldValue("branch_based_GST", branchGSTDetails[0]);
        }

        if (response.status) {
          setBasicInfoDetails(response);
          formik.setFieldValue("from_training_id", response?.updatedStudentData?.id);
        } else {
          setBasicInfoDetails({});
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleOut = async(option: any, forId: string, name: any, mapData: any, mapThrough: any) => {
        formik.setFieldValue(name, option);
        const filterForID = mapData?.filter((data: any) => data[mapThrough] === option);
        const IdFilter:any = filterForID?.[0]
        formik.setFieldValue(forId, Number(IdFilter["id"]));
        const readData: any = await getRefundPreloadData();
        formik.setFieldValue("to_branch_id", null)
        formik.setFieldValue("to_branch", "")
        formik.setFieldValue("to_city_id", null)
        formik.setFieldValue("to_city", "")
        formik.setFieldValue("to_student_uid", "")
        formik.setFieldValue("to_student", "")
        formik.setFieldValue("to_course_id", null)
        formik.setFieldValue("to_course", "")
        formik.setFieldValue("student", "")
        formik.setFieldValue("studentUid", "")
        formik.setFieldValue("course", "")
        formik.setFieldValue("courseId", null);
        setBasicInfoDetails({})
        if (name === "from_city") {
            formik.setFieldValue('from_branch_id', null)
            formik.setFieldValue('from_branch', "")
            const filterOnlyAccessBasedBranches = readData?.branches?.filter((ft:any)=> signInData?.data[0]?.branches?.includes(ft?.id))
            const filterBranch = filterOnlyAccessBasedBranches?.filter((branch: any) => branch.city === Number(IdFilter["id"]));
            setBranchesData(filterBranch);
            const filterNames = filterBranch?.map((branch: any) => branch.branch_name);
            setBranchesName(filterNames);
        } else {
            const studentResponse:any = await getStudentBasedOnBranch(IdFilter["id"]);
            setStudentsData(studentResponse);
            const mapStudentsData = studentResponse?.map((stu: any) => `${stu?.name} - ${stu?.phoneNumber}`)
            setStudentsName(mapStudentsData);
        }
    }

  return (
    <div className="w-11/12 mx-auto space-y-3">
        <h1 className='text-start w-full font-Roboto font-[500] text-[#000000] text-[28px]'>Move From</h1>
        <div className="w-full flex items-center justify-between">
            <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                label="City"
                placeholder="City"
                options={citiesName}
                name={"from_city"}
                forId={"from_city_id"}
                value={formik.values.from_city}
                handleOut={handleOut}
                onBlur={formik.handleBlur}
                mapData={citiesData}
                mapThrough={"city_name"}
                formik={formik}
                disabled={false}
                zIndex={"z-40"}
            />
            <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                label="Branch"
                placeholder="Branch"
                options={branchesName}
                name={"from_branch"}
                forId={"from_branch_id"}
                value={formik.values.from_branch}
                handleOut={handleOut}
                onBlur={formik.handleBlur}
                mapData={branchesData}
                mapThrough={"branch_name"}
                formik={formik}
                disabled={false}
                zIndex={"z-40"}
            />
        </div>
        <div className='w-full flex items-center justify-between'>
            <DropdownWithHandleOut
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                options={studentsName}
                label="Student Details"
                placeholder="Student Details"
                name={"student"}
                value={formik.values.student}
                handleInputChange={handleInputChange}
                onBlur={formik.handleBlur}
                formik={formik}
                zIndex={"z-20"}
            />
            <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-[48%]"}
                classNameInput={"w-full"}
                label="Course"
                placeholder="Course"
                options={coursesName}
                name={"course"}
                forId={"courseId"}
                value={formik.values.course}
                handleOut={handleEnteredCourse}
                onBlur={formik.handleBlur}
                mapData={coursesData}
                mapThrough={"course_name"}
                formik={formik}
                disabled={false}
            />
        </div>
    </div>
  )
}
