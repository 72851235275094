import {useState, useEffect} from 'react';
import { Loader } from '../../Elements/Loader/Loader';
import { onKeyDown } from '../../../Services/CommonFunctions';
import { ReadData } from '../../Elements/ReadData';
import { Course } from './Course';
import { StudentDetails } from './StudentDetails';
import { batchProgressById, getSessionById, createSession, updateSession, getStudentsByBatchid, getStudentsOfSingleSession } from '../../../functions/admin/Course Progress/courseProgress';
import { useSharedLogic, FormikCommonFunc } from '../../CommonFunctions/CommonFunc';
import { add_session } from '../../Formik/InitialValues';
import { DropdownInputWithCheckbox } from '../../Elements/DropdownInputWithCheckbox';
import { InputBox } from '../../Elements/InputBox';
import { AddItemsButton } from '../../Elements/AddItemsButton';
import { DropdownSingleInputWithCheckbox } from '../../Elements/DropdownSingleInputWithCheckbox';
import toast from 'react-hot-toast';
import { AddSession } from '../../Formik/FormikValidation';
import { isTime1GreaterThanTime2 } from '../../../Services/CommonFunctions';

export const Components = {
    useState, useEffect, Loader, onKeyDown, ReadData, Course, StudentDetails, batchProgressById, getSessionById, createSession, useSharedLogic, FormikCommonFunc, add_session, DropdownInputWithCheckbox, InputBox, AddItemsButton, DropdownSingleInputWithCheckbox, getStudentsByBatchid, getStudentsOfSingleSession, updateSession, toast, AddSession, isTime1GreaterThanTime2
}