export const FreePaidContent = [
  {
    label: "Free",
    value: "free",
  },
  {
    label: "Paid",
    value: "paid",
  }
];

export const SelfAndInstructorContent = [
  {
    label: "Self Paced",
    value: "selfpaced",
  },
  {
    label: "Instructor Led",
    value: "instructorled",
  },
];

// export const CourseMode = [
//   {
//     label: "Classroom",
//     value: "Classroom",
//   },
//   {
//     label: "Online",
//     value: "Online",
//   },
//   {
//     label: "Hybrid",
//     value: "Hybrid",
//   },
// ];

export const CourseMode = ["Classroom", "Online", "Hybrid"];

export const conditionStatement = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];
