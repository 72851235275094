import React from 'react';
import { CardDesign } from './CardDesign';
import { Doughnut } from 'react-chartjs-2';

export const Operations = ({ DashboardData }: any) => {
    const dougnetoptions = {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: true,  
        plugins: {
            legend: {
            display: true,
            },
        },
    };

    const dougnetdata = {
      labels: (DashboardData[0].dataOp || []).map((d: any) => d.name),
      datasets: [
        {
          // label: "Count",
          backgroundColor: (DashboardData[0].dataOp || []).map(
            (d: any) => d.fill
          ),
          data: (DashboardData[0].dataOp || []).map((d: any) => d.value),
        },
      ],
    };

  return (
        <div className="bg-[#F9FCFF] w-11/12 mx-auto">
          {DashboardData.map((item: any) => {
            return (
              <div>
                <h1 className="font-Roboto font-[400] text-[18px] py-4 px-2">
                  {item.operationTitle}
                </h1>
                <div className="flex items-center gap-4 p-4">
                  <div className="w-[30%]">
                    <div className="flex items-center justify-center w-8/12 mx-auto py-2">
                      <Doughnut
                        options={dougnetoptions}
                        data={dougnetdata}
                        width={"50px"}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-2 w-[70%]">
                    {item.operations.map((data: any) => {
                      return (
                        <div>
                          <CardDesign data={data} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
  )
}
