import React, { useState } from 'react';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";

interface Tab {
  id: number;
  title: string;
  titles: string;
}

interface TabsProps {
  tabs: Tab[];
}

const ViewTabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(tabs[0].id);
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
//console.log("Tab Data", activeTab);
  const handleTabClick = (tabId: number,titles: string) => {
    setActiveTab(tabId);
    navigate({
      pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
      search: createSearchParams({id: `${id}`,  mode: `${titles}` }).toString(),
    });

  };

  return (
    <div className="flex space-x-4">
      {tabs.map((tab,index) => (
        <button
          key={tab.id}
          className={`pr-3 py-2  ${
            tab.titles === mode ? 'text-cyan-600 underline underline-offset-4' : 'text-slate-600'
          } ${index === tabs.length - 1 ? '' : 'border-r-4'}`}
          onClick={() => handleTabClick(tab.id, tab.titles)}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
};

export default ViewTabs;