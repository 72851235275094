import { TableNames } from "../config/Tables";
import { FetchData, BulkUpdate } from "../crud";

export const SelectAllOptionsForUsersByDefault = async () => {
    const { data: users, error: userError } = await FetchData(TableNames.users);
    if (userError) throw userError;

    const mapSuperUsers = users?.filter((fil: any) => fil?.super_user);

    if (mapSuperUsers?.length > 0) {
        // const { data: branches, error: branchError } = await FetchData(TableNames.branches);
        // if (branchError) throw branchError;

        // const { data: roles, error: rolesError } = await FetchData(TableNames.roles);
        // if (rolesError) throw rolesError;

        const { data: courses, error: courseError } = await FetchData(TableNames.courses);
        if (courseError) throw courseError;

        // const { data: cities, error: citiesError } = await FetchData(TableNames.cities);
        // if (citiesError) throw citiesError;

        // Function to populate idsByName object
        const populateIdsByName = (data: any[], key: string) => {
            if (!Array.isArray(idsByName[key])) {
                idsByName[key] = [];
            }
            data.forEach((item: any) => {
                idsByName[key].push(item.id);
            });
        };

        // Initialize idsByName object
        let idsByName: any = {
            courses: {
                Paid: [],
                Free: [],
                Both: []
            },
        };
        
        // Separate courses based on type
        courses.forEach((course: any) => {
            if (course.type === 'Paid') {
                idsByName.courses.Paid.push(course.id);
                idsByName.courses.Both.push(course.id);
            } else if (course.type === 'Free') {
                idsByName.courses.Free.push(course.id);
                idsByName.courses.Both.push(course.id);
            }
        });
        
        const flatIdsByName: any = {
            courses: {
                Paid: idsByName.courses.Paid.flatMap((id: any) => id),
                Free: idsByName.courses.Free.flatMap((id: any) => id),
                Both: idsByName.courses.Paid.concat(idsByName.courses.Free) // Concatenate Paid and Free arrays
            },
        };

        // console.log(flatIdsByName, "flatIdsByName")

        // Function to replace properties in mapSuperUsers objects
        const replaceProperties = (mapSuperUsers: any[], flatIdsByName: any) => {
            return mapSuperUsers.map((obj: any) => ({
                ...obj,
                courses: flatIdsByName.courses[obj.course_type],
            }));
        };

        const updatedMapSuperUsers = replaceProperties(mapSuperUsers, flatIdsByName);
        // console.log(updatedMapSuperUsers);


        const { error: training_error } = await BulkUpdate(TableNames.users, updatedMapSuperUsers)

        if (training_error) throw training_error
        return true
    }
    return true;
}

        // let idsByName: any = {
        //     // branches: {},
        //     // roles: {},
        //     courses: {},
        //     // cities: {},
        // };

        // // Function to populate idsByName object
        // const populateIdsByName = (data: any[], key: string) => {
        //     if (!Array.isArray(idsByName[key])) {
        //         idsByName[key] = [];
        //     }
        //     data.forEach((item: any) => {
        //         idsByName[key].push(item.id);
        //     });
        // };

        // // populateIdsByName(branches || [], 'branches');
        // // populateIdsByName(roles || [], 'roles');
        // populateIdsByName(courses || [], 'courses');
        // // populateIdsByName(cities || [], 'cities');

        // // Function to replace properties in dataArray objects
        // const replaceProperties = (mapSuperUsers:any, idsByName:any) => {
        //     return mapSuperUsers.map((obj:any) => ({
        //         ...obj,
        //         // roles_array: idsByName.roles,
        //         courses: idsByName.courses,
        //         // cities: idsByName.cities,
        //         // branches: idsByName.branches
        //     }));
        // };

        // // Replace properties in dataArray objects
        // const updatedDataArray = replaceProperties(mapSuperUsers, idsByName);

        // // console.log(updatedDataArray);