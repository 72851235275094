import React from "react";
import { CardDesign } from "./CardDesign";

export const SessionFeedback = ({ DashboardData }: any) => {
  const ProgressCount = (data: any) => {
    return (
      <div className="w-full">
        {data.data.name}
        <div className="flex items-center w-full">
          <div className="bg-gray-200 text-lg rounded-md my-3 w-full">
            <div
              className={`h-5 rounded-md `}
              style={{
                backgroundColor: data.data.COLORS,
                width: data.data.count,
              }}
            ></div>
          </div>
          <p className="ml-2">{data.data.count}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-[#F9FCFF] w-11/12 mx-auto shadow-[#00000029_0px_3px_8px] rounded-[8px]">
      {DashboardData.map((item: any) => {
        return (
          <div>
            <h1 className="font-Roboto font-[400] text-[18px] bg-[#FBFBFB] py-4 px-2">
              {item.sessionFTitle}
            </h1>
            <div className="flex items-center justify-between gap-4 p-4 bg-white">
              <div className="w-[45%]">
                {item.SessionChart.map((data: any) => {
                  return <ProgressCount data={data} />;
                })}
              </div>
              <div className="grid grid-cols-2 gap-2 justify-between w-[50%]">
                {item.Sessiondata.map((data: any) => {
                  return <CardDesign data={data} />;
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
