import { useState, useEffect, useRef } from "react";
import { Loader } from "../../Elements/Loader/Loader";
import Youtube from "../../../Assets/Youtube.png";
import ReactPlayer from 'react-player';
import { YoutubeThumbnail } from "./YoutubeThumbnail";

export const TestimonialsPreview = ({ isLoading, previewAll, setPreviewAll, deleteExistingLinks, accessDelete }: any) => {
    const [showMore, setShowMore] = useState(4);

    const handleClick = () => {
        setShowMore((prev) => prev + 4);
    };

  const RenderData = () => {
    return (
      <div className="grid grid-cols-3 place-items-center w-9/12 mx-auto space-y-5 relative">
        {previewAll.slice(0, showMore).map((preview: any, index: number) => {
          return (
            <div className="relative w-10/12 mx-auto h-[200px]">
              {preview.type === "Youtube Link" ? (
                <YoutubeThumbnail
                  url={preview?.external_link ? preview?.external_link : ""}
                />
              ) : (
                <img
                  src={preview?.thumbnail?.url}
                  alt="Internal"
                  className="w-full h-full border-2 rounded-tl-xl rounded-br-xl"
                />
              )}
              {accessDelete && (
                <div
                  onClick={() =>
                    deleteExistingLinks(
                      preview.id,
                      index,
                      preview.type === "Youtube Link"
                        ? preview?.external_link
                        : preview?.video_url?.key,
                      preview?.type
                    )
                  }
                  className="select-none bg-[#FF0000] w-6 h-6 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-2 -top-2"
                >
                  x
                </div>
              )}
            </div>
          );
        })}
        {showMore < previewAll.length && (
          <div className="w-10/12 mx-auto">
            <button
              type="button"
              className="text-black font-Roboto font-[500] text-[20px] underline decoration-[#036AD1] underline-offset-4 p-2 rounded-[4px]"
              onClick={handleClick}
            >
              Show More
            </button>
          </div>
        )}
      </div>
    );
    };
    
  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <RenderData />
      )}
    </div>
  )
}
