import React from 'react';
import FITA_Logo from "../../../../Assets/FITA_Logo.png";
import FITA_Logo_Watermark from "../../../../Assets/FITA_Logo_Watermark.svg";
import { ReadData } from '../../../Elements/ReadData';
import { dateFormat } from '../../../../Services/CommonFunctions';
import {
  feeAfterDiscountCalculation,
  gstCalculationForStudent,
  totalGstCalculationForStudent,
} from "../../../../functions/admin/Students/students";

export const OnlineBill = ({
  arrayValue,
  updatedStudentData,
  paymentObject,
  branchDetails,
}: any) => {
  const currentDate: any = new Date();
  return (
    <div className="w-full mx-auto p-8 h-screen overflow-hidden space-y-10 relative">
      <img
        src={FITA_Logo_Watermark}
        alt="FITA_Logo_Watermark"
        className="absolute w-[90%] h-full opacity-40"
      />
      <p className="text-[18px] absolute right-10 -top-5">
        {dateFormat(currentDate, "DD MMM YYYY")}
      </p>
      <div className="relative h-[40%] flex flex-col justify-between">
        <div className="space-y-3">
          <img src={FITA_Logo} alt="FITA_Logo" className="w-[120px] h-[75px]" />
          <InvoicePdf />
        </div>
        <AddressPdf />
        <BillReceiver updatedStudentData={updatedStudentData} />
      </div>
      <Course updatedStudentData={updatedStudentData} />
      <PaymentDetailsForPdf
        arrayValue={arrayValue}
        updatedStudentData={updatedStudentData}
        paymentObject={paymentObject}
        branchDetails={branchDetails}
      />
      <Note />
    </div>
  );
};

const InvoicePdf = () => {
    return (
      <div className="">
        <h1 className="font-Roboto font-[500] text-[22px] tracking-wide">
          Invoice
        </h1>
        <p className="font-Roboto text-[16px]">#123456</p>
      </div>
    );
}

const AddressPdf = () => {
    return (
      <div className="absolute top-14 xl:top-16 right-0 px-2 w-1/3 space-y-1">
        <h1 className="text-left text-[#036AD1] tracking-wide text-[26px] font-Roboto font-[500]">
          FITA Academy
        </h1>
        <div className="text-[18px]">
          <p>335A Ram Nagar South Extn,</p>
          <p>13th st, Pallikaranai,</p>
          <p>Chennai-91</p>
        </div>
        <h3 className="text-[18px]">
          GSTIN:<span className="px-1">33ASHPK6835A2Z0</span>
        </h3>
        <p className="text-[18px]">9952368680</p>
      </div>
    );
}

const BillReceiver = ({updatedStudentData}:any) => {
    return (
      <div className="space-y-3">
        <h1 className="text-[18px] font-Roboto font-[500]">Bill to</h1>
        <div>
          <h1 className="text-[18px] font-Roboto font-[500]">
            {updatedStudentData?.student_id?.name}
          </h1>
          <p className="text-[18px] font-Roboto">
            {updatedStudentData?.student_id?.phoneNumber}
          </p>
          <p className="text-[18px] font-Roboto">
            {`${updatedStudentData?.branch_id?.branch_name}, ${updatedStudentData?.city_id?.city_name}`}
          </p>
        </div>
      </div>
    );
}

const Course = ({ updatedStudentData }:any) => {
  return (
    <div className="border-spacing-1 border rounded-[3px] border-[#686868] w-[40%] flex items-center justify-between">
      <div className="space-y-2 w-1/2">
        <h1 className="bg-[#F8FBFF] py-1.5 px-2">Course</h1>
        <p className="p-1.5">{updatedStudentData?.course_id?.course_name}</p>
      </div>
      <div className="space-y-2 w-1/2 flex flex-col items-center">
        <h1 className="bg-[#F8FBFF] py-1.5 w-full text-center">Course Fee</h1>
        <p className="p-1.5">
          <span className="px-1">&#8377;</span>
          {updatedStudentData?.course_fee.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </div>
    </div>
  );
};

const PaymentDetailsForPdf = ({
  arrayValue,
  updatedStudentData,
  paymentObject,
  branchDetails,
}: any) => {
  const pay = arrayValue?.paid_amount;
  const feeInNumber = pay;

    const TotalInclGST = feeAfterDiscountCalculation(
      feeInNumber
    );
    const TotalExclGST = TotalInclGST / (1 + (paymentObject?.check_feeType==="None" ? 0 : ((paymentObject?.check_feeType === "IGST" ? Number(branchDetails?.igst) : Number(branchDetails?.sgst) + Number(branchDetails?.cgst))/100)))
    const Sgst = gstCalculationForStudent(
      paymentObject?.sendPercentageSGST,
      TotalExclGST
    );
    const Cgst = gstCalculationForStudent(
      paymentObject?.sendPercentageCGST,
      TotalExclGST
      );
    const Igst = gstCalculationForStudent(
      paymentObject?.sendPercentageIGST,
      TotalExclGST
    );

      const mappingForTotal: any = [
        {
          left: "Total (Excl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalExclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(TotalExclGST)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `SGST (${branchDetails?.sgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Sgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : parseFloat(Number(Sgst)?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        branchDetails &&
          paymentObject?.check_feeType === "SGST" && {
            left: `CGST (${branchDetails?.cgst}%)`,
            right:
              pay !== null
                ? typeof pay === "number"
                  ? parseFloat(Cgst?.toFixed(1))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : Number(Cgst)
                      ?.toFixed(1)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0,
          },
        paymentObject?.check_feeType === "IGST" && {
          left: `IGST (${branchDetails?.igst}%)`,
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(Igst?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : Number(Igst)
                    ?.toFixed(1)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
        {
          left: "Total (Incl. GST)",
          right:
            pay !== null
              ? typeof pay === "number"
                ? parseFloat(TotalInclGST?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : parseFloat(Number(TotalInclGST)?.toFixed(1))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
        },
      ];

  return (
    <div className="space-y-4">
      <h1 className="text-[20px] text-[#686868]">Payment Details</h1>
      <div className="shadow-md shadow-[#00000029] rounded-[3px] p-2 flex justify-between">
        <ReadData
          head={"Payment Date"}
          body={dateFormat(arrayValue?.created_at, "DD MMM YYYY")}
        />
        <ReadData head={"Mode of Payment"} body={arrayValue?.mode_of_payment} />
        <ReadData
          head={"Transaction ID"}
          body={arrayValue?.transaction_id ? arrayValue?.transaction_id : "-"}
        />
        <ReadData
          head={"Balance Due"}
          body={arrayValue?.balance}
          color={"text-[#FF0000]"}
          addSpan={arrayValue?.balance}
        />
        <div className="w-[40%] flex flex-col justify-between">
          {mappingForTotal.map((text: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex justify-around w-full ${
                  index === 4 && "bg-[#EFF7FF] py-1.5"
                } ${text.left && "py-2"}`}
              >
                <p
                  className={`w-[40%] text-end ${
                    index === 4
                      ? "text-black font-Roboto font-[500]"
                      : "text-[#707070] font-Roboto font-[400]"
                  }`}
                >
                  {text.left}
                </p>
                <p
                  className={`w-[20%] ${
                    index === 4
                      ? "text-black font-Roboto font-[500]"
                      : "text-[#707070] font-Roboto font-[400]"
                  }`}
                >
                  {text.left && <span className="px-1">&#8377;</span>}
                  {text.right}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Note = () => {
    return (
        <div className='text-[18px] tracking-wide'>Note: Payments once made are non-refundable or Non-redeemable</div>
    )
}