import { dateFormat } from "../../../Services/CommonFunctions";
import { calculatePercentage, calculateTimeDifference, errorNotifier, percentageBasedOnRange } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchData, InsertData, UpdateData } from "../../crud";
import { SessionInterface } from "./interface";
import { SUPABASE_CLIENT } from "../../../Services/Supabase";

export const getCourseProgressTableData = async (UsersDetails: any) => {

    try {

        const { data: batches, error } = await FetchData(TableNames.batches, ["id", "mode", "course:courses(course_name)", "schedule", "status", "branch_id","start_date", "start_time", "city:cities(city_name)", "duration", "batch_code", "batch_progress_in_hrs", "trainer:users(first_name, last_name, phone_number, uid)", "branch:branches(branch_name)", "training:trainings(student:students(id,name,phoneNumber),id)"]).in("branch_id", UsersDetails?.branches).order("id", {ascending: false});

        if (error) throw error;

        if (UsersDetails?.roles.includes('Trainer')) {
            const filterBatches = batches?.filter((batch: any) => (UsersDetails?.name) === `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`);
            // console.log(filterBatches, "filterBatches")
            const batchData = filterBatches.map((batch: any) => ({
                ...batch, student_list: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.name),
                student_Phone_Numbers: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.phoneNumber),
                batch_progress_in_percent: calculatePercentage(batch?.batch_progress_in_hrs, batch?.duration),
                course_name: batch?.course?.course_name, branch_name: batch?.branch?.branch_name, city: batch?.city?.city_name, start_date: dateFormat(batch?.start_date, "DD MMM YYYY"), trainer_name: `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`, trainer_mobile: batch?.trainer?.phone_number
            }))

            return batchData;
        } else {
            const batchData = batches.map((batch: any) => ({
              ...batch,
              student_list:
                batch?.training?.length > 0 &&
                batch?.training?.map((tr: any) => tr?.student?.name),
              student_Phone_Numbers: batch?.training?.length > 0 && batch?.training?.map((tr: any) => tr?.student?.phoneNumber),
              batch_progress_in_percent: (batch?.batch_progress_in_hrs!=="0" ? 
                    calculatePercentage(
                    batch?.batch_progress_in_hrs,
                    batch?.duration
                    ) : "0"),
              course_name: batch?.course?.course_name,
              branch_name: batch?.branch?.branch_name,
              city: batch?.city?.city_name,
              start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
              trainer_name: `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`,
              trainer_mobile: batch?.trainer?.phone_number,
              progress_range: percentageBasedOnRange(batch?.batch_progress_in_hrs!=="0" ? 
                    calculatePercentage(
                    batch?.batch_progress_in_hrs,
                    batch?.duration
                    ) : "0")
            }));

            return batchData;
        }

    } catch (error) {

        return errorNotifier(error);

    }

}

export const batchProgressById = async (batchId: number | string) => {
    try {
        const { data: batch, error }: any = await FetchData(TableNames.batches, ["*", "course:courses(course_name)", "branch:branches(branch_name)", "city:cities(city_name)", "trainer:users(uid)"]).eq("id", batchId);

        const { data: sessions, error: err } = await FetchData(TableNames.sessions, ["duration", "attendees", "session_status"]).eq("batch_id", batchId)
        const filterStatusBasedSession = sessions?.filter((fil: any) => fil?.session_status === "Success");
        const batchData = batch.map((el: any) => ({...el, start_date: dateFormat(el.start_date, "DD MMM YYYY"), planned_date_of_completion: dateFormat(el.planned_date_of_completion, "DD MMM YYYY")}))
    
        const progress_in_hrs = filterStatusBasedSession?.reduce((accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue?.duration), 0)

        const batch_progress_in_percent = calculatePercentage(progress_in_hrs, batch[0].duration)

        if (error || err ) throw error  || err;
        return {batch: batchData[0], progress_in_hrs, total_duration: batch[0].duration, batch_progress_in_percent, trainer_id: batch[0].trainer.uid};
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getSessionById = async (sessionId: number) => {
    try {
        const { data, error } = await FetchData(TableNames.sessions, ["id", "attendees", "start_time","end_time","batch:batches(batch_code, course:courses(course_name))", "details", "session_date"]).eq("id",sessionId);
        if (error) throw error;
        return data[0];
    } catch (error) {
        return errorNotifier(error)
    }
}

export const createSession = async (sessionData: SessionInterface) => {
    try {

        const { data: duration_session, error: e }: any = await FetchData(TableNames.sessions, ["id", "batch:batches(course:courses(duration))", "duration"]).eq("batch_id", sessionData.batch_id).order("id")

        const {data: batch }: any = await FetchData(TableNames.batches).eq("id", sessionData?.batch_id)

        const session_duration = calculateTimeDifference(sessionData.start_time, sessionData.end_time)
        const newSessionData: SessionInterface = {
            ...sessionData,
            duration: session_duration,
        } 

        if (duration_session.length > 0) {
             const sessionUpdate = {
                    recent_session: batch[0].in_progress ? false : true
            }    
            await UpdateData(TableNames.sessions, sessionUpdate, { conditionKey: "id", conditionValue: duration_session[duration_session.length - 1]?.id })
        }
        if (batch[0].batch_progress_in_hrs === batch[0].duration) {
            return { message: "The batch you are trying to add session is ended.", status: false }
        } else {
            var { data, error } = await InsertData(TableNames.sessions, newSessionData)
            if (error) throw error 
        }
        const { data: sessions, error: session_err }: any = await FetchData(TableNames.sessions, ["id", "batch:batches(course:courses(duration))", "duration", "session_status"]).eq("batch_id", sessionData.batch_id).order("id")
        const filterStatusBasedSession = sessions?.filter((fil: any) => fil?.session_status === "Success");
        
        const batch_hours = filterStatusBasedSession.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
    
        const updateTemplate = batch_hours === batch[0].duration ? {
            batch_progress_in_hrs: batch_hours,
            in_progress: false
        } : {
            batch_progress_in_hrs: batch_hours
            }
        
        await UpdateData(TableNames.batches, updateTemplate, { conditionKey: "id", conditionValue: sessionData.batch_id })

        return { data: data, status: true};

    } catch (error) {
        return errorNotifier(error)
    }
}

// export const createSessionsBulk = async (sessionsData: SessionInterface[]) => {
//   try {
//     // 1. Validate individual session data and calculate durations
//     const validSessions = sessionsData.map((sessionData) => {
//       const session_duration = calculateTimeDifference(sessionData.start_time, sessionData.end_time);

//       if (!session_duration) {
//         throw new Error(`Invalid session data: Start time (${sessionData.start_time}) must be before end time (${sessionData.end_time})`);
//       }

//       return {
//         ...sessionData,
//         duration: session_duration,
//       };
//     });

//     // 2. Perform bulk insertion using Supabase's InsertData
//     const { data, error } = await SUPABASE_CLIENT.from(TableNames.sessions).insert(validSessions).select();
//     if (error) throw error; // Re-throw error for proper handling

//     // 3. Update batch progress and session status (optional)
//     if (data?.length) {
//       const batchId = data[0].batch_id; // Assuming first inserted session has the batch ID
//       await updateBatchProgressAndSessionStatus(batchId);
//     }

//     return { data, status: true };
//   } catch (error) {
//     return errorNotifier(error); // Handle errors appropriately
//   }
// };

// async function updateBatchProgressAndSessionStatus(batchId: number) {
//   try {
//     // 1. Fetch all sessions for the batch
//     const { data: sessions, error } = await SUPABASE_CLIENT
//       .from(TableNames.sessions)
//       .select(["id", "session_status"])
//       .eq("batch_id", batchId);

//     if (error) throw error;

//     // 2. Filter sessions based on success status (optional)
//     const successfulSessions = sessions?.filter((session) => session.session_status === "Success");

//     // 3. Calculate total session duration
//     const totalSessionDuration = successfulSessions?.reduce((acc, session) => Number(acc) + Number(session.duration), 0);

//     // 4. Update batch progress and optionally update all session `recent_session` flag
//     await Promise.all([
//       SUPABASE_CLIENT.from(TableNames.batches)
//         .update({ batch_progress_in_hrs: totalSessionDuration })
//         .eq("id", batchId),
//       // Optional update for `recent_session` flag (assuming recent sessions are not successful)
//       // SUPABASE_CLIENT.from(TableNames.sessions)
//       //   .update({ recent_session: false })
//       //   .eq("batch_id", batchId),
//     ]);
//   } catch (error) {
//     console.error("Error updating batch progress and session status:", error);
//     // Handle errors appropriately (e.g., logging, notification)
//   }
// }

export const getSessionsAndTrainersByBatchId = async (batchId: number | string) => {
    try {
        const { data, error } = await FetchData(TableNames.sessions, ["id", "session_date", "trainer:users(uid,first_name, last_name, phone_number)", "duration", "start_time", "end_time", "details", "attendees", "session_status"]).eq("batch_id", batchId).order("id", {ascending: false})
        const { data: batch_trainers, error: trainer_error }: any = await FetchData(TableNames.batch_trainers, ["trainer:users(uid ,first_name, last_name, email, phone_number)", "start_date", "end_date"]).eq("batch_id", batchId)

        const updateTrainerData = batch_trainers?.map((el: any) => ({...el, trainer_name: `${el.trainer.first_name} ${el.trainer.last_name}`, trainer_mobile: el.trainer.phone_number, duration: data?.filter((ele: any) => (ele.trainer.uid === el.trainer.uid)).reduce((acc: any, curr_val: any) => Number(acc) + Number(curr_val.duration), 0), current_trainer: batch_trainers[batch_trainers.length - 1].trainer.uid === el.trainer.uid ? true : false}))

        const updatedSessionData = data?.map((el: any) => ({...el, session_date: dateFormat(el.session_date, "DD MMM YYYY"), trainer_name: `${el.trainer.first_name} ${el.trainer.last_name}`, trainer_mobile: el.trainer.phone_number, participants: el?.attendees?.length}))

        if (error || trainer_error) throw error || trainer_error
        return {updatedSessionData, updateTrainerData}
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getStudentsOfSingleSession = async (attendees: string[]) => {
    try {
        const { data, error } = await FetchData(TableNames.students, ["name", "email", "phoneNumber"]).in("uid", attendees)
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getStudentsByBatchid = async (batchId: number) => {
    try {
        const { data, error } = await FetchData(TableNames.trainings, ["student:students(uid, name, phoneNumber)", "status"]).eq("batch_id", batchId)
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateSession = async (sessionData: Partial<SessionInterface>, sessionId: number) => {
    try {        
        const session_duration = calculateTimeDifference(sessionData.start_time, sessionData.end_time);
        const updatedSessionData = {
            ...sessionData,
            duration: session_duration
        }
        const { data, error } = await UpdateData(TableNames.sessions, updatedSessionData, { conditionKey: "id", conditionValue: sessionId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCancelledSessionsForVerified = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.cancelled_sessions, ["id", "created_at", "batch:batches(id, batch_code, courses(course_name), cities(city_name), branches(id, branch_name))", "cancellation_follow_up:cancellation_followup(id)"]);
        if (error) throw error;
        const filterBasedOnCancellationFollowUp = data.filter((fil: any) => fil?.cancellation_follow_up?.length !== 0);

        const DataMapping = filterBasedOnCancellationFollowUp?.map((dt:any)=>{
            if (UsersDetails?.branches?.includes(dt?.batch?.branches?.id)) {
                return {
                    id: dt?.id,
                    created_at: dateFormat(dt?.created_at, "DD-MM-YYYY"),
                    batch_code: dt?.batch?.batch_code,
                    course_name: dt?.batch?.courses?.course_name,
                    city_name: dt?.batch?.cities?.city_name,
                    branch_name: dt?.batch?.branches?.branch_name,
                    batch_id: dt?.batch?.id
                }
            }
        })
        return DataMapping;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCancelledSessionsForPending = async (UsersDetails: any) => {
    try {
        const { data, error } = await FetchData(TableNames.cancelled_sessions, ["id", "created_at", "batch:batches(id, batch_code, courses(course_name), cities(city_name), branches(id, branch_name))", "cancellation_follow_up:cancellation_followup(id)"]);
        if (error) throw error;
        const filterBasedOnCancellationFollowUp = data.filter((fil: any) => fil?.cancellation_follow_up?.length === 0);

        const DataMapping = filterBasedOnCancellationFollowUp?.map((dt:any)=>{
            if (UsersDetails?.branches?.includes(dt?.batch?.branches?.id)) {
                return {
                    id: dt?.id,
                    created_at: dateFormat(dt?.created_at, "DD-MM-YYYY"),
                    batch_code: dt?.batch?.batch_code,
                    course_name: dt?.batch?.courses?.course_name,
                    city_name: dt?.batch?.cities?.city_name,
                    branch_name: dt?.batch?.branches?.branch_name,
                    batch_id: dt?.batch?.id
                }
            }
        })
        return DataMapping;
    } catch (error) {
        return errorNotifier(error)
    }
}

function calculateSecondsFromTime (hrs:string|number,mins:string|number,seconds:string|number) {
    return Number(hrs) * 3600 + Number(mins) * 60 + Number(seconds);
}

function formatDateString(dateString: Date){
    let date = dateString.getDate();
    let month = dateString.getMonth() + 1;
    let year = dateString.getFullYear();
    return `${year}-${month}-${date}`;
}

export const createCancellationSession = async(bodyData:any) => {
    try {
        const {data, error} = await InsertData(TableNames.cancellation_followup, bodyData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getAllCancellationFollowupData = async(Id:any) => {
    try {
        const { data, error } = await FetchData(TableNames.cancellation_followup, ["created_at", "followup_date", "comment"]).eq(`cancellation_id`, Id);
        if (error) throw error;
        return data;
    } catch(error) {
        return errorNotifier(error);
    }
}