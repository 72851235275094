import { useEffect, useState } from "react";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import { Loader } from "../../Elements/Loader/Loader";
import { onKeyDown } from "../../../Services/CommonFunctions";
import { useFormik } from "formik";
import { InputBox } from "../../Elements/InputBox";
import { StatusButton } from "../../Elements/StatusButton";
import { DropdownInputWithCheckbox } from "../../Elements/DropdownInputWithCheckbox";
import { createTechnology, updateTechnology, getTechnologyPreloadData, getTechnologyById } from "../../../functions/admin/Technologies/Technologies";
import { AddTechnology } from "../../Formik/FormikValidation";
import { useSharedLogic, FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from "../../..";

export const AddNewTechnology = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState<any>("Active");
  const [loaderThumbnail, setLoaderThumbnail] = useState(false);
  const [courseData, setCourseData] = useState([] as any);
  const [courseNames, setCourseNames] = useState([] as any);
  const isId: any = id !== "new-technology" && id;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  useEffect(() => {
    (async () => {
      if(signInData?.data?.length > 0) {
        const loadData: any = await getTechnologyPreloadData({
          branches: signInData?.data[0]?.branches,
          cities: signInData?.data[0]?.cities
        });
        setCourseData(loadData)
        if (loadData) {
          const mapCourses =
            loadData && loadData.map((course: any) => course.course_name);
          setCourseNames(mapCourses)
        }
        if (id !== "new-technology") {
          const techData: any = await getTechnologyById(isId);
          const { technology, status, courses } = techData;
            const mapCoursesForID: any =
              loadData &&
              loadData.filter((data: any) =>
                courses.includes(data.id)
            );
            const mapCoursesForName: any =
              mapCoursesForID &&
              mapCoursesForID.map((data: any) => data.course_name)
          if (techData) {
            setValues({
              ...values,
              technology,
              courses,
              course_name: mapCoursesForName,
              status
            }, true)
            setChecked(status);
          }
        }
      }
    })()
  },[])

  const forNewTechnology = async () => {
    setIsLoading(true)
    const { course_name, ...rest } = values;
    const body: any = { ...rest }
    const response = await (id === 'new-technology' ? createTechnology(body) : updateTechnology(body, isId))
    if (response) {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({
          id: `${
            id === "new-technology"
              ? "created-success"
              : "update-success"
          }`,
        }).toString(),
      })
    }
    setIsLoading(false)
  };

  const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc({
      technology: "",
      courses: [],
      course_name: [],
      status: "Active",
    }, AddTechnology, forNewTechnology);

  const CheckValue = (label: any) => {
    setChecked(label);
    setFieldValue('status', label)
  };

  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <form
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
          className="bg-white w-10/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6"
        >
          <div className="text-left border-b-2 p-2 flex items-center justify-between">
            <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
              {id === "new-technology"
                ? "Add New Technology"
                : "Edit Technology"}
            </p>
          </div>
          <div className="w-10/12 mx-auto py-10 space-y-14">
            <div className="w-full flex items-center justify-between gap-10">
              <InputBox
                label="Technology:"
                placeholder="Technology"
                topDivStyle={"flex flex-col w-1/2"}
                classNameInput={"w-full"}
                name={"technology"}
                value={values.technology}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.technology}
                isTouched={touched.technology}
              />
              <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-1/2"}
                classNameInput={"w-full"}
                label="Course"
                placeholder="Course"
                options={courseNames}
                name={"course_name"}
                forId={"courses"}
                value={values.course_name}
                onBlur={handleBlur}
                error={errors.course_name}
                isTouched={touched.course_name}
                mapData={courseData}
                mapThrough={"course_name"}
                formik={formik}
              />
            </div>
            <div>
              <StatusButton
                type={"submit"}
                buttonText={
                  id === "new-technology"
                    ? "Add New Technology"
                    : "Update Technology"
                }
                CheckValue={CheckValue}
                checked={checked}
                buttonWidth={"w-11/12"}
                show={true}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
