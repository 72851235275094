import React from "react";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { trainerTransaction } from "../../../utils/json/trainerTransaction";
import {
  useSharedLogic,
  CommonTableComp,
} from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from "../../..";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { ViewTrainerStudents } from "./ViewTrainerStudents";
import { BackButton } from "../../Elements/BackButton";

export const TrainerTransaction = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  return (
    <div>
      {!id && (
        <div className="space-y-2">
          <h1 className="w-[95%] mx-auto text-[30px] text-white font-Roboto font-medium">
            Trainer Transaction
          </h1>
          {allowOnlyActions("view") && (
            <TableComponent
              TABLE_JSON={trainerTransaction}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
      {id && mode === "view" && allowOnlyActions("view") && (
        <div className="space-y-2">
          <BackButton
            topDivStyle={"w-9/12 justify-between"}
            header={"Student Payment Split Up"}
          />
          <ViewTrainerStudents id={id} />
        </div>
      )}
    </div>
  );
};
