import { studentData } from "../../utils/json/studentData";
import { TableComponent } from "../Elements/Table/TableComponent";
import { HeadingSection } from "../Elements/HeadingSection";
import { FreeStudentInfo } from "./FreeStudentInfo";
import { PaidStudentInfo } from "./PaidStudentInfo";
import { CourseProgress } from "./CourseProgress";
import { BackButton } from "../Elements/BackButton";
import { useSharedLogic } from "../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../CommonFunctions/Privileges";

export const AllStudents = () => {
  const { id, mode, courseId }: any = useSharedLogic();
  const { allowAllActions }: any = FunctionForPrivileges();

  return (
    <div>
      {courseId && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-9/12 justify-end"} />
          <CourseProgress />
        </div>
      )}
      {mode === "free" && !courseId && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-9/12 justify-end"} />
          <FreeStudentInfo id={id} mode={mode} />
        </div>
      )}
      {mode === "paid" && !courseId && (
        <div className="space-y-2">
          <BackButton topDivStyle={"w-9/12 justify-end"} />
          <PaidStudentInfo id={id} />
        </div>
      )}
      {allowAllActions() && !id && !courseId && (
        <>
          <HeadingSection head={"All Students"} />
          <TableComponent TABLE_JSON={studentData} />
        </>
      )}
    </div>
  );
};
