import { useState } from "react";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import tableViewEyeIcon from "../../../Assets/tableViewEyeIcon.svg";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const { navigate }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  const navigateToViewPage = () => {
    navigate({
      pathname: `/action_center/trainer_transaction`,
      search: createSearchParams({
        id: `${recordData.id}`,
        mode: `view`,
        // routePath: 'student_actions'
      }).toString(),
    })
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
      {allowOnlyActions("view") && (
        <div
          onClick={navigateToViewPage}
          className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
        >
          <img
            src={tableViewEyeIcon}
            alt="viewIcon"
            className="w-[21px] h-[10px]"
          />
        </div>
      )}
    </div>
  );
};
