import React from 'react'
import { PulseLoader } from 'react-spinners';

export const Loader = ({widthFix, heightIfGiven}:any) => {
  return (
    <div
      className={`${widthFix} mx-auto shadow-md shadow-[#00000029] bg-white rounded ${heightIfGiven?heightIfGiven:'min-h-[400px]'} flex items-center justify-center`}
    >
      <PulseLoader color="#065FEF" size={8} />
    </div>
  );
}
