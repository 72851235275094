import { useState, useEffect } from "react";
import { Loader } from "../../Elements/Loader/Loader";
import { CardLayoutThree } from "../../Elements/Cards/CardLayoutThree";
import { ReadData } from "../../Elements/ReadData";
import { BasicInfo } from "./BasicInfo";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { BuildJSON, TrainerData, Students_Data } from "./CommonJson";
import { Progressbar } from "../../Elements/Progressbar";
import { createSearchParams } from "react-router-dom";
import { TrainerDetails } from "./TrainerDetails";
import { ChangeTrainerReason } from "./ChangeTrainerReason";
import { StudentDetails } from "./StudentDetails";
import { formatNumberWithCommas } from "../../../Services/CommonFunctions";
import { getBatchById, getStudentBasedOnTrainer } from "../../../functions/admin/Batches/Batches";
import toast from "react-hot-toast";
import { StudentDetailsForTrainerPayment } from "./StudentDetailsForTrainerPayment";
import { InputBox } from "../../Elements/InputBox";

export const Components = {
  useState,
  useEffect,
  Loader,
  CardLayoutThree,
  ReadData,
  BasicInfo,
  useSharedLogic,
  BuildJSON,
  Progressbar,
  createSearchParams,
  TrainerDetails,
  TrainerData,
  ChangeTrainerReason,
  StudentDetails,
  Students_Data,
  formatNumberWithCommas,
  getBatchById,
  getStudentBasedOnTrainer,
  toast,
  StudentDetailsForTrainerPayment,
  InputBox
};