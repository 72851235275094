import { useEffect } from "react";
import { Components } from "./Imports";
import { useAppSelector } from "../..";
import { SelectAllOptionsForUsersByDefault } from "../../functions/admin/SelectAllUsers";

export const RightSideScreens = () => {
  const {
    useParams,
    AdminPanelDashboard,
    AdminManagement,
    AllStudents,
    ContentManagement,
    StudentManagement,
    BatchManagement,
    CourseProgress,
    ActionCenter,
    HrStudentPlacement,
    Logs,
    AppEnquiries,
    Reports,
  }: any = Components;
  const params = useParams();
  const routeName: any = params.dashboardScreen;
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  
  const screens: any = {
    dashboard: <AdminPanelDashboard />,
    admin_management: <AdminManagement />,
    all_students: <AllStudents />,
    content_management: <ContentManagement />,
    student_management: <StudentManagement />,
    batch_management: <BatchManagement />,
    course_progress: <CourseProgress />,
    action_center: <ActionCenter />,
    hr_student_placement: <HrStudentPlacement />,
    reports: <Reports />,
    logs: <Logs />,
    app_enquiries: <AppEnquiries />,
  };
  
  useEffect(() => {
    (async () => {
      await SelectAllOptionsForUsersByDefault();
    })();
  }, [screens?.admin_management, screens?.content_management]);

  return (
    <div className="w-full absolute top-[100px] z-10 pb-10">
      {signInData?.data?.length > 0 &&
        (screens[routeName] || <AdminPanelDashboard />)}
    </div>
  );
};

// <div className="w-full z-10">
//   <Header />
//   {screens[routeName] || <AdminPanelDashboard />}
// </div>;
