import { useState, useEffect } from 'react';
import { Loader } from '../../Elements/Loader/Loader';
import { ReportInSections } from './ReportInSections';
import { tableDataForMISReport } from '../../../functions/admin/Reports/Reports';
import { useAppSelector } from '../../..';

export const MISReports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({} as any);
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await tableDataForMISReport(signInData?.data[0]);
      console.log(response, "RESP");
      setResponseData(response)
      setIsLoading(false);
    })()
  },[])

  return (
    <div>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) :
        <div className="bg-white w-11/12 mx-auto shadow-md shadow-[#00000029] rounded-[6px] py-6 pb-10 space-y-8">
          <h1 className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">MIS Report</h1>
          <ReportInSections response={responseData} />
      </div>}
    </div>
  )
}
