import { MenuInterface, PrivilegeInterface } from "./interfaces";

export const formatMenuData = (menudata: MenuInterface[],privileges:PrivilegeInterface[]) => {
    var filtermenus = menudata.filter((menu) => menu.mainmenuid === null);
    var displaymenus:any = filtermenus.map(menu=>({...menu,data:privileges}));
    for (let i = 0; i < displaymenus.length; ++i) {
        var subfiltermenus = menudata.filter((menu) => menu.mainmenuid === displaymenus[i].id);
        var submenus:any = subfiltermenus.map((menu)=>({...menu,data:privileges}));
        displaymenus[i]['submenus'] = submenus;
    }
    return displaymenus.sort((a: any, b: any) => a.displayorder - b.displayorder);
}

export const isRoleAlreadyExists = (roleData: any[], role: string) => {
    return roleData.some((roleD: any) => roleD.role?.toLowerCase() === role.toLowerCase());
}