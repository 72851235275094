import { LoginUserDetails } from "./LoginUserDetails";

export const Header = () => {
  return (
    <div className="h-[344px] bg-[#036AD1]">
      <div className="h-[172px] flex items-center justify-center mt-4 w-[95%] mx-auto relative">
        <p className="font-poppins font-bold text-white opacity-[0.03] text-[180px]">
          ONE APP
        </p>
        <LoginUserDetails />
      </div>
    </div>
  );
}

// import { LoginUserDetails } from "./LoginUserDetails";

// export const Header = () => {
//   return (
//     <div className="flex items-center justify-end gap-2 static top-0">
//       <p className="text-white">BELL</p>
//       <div className="bg-white w-[42px] h-[42px] flex items-center justify-center text-[#036AD1] rounded-full">
//         VP
//       </div>
//       <div className="flex flex-col items-start">
//         <h1 className="text-[20px] font-medium font-Roboto text-white">
//           Venkatesh Pannirchelvan
//         </h1>
//         <p className="text-[15px] font-normal font-Roboto text-white">
//           Super Admin
//         </p>
//       </div>
//     </div>
//   );
// }
