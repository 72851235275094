import { ViewEditDeleteActionComponent } from "../../Components/Action Center/Daily Transactions/ActionComponent";
import { getRefundTableData } from "../../functions/admin/Action center/Refund and Adjust Payment/issueRefundAndAdjustPayment";

export const refundData = {
    headers: [
        {
            name: "Student Name",
            apiKey: "student_name",
            headerClass: "w-[120px] text-center",
            className: "w-[120px] text-center break-words",
        },
        {
            name: "Course",
            apiKey: "course_name",
            headerClass: "w-[90px] text-center",
            className: "w-[120px] text-center break-words",
        },
        {
            name: "Branch",
            apiKey: "branch_name",
            headerClass: "w-[100px] text-center",
            className: "w-[190px] text-center break-words",
        },
        {
            name: "Status",
            apiKey: "status",
            headerClass: "w-[100px] text-center",
            className: "w-[120px] text-center break-words",
        },
        {
            name: "Total Fee",
            apiKey: "totalFee",
            headerClass: "w-[80px] text-center",
            className: "w-[120px] text-center",
        },
        {
            name: "Refund Amount",
            apiKey: "refundAmount",
            headerClass: "w-[100px] text-center",
            className: "w-[120px] text-center break-words",
        },
    ],
    BackendFunctionCall: (params:any)=>{return getRefundTableData(params)},
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "created_at",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
        ],
        checkBox: [
            {
                name: "Branch",
                apiKey: "branch_name",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
            {
                name: "Course",
                apiKey: "course_name",
                isSearchNeeded: true,
                className: "w-[200px]",
            },
        ],
        searchBox: [
            {
                matchFields: ["student_name", "course_name"],
                searchKey: "student_nameCourse",
                name: "Student Search...",
                searchStyle: "w-[220px]",
            }
        ]
    },
    ActionComponent: ViewEditDeleteActionComponent,
};