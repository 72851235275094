import React from "react";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { studentFeedbackConcern } from "../../../utils/json/StudentFeedbackConcern";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { FeedbackConcernsInfo } from "./StudentFeedbackInfo/FeedbackConcernsInfo";
import { BackButton } from "../../Elements/BackButton";
import { FunctionForPrivileges } from '../../CommonFunctions/Privileges';

export const StudentFeedbackConcern = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  return (
    <div>
      {mode === "view" && (
        <div className="space-y-4">
          <BackButton
            topDivStyle={"flex items-center justify-end w-10/12 mx-auto"}
          />
          <FeedbackConcernsInfo id={id} mode={mode} />
        </div>
      )}
      {!id && (
        <div className="space-y-2">
          <h1 className="w-[95%] mx-auto text-[30px] text-white font-Roboto font-medium">
            Student Feedback/Concerns
          </h1>
          {allowOnlyActions('view') && <TableComponent TABLE_JSON={studentFeedbackConcern} />}
        </div>
      )}
    </div>
  );
};
