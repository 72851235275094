import React from "react";
import { TableComponent } from "../../Elements/Table/TableComponent";
import { studentFeedbackNotGiven } from "../../../utils/json/studentFeedbackNotGiven";
import {
  useSharedLogic,
  CommonTableComp,
} from "../../CommonFunctions/CommonFunc";
import { useAppSelector } from "../../..";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";
import { StudentFeedbackFollowUp } from "./StudentFeedbackFollowUp";
import { AddNew } from "./AddNew";

export const StudentFeedbackNotGiven = () => {
  const { id, mode }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const { signInData } = useAppSelector(({ signInInput }: any) => signInInput);
  return (
    <div>
      {mode === "add_new_comment" && (
        <div className="space-y-2">
          {/* <h1 className="w-11/12 mx-auto text-[30px] text-white font-Roboto font-medium">
            Student Attendance
          </h1> */}
          <StudentFeedbackFollowUp id={id} />
        </div>
      )}
      {allowOnlyActions("view") && mode === "edit" && (
        <div className="space-y-2">
          {/* <h1 className="w-11/12 mx-auto text-[30px] text-white font-Roboto font-medium">
            Student Attendance
          </h1> */}
          <AddNew id={id} />
        </div>
      )}
      {!id && (
        <div className="space-y-2">
          <h1 className="w-[95%] mx-auto text-[30px] text-white font-Roboto font-medium">
            Student Feedback Not Given
          </h1>
          {allowOnlyActions("view") && (
            <TableComponent
              TABLE_JSON={studentFeedbackNotGiven}
              backEndCallParams={signInData?.data[0]}
            />
          )}
        </div>
      )}
    </div>
  );
};
