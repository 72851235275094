import { operationalReportsForBatchTable } from "../../functions/admin/Reports/Reports";

export const batchReports = {
  headers: [
    {
      name: "Batch Code",
      apiKey: "batch_code",
      headerClass: "w-[100px] text-start",
      className: "w-[100px] text-start",
    },
    {
      name: "Planned End Date",
      apiKey: "planned_date_of_completion",
      headerClass: "w-[100px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Age",
      apiKey: "completion_age",
      headerClass: "w-[80px] text-center px-2",
      className: "w-[80px] text-center break-words px-2",
    },
    {
      name: "Batch Progress",
      apiKey: "batch_progress_in_hrs",
      headerClass: "w-[90px] text-center px-2",
      className: "w-[90px] text-center px-2",
    },
    {
      name: "Course",
      apiKey: "course_name",
      headerClass: "w-[150px] text-center px-2",
      className: "w-[150px] text-center break-words px-2",
    },
    {
      name: "Trainer Name",
      apiKey: "trainer_name",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Mode",
      apiKey: "mode",
      headerClass: "w-[120px] text-center px-2",
      className: "w-[120px] text-center break-words px-2",
    },
    {
      name: "Schedule",
      apiKey: "schedule",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "City",
      apiKey: "city_name",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
    {
      name: "Branch",
      apiKey: "branch_name",
      headerClass: "w-[120px] text-start px-2",
      className: "w-[120px] text-start break-words px-2",
    },
  ],
  BackendFunctionCall: (params: any) => {
    return operationalReportsForBatchTable(params);
  },
  filters: {
    // dateFilters: [
    //   {
    //     name: "Date",
    //     apiKey: "date",
    //     isSearchNeeded: true,
    //     className: "w-[170px]",
    //   },
    // ],
    checkBox: [
      {
        name: "Planned End Date",
        apiKey: "planned_date_of_completion",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Batch Status",
        apiKey: "status",
        isSearchNeeded: true,
        className: "w-[180px]",
      },
      {
        name: "Course",
        apiKey: "course_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Mode",
        apiKey: "mode",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
      {
        name: "Schedule",
        apiKey: "schedule",
        isSearchNeeded: true,
        className: "w-[170px]",
      },      
      {
        name: "Ownership",
        apiKey: "branch_ownership",
        isSearchNeeded: true,
        className: "w-[170px]",
      },     
      {
        name: "City",
        apiKey: "city_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },      
      {
        name: "Branch",
        apiKey: "branch_name",
        isSearchNeeded: true,
        className: "w-[170px]",
      },
    ],
    // searchBox: [
    //   {
    //     matchFields: ["trainerName", "trainerNumber"],
    //     searchKey: "student_nameCourse",
    //     name: "Trainer Search...",
    //     searchStyle: "w-[220px]",
    //   },
    // ],
  },
};
