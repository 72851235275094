import { useEffect, useState } from "react";
import { CardLayoutOne } from "../../Elements/Cards/CardLayoutOne";
import { Checkbox } from "../../Elements/Checkbox";
import { InputBox } from "../../Elements/InputBox";
import { useFormik } from "formik";
import { useAppSelector } from "../../..";
import { Loader } from "../../Elements/Loader/Loader";
import { RandomString } from "../../../Services/CommonFunctions";
import { uploadFile, deleteFile } from "../../../functions/storage/storage";
import { getAllTestimonials, createTestimonial, deleteTestimonial } from "../../../functions/admin/Testimonials/Testimonials";
import { AddTestimonial } from "../../Formik/FormikValidation";
import toast from "react-hot-toast";
import { onKeyDown } from "../../../Services/CommonFunctions";
import { UploadOrUpdateBucket } from "../../CommonFunctions/CommonFileFunction";
import { TestimonialsPreview } from "./TestimonialsPreview";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const TestimonialsUpdate = ({ id, mode }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderThumbnail, setLoaderThumbnail] = useState(false);
  const { userDetails } = useAppSelector(({ login }: any) => login);
  const [checked, setChecked] = useState("Youtube Link");
  const [previewAll, setPreviewAll] = useState([] as any);
  const [loadLatest, setLoadLatest] = useState(false);
  const { allowOnlyActions }: any = FunctionForPrivileges();

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const ReadData:any = await getAllTestimonials();
      setPreviewAll(ReadData);
      setLoadLatest(false);
      setIsLoading(false)
    })()
  },[loadLatest])

  const CheckValue = (label: any) => {
    setChecked(label);
    setFieldValue('type', label)
  };

  const forTestimonials = async () => {
    setIsLoading(true)
    const {type, external_link, video_url, ...rest} = values
    const body: any = {
      type,
      ...(type === "Youtube Link" && { external_link: external_link }),
      ...(type === "Upload Video" && { video_url: video_url }),
      ...rest
    };
    const postResponse = await createTestimonial(body);
    if (postResponse) {
      type === "Youtube Link" ? toast.success('Testimonial Added Successfully') : toast.success('Video Uploaded Successfully')
      setValues({
        ...values,
        type: "Youtube Link",
        external_link: "",
        video_url: {
          filename: "",
          key: "",
          url: "",
        },
        thumbnail: {
          filename: "",
          key: "",
          url: ""
        },
      }, true)
      setLoadLatest(true)
    }
    setIsLoading(false)
  }

      const formik = useFormik({
        initialValues: {
          type: "Youtube Link",
          external_link: "",
          video_url: {
            filename: "",
            key: "",
            url: "",
          },
          thumbnail: {
            filename: "",
            key: "",
            url: ""
          },
          created_by: userDetails,
        },
        validationSchema: ()=>AddTestimonial(values.type),
        onSubmit: () => {
          forTestimonials();
        },
      });

      const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setValues,
        setFieldValue,
      } = formik;

  const handleValues:any = (response: any, Method: string, index:number, subBucket:string, file?: any, name?: any, randomKey?: string) => {
    if (['Upload', 'Update'].includes(Method)) {
      const cacheBuster = Date.now();
      setFieldValue(index === 1 ? "thumbnail" : "video_url", {
        url: mode !== "edit" ? response?.publicUrl : `${response?.publicUrl}?cb=${cacheBuster}`,
        filename: file?.name, key: mode!=="edit" && randomKey
      });
    } else {
        setFieldValue(index===1 ? "thumbnail" : "video_url", { filename: "", url: "", key: "" });
    }
  }

  const deleteExistingLinks = async (Id: any, index:number, key:string, type:string) => {
    const deleteResponse = await deleteTestimonial(Id);
    const deleteFileData: any =
      type === "Upload Video" ? (await deleteFile(key, "testimonials")) : true
    if (deleteResponse && deleteFileData) {
      const newArray = [...previewAll];
      newArray.splice(index, 1);
      setPreviewAll(newArray);
    }
  }

  return (
    <div className="space-y-10">
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <CardLayoutOne>
          <form
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
            className="space-y-8 flex flex-col items-center justify-center"
          >
            <div className="flex items-center justify-around w-[84%] lg:w-3/5 xl:w-2/5 mx-auto">
              <Checkbox
                label={"Youtube Link"}
                checked={checked}
                CheckValue={CheckValue}
                textStyle={"text-[#000000] text-[20px]"}
                show={true}
              />
              <Checkbox
                label={"Upload Video"}
                checked={checked}
                CheckValue={CheckValue}
                textStyle={"text-[#000000] text-[20px]"}
                show={true}
              />
            </div>
            {checked === "Youtube Link" ? (
              <InputBox
                label="Testimonial URL"
                placeholder="URL"
                topDivStyle={"flex flex-col w-2/3 lg:w-2/4"}
                classNameInput={"w-full"}
                name={"external_link"}
                value={values.external_link}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.external_link}
                isTouched={touched.external_link}
                disabled={!allowOnlyActions('create')}
              />
            ) : (
              <div className="flex items-center gap-4 w-9/12 mx-auto">
                <div
                  className={`w-full min-h-[100px] lg:min-h-[130px] rounded-[5px] border-[1px] border-[#707070] border-dashed mx-auto flex flex-col items-center justify-center space-y-1`}
                >
                  {allowOnlyActions('create') && <UploadOrUpdateBucket
                    mode={mode}
                    id={"video"}
                    labelText={"Upload Video"}
                    accept={"video/*"}
                    fileSize={5}
                    sizeInText={"Image Size 5Mb"}
                    Method={"Upload"}
                    bucketName={"testimonials"}
                    forValue={handleValues}
                    fileNameData={values.video_url.filename}
                    fileNameKey={values.video_url.key}
                    preview={values.video_url.url}
                    index={2}
                    name={'video_url.url'}
                    error={errors.video_url?.url}
                    isTouched={touched.video_url?.url}
                  />}
                </div>
                <div
                  className={`w-full min-h-[100px] lg:min-h-[130px] rounded-[5px] border-[1px] border-[#707070] border-dashed mx-auto flex flex-col items-center justify-center space-y-1`}
                >
                  {allowOnlyActions('create') && <UploadOrUpdateBucket
                    mode={mode}
                    id={"thumbnail"}
                    labelText={"Upload Thumbnail"}
                    accept={"image/*"}
                    fileSize={100 * 100}
                    sizeInText={"Image Size 100x100"}
                    Method={"Upload"}
                    bucketName={"testimonials"}
                    forValue={handleValues}
                    fileNameData={values.thumbnail.filename}
                    fileNameKey={values.thumbnail.key}
                    preview={values.thumbnail.url}
                    index={1}
                    name={'thumbnail.url'}
                    error={errors.thumbnail?.url}
                    isTouched={touched.thumbnail?.url}
                  />}
                </div>
              </div>
            )}
            {allowOnlyActions('create') && <button
              type="submit"
              className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-6 font-Roboto font-normal"
            >
              Update
            </button>}
          </form>
        </CardLayoutOne>
      )}
      <TestimonialsPreview
        isLoading={isLoading}
        previewAll={previewAll}
        setPreviewAll={setPreviewAll}
        deleteExistingLinks={deleteExistingLinks}
        accessDelete={allowOnlyActions('delete')}
      />
    </div>
  );
};
