import buttonWithRightArrow from "../../../Assets/buttonWithRightArrow.svg";
import { manageCategory } from "../../../utils/json/manageCategory";
import { BackButton } from "../../Elements/BackButton";
import { AddNewCategory } from "./AddNewCategory";
import { MessageCard } from "../../Elements/MessageCard";
import { useSharedLogic, CommonTableComp } from "../../CommonFunctions/CommonFunc";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const CourseCategory = () => {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  
  return (
    <div className="w-11/12 mx-auto">
      {(id === "created-success" || id === "update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "created-success"
                ? "Category Created Successfully"
                : "Category Updated Successfully"
            }
            buttonText={"Manage Category"}
            handleClick={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}/${routeNameForSubSection}`)
            }
          />
        </div>
      )}
      {(id === "new-category" || mode === "edit") && (
        <div className="space-y-4">
          <BackButton topDivStyle={"w-10/12 justify-end"} />
          <AddNewCategory id={id} mode={mode} />
        </div>
      )}
      {allowOnlyActions('view') &&
      <CommonTableComp
        id={id}
        head={"Manage Category"}
        buttonType={"right"}
        buttonText={"Add New Category"}
        onClick={()=>createFunc("new-category")}
        buttonImg={buttonWithRightArrow}
        TABLE_JSON={manageCategory}
        allowCondition={allowOnlyActions("create")}
      />}
    </div>
  );
}
