import React from 'react';
import { SubmitButton } from '../../../Elements/SubmitButton';  
import { InputBox } from '../../../Elements/InputBox';
import { DropdownSingleInputWithCheckbox } from '../../../Elements/DropdownSingleInputWithCheckbox';
import { FunctionForPrivileges } from '../../../CommonFunctions/Privileges';

export const RefundTo = ({formik, basicInfoDetails}:any) => {
  const Payment_Mode = ["Cash", "UPI"];
  const { allowOnlyActions }: any = FunctionForPrivileges();
  const mapPaidAmount = basicInfoDetails?.paymentDetails?.map(
    (amt: any) => amt?.paid_amount
  );
  const sum =
    mapPaidAmount &&
    mapPaidAmount.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      0
    );
  const numberWithoutCommas = sum.replace(/[^0-9]/g, "");
  const finalPaidAmount = parseInt(numberWithoutCommas, 10);

    const handleChangeValue = (index: any, value: any, check: any) => {
        const numericValue = value.replace(/[^0-9\.]/g, '');
        const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        if (numericValue === "") {
            formik.setFieldValue('amount', "");
        } else {
            const [integerPart, decimalPart] = numericValue.split('.');
            const parsedValue = parseInt(integerPart) + (decimalPart ? parseFloat(`0.${decimalPart}`) : 0);

            if (parsedValue <= finalPaidAmount) {
            formik.setFieldValue('amount', formattedValue);
            } else {
            formik.setFieldValue('amount', formattedValue.substring(0, formattedValue.length - 1));
            }
        }
    };
  
  return (
    <div className="w-full py-10 flex flex-col items-center space-y-10">
        <div className="flex flex-col items-end justify-between w-full space-y-10">
            <div className="flex items-end justify-between w-11/12 mx-auto">
                <InputBox
                topDivStyle={"flex flex-col w-[47%]"}
                classNameInput={"w-full"}
                label={"Amount(₹)"}
                placeholder={"Amount"}
                type={"text"}
                inputMode="numeric"
                name={"amount"}
                value={formik.values.amount}
                onChange={handleChangeValue}
                onBlur={formik.handleBlur}
                error={formik.errors.amount}
                isTouched={formik.touched.amount}
                disabled={false}
                index={0}
                max={finalPaidAmount}
                />
                <DropdownSingleInputWithCheckbox
                topDivStyle={"flex flex-col w-[47%]"}
                classNameInput={"w-full"}
                label="Mode of Payment"
                placeholder="Mode of Payment"
                options={Payment_Mode}
                name={"mode_of_payment"}
                value={formik.values.mode_of_payment}
                onBlur={formik.handleBlur}
                error={formik.errors.mode_of_payment}
                isTouched={formik.touched.mode_of_payment}
                mapThrough={"mode_of_payment"}
                formik={formik}
                zIndex={"z-20"}
                disabled={false}
                />
            </div>
            <div
                className={`w-1/2 mx-auto`}
                >
                <textarea
                    name="reason"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={false}
                    placeholder="Enter the Reason"
                    className="border-[#707070] border-[0.5px] p-1 w-full min-h-[100px] max-h-[150px] rounded-[3px] resize-none"
                >
                    {formik.values.reason}
                </textarea>
                {formik.touched?.reason && formik.errors?.reason && (
                    <span className="text-red-500 absolute -bottom-6 right-0">
                    {formik.errors?.reason}
                    </span>
                )}
            </div>
        </div>
        {allowOnlyActions("create") && <SubmitButton />}
    </div>
  )
}
