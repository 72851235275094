import { useState, useEffect } from 'react';
import { CardLayoutTwo } from '../../../Elements/Cards/CardLayoutTwo';
import { Loader } from '../../../Elements/Loader/Loader';
import { BasicInfo } from './BasicInfo';
import { Feedback } from './Feedback';
import { getStudentConcernAndFeedbackById } from '../../../../functions/admin/Action center/Session Feedback/sessionFeedback';

export const FeedbackConcernsInfo = ({ id, mode }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [studentData, setStudentData] = useState([] as any);
    const [showFb, setShowFb] = useState("reply")
    const [selectedStatus, setSelectedStatus] = useState("Open")
    const [selectedIssue, setSelectedIssue] = useState("")
    const isId: any = id !== "new-course" && id;

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const studentResponse = await getStudentConcernAndFeedbackById(isId)
            if (studentResponse) {
                setStudentData(studentResponse);
                setSelectedStatus(studentResponse?.status)
                studentResponse?.issue_priority!==null && setSelectedIssue(studentResponse?.issue_priority)
                studentResponse?.reply!==null && setShowFb("replied")
            }
            setIsLoading(false);
        })()
        }, [])
    
    return (
        <>
          {isLoading ? (
              <Loader widthFix={"w-9/12"} />
          ) : (
            <CardLayoutTwo>
                <div className="space-y-4">
                    <div className="text-left border-b-2 p-2 flex items-center justify-between">
                        <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
                            Feedback/Concerns Information
                        </p>
                    </div>
                    <BasicInfo id={id} mode={mode} studentData={studentData} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} selectedIssue={selectedIssue} setSelectedIssue={setSelectedIssue} />
                    <Feedback id={id} studentData={studentData} showFb={showFb} setShowFb={setShowFb} />
                </div>
            </CardLayoutTwo>)}
        </>
  )
}
