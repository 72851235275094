import { ViewEditDeleteActionComponent } from "../../Components/Content Management/Resources/ActionComponentForResource";
import { getResourcesTableData } from "../../functions/admin/Resources/Resources";

export const resources = {
  headers: [
    {
      name: "Title",
      apiKey: "title",
      headerClass: "text-start w-[150px] px-2",
      className: "text-start w-[150px] break-words px-2",
    },
    {
      name: "Resource Category",
      apiKey: "category_name",
      headerClass: "text-start w-[150px] px-2",
      className: "text-start w-[150px] break-words px-2",
    },
    {
      name: "Date",
      apiKey: "date",
      headerClass: "text-start w-[130px]",
      className: "text-start w-[130px]",
    },
    {
      name: "Action",
      apiKey: "Courses",
      isComponent: true,
      Comp: ViewEditDeleteActionComponent,
      headerClass: "text-center w-[100px]",
      className: "text-center w-[100px]",
    },
  ],
  BackendFunctionCall: getResourcesTableData,
  filters: {
    dateFilters: [
      {
        name: "Date",
        apiKey: "date",
        isSearchNeeded: true,
        className: "w-[200px]",
      },
    ],
    checkBox: [
      // {
      //   name: "Date",
      //   apiKey: "date",
      //   isSearchNeeded: true,
      //   className: "w-[200px]",
      // },
      {
        name: "Resource Category",
        apiKey: "category_name",
        isSearchNeeded: true,
        className: "w-[250px]",
      },
    ],
    searchBox: [
      {
        matchFields: ["title", "category_name"],
        searchKey: "resources",
        name: "Search...",
        searchStyle: "w-[220px]",
      },
    ],
  },
};
