import { SUPABASE_CLIENT } from "../Services/Supabase";

interface ConditionProps{
    conditionKey: string,
    conditionValue: any
}

interface updateMatchInterface{
    key: string,
    value:number[]|string[]
}

export const FetchData = (table: string, requiredFields: string[] = []) => {
    return SUPABASE_CLIENT.from(table).select(requiredFields?.length>0 ?requiredFields?.join(","): "*");
}

export const FetchCount = (table: string, requiredFields: string[] = []) => {
    return SUPABASE_CLIENT.from(table).select(requiredFields?.length>0 ?requiredFields?.join(","): "*",{ count: 'exact', head: true });
}

export const FetchDataById = (table: string, id: string|number) => {
    return FetchData(table).eq("id", id);
}

export const InsertData = async (table: string, data: any) => {
    return await SUPABASE_CLIENT.from(table).insert(data).select();
}

export const UpdateData = async (table: string, data: any, condition: ConditionProps) => {
    const { conditionKey, conditionValue} = condition;
    return await SUPABASE_CLIENT.from(table).update(data).eq(conditionKey, conditionValue).select();
}

export const DeleteData = async (table: string, condition: ConditionProps) => {
    const { conditionKey, conditionValue} = condition;
    return await SUPABASE_CLIENT.from(table).delete().eq(conditionKey, conditionValue);
}

export const BulkDelete = async (table: string, ids: number[] | string[]) => {
    return await SUPABASE_CLIENT.from(table).delete().in("id", ids)
}

export const BulkInsert = async (table: string, data: any[]) => {
    return await SUPABASE_CLIENT.from(table).insert(data).select();
}

export const BulkUpdate = async (table: string, data: any, conflictId: string = "id") => {
    return await SUPABASE_CLIENT.from(table).upsert(data, { onConflict: conflictId });
}

export const UpdateDataWithMatchParameters = async (table: string, data: any, { key, value }: updateMatchInterface) => {
    return await SUPABASE_CLIENT.from(table).update(data).in(key,value);
}