import { useState } from "react";

export const SelectLogic = (formik: any, setCheckboxValues:any) => {

const handleCheckboxChange = (checkStatus:any, menuId: any, menuValue: any) => {
  setCheckboxValues((prevCheckboxValues:any) => {
    const updatedList = prevCheckboxValues.map((boxValue:any) => {
      if (boxValue.id !== menuId) return boxValue;

            const updatedData = boxValue?.data?.map((main: any) => {
                  if (menuValue === 'all') {
                      if(checkStatus) {
                        if(main.label === "Not Applicable") return { ...main, checked: false}
                        else return {...main, checked: true}
                      } else {
                        if(main.label === "Not Applicable") return { ...main, checked: true}
                        else return {...main, checked: false}
                      }
                  } else if (menuValue === 'not applicable') {
                    if (main.label === "Not Applicable") {
                        return {...main, checked: !main?.checked}
                    } else return { ...main, checked: false };
                  } else if (menuValue === 'view') {
                    if(checkStatus && main.label === "Not Applicable") return { ...main, checked: false}
                    else {
                        if (main.label === "All") return { ...main, checked: false }
                        if(main.label === "Not Applicable") return { ...main, checked: true}
                    }  
                    if(main.label === "Create") return { ...main, checked: false}
                    if(main.label === "Edit") return { ...main, checked: false}
                    if(main.label === "Delete") return { ...main, checked: false}
                      if (main.label === "View") {
                          return { ...main, checked: !main?.checked}
                      } else return { ...main, checked: main?.checked}
                  } else if (menuValue === 'create') {
                    if(checkStatus && main.label === "Not Applicable") return { ...main, checked: false}
                    if(main.label === "View") return { ...main, checked: true}
                    if (main.label === "Create") {
                        return { ...main, checked: !main?.checked}
                    } else return { ...main, checked: main?.checked}
                  } else if (menuValue === 'edit') {
                    if(checkStatus && main.label === "Not Applicable") return { ...main, checked: false}
                    if(main.label === "View") return { ...main, checked: true}
                    if (main.label === "Edit") {
                        return { ...main, checked: !main?.checked}
                    } else return { ...main, checked: main?.checked}
                  } 
                  else if (menuValue === 'delete') {
                    if(checkStatus && main.label === "Not Applicable") return { ...main, checked: false}
                    if (main.label === "View")
                      return { ...main, checked: true };
                    if (main.label === "Delete") {
                        return { ...main, checked: !main?.checked}
                    } else return { ...main, checked: main?.checked}
                  } else return main
              })

      const updateSubData = boxValue?.submenus?.map((ab:any)=> {return {
        ...ab, 
        data: 
        menuValue === "all" && ab?.data?.map((cd: any) => (cd?.value === "not applicable" ? { ...cd, checked: !checkStatus } : { ...cd, checked: checkStatus })) ||
        menuValue === "view" && ab?.data?.map((cd: any) => ((cd?.value === "view" ? { ...cd, checked: checkStatus }:cd?.value === "not applicable"?{...cd, checked: !checkStatus}:{...cd, checked:false}))) ||
        menuValue==="create" && ab?.data?.map((cd:any) => (((cd?.value==="view"&&!cd?.checked)||cd?.value==="create")?{...cd, checked: checkStatus}:cd?.value === "not applicable"?{...cd, checked: !checkStatus}:cd)) ||
        menuValue==="edit" && ab?.data?.map((cd:any) => (((cd?.value==="view"&&!cd?.checked) || cd?.value==="edit")?{...cd, checked: checkStatus}:cd?.value === "not applicable"?{...cd, checked: !checkStatus}:cd)) ||
        menuValue==="delete" && ab?.data?.map((cd:any) => (((cd?.value==="view"&&!cd?.checked) || cd?.value==="delete")?{...cd, checked: checkStatus}:cd?.value === "not applicable"?{...cd, checked: !checkStatus}:cd)) ||
        menuValue==="not applicable" && ab?.data?.map((cd:any) => (cd?.value==="not applicable"?{...cd, checked: checkStatus}:{...cd, checked: false}))
      }})

      const checkForAllSelection = updateSubData?.map((subVal:any)=> {return{
        ...subVal, data: subVal?.data?.map((val:any)=> 
            (((val?.value==="view"&&val?.checked)&&(val?.value==="create"&&val?.checked)&&(val?.value==="edit"&&val?.checked)&&(val?.value==="delete"&&val?.checked)&&(val?.value==="all"&&!val?.checked)&&(val?.value==="not applicable"&&!val?.checked))?{...val, checked: true}:val))
      }})

      const allChecked = updatedData.some((main:any) => main.checked);
      const otherUnchecked = updatedData.some((main:any) =>
        main.label !== 'All' && main.label !== 'Not Applicable' && !main.checked
      );

      const updatedAll = updatedData.map((main:any) =>
        main.label === 'All' ? { ...main, checked: allChecked && !otherUnchecked } : main
      );


      return { ...boxValue, data: updatedAll, submenus: checkForAllSelection };
    });
      
    // pending one 'ALL' condition for overall checker.

    return updatedList;
  });
};
    
const handleCheckboxChangeForSubMenu = (checkStatus:any, menuId:any, menuValue:any, subIndex:number) => {
    setCheckboxValues((prevCheckboxValues:any) => {
        const updatedList = prevCheckboxValues.map((boxValue:any) => {
        if (boxValue.id !== menuId) return boxValue;

        const updatedSubmenus = boxValue.submenus.map((sub:any, index: number) => {
            const updatedMainData = sub.data.map((main: any) => {
            if (index === subIndex) {
                  if (menuValue === "all") {
                    if (checkStatus) {
                      if (main.label === "Not Applicable")
                        return { ...main, checked: false };
                      else return { ...main, checked: true };
                    } else {
                      if (main.label === "Not Applicable")
                        return { ...main, checked: true };
                      else return { ...main, checked: false };
                    }
                  } else if (menuValue === "not applicable") {
                    if (main.label === "Not Applicable") {
                      return { ...main, checked: !main?.checked };
                    } else return { ...main, checked: false };
                  } else if (menuValue === "view") {
                    if (checkStatus && main.label === "Not Applicable")
                      return { ...main, checked: false };
                    else {
                      if (main.label === "All")
                        return { ...main, checked: false };
                      if (main.label === "Not Applicable")
                        return { ...main, checked: true };
                    }
                    if (main.label === "Create")
                      return { ...main, checked: false };
                    if (main.label === "Edit")
                      return { ...main, checked: false };
                    if (main.label === "Delete")
                      return { ...main, checked: false };
                    if (main.label === "View") {
                      return { ...main, checked: !main?.checked };
                    } else return { ...main, checked: main?.checked };
                  } else if (menuValue === "create") {
                    if (checkStatus && main.label === "Not Applicable")
                      return { ...main, checked: false };
                    if (main.label === "View")
                      return { ...main, checked: true };
                    if (main.label === "Create") {
                      return { ...main, checked: !main?.checked };
                    } else return { ...main, checked: main?.checked };
                  } else if (menuValue === "edit") {
                    if (checkStatus && main.label === "Not Applicable")
                      return { ...main, checked: false };
                    if (main.label === "View")
                      return { ...main, checked: true };
                    if (main.label === "Edit") {
                      return { ...main, checked: !main?.checked };
                    } else return { ...main, checked: main?.checked };
                  } else if (menuValue === "delete") {
                    if (checkStatus && main.label === "Not Applicable")
                      return { ...main, checked: false };
                    if (main.label === "View")
                      return { ...main, checked: true };
                    if (main.label === "Delete") {
                      return { ...main, checked: !main?.checked };
                    } else return { ...main, checked: main?.checked };
                  }
            }
            return main;
            });

            const allChecked = updatedMainData.some((main:any) => main.checked);
            const otherUnchecked = updatedMainData.some(
            (main:any) => main.label !== 'All' && main.label !== 'Not Applicable' && !main.checked
            );

            const updatedAll = updatedMainData.map((main:any) =>
            main.label === 'All' ? { ...main, checked: allChecked && !otherUnchecked } : main
            );

            return { ...sub, data: updatedAll };
        });

        const checkedDataInsideSubmenus = updatedSubmenus.map((arr: any) =>
          arr.data.map((sub: any) => sub.checked)
        );

        return { ...boxValue, submenus: updatedSubmenus };
        });

        return updatedList;
    });
  }
    // const [randomString, setRandomString] = useState([] as any);

    // const checkFunction = (
    //     label: any,
    //     checked: any,
    //     mapOneIndex: any,
    //     mapTwoIndex: any
    // ) => {
    //     let randomVar = [...formik.values.menuaccess];
    //     let randomString = "";

    //     const updatedCheckboxes: any = formik.values.menuaccess.map(
    //     (item: any, index: number) => {
    //         if (index === mapOneIndex) {
    //         return {
    //             ...item,
    //             data: item.data.map((box: any) => {
    //             if (label === "All") {
    //                 randomString = "All";
    //                 setRandomString([...randomString, randomString])
    //                 if (box.label !== "Not Applicable") {
    //                 return {
    //                     ...box,
    //                     checked: !checked,
    //                 };
    //                 } else return { ...box, checked: false };
    //             } else if (label === "Not Applicable") {
    //                 randomString = "Not Applicable";
    //                 if (box.label === "Not Applicable") {
    //                 return {
    //                     ...box,
    //                     checked: !checked,
    //                 };
    //                 } else return { ...box, checked: false };
    //             } else if (
    //                 box.id === Number(mapTwoIndex) + 1 ||
    //                 box.label === "Not Applicable" ||
    //                 box.label === "All"
    //             ) {
    //                 randomString = label;
    //                 if (box.label === "Not Applicable" || box.label === "All") {
    //                     return {
    //                         ...box,
    //                         checked: false,
    //                     };
    //                 }
    //                 return { ...box, checked: !checked };
    //             } else return { ...box };
    //             }),
    //             submenus:
    //             item.submenus &&
    //             item.submenus.map((sub: any) => {
    //                 return {
    //                 ...sub,
    //                 data:
    //                     sub &&
    //                     sub.data.map((subdata: any) => {
    //                     if (randomString === "All") {
    //                         if (subdata.label !== "Not Applicable") {
    //                         return {
    //                             ...subdata,
    //                             checked: !checked,
    //                         };
    //                         } else return { ...subdata, checked: false };
    //                     } else if (randomString === "Not Applicable") {
    //                         if (subdata.label === "Not Applicable") {
    //                         return {
    //                             ...subdata,
    //                             checked: !checked,
    //                         };
    //                         } else return { ...subdata, checked: false };
    //                     } else if (
    //                         (randomString === subdata.label &&
    //                         subdata.id === Number(mapTwoIndex) + 1) ||
    //                         subdata.label === "Not Applicable" ||
    //                         subdata.label === "All"
    //                     ) {
    //                         if (
    //                         subdata.label === "Not Applicable" ||
    //                         subdata.label === "All"
    //                         ) {
    //                         return {
    //                             ...subdata,
    //                             checked: false,
    //                         };
    //                         }
    //                         return { ...subdata, checked: !checked };
    //                     } else return { ...subdata };
    //                     }),
    //                 };
    //             }),
    //         };
    //         } else return item;
    //     }
    //     );
    //     randomVar = updatedCheckboxes;
    //     formik.setFieldValue("menuaccess", randomVar);
    // };

    // const checkFunctionForSubMenus = (
    //     label: any,
    //     checked: any,
    //     mapOneIndex: any,
    //     mapTwoIndex: any,
    //     mapThirdIndex: any
    // ) => {
    //     let randomVar = [...formik.values.menuaccess];
    //         const updatedCheckboxes: any = formik.values.menuaccess.map(
    //         (box: any, index: number) => {
    //             if (index === mapOneIndex) {
    //             return {
    //                 ...box,
    //                 submenus:
    //                 box.submenus &&
    //                 box.submenus.map((sub: any, i: number) => {
    //                     if (i === mapTwoIndex) {
    //                     return {
    //                         ...sub,
    //                         data: sub.data.map((subdata: any) => {
    //                         if (label === "All") {
    //                             if (subdata.label !== "Not Applicable") {
    //                             return {
    //                                 ...subdata,
    //                                 checked: !checked,
    //                             };
    //                             } else return { ...subdata, checked: false };
    //                         } else if (label === "Not Applicable") {
    //                             if (subdata.label === "Not Applicable") {
    //                             return {
    //                                 ...subdata,
    //                                 checked: !checked,
    //                             };
    //                             } else return { ...subdata, checked: false };
    //                         } else if (
    //                             subdata.id === Number(mapThirdIndex) + 1 ||
    //                             subdata.label === "Not Applicable" ||
    //                             subdata.label === "All"
    //                         ) {
    //                             if (
    //                             subdata.label === "Not Applicable" ||
    //                             subdata.label === "All"
    //                             ) {
    //                             return {
    //                                 ...subdata,
    //                                 checked: false,
    //                             };
    //                             }
    //                             return { ...subdata, checked: !checked };
    //                         } else return { ...subdata };
    //                         }),
    //                     };
    //                     } else return sub;
    //                 }),
    //             };
    //             } else return box;
    //         }
    //         );
    //         console.log(updatedCheckboxes, "updatedCheckboxes");
    //         randomVar = updatedCheckboxes;
    //         formik.setFieldValue("menuaccess", randomVar);
    //     };
  return {
    handleCheckboxChange, handleCheckboxChangeForSubMenu
  }
}
