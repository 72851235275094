import { CardLayoutThree } from "../../Elements/Cards/CardLayoutThree";
import { Components } from "./ModuleImports";
import { ModalForInvoice } from "./PDF/ModalForInvoice";
import { ExistingActions } from "./ForActionCenter/ExistingActions";

export const StudentCourseProgress = ({ id, mode }: any) => {
  const {
    useState,
    useEffect,
    Loader,
    CardLayoutOne,
    getBranchForGST,
    ReadData,
    BasicInfo,
    TrainerDetails,
    SessionDetails,
    CourseDetails,
    PaymentSummary,
    PaymentDetails,
    AppliedJobs,
    useSharedLogic,
    PendingPayments,
    Comments,
    Refund,
    AdjustPayment,
    NullifyPayment,
    getStudentManagementDetailsById,
    getTrainerAndSessionDetails,
    PerformAction,
  }: any = Components;
  const { courseId, routePath }: any = useSharedLogic();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([] as any);
  const [updatedStudentData, setUpdatedStudentData] = useState([] as any);
  const [batchStartDate, setBatchStartDate] = useState("");
  const [feeTotal, setFeeTotal] = useState(null);
  const [batch_data, setBatch_data] = useState([] as any);
  const [trainers, setTrainers] = useState([] as any);
  const [sessions, SetSessions] = useState([] as any);
  const [approval_data, setApproval_data] = useState([] as any);
  const [refundData, setRefundData] = useState([] as any);
  const [adjustPaymentData, setAdjustPaymentData] = useState([] as any);
  const [appliedJobs, setAppliedJobs] = useState([] as any);
  const [isPlacementNeeded, setIsPlacementNeeded] = useState();
  const isId: any = id !== "new-student-course" && id;
  const [branchDetails, setBranchDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalId, setCurrentModalId] = useState(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const IndividualId = await getStudentManagementDetailsById(isId);
      // console.log(IndividualId, "IndividualId");
      routePath &&
        (routePath === "student_actions" ||
          routePath === "student_approval_queue") &&
      setApproval_data(IndividualId?.approval_data);
      setBatchStartDate(IndividualId?.batch_start_date);
      setPaymentDetails(IndividualId?.paymentDetails);
      setUpdatedStudentData(IndividualId?.updatedStudentData);
      setIsPlacementNeeded(IndividualId?.updatedStudentData?.placement);
      setBatch_data(IndividualId?.batch_data?.[0]);
      setRefundData(IndividualId?.refund_data);
      setAdjustPaymentData(IndividualId?.adjustedPayment);
      setAppliedJobs(IndividualId?.appliedJobs);
      const Course_Progress_Data: any = await getTrainerAndSessionDetails(isId);
      setTrainers(Course_Progress_Data?.trainers_data);
      SetSessions(Course_Progress_Data?.current_student_sessions);
      setIsLoading(false);
      if (
        Object.keys(IndividualId?.updatedStudentData)?.length &&
        IndividualId?.updatedStudentData?.branch_id?.id
      ) {
        const branchGSTDetails: any = await getBranchForGST(
          IndividualId?.updatedStudentData?.branch_id?.id
        );
        // console.log(branchGSTDetails, "branchGSTDetails")
        if (branchGSTDetails?.length > 0) {
          setBranchDetails(branchGSTDetails[0]);
        }
      }
    })();
  }, []);

  const check_feeType = branchDetails && branchDetails?.fee_type;
  const sendPercentageIGST = check_feeType === "IGST" && branchDetails?.igst;
  const sendPercentageSGST = check_feeType === "SGST" && branchDetails?.sgst;
  const sendPercentageCGST = check_feeType === "SGST" && branchDetails?.cgst;
  const sendPercentage =
    check_feeType === "SGST"
      ? {
          cgst: branchDetails?.cgst,
          sgst: branchDetails?.sgst,
        }
      : check_feeType === "IGST"
      ? branchDetails?.igst
      : null;

  const paymentObject = {
    check_feeType: check_feeType,
    sendPercentageIGST: sendPercentageIGST,
    sendPercentageSGST: sendPercentageSGST,
    sendPercentageCGST: sendPercentageCGST,
    sendPercentage: sendPercentage,
  };

  const handleOpenModal = (id: any) => {
    setCurrentModalId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-11/12"} />
      ) : isModalOpen ? (
        <ModalForInvoice
          onClose={handleCloseModal}
          paymentDetails={paymentDetails}
          currentModalId={currentModalId}
          updatedStudentData={updatedStudentData}
          paymentObject={paymentObject}
          branchDetails={branchDetails}
        />
      ) : (
        <CardLayoutThree>
          <div className="space-y-4">
            <div className="text-left border-b-2 p-2 flex items-center justify-between">
              <p className="font-Roboto font-medium text-[22px] 2xl:text-[24px] text-[#707070] my-3 ml-4">
                Student Information
              </p>
              {courseId && (
                <div className="pr-10">
                  <ReadData head={"Batch Code"} body={batch_data?.batch_code} />
                </div>
              )}
            </div>
            <BasicInfo
              updatedStudentData={updatedStudentData}
              setPaymentDetails={setPaymentDetails}
              setUpdatedStudentData={setUpdatedStudentData}
              batchStartDate={batchStartDate}
              batch_data={batch_data}
              isPlacementNeeded={isPlacementNeeded}
              setIsPlacementNeeded={setIsPlacementNeeded}
            />
            {courseId ? (
              <div className="space-y-6">
                <TrainerDetails Trainers={trainers} />
                <SessionDetails Sessions={sessions} />
              </div>
            ) : (
              <div className="space-y-6">
                <CourseDetails
                  updatedStudentData={updatedStudentData}
                  setFeeTotal={setFeeTotal}
                  feeTotal={feeTotal}
                  branchDetails={branchDetails}
                  paymentObject={paymentObject}
                />
                <PaymentSummary
                  id={id}
                  updatedStudentData={updatedStudentData}
                  paymentDetails={paymentDetails}
                  feeTotal={feeTotal}
                  adjustPaymentData={adjustPaymentData}
                  // branchDetails={branchDetails}
                  // paymentObject={paymentObject}
                />
                <PaymentDetails
                  paymentDetails={paymentDetails}
                  branchDetails={branchDetails}
                  paymentObject={paymentObject}
                  handleOpenModal={handleOpenModal}
                />
                {/* <PendingPayments /> */}
                <Comments updatedStudentData={updatedStudentData} />
                {refundData?.length > 0 && (
                  <Refund
                    refundData={refundData}
                    updatedStudentData={updatedStudentData}
                  />
                )}
                {adjustPaymentData?.length > 0 && <AdjustPayment adjustPaymentData={adjustPaymentData} />}
                {/* <NullifyPayment /> */}
                {appliedJobs?.length > 0 && (
                  <AppliedJobs appliedJobs={appliedJobs} />
                )}
                {routePath &&
                  (routePath === "student_actions" ||
                    routePath === "student_approval_queue") && (
                    <PerformAction
                      id={id}
                      approval_data={approval_data}
                      updatedStudentData={updatedStudentData}
                      paymentDetails={paymentDetails}
                      setIsLoading={setIsLoading}
                      paymentObject={paymentObject}
                      branchDetails={branchDetails}
                    />
                )}
                {routePath && routePath === "student_actions" && (
                  <ExistingActions id={id} updatedStudentData={updatedStudentData} paymentObject={paymentObject} branchDetails={branchDetails} />
                )}  
              </div>
            )}
          </div>
        </CardLayoutThree>
      )}
    </>
  );
};
