import React from 'react';
import { useParams } from "react-router-dom";
import { Subsection } from '../SubSections/Subsection';

export const ContentManagement = () => {
    const params = useParams();
    const routeName: any = params.subsection;
  return (
    <div>
      <Subsection routeName={routeName} />
    </div>
  )
}
