import React from 'react'
import { TableComponent } from '../Elements/Table/TableComponent'
import { appEnquiries } from '../../utils/json/AppEnquiries'
import { useSharedLogic } from '../CommonFunctions/CommonFunc';
import { CommonTableComp } from '../CommonFunctions/CommonFunc';
import AppEnquiriesUpdate from './AppEnquiriesUpdate';
import { MessageCard } from '../Elements/MessageCard';
import { FunctionForPrivileges } from '../CommonFunctions/Privileges';

export default function AppEnquiries() {
  const { navigate, routeNameForHome, routeNameForSubSection, id, mode, createFunc }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();
  return (
    // <TableComponent
    //         TABLE_JSON={appEnquiries}
    //       />
    <div>
      {(id === "enquiry-created-success" || id === "enquiry-update-success") && (
        <div className="w-full flex justify-center">
          <MessageCard
            message={
              id === "enquiry-created-success"
                ? "App Enquiry Created Successfully"
                : "App Enquiry Updated Successfully"
            }
            buttonText={"Manage Enquiries"}
            handleClick={() =>
              navigate(`/${routeNameForHome}`)
            }
            closeFunc={() =>
              navigate(`/${routeNameForHome}`)
            }
          />
        </div>
      )}
       {(mode === "moved-to-crm") && (
        <div className="space-y-4">
          
          <AppEnquiriesUpdate  id={id} mode="moved-to-crm"/>
        </div>
      )}
       {allowOnlyActions('view') &&
    <CommonTableComp
      id={id}
      head={"App Enquiries"}
     // buttonType={"right"}
     // buttonText={"Download All Resumes"}
     // onClick={()=>createFunc("View-Applicants")}
     // buttonImg={downloadImg}
      TABLE_JSON={appEnquiries}
      //allowCondition={true}
    />}
    </div>
  )
}