import { Components } from "./ModuleImports";
import selectedCheckbox from "../../../Assets/selectedCheckbox.svg";
import emptyCheckbox from "../../../Assets/emptyCheckbox.svg";
import toast from "react-hot-toast";

export const StudentDetails = ({ formik, studentData }: any) => {
  const { useState }: any = Components;
  const [checked, setChecked] = useState();
  const bodyTextStyle = `w-[30%] text-[#686868] text-center text-[14px] font-Roboto font-[400]`;
  const Header = ["", "Name", "Phone Number", "Status"];

  const checkStatus = (uid: string, index: number, status:string) => {
    if (status !== 'Hold') {
      const attendees = [...formik.values.attendees];
      if (attendees.includes(uid)) {
          console.log("1");
          attendees.splice(attendees.indexOf(uid), 1);
      } else {
          console.log("2");
          attendees.splice(index, 0, uid);
      }
      formik.setFieldValue("attendees", attendees);
    } else toast.error("Student was in Hold")
  };

  return (
    <div className="space-y-4">
      <h1 className="text-[#000000] text-[20px] 2xl:text-[22px] font-Roboto font-[500]">
        Student Details
      </h1>
      <div className="shadow-md shadow-[#00000029] rounded-[7px] py-3">
        <div className="flex items-center justify-between bg-[#EFF7FF] py-3">
          {Header.map((head: any, i: number) => (
            <p
              className={`text-[#686868] text-[16px] font-Roboto font-[500] text-center ${
                i === 0 ? "w-[10%]" : "w-[30%]"
              }`}
            >
              {head}
            </p>
          ))}
        </div>
        <div className="min-h-[100px]">
          {studentData?.map((body: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center justify-between py-3 ${
                  index % 2 !== 0 && "bg-[#EFF7FF]"
                }`}
              >
                {/* <input type='checkbox' className='w-[10%]' checked={formik.values?.attendees.includes(body?.student?.uid)} onChange={()=>checkStatus(body?.student?.uid, index)} /> */}
                <div className="w-[10%] flex items-center justify-center">
                  <img
                    src={
                      formik.values?.attendees.includes(body?.student?.uid)
                        ? selectedCheckbox
                        : emptyCheckbox
                    }
                    alt="CheckType"
                    className={`w-4 h-4 cursor-pointer`}
                    onClick={() => checkStatus(body?.student?.uid, index, body?.status)}
                  />
                </div>
                <p className={bodyTextStyle}>{body?.student?.name}</p>
                <p className={bodyTextStyle}>{body?.student?.phoneNumber}</p>
                <p className={bodyTextStyle}>{body.status}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
