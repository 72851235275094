import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables";
import { DeleteData } from "../../crud";
import { FetchData, FetchDataById, InsertData, UpdateData } from "../../crud";
import { TechnologyInterface } from "./interface";

export const getAllTechnologies = async() => {
    try {
        const { data: technologies, error } = await FetchData(TableNames.technologies);
        if (error) throw error;
        return technologies;
    } catch (error) {
        return errorNotifier(error);
    }
} 

export const getTechnologyTableData = async () => {
    try {
        const { data: technologies, error } = await FetchData(TableNames.technologies, ["id", "technology", "courses", "status"]);

        const technologyData = technologies?.map((technology: any) => ({...technology, no_of_courses: technology?.courses.length}))

        if (error) throw error;
        return technologyData;
    } catch (error) {
        return errorNotifier(error);
    }
}

export const getTechnologyById = async (technologyId: string | number) => {
    try {
        const { data: technology, error } = await FetchDataById(TableNames.technologies, technologyId);
        if (error) throw error; 
        return technology[0];
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const createTechnology = async (technologyData: TechnologyInterface) => {
    try {
        technologyData.name_key = technologyData?.technology?.toLowerCase()
    const { data, error } = await InsertData(TableNames.technologies, technologyData);
        if (error) throw error;
        return data;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getTechnologyPreloadData = async (UsersDetails:any) => {
    try {
        const {data: courses} = await FetchData(TableNames.courses, ["id", "course_name"]).overlaps("branches", UsersDetails?.branches)
        return courses
    } catch (error) {
        return errorNotifier(error);
    }
}

export const updateTechnology = async (technologyData: Partial<TechnologyInterface>, technologyId: string | number) => {
    try {
        const { data, error } = await UpdateData(TableNames.technologies, technologyData, { conditionKey: "id", conditionValue: technologyId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const deleteTechnology = async (technologyId: string | number) => {
    try {
        const { data, error } = await DeleteData(TableNames.technologies, { conditionKey: "id", conditionValue: technologyId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

