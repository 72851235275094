import { ViewEditDeleteActionComponent } from "../../Components/HR & Student Placement/View Applicants/ActionComponent";
import { viewApplicants } from "../../functions/admin/HR Management/hr_management";
import ShortListApplicant from "../../Components/HR & Student Placement/View Applicants/ShortListApplicant";
import { useSharedLogic } from "../../Components/CommonFunctions/CommonFunc";
import PlacedApplicants from "../../Components/HR & Student Placement/View Applicants/PlacedApplicants";
import JoinedApplicants from "../../Components/HR & Student Placement/View Applicants/JoinedApplicants";
export const viewApplicant = {
    
    headers: [
        {
            name: "Name",
            apiKey: "student_name",
            headerClass: "w-[120px] text-center",
            className: "text-center",
        },
       
        {
            name: "Phone Number",
            apiKey: "student_mobile",
            headerClass: "w-[120px] text-center",
            className: "text-center break-words",
        },
        {
            name: "Email",
            apiKey: "student_email",
            headerClass: "w-[120px] text-center",
            className: "text-center break-words",
        },
        {
            name: "Course",
            apiKey: "course_name",
            headerClass: "w-[100px] text-center",
            className: "text-center",
        },
        {
            name: "Shortlisted Student",
            apiKey: "shortlisted_student",
            isComponent: true,
            Comp: ShortListApplicant,
            headerClass: "w-[80px] text-center",
            className: "text-center",
        },
        
        
        {
            name: "Placed Student",
            apiKey: "placed_student",
            isComponent: true,
            Comp: PlacedApplicants,
            headerClass: "w-[100px] text-center",
            className: "text-center break-words",
        },
        
        {
            name: "Joined the Company",
            apiKey: "joined_the_company",
            isComponent: true,
            Comp: JoinedApplicants,
            headerClass: "w-[130px] text-center",
            className: "text-center break-words",
        },
        {
            name: "Action",
            apiKey: "student_resume",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
            headerClass: "w-[130px] text-center",
            className: "w-[100px] text-center",
        },
    ],
  //   BackendFunctionCall: viewApplicants,
  BackendFunctionCall: (params:any, status:any) => {
   
   //console.log("Job ID VIEW",params)

    return viewApplicants(params,status)

},
    bodyData: [
        {
            name:"Anu Radha",
            phone_number:"9876543210",
            email:"user1234567 @gmail.com",
            course:"Digital Marketing",
            shortlisted_student:"",
            placed_student:"",
            joined_the_company:""
        },
        {
            name:"Anu Radha",
            phone_number:"9876543210",
            email:"user1234567 @gmail.com",
            course:"Digital Marketing",
            shortlisted_student:"",
            placed_student:"",
            joined_the_company:""
        },
        {
            name:"Anu Radha",
            phone_number:"9876543210",
            email:"user1234567 @gmail.com",
            course:"Digital Marketing",
            shortlisted_student:"",
            placed_student:"",
            joined_the_company:""
        },
        {
            name:"Anu Radha",
            phone_number:"9876543210",
            email:"user1234567 @gmail.com",
            course:"Digital Marketing",
            shortlisted_student:"",
            placed_student:"",
            joined_the_company:""
        },
        {
            name:"Anu Radha",
            phone_number:"9876543210",
            email:"user1234567 @gmail.com",
            course:"Digital Marketing",
            shortlisted_student:"",
            placed_student:"",
            joined_the_company:""
        },
        
    ],
    filters: {
        checkBox: [
            //  {
            //     name: "Shortlisted Student",
            //     apiKey: "shortlisted_student",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[180px] xl:w-[70px] 2xl:w-[150px]",
            // },
            // {
            //     name: "Placed Student",
            //     apiKey: "placed_student",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[120px] xl:w-[70px] 2xl:w-[120px]",
            // },
            // {
            //     name: "Joined the Company",
            //     apiKey: "joined_the_company",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[120px] xl:w-[90px] 2xl:w-[120px]",
            // },
            // {
            //     name: "Date",
            //     apiKey: "date",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[180px] xl:w-[70px] 2xl:w-[150px]",
            // },
            // {
            //     name: "City",
            //     apiKey: "city",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[120px] xl:w-[70px] 2xl:w-[120px]",
            // },
            // {
            //     name: "Branch",
            //     apiKey: "branch",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[120px] xl:w-[90px] 2xl:w-[120px]",
            // },
            // {
            //     name: "Course",
            //     apiKey: "course",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[120px] xl:w-[90px] 2xl:w-[120px]",
            // },
            // {
            //     name: "Schedule",
            //     apiKey: "schedule",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[180px] xl:w-[100px] 2xl:w-[120px]",
            // },
            // {
            //     name: "Status",
            //     apiKey: "status",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[180px] xl:w-[80px] 2xl:w-[130px]",
            // },
            // {
            //     name: "Admin",
            // apiKey: "admin",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[200px] xl:w-[90px] 2xl:w-[190px]",
            // },
            // {
            //     name: "Issue Priority",
            // apiKey: "issue_priority",
            //     isSearchNeeded: true,
            //     className: "md:w-[130px] lg:w-[200px] xl:w-[130px] 2xl:w-[190px]",
            // }
        ],
        searchBox: [
            {
                matchFields: ["student_name", "student_email", "student_mobile"],
                searchKey: "batch_start_datecourse",
                name: "Search...",
                searchStyle: "w-[200px]",
            },
            
        ]
    },
    ActionComponent: ViewEditDeleteActionComponent,
};