import { useState } from "react";
import tableEditIcon from "../../../Assets/tableEditIcon.svg";
import { useSharedLogic } from "../../CommonFunctions/CommonFunc";
import { createSearchParams } from "react-router-dom";
import { FunctionForPrivileges } from "../../CommonFunctions/Privileges";

export const ViewEditDeleteActionComponent = ({ recordData }: any) => {
  const { navigate }: any = useSharedLogic();
  const { allowOnlyActions }: any = FunctionForPrivileges();

  // console.log(recordData, "recordData")

  const navigateToViewPage = () => {
    navigate({
      pathname: `/action_center/student_feedback_not_given`,
      search: createSearchParams({
        id: `${recordData.id}`,
        mode: `edit`,
        // routePath: 'student_actions'
      }).toString(),
    })
  };

  return (
    <div className="flex items-center justify-center text-center space-x-4">
      {allowOnlyActions("edit") && (
        <div
          onClick={navigateToViewPage}
          className="bg-[#7E7E7E] cursor-pointer h-[32px] w-[32px] flex justify-center items-center rounded-[50%]"
        >
          <img
            src={tableEditIcon}
            alt="editIcon"
            className="w-[21px] h-[10px]"
          />
        </div>
      )}
    </div>
  );
};
