import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createSearchParams } from 'react-router-dom';
import { CardLayoutTwo } from '../../Elements/Cards/CardLayoutTwo';
import { InputBox } from '../../Elements/InputBox';
import { DropdownInputWithCheckbox } from '../../Elements/DropdownInputWithCheckbox';
import { add_Resource } from '../../Formik/InitialValues';
import { Checkbox } from '../../Elements/Checkbox';
import { useSharedLogic } from '../../CommonFunctions/CommonFunc';
import { getResourcesPreloadData, getResourceById, createResources, updateResource, deleteResource } from '../../../functions/admin/Resources/Resources';
import { onKeyDown } from '../../../Services/CommonFunctions';
import { Loader } from '../../Elements/Loader/Loader';
import { UploadOrUpdateBucket } from '../../CommonFunctions/CommonFileFunction';
import { AddResourcePost } from '../../Formik/FormikValidation';
import { useFormik } from 'formik';
import './customQuillStyles.css';

export const AddPost = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const ContentType = ["External Link", "Upload Video", "Text", "Image"];
  const [checked, setChecked] = useState<any>("External Link");
  const [isLoading, setIsLoading] = useState(false);
  const [resourceData, setResourceData] = useState([] as any);
  const [techData, setTechData] = useState([] as any);
  const [resourceNames, setResourceNames] = useState([] as any);
  const [techNames, setTechNames] = useState([] as any);
  const isId = id !== "new-post" && id;

  const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        ['blockquote', 'code-block'],
        ['image'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean'] // Remove formatting button
    ],
};

const formats = [
  'header',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'color',
  'background',
  'image',
  'blockquote',
  'code-block'
];

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const readData: any = await getResourcesPreloadData();
      setResourceData(readData?.resource_category)
      setTechData(readData?.technologies)
      if(readData) {
        const mapBranches:any = readData && readData?.resource_category.map((cat: any) => cat.category_name)
          setResourceNames(mapBranches)
        const mapTech:any = readData && readData?.technologies.map((tech: any) => tech.technology)
          setTechNames(mapTech)
      }
      if (id !== "new-post") {
        const resourceResponse = await getResourceById(isId);
        const {
          content_type,
          external_link,
          resource_category,
          technologies,
          text_message,
          thumbnail,
          title,
          video,
        }: any = resourceResponse;
        if (resourceResponse) {
          const categoryFilter:any = readData?.resource_category.filter((cat: any) => cat.id === resource_category);
          const technologyFilterForId:any = readData?.technologies.filter((tech: any) =>
            technologies.includes(tech.id)
          );
          const technologyFilterForName =
            technologyFilterForId && technologyFilterForId.map((data:any)=> data.technology)
          setValues(
            {
              ...values,
              title,
              resource_category,
              resource_category_name: categoryFilter[0]["category_name"],
              technologies: technologies,
              technology_names: technologyFilterForName,
              content_type,
              ...(content_type === "External Link" && { external_link }),
              ...(content_type === "Upload Video" && { video }),
              ...(content_type === "Text" && { text_message }),
              thumbnail,
            },
            true
          );
        }
      }
      setIsLoading(false)
    })()
  },[])

  const forAddPost = async () => {
    setIsLoading(true)
    const {content_type, technology_names, resource_category_name, external_link, video, text_message, ...rest}: any = values;
    const body: any = {
      ...(content_type === "External Link" && {external_link}),
      ...(content_type === "Upload Video" && {video}),
      ...(content_type === "Text" && { text_message }),
      content_type,
      ...rest
    }
    const response = await (id === "new-post" ? createResources(body) : updateResource(body, isId))
    if (response) {
      navigate({
        pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
        search: createSearchParams({ id: `${id==="new-post" ? "created-success" : "update-success"}`, mode: "resources" }).toString(),
      });
    }
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues: add_Resource,
    validationSchema: ()=>AddResourcePost(values.content_type),
    onSubmit: () => {
      forAddPost();
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  } = formik;
    
    const CheckValue = (label: any) => {
        setChecked(label);
        setFieldValue("content_type", label);
    };

  const handleValues:any = (response: any, Method: string, index:number, subBucket:string, file?: any, name?: any, randomKey?: string) => {
    if (['Upload', 'Update'].includes(Method)) {
      const cacheBuster = Date.now();
      setFieldValue(index === 1 ? "thumbnail" : "video", {
        url: mode !== "edit" ? response?.publicUrl : `${response?.publicUrl}?cb=${cacheBuster}`,
        fileName: file?.name, storage_key: mode!=="edit" && randomKey
      });
    } else {
        setFieldValue(index===1 ? "thumbnail" : "video", { fileName: "", url: "", storage_key: "" });
    }
  }
    
  return (
    <>
      {isLoading ? (
        <Loader widthFix={"w-10/12"} />
      ) : (
        <CardLayoutTwo>
          <form
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
            className="pt-6 w-10/12 mx-auto space-y-4"
          >
            <InputBox
              label="Title"
              placeholder="Title"
              topDivStyle={"flex flex-col w-full"}
              classNameInput={"w-full"}
              mandatory={true}
              mandatoryClass={`text-[#FF0000]`}
              name={"title"}
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.title}
              isTouched={touched.title}
            />
            <div className="flex items-center gap-8">
              <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-full"}
                classNameInput={"w-full"}
                label="Resource Category"
                placeholder="Resource Category"
                options={resourceNames}
                name={"resource_category_name"}
                forId={"resource_category"}
                value={values.resource_category_name}
                onBlur={handleBlur}
                error={errors.resource_category_name}
                isTouched={touched.resource_category_name}
                mapData={resourceData}
                mapThrough={"category_name"}
                formik={formik}
                disabled={false}
              />
              <DropdownInputWithCheckbox
                topDivStyle={"flex flex-col w-full"}
                classNameInput={"w-full"}
                label="Technology"
                placeholder="All Technologies"
                options={techNames}
                name={"technology_names"}
                forId={"technologies"}
                value={values.technology_names}
                onBlur={handleBlur}
                // error={errors.technology_names}
                // isTouched={touched.technology_names}
                mapData={techData}
                mapThrough={"technology"}
                formik={formik}
                disabled={false}
              />
            </div>
            <div className="flex items-center justify-center gap-4 w-11/12 mx-auto py-6">
              <h1 className="text-[#000000] text-[26px] font-Roboto font-[500]">
                Content Type:
              </h1>
              <div className="flex items-center gap-14">
                {ContentType.map((type, i) => {
                  return (
                    <Checkbox
                      label={type}
                      checked={values.content_type}
                      CheckValue={CheckValue}
                      textStyle={
                        "text-[#000000] text-[22px] font-Roboto font-[400]"
                      }
                      show={true}
                    />
                  );
                })}
              </div>
            </div>
            <div
              className={`${
                values.content_type === "Text"
                  ? "flex-col space-y-2"
                  : "flex gap-4"
              } items-center`}
            >
              {values.content_type === "External Link" && (
                <InputBox
                  label="External Link"
                  placeholder="External Link"
                  topDivStyle={"flex flex-col w-full"}
                  classNameInput={"w-full"}
                  mandatory={true}
                  mandatoryClass={`text-[#FF0000]`}
                  name={"external_link"}
                  value={values.external_link}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.external_link}
                  isTouched={touched.external_link}
                />
              )}
              {values.content_type === "Upload Video" && (
                <div
                  className={`w-full min-h-[100px] lg:min-h-[130px] rounded-[5px] border-[1px] border-[#707070] border-dashed mx-auto flex flex-col items-center justify-center space-y-1`}
                >
                  <UploadOrUpdateBucket
                    mode={mode}
                    id={"video"}
                    labelText={"Upload Video"}
                    accept={"video/*"}
                    fileSize={5}
                    sizeInText={"Image Size 5Mb"}
                    Method={values.video.url==="" ? "Upload" : "Update"}
                    bucketName={"resources"}
                    forValue={handleValues}
                    fileNameData={values.video.fileName}
                    fileNameKey={values.video.storage_key}
                    preview={values.video.url}
                    index={2}
                    name={'video.url'}
                    error={errors.video && errors.video?.url}
                    isTouched={touched.video && touched.video?.url}
                  />
                </div>
              )}
                {values.content_type === "Text" && (
                  <div className="relative w-full">
                    {/* <ReactQuill
                      placeholder="< />"
                      theme='snow'
                      modules={modules}
                      value={values.text_message}
                      onChange={handleChange}
                      className="resize-none w-full min-h-[140px] border-[0.5px] border-[#707070] rounded-[3px] p-1.5"
                    /> */}
                    {/* <textarea
                      name="text_message"
                      placeholder="< />"
                      value={values.text_message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="resize-none w-full min-h-[140px] border-[0.5px] border-[#707070] rounded-[3px] p-1.5"
                    >
                      {values.text_message}
                    </textarea> */}
                    <ReactQuill
                    theme="snow"
                    style={{height : "300px", marginBottom : "70px"}}
                    value={values.text_message}
                    onChange={(content) => setFieldValue('text_message', content)} // Update formik value
                 // onBlur={() => setFieldTouched('text_message', true)} // Handle formik blur
                    placeholder="< />"
                    formats={formats}
                    modules={modules}
                    className="w-full min-h-[140px] rounded-[3px] p-1.5"
                    />
                    {errors.text_message && touched.text_message && (
                    <span className="text-red-500 absolute -bottom-6 right-0">{errors.text_message}</span>
                    )}
                </div>
              )}
              <div
                className={`w-full min-h-[100px] lg:min-h-[130px] rounded-[5px] border-[1px] border-[#707070] border-dashed mx-auto flex flex-col items-center justify-center space-y-1`}
              >
                <UploadOrUpdateBucket
                  mode={mode}
                  id={"thumbnail"}
                  labelText={"Upload Thumbnail"}
                  accept={"image/*"}
                  fileSize={100 * 100}
                  sizeInText={"Image Size 100x100"}
                  Method={values.thumbnail.url==="" ? "Upload" : "Update"}
                  bucketName={"resources"}
                  forValue={handleValues}
                  fileNameData={values.thumbnail.fileName}
                  fileNameKey={values.thumbnail.storage_key}
                  preview={values.thumbnail.url}
                  index={1}
                  name={'thumbnail.url'}
                  error={errors.thumbnail && errors.thumbnail?.url}
                  isTouched={touched.thumbnail && touched.thumbnail?.url}
                />
              </div>
            </div>
            <div className="flex justify-center items-center w-4/12 mx-auto py-4">
              <button
                type="submit"
                className="bg-[#036AD1] rounded-[27px] text-white text-[20px] p-2 px-8 font-Roboto font-[400]"
              >
                {id === "new-post" ? "Submit" : "Update"}
              </button>
            </div>
          </form>
        </CardLayoutTwo>
      )}
    </>
  );
}
