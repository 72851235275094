import { Admissions } from "./Admissions";
import { Invoice } from "./Invoice";
import { Operations } from "./Operations";
import { Batch } from "./Batch";
import { SessionFeedback } from "./SessionFeedback";
import { FeedbackConcern } from "./FeedbackConcern";
import { Placement } from "./Placement";
import { useAppSelector } from "../.."; 

export const Tabs = ({ DashboardData, response }: any) => {
  const { userRoles } = useAppSelector(({ roles }: any) => roles);
  const Roles = userRoles?.selectedRoles?.[0]?.submenus;
  // console.log(Roles, "Roles");

  const checkIsValidSection = (idkey:string) => {
    const val = Roles?.filter((role: any) => (role?.idkey === idkey && role?.privileges.includes('view')))
    return val?.length ? true : false
  };

    return (
      <div className="space-y-4">
        {checkIsValidSection("admissions") && <Admissions DashboardData={DashboardData} response={response?.admission} />}
        {checkIsValidSection("invoices&revenue") && <Invoice DashboardData={DashboardData} response={response?.invoiceAnalysis} />}
        {checkIsValidSection("operations") && <Operations DashboardData={DashboardData} response={response?.operations} />}
        {checkIsValidSection("batch") && <Batch DashboardData={DashboardData} response={response?.batch} /> }
        {checkIsValidSection("session_feedback_dashboard") && <SessionFeedback DashboardData={DashboardData} response={response?.feedback} />}
        {checkIsValidSection("feedback_concern") &&<FeedbackConcern DashboardData={DashboardData} response={response?.feedback} />}
        {checkIsValidSection("placement") && <Placement DashboardData={DashboardData} response={response?.jobs} />}
      </div>
    );
}



