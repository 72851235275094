import React from 'react';
import { InputBox } from '../../../Elements/InputBox';
import toast from 'react-hot-toast';

export const InvoiceData = ({formik, setIsModalOpen}:any) => {

    const handleOpenModal = (id:any) => {
        if (formik.values.invoice_details?.gstin!=="" && formik.values.invoice_details?.name!=="" && formik.values.invoice_details?.address!=="") {
            setIsModalOpen(true);
        } else {
            toast.error("Fill the Details")
        }
    };
    
  return (
    <div className="space-y-6 py-6 px-3 shadow-sm shadow-[#00000029]">
      <div className="flex items-center gap-6">
        <InputBox
          label="GSTIN"
          placeholder="Gstin"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"gstin"}
          type={"text"}
          value={formik.values.invoice_details?.gstin}
          onChange={(e: any) =>
            formik.setFieldValue('invoice_details', { ...formik.values.invoice_details, gstin: e.target.value })
          }
          disabled={false}
        />
        <InputBox
          label="Name"
          placeholder="name"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"name"}
          type={"text"}
          value={formik.values.invoice_details?.name}
          onChange={(e: any) =>
            formik.setFieldValue('invoice_details', { ...formik.values.invoice_details, name: e.target.value })
          }
          disabled={false}
        />
        <InputBox
          label="Address"
          placeholder="address"
          topDivStyle={"flex flex-col w-full"}
          classNameInput={"w-full"}
          name={"address"}
          type={"text"}
          value={formik.values.invoice_details?.address}
          onChange={(e: any) =>
            formik.setFieldValue('invoice_details', { ...formik.values.invoice_details, address: e.target.value })
          }
          disabled={false}
        />
      </div>
      <button onClick={handleOpenModal} type="button" className="bg-green-800 text-white p-2 px-4 rounded-lg">
        Generate Invoice
      </button>
    </div>
  );
}
