import { RadioInput } from "../RadioInput";

export const ContentType = ({ classStyle, typeSelect, formik, disabled }: any) => {
  const FieldsInEachContent = [
    "External Link",
    "Upload Video",
    "Text",
    "Image",
  ];
  return (
    <div className={`flex items-center justify-around mx-auto ${classStyle}`}>
      {FieldsInEachContent.map((text, index) => {
        return (
          <RadioInput
            index={index}
            label={text}
            name={"sub_type"}
            value={formik.values.sub_type}
            onBlur={formik.handleBlur}
            classStyle={"2xl:text-[23px] lg:text-[20px] text-[19px] font-[400]"}
            onChange={typeSelect}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};
